import React, { useState } from "react";

// import "./AddNewUser.css";
import { useAuth } from "../context/Auth";

import { getRequestOptions } from "../../Utils/commonApi";
import {
  Form,
  InputGroup,
  Button,
  Spinner,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { sanitizeText } from "../../Utils/SanitizeText";

const AddNewUser = (props) => {
  const [role, setRole] = useState("CEO");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [alertDialog] = useState("");
  const { authTokens } = useAuth();
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const radios = [
    { name: "CEO", value: "CEO" },
    { name: "CTO", value: "CTO" },
    { name: "CFO", value: "CFO" },
  ];

  // const renderTooltip = (message) => (
  //   <Tooltip className="custom_tooltip">
  //     <div className="custom_tooltip_wrapper">
  //       <div className="tooltip_header border-bottom-0">
  //         <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
  //           {message}
  //         </span>
  //       </div>
  //     </div>
  //   </Tooltip>
  // );

  async function handleSendInvite(e) {
    setIsSubmit(true);

    e.preventDefault();
    if (handleValidations()) {
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
      setError(false);
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateUser`,
        getRequestOptions(authTokens, "POST", {
          EmailAddress: email,
          FirstName: firstName,
          Surname: surName,
          UserRole: role,
          AccessKey: localStorage.getItem("user_MessageObj")
            ? JSON.parse(localStorage.getItem("user_MessageObj"))
            : "null",
        })
      ).then((response) => response.json());
      setIsSubmit(false);
      if (data.Success == true) {
        setEmail("");
        setFirstName("");
        setSurName("");
        TrackUser("Team Member Added");
        let modal = document.querySelector(".modal");
        if (modal) {
          modal.click();
        }
        if (props.refreshData) {
          props.refreshData();
        }

        setTimeout(() => {
          CallToast("User created successfully", false, false, "v2style");
        }, 700);

        // setAlertDialog(
        //   <Alert
        //     variant={"success"}
        //     onClose={() => setAlertDialog("")}
        //     dismissible
        //   >
        //     {data.Message}
        //   </Alert>
        // );
      } else {
        if (data.Success == false) {
          setEmailError(data.Message);
          // setAlertDialog(
          //   <Alert
          //     variant={"warning"}
          //     onClose={() => setAlertDialog("")}
          //     dismissible
          //   >
          //     {data.Message}
          //   </Alert>
          // );
        }
      }
    }
  }
  const handleValidations = () => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email == "" || firstName == "") {
      setError("All fields are required ");
      return true;
    } else if (email && !re.test(email)) {
      setError("Invalid email address format");
      setEmailError("Invalid email address format");
      return true;
    } else {
      setError("Failed");
    }
    setIsSubmit(false);
    return false;
  };
  const message = error ? error : "";
  return (
    <React.Fragment>
      {alertDialog}
      <div className="grayBackground roundedCorners incidentResponse">
        <div style={{ paddingTop: "42px" }}>
          <div style={{ padding: "0 24px" }}>
            <div className="txt-grey">
              We will send email invintation to your new member{" "}
            </div>

            <br />
          </div>
          <div className="mt24px">
            <Form className="incidentResponseForm">
              <div style={{ padding: "0 24px" }}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Firstname:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    id=""
                    type="text"
                    onChange={(event) => setFirstName(window.sanitizeHGtext(event.target.value))}
                    required
                  />
                </InputGroup>

                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Surname:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    id=""
                    type="text"
                    onChange={(event) => setSurName(window.sanitizeHGtext(event.target.value))}
                    required
                  />
                </InputGroup>
                <InputGroup className="mt40px mb40px">
                  <div
                    style={{
                      color: "black",
                      background: "none",
                      fontWeight: "500",
                      fontSize: "14px",
                      paddingLeft: "8px",
                    }}
                  >
                    User Role
                  </div>
                  <ButtonGroup toggle className="mx-auto w-100">
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={role === radio.value}
                        onChange={(e) => setRole(e.currentTarget.value)}
                        className={`pl-0 pr-0 ${
                          idx == 0 && "border-left-rounded"
                        } ${
                          idx == radios.length - 1 && "border-right-rounded"
                        }`}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </InputGroup>
                <InputGroup
                  className={`${
                    emailError !== "" ? "input-error" : ""
                  } radius-4`}
                >
                  <InputGroup.Prepend className="mb-0">
                    <InputGroup.Text>Email:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    id="incidentSubject"
                    type="text"
                    onChange={(event) => {
                      setEmailError("");

                      setEmail(window.sanitizeHGtext(event.target.value));
                    }}
                    required
                    className="mb-0"
                  />
                </InputGroup>
                {emailError && <div className="f-danger"> {emailError} </div>}
                {/* {error && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 200, hide: 250 }}
                      overlay={renderTooltip(message)}
                    >
                      <span>
                        <img alt="" src="/images/icons/error.svg" /> Attempt
                        failed.
                      </span>
                    </OverlayTrigger>
                  </Form.Control.Feedback>
                )} */}
              </div>
              <div className="mt16px" style={{ background: "#f6f8fb" }}>
                <Button
                  className="w-100 submit-btn mt-16px"
                  type="submit"
                  onClick={(e) => handleSendInvite(e)}
                  disabled={firstName === "" || email === ""}
                >
                  {isSubmit ? (
                    <span>
                      <Spinner animation="border" variant="light" size="sm" />
                      Sending Invitation
                    </span>
                  ) : (
                    " Send Invitation"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AddNewUser;
