import React from 'react'; 
import CollapsabileTable from '../CollapsabileTable/CollapsabileTable';

const ActiveRisks = (props) => {    
    return (
        <CollapsabileTable
            pageType={"Active Risks"}
            clientId={props.clientId}
        />
    )
}

 
export default ActiveRisks;