import React, { useEffect, useState } from "react";
import SlidingModal from "../../../CustomModal/SlidingModal";
import { Button, Dropdown } from "react-bootstrap";
import "./mspPriceCalculator.scss";

import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";

import MspServiceModalToolTip from "./mspCalculatorDropdownAndModals/MspServiceModalToolTip";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import {
  all_integration_data,
  removeWhiteSpace,
} from "../../../../Utils/CommonFunctions";
import { allCountryCurrencies, allTaxList } from "./MspAddEditServiceModal";
import {
  AddEditTaxRateModal,
  CurrencyDropdown,
  TaxRateDropdown,
  deleteTaxRate,
} from "../../MspAttackSurface.js/addItemInSpendModal/AddItemInSpendModal";
import moment from "moment";
import { HGHighlightText } from "../../../App/App";
import CreateCategorySpendModal from "../../MspAttackSurface.js/CreateCategorySpendModal";

import AddSubcategoryModal from "../../MspAttackSurface.js/AddSubcategoryModal";
import {
  CustomDeleteModal,
  DeleteModal,
} from "../../../DeleteModal/DeleteModal";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import AddSnapToUnitDropdown from "./mspCalculatorDropdownAndModals/AddSnapToUnitDropdown";
const MspAddEditProductModal = ({
  show,
  hideModal,
  parentID,
  selectedProductId, // = 3960898,
  deleteProduct,
  refreshData,
  modalID,
  mapNestedModalData,
  className,
  newProductName,
  setReturnData,
  refreshService,
  serviceId,
}) => {
  // home
  // addProduct
  const [page, setPage] = useState("home");
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();

  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [categorySearchString, setCategorySearchString] = useState("");
  const [subCategorySearchString, setSubCategorySearchString] = useState("");
  const [mainState, setMainState] = useState({
    showCreateCategoryModal: false,
    showCreateSubCategoryModal: false,
  });
  const [deleteModalConfig, setDeleteModalConfig] = useState({
    show: false,
    hideModal: () => {
      setDeleteModalConfig({ ...deleteModalConfig, show: false });
    },
    deleteMessage: "You want to delete this product?",
    deleteType: "mspProduct",
    ID: selectedProductId,
    deleteFunction: deleteProduct,
  });
  const [warningModalConfig, setWarningModalConfig] = useState({
    showWarningModal: false,
  });
  // VARIABLES FOR MODAL---START
  const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  // Billing Period Variables  {
  const [billingPeriodDropdownList, setBillingPeriodDropdownList] = useState(
    []
  );

  const [snapToUnitsList, setSnapToUnitsList] = useState([]);

  // PRODUCTS DATA VARIABLES---START------
  const [productName, setProductName] = useState("");
  // const [description, setDescription] = useState("");
  const [productID, setProductID] = useState("");
  const [selectedBillingForProduct, setselectedBillingForProduct] = useState();
  const [selectedSnapToUnitForProduct, setselectedSnapToUnitForProduct] =
    useState();
  const [productCost, setProductCost] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productQuantity, setProductQuantity] = useState(1);
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  const [productCategoryList, setProductCategoryList] = useState([]);

  const [selectedProductSubCategory, setSelectedProductSubCategory] =
    useState();
  const [productSubCategoryList, setProductSubCategoryList] = useState([]);
  const [toolTipsData, setToolTipsData] = useState([]);
  const [productData, setProductData] = useState();

  const [userSelectedCurrency, setUserSelectedCurrency] = useState({});
  const [defaultCurrency, setDefaultCurrency] = useState({});
  const [currencyList, setCurrencyList] = useState(allCountryCurrencies);
  const [taxList, setTaxList] = useState(allTaxList);
  const [exchangeRateData, setExchangeRateData] = useState({});
  const [selectedTax, setSelectedTax] = useState();
  const [taxRateData, setTaxRateData] = useState({});
  const [ForeignCurrencyRate, setForeignCurrencyRate] = useState(1);
  const [disableSubcategory, setDisableSubcategory] = useState(false);
  const [productPageErrors, setProductPageErrors] = useState({});

  useEffect(() => {
    if (page === "addProduct") {
      setChanged(false);
      setProductPageErrors({});
      setDisableSubcategory(false);
      setProductName("");
      // setDescription("");
      setProductID("");
      setProductCost("");
      setselectedBillingForProduct();
      setselectedSnapToUnitForProduct();
      setProductPrice("");
      setSelectedProductCategory();
      setSelectedProductSubCategory();
      setReturnData({});
    }
  }, [page]);

  useEffect(() => {
    if (show) {
      setChanged(false);
      setPage("home");
      setDisableSubcategory(false);
      getToolTipsData();
      setLoading(true);
      getProductDetails(selectedProductId);
      if (newProductName) {
        setProductName(newProductName);
      }
    }
  }, [show]);
  useEffect(() => {
    if (!selectedBillingForProduct && !selectedSnapToUnitForProduct) {
      setProductPageErrors((prev) => ({
        ...prev,
        snapToUnitsError: true,
        billingError: true,
      }));
    } else if (!selectedBillingForProduct) {
      setProductPageErrors((prev) => ({
        ...prev,
        billingError: true,
        snapToUnitsError: false,
      }));
    } else if (!selectedSnapToUnitForProduct) {
      setProductPageErrors((prev) => ({
        ...prev,
        snapToUnitsError: true,
        billingError: false,
      }));
    } else {
      setProductPageErrors((prev) => ({
        ...prev,
        snapToUnitsError: false,
        billingError: false,
      }));
    }
  }, [productData]);

  async function getToolTipsData() {
    await getAPIData(623, authTokens, {
      optionEnum1: 0,
      optionEnum2: 0,
    }).then((response) => {
      setToolTipsData(response?.PackageHelpToolTip_List);
    });
  }

  function getToolTip(_enum = 0) {
    if (toolTipsData?.length > 0) {
      let data = [...toolTipsData];
      let itemIndex = data.findIndex(
        (item) => item?.PackageHelpToolTip_Enum === _enum
      );
      if (itemIndex === -1) {
        return {};
      } else {
        return data[itemIndex];
      }
    } else {
      return {};
    }
  }

  async function createProduct() {
    return new Promise((resolve, reject) => {
      postData(authTokens, "CreateSecurityPackProduct", {
        ObjectId: selectedProductId ?? 0,
        ProductName: productName,
        // Description: description,
        productID: productID,
        Cost: productCost,
        BillingPeriod_Text: selectedBillingForProduct?.DDLText,
        SnapToUnit_Text: selectedSnapToUnitForProduct?.DDLText,
        Price: productPrice,
        CategoryId: selectedProductCategory?.ObjectId,
        SubCategoryId: selectedProductSubCategory?.ObjectId,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        ForeignCurrencyAmount: (
          exchangeRateData?.Rate * Number(getTotalAmountForProduct() ?? 0)
        )?.toFixed(2),
        ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
        CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
        Quantity: 1, // productQuantity,
        TaxRates_Selected: JSON.stringify(selectedTax),
        IsReturnData: true,
      })
        .then((response) => {
          if (response?.ObjectTemplateList?.length > 0) {
            setReturnData(response?.ObjectTemplateList[0]);
          } else {
            setReturnData({});
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  async function getProductDetails(_productId) {
    await getAPIData(644, authTokens, {
      optionEnum1: _productId,
    })
      .then((response) => {
        setProductName(
          response?.ProductName
            ? response?.ProductName
            : newProductName
            ? newProductName
            : ""
        );
        setProductData(response);
        setSelectedProductCategory(response?.Category_Selected ?? {});
        setProductID(response?.ProductID ?? "");
        // setDescription(response?.Description ?? "");
        setProductCost(response?.Cost?.toFixed(2) ?? 0);

        setSelectedProductSubCategory(response?.SubCategory_Selected ?? {});
        setProductPrice(response?.Price_Text ?? 0);
        setBillingPeriodDropdownList(response?.BillingPeriod_List ?? []);
        setSnapToUnitsList(response?.SnapToUnit_List ?? []);
        let selected_snap_to_units = response?.SnapToUnit_List?.filter(
          (item) => item?.DDLText === response?.SnapToUnit_Text
        )?.[0];
        setselectedSnapToUnitForProduct(selected_snap_to_units ?? null);

        let selected_billing_period = response?.BillingPeriod_List?.filter(
          (item) => item?.DDLText === response?.BillingPeriod_Text
        )?.[0];
        setselectedBillingForProduct(selected_billing_period ?? null);

        // setProductQuantity(response?.Quantity ?? 1);
        // TAX SETUP
        setCurrencyList(response?.CurrencyCode_List);
        updateExchangeRateData(
          response?.CurrencyCode_Selected?.CurrencyName ??
            response?.CurrencyCode_List?.find((f) => f.IsDefault)
              ?.CurrencyName ??
            "GBP"
        );
        setDefaultCurrency(
          response?.CurrencyCode_List?.find((f) => f.IsDefault)
        );

        if (
          !response?.CurrencyCode_Selected ||
          response?.CurrencyCode_Selected.ObjectId == 0
        ) {
          setUserSelectedCurrency(
            response?.CurrencyCode_List?.find((f) => f.IsDefault)
          );
        } else {
          setUserSelectedCurrency(response?.CurrencyCode_Selected);
        }

        setTaxList(response?.TaxRates_List);
        setSelectedTax(response?.TaxRates_Selected);
        setForeignCurrencyRate(response?.ForeignCurrencyRate);

        if (!selected_billing_period && !selected_snap_to_units) {
          setProductPageErrors((prev) => ({
            ...prev,
            snapToUnitsError: true,
            billingError: true,
          }));
        } else if (!selected_billing_period) {
          setProductPageErrors((prev) => ({
            ...prev,
            billingError: true,
            snapToUnitsError: false,
          }));
        } else if (!selected_snap_to_units) {
          setProductPageErrors((prev) => ({
            ...prev,
            snapToUnitsError: true,
            billingError: false,
          }));
        } else {
          setProductPageErrors((prev) => ({
            ...prev,
            snapToUnitsError: false,
            billingError: false,
          }));
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  function updateExchangeRateData(_currency = "GBP") {
    return new Promise((resolve, reject) => {
      postData(authTokens, "GetCurrencyConverionRate", {
        FromCurrency: _currency,
        ToCurrency: defaultCurrency?.CurrencyName ?? "GBP",
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          setExchangeRateData(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  function getTaxRateList() {
    return new Promise((resolve, reject) => {
      getAPIData("774", authTokens, {
        // ObjId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          setTaxList(response?.TaxRates_List ?? []);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function updateTaxList(stoploading = false) {
    if (!stoploading) {
      setLoading(true);
    }
    getTaxRateList()
      .then((response) => {
        setTaxList(response?.TaxRates_List);
        //if selectd tax item not exist in Tax list set to default
        let selectedTaxIndex = response?.TaxRates_List?.findIndex(
          (item) => item?.ObjectId === selectedTax?.ObjectId
        );
        if (selectedTaxIndex === -1) {
          setSelectedTax(response?.TaxRates_List?.[0]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (userSelectedCurrency?.CurrencyName || defaultCurrency?.CurrencyName) {
      updateExchangeRateData(
        userSelectedCurrency?.CurrencyName ??
          defaultCurrency?.CurrencyName ??
          "GBP"
      );
    }
  }, [userSelectedCurrency]);
  function saveButtonDisabled() {
    if (page === "home") {
      if (
        productName === "" ||
        !selectedBillingForProduct ||
        productPrice === ""
      ) {
        return true;
      }
    }
  }

  useEffect(() => {
    if (show) {
      getProductCategories();

      // reset saving loadings

      setSaving(false);
    }
  }, [show]);

  async function getProductCategories() {
    await getAPIData(636, authTokens, {
      // optionEnum1: _linkId ?? selectedLinkList?.ObjectId,
    })
      .then((response) => {
        setProductCategoryList(response?.TechnologySpendDetails_List);
      })
      .catch((err) => {});
  }
  async function getProductSubCategories(_categoryId) {
    await getAPIData(627, authTokens, {
      optionEnum1: _categoryId, // _categoryId,
    })
      .then((response) => {
        setProductSubCategoryList(response?.TechnologySpendDetails_List);
      })
      .catch((err) => {});
  }

  function getTotalAmountForProduct() {
    let total = 0;

    if (productPrice !== undefined && productQuantity !== undefined) {
      const price = Number(productPrice);
      const quantity = Number(productQuantity);
      const taxRate = Number(selectedTax?.TaxRate ?? 0);

      if (!isNaN(price) && !isNaN(quantity) && !isNaN(taxRate)) {
        const taxAmount = (price * quantity * taxRate) / 100;
        total = price * quantity + taxAmount;
      } else {
        console.error(
          "Invalid input values: productPrice, productQuantity, and selectedTax.TaxRate must be valid numbers."
        );
      }
    } else {
      console.error(
        "Missing input values: productPrice, productQuantity, and selectedTax must be provided."
      );
    }

    return Number(total.toFixed(2)); // Round to 2 decimal places for accurate display
  }

  async function CheckValidationForProductsPage() {
    let errors = 0;
    if (productName === "") {
      errors += 1;
      setProductPageErrors((prev) => ({ ...prev, productNameError: true }));
    }
    if (!selectedProductCategory?.ObjectId) {
      errors += 1;
      setProductPageErrors((prev) => ({
        ...prev,
        categoryError: true,
      }));
    }
    // if (!selectedProductSubCategory?.ObjectId) {
    //   errors += 1;
    //   setProductPageErrors((prev) => ({
    //     ...prev,
    //     subcategoryError: true,
    //   }));
    // }

    if (!selectedSnapToUnitForProduct) {
      errors += 1;
      setProductPageErrors((prev) => ({
        ...prev,
        snapToUnitsError: true,
      }));
    }

    if (!selectedBillingForProduct) {
      errors += 1;
      setProductPageErrors((prev) => ({
        ...prev,
        billingError: true,
      }));
    }

    if (!productPrice) {
      errors += 1;
      setProductPageErrors((prev) => ({
        ...prev,
        priceError: true,
      }));
    }

    if (!productCost) {
      errors += 1;
      setProductPageErrors((prev) => ({
        ...prev,
        costError: true,
      }));
    }

    if (!selectedTax || !selectedTax?.TaxName || selectedTax?.TaxName == "") {
      errors += 1;
      setProductPageErrors((prev) => ({
        ...prev,
        selectedTaxError: true,
      }));
    }

    return errors;
  }
  const formatCurrency = (value) => {
    const fixedNumber = Number(value).toFixed(2);

    const [integerPart, decimalPart] = fixedNumber.split(".");

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    const result = formattedIntegerPart + "." + decimalPart;

    return result;
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [close, setClose] = useState(0);
  useEffect(() => {
    if (!changed) {
      hideModal();
      setProductPageErrors({});
      setChanged(false);
    } else {
      setWarningModalConfig((prev) => ({
        ...prev,
        showWarningModal: true,
      }));
    }
  }, [close]);

  function checkClosing() {
    setClose((prevClose) => prevClose + 1);
    console.log(close);
  }

  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id={modalID ?? "qqwertfd"}
      width={"720px"}
      onHide={() => {
        checkClosing();

        // if (!changed) {
        //   hideModal();
        //   setChanged(false);
        // } else {
        //   setWarningModalConfig((prev) => ({
        //     ...prev,
        //     showWarningModal: true,
        //   }));
        // }
      }}
      backdropZindex={11}
      className={className}
    >
      <SlidingModal.Header className="text-center d-flex p-3 border-bottom">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {selectedProductId === 0 ? "Add Product" : "Edit Product"}
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            if (!changed) {
              hideModal();
              setProductPageErrors({});
              setChanged(false);
            } else {
              setWarningModalConfig((prev) => ({
                ...prev,
                showWarningModal: true,
              }));
            }
          }}
        >
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>
      <SlidingModal.Body className={`msp-product-add-edit-modal    ${true}`}>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {page === "home" && (
              <>
                {/* Add Products header */}

                {/* Add Products Body */}
                <div className="p-3">
                  {productData?.IntegrationId > 0 ? (
                    <div className="d-flex align-items-center p-1">
                      <div
                        className="f-darkgrey"
                        style={{ padding: "4px 8px 4px 4px" }}
                      >
                        Name:
                      </div>
                      <div className="ml-1">{productName}</div>
                    </div>
                  ) : (
                    <div
                      className={`  ${
                        productPageErrors?.productNameError ? "input-error" : ""
                      } highlight-input-with-grey-border  mb-2 w-100  d-flex align-items-center p-2 radius-4 mt-2   ${true} `}
                    >
                      <div className="f-darkgrey">Name:</div>
                      <input
                        tabIndex={0}
                        type="text"
                        placeholder="Product name"
                        className="flex-grow-1 pl-1 pr-2"
                        value={productName}
                        onChange={(e) => {
                          setChanged(true);
                          setProductPageErrors((prev) => ({
                            ...prev,
                            productNameError: false,
                          }));

                          setProductName(removeWhiteSpace(e.target.value));
                        }}
                      />
                    </div>
                  )}
                  {productPageErrors?.productNameError && (
                    <div className="f-danger f-12 my-1">
                      Product Name is required
                    </div>
                  )}
                  {productData?.IntegrationId > 0 && (
                    <div className="d-flex align-items-center p-1">
                      <div
                        className="f-darkgrey"
                        style={{ padding: "4px 8px 4px 4px" }}
                      >
                        ProductID:
                      </div>
                      <div className="ml-1">{productID ?? ""}</div>
                    </div>
                  )}
                  {productData?.IntegrationId > 0 ? (
                    <div className="d-flex align-items-center p-1">
                      <div
                        className="f-darkgrey"
                        style={{ padding: "4px 8px 4px 4px" }}
                      >
                        Category:
                      </div>
                      <div className="ml-1">
                        {selectedProductCategory?.CategoryName ?? ""}
                      </div>
                    </div>
                  ) : (
                    <div className="mb-2">
                      <Dropdown
                        className="product-child-dropdown w-100"
                        onToggle={(e) => {
                          if (e) {
                            setCategorySearchString("");
                          }
                        }}
                      >
                        <Dropdown.Toggle className="w-100">
                          <div
                            className={`no-highlight-input-with-grey-border p-2 radius-4 d-flex align-items-center ${
                              productPageErrors?.categoryError
                                ? "input-error"
                                : ""
                            }`}
                          >
                            <div className="f-darkgrey">Category:</div>
                            <input
                              className="border-none bg-transparent flex-grow-1 pl-1 hide-caret"
                              type="text"
                              value={`${
                                selectedProductCategory?.CategoryName ?? ""
                              }`}
                              onChange={(e) => {}}
                              placeholder="Choose.."
                            />
                            <img
                              alt=""
                              src="/images/chevron-down.svg"
                              className="mx-2"
                            />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100 py-1">
                          <div className=" radius-4 highlight-input-with-grey-border d-flex flex-wrap p-1 mx-1">
                            <input
                              placeholder="Find or add category.."
                              type="text"
                              value={categorySearchString}
                              className="p-1"
                              onChange={(e) => {
                                setChanged(true);
                                setProductPageErrors((prev) => ({
                                  ...prev,
                                  categoryError: false,
                                }));
                                setCategorySearchString(e.target.value);
                                setDisableSubcategory(false);
                              }}
                              autoFocus
                            />
                          </div>
                          <div
                            className="transparent-scroller ml-2"
                            style={{
                              maxHeight: "300px",
                              overflowY: "auto",
                            }}
                          >
                            {[
                              {
                                title: "HighGround",
                                listing: productCategoryList.filter(
                                  (item) => item.IsPSACategory === false
                                ),
                              },
                              {
                                title: "Imported from PSA",
                                listing: productCategoryList.filter(
                                  (item) => item.IsPSACategory === true
                                ),
                              },
                            ]?.map((category, categoryIndex) => {
                              return (
                                <>
                                  {category.listing?.filter(
                                    (item) =>
                                      item?.CategoryName?.toLowerCase()?.includes(
                                        categorySearchString?.toLowerCase()
                                      ) ||
                                      item?.SellProductCategory_List?.filter(
                                        (item) =>
                                          item?.CategoryName?.toLowerCase()?.includes(
                                            categorySearchString?.toLowerCase()
                                          )
                                      )?.length > 0
                                  )?.length > 0 && (
                                    <div className="f-12 f-500 f-darkgrey my-2">
                                      {category.title}
                                    </div>
                                  )}

                                  {category.listing
                                    ?.filter(
                                      (item) =>
                                        item?.CategoryName?.toLowerCase()?.includes(
                                          categorySearchString?.toLowerCase()
                                        ) ||
                                        item?.SellProductCategory_List?.filter(
                                          (item) =>
                                            item?.CategoryName?.toLowerCase()?.includes(
                                              categorySearchString?.toLowerCase()
                                            )
                                        )?.length > 0
                                    )
                                    .map((item, itemIndex) => {
                                      return (
                                        <div
                                          className="d-flex align-items-center justify-content-between my-2 pointer pl-2"
                                          onClick={(e) => {
                                            setChanged(true);
                                            setSelectedProductCategory(item);
                                            setSelectedProductSubCategory({});
                                            setDisableSubcategory(false);
                                            setProductPageErrors((prev) => ({
                                              ...prev,
                                              categoryError: false,
                                            }));
                                            getProductSubCategories(
                                              item?.ObjectId
                                            );
                                            e.target
                                              .closest(
                                                ".product-child-dropdown"
                                              )
                                              .click();
                                          }}
                                        >
                                          <div
                                            className={`${
                                              item?.ObjectId ===
                                              selectedProductCategory?.ObjectId
                                                ? "f-grey f-500"
                                                : "f-grey"
                                            }`}
                                          >
                                            <img
                                              alt=""
                                              className=" mr-2"
                                              src="/images/msp/dropdown-grey-tick.svg"
                                              style={{
                                                visibility:
                                                  item?.ObjectId ===
                                                  selectedProductCategory?.ObjectId
                                                    ? "visible"
                                                    : "hidden",
                                              }}
                                            />

                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: HGHighlightText(
                                                  item?.CategoryName,
                                                  categorySearchString
                                                ),
                                              }}
                                            />
                                          </div>
                                          <div></div>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            })}
                          </div>

                          {categorySearchString?.length > 0 && (
                            <div className="mx-2">
                              <hr className="my-2" />
                              <div
                                className="f-14 f-500 f-blue mb-2 ml-2 pointer"
                                onClick={() => {
                                  setMainState({
                                    ...mainState,
                                    showCreateCategoryModal: true,
                                  });
                                }}
                              >
                                + Add “{categorySearchString}”
                              </div>
                            </div>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  {productPageErrors?.categoryError && (
                    <div className="f-danger f-12 my-1">
                      Category must be selected
                    </div>
                  )}

                  {productData?.IntegrationId > 0 ? (
                    <div className="d-flex align-items-center p-1">
                      <div
                        className="f-darkgrey"
                        style={{ padding: "4px 8px 4px 4px" }}
                      >
                        Subcategory:
                      </div>
                      <div className="ml-1">
                        {selectedProductSubCategory?.CategoryName ?? ""}
                      </div>
                    </div>
                  ) : (
                    <div
                      className=""
                      onClick={(e) => {
                        if (!selectedProductCategory?.ObjectId) {
                          setDisableSubcategory(true); // Toggle disableSubcategory
                          setDropdownOpen(false);
                        } else {
                          setDisableSubcategory(false);
                        }
                        if (!disableSubcategory) {
                          setDropdownOpen(true); // Open the dropdown on the second click
                        }

                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <div
                        style={{
                          pointerEvents: disableSubcategory ? "none" : "auto",
                        }}
                      >
                        <Dropdown
                          className="product-child-dropdown w-100"
                          onToggle={(e) => {
                            // e.stopPropagation();
                            if (!dropdownOpen && !disableSubcategory) {
                              // If dropdown is not supposed to be open, prevent it from opening

                              setDropdownOpen(false);
                              return false;
                            }
                            setDropdownOpen(e);
                            if (e) {
                              setSubCategorySearchString("");
                            }
                          }}
                        >
                          <Dropdown.Toggle className="w-100">
                            <div
                              className={`no-highlight-input-with-grey-border p-2 radius-4 d-flex align-items-center ${
                                productPageErrors?.subcategoryError ||
                                disableSubcategory
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <div className="f-darkgrey">Subcategory:</div>
                              <input
                                className="border-none bg-transparent flex-grow-1 hide-caret pl-1"
                                type="text"
                                value={`${
                                  selectedProductSubCategory?.CategoryName ?? ""
                                }`}
                                onChange={(e) => {}}
                                disabled={
                                  !selectedProductCategory?.CategoryName
                                }
                                placeholder="Choose.."
                              />
                              <>
                                {" "}
                                {!selectedProductCategory?.CategoryName ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mx-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/chevron-down.svg"
                                    className="mx-2"
                                  />
                                )}
                              </>
                            </div>
                          </Dropdown.Toggle>
                          {dropdownOpen && !disableSubcategory && (
                            <Dropdown.Menu className="w-100">
                              <div className=" radius-4 highlight-input-with-grey-border d-flex flex-wrap p-1 mx-1">
                                <input
                                  placeholder="Find or add subcategory.."
                                  type="text"
                                  value={subCategorySearchString}
                                  className="p-1"
                                  onChange={(e) => {
                                    setChanged(true);
                                    setDisableSubcategory(false);
                                    setSubCategorySearchString(e.target.value);
                                  }}
                                  autoFocus
                                />
                              </div>

                              <div
                                className="transparent-scroller ml-2"
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                {productSubCategoryList
                                  ?.filter((item) =>
                                    item?.CategoryName?.toLowerCase()?.includes(
                                      subCategorySearchString?.toLowerCase()
                                    )
                                  )

                                  ?.map((item, itemIndex) => {
                                    return (
                                      <div
                                        className="d-flex align-items-center justify-content-between my-2 pointer pl-2"
                                        onClick={(e) => {
                                          setChanged(true);
                                          setSelectedProductSubCategory(item);
                                          setProductPageErrors((prev) => ({
                                            ...prev,
                                            subcategoryError: false,
                                          }));
                                          e.target
                                            .closest(".product-child-dropdown")
                                            .click();
                                        }}
                                      >
                                        <div
                                          className={`${
                                            item?.ObjectId ===
                                            selectedProductSubCategory?.ObjectId
                                              ? "f-grey f-500"
                                              : "f-grey"
                                          }`}
                                        >
                                          {
                                            <img
                                              alt=""
                                              className=" mr-2"
                                              src="/images/msp/dropdown-grey-tick.svg"
                                              style={{
                                                visibility:
                                                  item?.ObjectId ===
                                                  selectedProductSubCategory?.ObjectId
                                                    ? "visible"
                                                    : "hidden",
                                              }}
                                            />
                                          }{" "}
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: HGHighlightText(
                                                item?.CategoryName,
                                                subCategorySearchString
                                              ),
                                            }}
                                          />
                                        </div>
                                        <div></div>
                                      </div>
                                    );
                                  })}
                              </div>

                              {subCategorySearchString?.length > 0 &&
                                selectedProductCategory && (
                                  <div className="mx-2">
                                    <hr className="my-2" />
                                    <div
                                      className="f-14 f-500 f-blue mb-2 ml-2 pointer"
                                      onClick={() => {
                                        setMainState({
                                          ...mainState,
                                          showCreateSubCategoryModal: true,
                                        });
                                      }}
                                    >
                                      + Add “{subCategorySearchString}”
                                    </div>
                                  </div>
                                )}
                              {productSubCategoryList?.filter((item) =>
                                item?.CategoryName?.toLowerCase()?.includes(
                                  subCategorySearchString?.toLowerCase()
                                )
                              )?.length == 0 && (
                                <div className="f-14 f-grey m-2">
                                  No subcategories found
                                </div>
                              )}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </div>

                      {productPageErrors?.subcategoryError && (
                        <div className="f-danger f-12 my-1">
                          Subcategory must be selected
                        </div>
                      )}
                      {disableSubcategory && (
                        <div className="f-danger f-12 p-1">
                          Select a category before selecting a subcategory
                        </div>
                      )}
                    </div>
                  )}

                  {productData?.IntegrationId > 0 && (
                    <div className="d-flex align-items-center p-1">
                      <div
                        className="f-darkgrey"
                        style={{ padding: "4px 8px 4px 4px" }}
                      >
                        PSA-Billing Period:
                      </div>
                      <div className="ml-1">
                        {productData?.PSABillingPeriod_Text}
                      </div>
                    </div>
                  )}
                  {productData?.IntegrationId > 0 && (
                    <div className="d-flex align-items-center p-1">
                      <div
                        className="f-darkgrey"
                        style={{ padding: "4px 8px 4px 4px" }}
                      >
                        PSA-Unit of Measurement:
                      </div>
                      <div className="ml-1">
                        {productData?.PSASnapToUnit_Text}
                      </div>
                    </div>
                  )}

                  {/* Add product Pricing Block START */}
                  <hr />
                  <div className="f-12 f-500 f-darkgrey mb-2">Product Cost</div>
                  <div className="d-flex w-100 flex-column mb-3">
                    <div className="d-flex w-100 ">
                      <div className="w-50">
                        <div className="d-flex f-500">
                          Billing period{" "}
                          <div className="msp-service-tool-tip-parent w-fit-content">
                            <img
                              className="ml-2 pointer"
                              src="/images/msp/ques-info-icon.svg"
                              alt=""
                            />
                            <MspServiceModalToolTip data={getToolTip(4)} />
                          </div>
                        </div>
                      </div>

                      <div
                        className="w-50 d-flex justify-content-between"
                        style={{}}
                      >
                        <div
                          className={`msp-service-dropdown w-fit-content radius-4 ${
                            productPageErrors?.billingError ? "input-error" : ""
                          }`}
                        >
                          <CompanySettingsDropdown
                            fieldId={"DDLId"}
                            fieldValue={"DDLText"}
                            selected={selectedBillingForProduct}
                            placeholder="Choose..."
                            //  showid={true}
                            onClick={(id, val, e, obj) => {
                              setChanged(true);
                              setProductPageErrors((prev) => ({
                                ...prev,
                                billingError: false,
                              }));
                              setselectedBillingForProduct(obj);
                              setProductData({
                                ...productData,
                                IsBillingPeriodMissing: false,
                              });
                            }}
                            data={billingPeriodDropdownList}
                          />
                        </div>
                        {(productData?.IsBillingPeriodMissing ||
                          !selectedBillingForProduct) && (
                          <div className="d-flex align-items-center f-darkgrey f-500 f-12 mr-1">
                            {" "}
                            <img
                              width={16}
                              alt=""
                              className="mr-1"
                              src="/images/msp/grey-alert.svg"
                            />
                            <div>Missing Details</div>
                          </div>
                        )}
                      </div>
                    </div>

                    {productPageErrors?.billingError && (
                      <div className="f-danger f-12 my-1">
                        Billing period must be selected
                      </div>
                    )}
                  </div>
                  <div className="d-flex w-100 flex-column mb-3">
                    <div className="d-flex w-100">
                      <div className="w-50">
                        <div className="d-flex f-500">
                          Snap to unit{" "}
                          <div className="msp-service-tool-tip-parent w-fit-content">
                            <img
                              className="ml-2 pointer"
                              src="/images/msp/ques-info-icon.svg"
                              alt=""
                            />
                            <MspServiceModalToolTip data={getToolTip(5)} />
                          </div>
                        </div>
                      </div>

                      <div
                        className="w-50 d-flex justify-content-between"
                        style={{}}
                      >
                        <div
                          className={`msp-service-dropdown w-fit-content radius-4 ${
                            productPageErrors?.snapToUnitsError
                              ? "input-error"
                              : ""
                          }`}
                        >
                          <AddSnapToUnitDropdown
                            selectionArrowAtLeft
                            // disabled={serviceModalData?.IsDisableSnapToUnit}
                            // disabledIcon={serviceModalData?.IsDisableSnapToUnit}
                            fieldId={"DDLId"}
                            fieldValue={"DDLText"}
                            selected={selectedSnapToUnitForProduct}
                            placeholder="Choose..."
                            onClick={(id, val, e, obj) => {
                              setChanged(true);
                              setProductPageErrors((prev) => ({
                                ...prev,
                                snapToUnitsError: false,
                              }));

                              setselectedSnapToUnitForProduct(obj);
                              setProductData({
                                ...productData,
                                IsSnapToUnitMissing: false,
                              });
                            }}
                            data={snapToUnitsList}
                          />
                          {/* <CompanySettingsDropdown
                            fieldId={"DDLId"}
                            fieldValue={"DDLText"}
                            selected={selectedSnapToUnitForProduct}
                            placeholder="Choose..."
                            onClick={(id, val, e, obj) => {
                              setChanged(true);
                              setProductPageErrors((prev) => ({
                                ...prev,
                                snapToUnitsError: false,
                              }));

                              setselectedSnapToUnitForProduct(obj);
                              setProductData({
                                ...productData,
                                IsSnapToUnitMissing: false,
                              });
                            }}
                            data={snapToUnitsList}
                          /> */}
                        </div>
                        {(productData?.IsSnapToUnitMissing ||
                          !selectedSnapToUnitForProduct) && (
                          <div className="d-flex align-items-center f-darkgrey f-500 f-12 mr-1">
                            {" "}
                            <img
                              width={16}
                              alt=""
                              className="mr-1"
                              src="/images/msp/grey-alert.svg"
                            />
                            <div>Missing Details</div>
                          </div>
                        )}
                      </div>
                    </div>

                    {productPageErrors?.snapToUnitsError && (
                      <div className="f-danger f-12 my-1">
                        Snap to unit must be selected
                      </div>
                    )}
                  </div>

                  {productData?.IntegrationId > 0 && (
                    <div className="w-100 flex-column   d-flex  f-black mb-3">
                      <div className="d-flex w-100 align-items-center">
                        <div className="w-50">Cost</div>
                        <div className="w-50 d-flex align-items-center ">
                          <div className="mr-1">
                            {" "}
                            {userSelectedCurrency?.CurrencySymbol ??
                              defaultCurrency?.CurrencySymbol ??
                              "£"}{" "}
                          </div>
                          <div>{productCost}</div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className="w-100 flex-column   d-flex  f-black mb-3"
                    style={{
                      pointerEvents:
                        productData?.IntegrationId > 0 ? "none" : "auto",
                    }}
                  >
                    <div className="d-flex w-100 align-items-center">
                      <div className="w-50">Price</div>
                      <div className="w-50 d-flex align-items-center ">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="d-flex align-items-center w-50">
                            <div
                              className={`${
                                productPageErrors?.priceError
                                  ? "input-error"
                                  : ""
                              }  w-45 d-flex align-items-center radius-4 highlight-input  p-2  mr-1  `}
                              style={{
                                marginLeft:
                                  productData?.IntegrationId > 0 ? "-9px" : "",
                              }}
                            >
                              <div className="mr-1">
                                {" "}
                                {userSelectedCurrency?.CurrencySymbol ??
                                  defaultCurrency?.CurrencySymbol ??
                                  "£"}{" "}
                              </div>
                              <input
                                type="number"
                                placeholder="0"
                                value={productPrice == 0 ? "" : productPrice}
                                onChange={(e) => {
                                  setChanged(true);
                                  setProductPageErrors((prev) => ({
                                    ...prev,
                                    priceError: false,
                                  }));

                                  let value = e.target.value;

                                  const regex = /^-?\d*\.?\d{0,2}$/;

                                  if (regex.test(value)) {
                                    setProductPrice(value);
                                  } else {
                                    value = value.slice(0, -1);
                                    setProductPrice(value);
                                  }
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  if (!isNaN(value)) {
                                    if (value.includes(".")) {
                                      const decimalPartLength =
                                        value.split(".")[1].length;

                                      if (decimalPartLength === 1) {
                                        setProductPrice(value + "0");
                                      } else {
                                        setProductPrice(value);
                                      }
                                    } else {
                                      setProductPrice(value + ".00");
                                    }
                                  }
                                }}
                                className="bg-transparent flex-grow-1 border-none black-placeholder w-100"
                              />
                            </div>
                            <div
                              className="mr-2"
                              style={{
                                visibility:
                                  productData?.IntegrationId > 0
                                    ? "hidden"
                                    : "visible",
                              }}
                            >
                              <CurrencyDropdown
                                currencyList={currencyList}
                                onChange={(data) => {
                                  setChanged(true);
                                  setUserSelectedCurrency(data);
                                }}
                                selected={userSelectedCurrency}
                              />
                            </div>{" "}
                          </div>
                          <div className="f-grey d-flex w-50 justify-content-end">
                            {" "}
                            <span className="mr-2">
                              {selectedSnapToUnitForProduct?.DDLColour ??
                                "/unit"}
                            </span>
                            {"   "}/
                            {selectedBillingForProduct?.DDLColour ?? "period"}
                          </div>{" "}
                        </div>
                      </div>
                    </div>

                    {productPageErrors?.priceError && (
                      <div className="f-danger f-12 my-1">
                        Price is required
                      </div>
                    )}
                  </div>


                  <div
                    className="w-100 flex-column   d-flex  f-black mb-3"
                    style={{
                      pointerEvents:
                        productData?.IntegrationId > 0 ? "none" : "auto",
                    }}
                  >
                    <div className="d-flex w-100 align-items-center">
                      <div className="w-50">Cost</div>
                      <div className="w-50 d-flex align-items-center ">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="d-flex align-items-center w-50">
                            <div
                              className={`${
                                productPageErrors?.costError
                                  ? "input-error"
                                  : ""
                              }  w-45 d-flex align-items-center radius-4 highlight-input  p-2  mr-1  `}
                              style={{
                                marginLeft:
                                  productData?.IntegrationId > 0 ? "-9px" : "",
                              }}
                            >
                              <div className="mr-1">
                                {" "}
                                {userSelectedCurrency?.CurrencySymbol ??
                                  defaultCurrency?.CurrencySymbol ??
                                  "£"}{" "}
                              </div>
                              <input
                                type="number"
                                placeholder="0"
                                value={productCost == 0 ? "" : productCost}
                                onChange={(e) => {
                                  setChanged(true);
                                  setProductPageErrors((prev) => ({
                                    ...prev,
                                    costError: false,
                                  }));

                                  let value = e.target.value;

                                  const regex = /^-?\d*\.?\d{0,2}$/;

                                  if (regex.test(value)) {
                                    setProductCost(value);
                                  } else {
                                    value = value.slice(0, -1);
                                    setProductCost(value);
                                  }
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  if (!isNaN(value)) {
                                    if (value.includes(".")) {
                                      const decimalPartLength =
                                        value.split(".")[1].length;

                                      if (decimalPartLength === 1) {
                                        setProductCost(value + "0");
                                      } else {
                                        setProductCost(value);
                                      }
                                    } else {
                                      setProductCost(value + ".00");
                                    }
                                  }
                                }}
                                className="bg-transparent flex-grow-1 border-none black-placeholder w-100"
                              />
                            </div>
                            <div
                              className="mr-2"
                              style={{
                                visibility:
                                  productData?.IntegrationId > 0
                                    ? "hidden"
                                    : "visible",
                              }}
                            >
                              <CurrencyDropdown
                                currencyList={currencyList}
                                onChange={(data) => {
                                  setChanged(true);
                                  setUserSelectedCurrency(data);
                                }}
                                selected={userSelectedCurrency}
                              />
                            </div>{" "}
                          </div>
                          <div className="f-grey d-flex w-50 justify-content-end">
                            {" "}
                            <span className="mr-2">
                              {selectedSnapToUnitForProduct?.DDLColour ??
                                "/unit"}
                            </span>
                            {"   "}/
                            {selectedBillingForProduct?.DDLColour ?? "period"}
                          </div>{" "}
                        </div>
                      </div>
                    </div>

                    {productPageErrors?.costError && (
                      <div className="f-danger f-12 my-1">
                        Cost is required
                      </div>
                    )}
                  </div>

                  <div
                    className="w-100 mb-3 d-flex  f-black flex-column"
                    style={{
                      // display: (productData?.IntegrationId > 0  ) ? "none" : "flex"
                      pointerEvents:
                        productData?.IntegrationId > 0 ? "none" : "auto",
                    }}
                  >
                    <div className="d-flex w-100 align-items-center">
                      <div className="w-50">Tax Rate</div>
                      <div className="w-50 d-flex align-items-center justify-content-between">
                        {productData?.IntegrationId > 0 ? (
                          <div className="f-14 f-black" style={{ padding: "" }}>
                            {selectedTax?.TaxName ?? "No tax rate"}
                          </div>
                        ) : (
                          <div
                            style={{
                              background: "#f6f8fb",
                              padding: "8px 16px",
                            }}
                            className={`radius-4 ${
                              productPageErrors?.selectedTaxError
                                ? "input-error"
                                : ""
                            }`}
                          >
                            <TaxRateDropdown
                              deleteTaxRate={deleteTaxRate}
                              updateTaxList={updateTaxList}
                              onChange={(data) => {
                                setChanged(true);
                                setSelectedTax(data);
                                setProductPageErrors((prev) => ({
                                  ...prev,
                                  selectedTaxError: false,
                                }));
                              }}
                              taxList={taxList}
                              selected={selectedTax}
                              onClickEditTaxRate={(data) => {
                                setChanged(true);
                                setTaxRateData(data);
                                setShowAddEditTaxRateModal(true);
                              }}
                              onClickAddTaxRate={() => {
                                setChanged(true);
                                setTaxRateData({
                                  ObjectId: 0,
                                  TaxCode: "",
                                  TaxName: "",
                                  TaxRate: "",
                                });
                                setShowAddEditTaxRateModal(true);
                              }}
                            />
                          </div>
                        )}

                        <div className="d-flex align-items-center radius-4 f-grey">
                          {selectedTax?.TaxRate ?? 0}% ={" "}
                          {userSelectedCurrency?.CurrencySymbol ??
                            defaultCurrency?.CurrencySymbol ??
                            "£"}{" "}
                          {Number(
                            ((productPrice ?? 0) *
                              (productQuantity ?? 0) *
                              (selectedTax?.TaxRate ?? 0)) /
                              100 ?? 0
                          )?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    {productPageErrors?.selectedTaxError && (
                      <div className="f-danger f-12 my-1">
                        Tax Rate must be selected
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center w-100 mb-3 mt-4">
                    <div className="w-50">
                      <div className="d-flex f-500">Total </div>
                    </div>

                    <div className="w-50 d-flex f-500 f-black " style={{}}>
                      {getTotalAmountForProduct() === 0 ||
                      !userSelectedCurrency?.CurrencySymbol ||
                      userSelectedCurrency?.CurrencySymbol ===
                        defaultCurrency?.CurrencySymbol ? (
                        <>
                          {getTotalAmountForProduct() === 0 ? (
                            <div className="f-600 f-darkgrey">
                              {userSelectedCurrency?.CurrencySymbol ??
                                defaultCurrency?.CurrencySymbol}{" "}
                              0.00
                            </div>
                          ) : (
                            <div className="f-600 f-black">
                              {userSelectedCurrency?.CurrencySymbol ??
                                defaultCurrency?.CurrencySymbol}{" "}
                              {formatCurrency(
                                Number(getTotalAmountForProduct() ?? 0)
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div>
                            {userSelectedCurrency?.CurrencySymbol ??
                              defaultCurrency?.CurrencySymbol ??
                              "£"}{" "}
                            {formatCurrency(
                              Number(getTotalAmountForProduct() ?? 0)
                            )}
                          </div>
                          <AttackSurfaceTooltip
                            bottomLeft={true}
                            topLeft={false}
                            content={
                              <span>
                                <img
                                  alt=""
                                  width={13}
                                  height={13}
                                  src="/images/msp/white-exchange-rate-icon.svg"
                                  className="mr-1"
                                />{" "}
                                The exchange rate on{" "}
                                {moment(exchangeRateData?.Date ?? "")?.format(
                                  "DD MMM YYYY"
                                )}{" "}
                                is 1 {exchangeRateData?.FromCurrency} ={" "}
                                {selectedProductId === 0
                                  ? exchangeRateData?.Rate
                                  : ForeignCurrencyRate}{" "}
                                {defaultCurrency?.CurrencyName ?? "GBP"}{" "}
                              </span>
                            }
                          >
                            <img
                              alt=""
                              width={15}
                              height={15}
                              src="/images/msp/exchange-rate-icon.svg"
                              className="mr-1"
                            />
                          </AttackSurfaceTooltip>
                          {/* <img
                            width={15}
                            height={15}
                            src="/images/msp/exchange-rate-icon.svg"
                            alt=""
                          /> */}
                          <div className="">
                            {" "}
                            £{" "}
                            {formatCurrency(
                              exchangeRateData?.Rate *
                                Number(getTotalAmountForProduct() ?? 0)
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Add product Pricing Block STOP */}
                </div>
              </>
            )}
          </>
        )}
        <div
          className={`add-edit-tax-rate-modal-wrapper ${
            showAddEditTaxRateModal ? "show" : ""
          }`}
        >
          <AddEditTaxRateModal
            show={showAddEditTaxRateModal}
            hideModal={() => {
              setShowAddEditTaxRateModal(false);
            }}
            taxRateData={taxRateData}
            updateTaxList={updateTaxList}
            deleteTaxRate={deleteTaxRate}
          />
        </div>

        <CreateCategorySpendModal
          show={mainState?.showCreateCategoryModal}
          categoryName={categorySearchString}
          hideModal={() => {
            setMainState({ ...mainState, showCreateCategoryModal: false });
          }}
          refreshData={(categoryId) => {
            getProductCategories();
            getProductSubCategories(categoryId);
          }}
          mapProductCategory={(_categoryData) => {
            setSelectedProductCategory(_categoryData);
          }}
        />
        <AddSubcategoryModal
          show={mainState?.showCreateSubCategoryModal}
          hideModal={() => {
            setMainState({ ...mainState, showCreateSubCategoryModal: false });
          }}
          parentCategoryId={selectedProductSubCategory?.ObjectId}
          colorList={[]}
          subCategoryName={subCategorySearchString}
          refreshData={() => {}}
          mapProductSubCategory={(_subCategoryData) => {
            setSelectedProductSubCategory(_subCategoryData);
          }}
        />
        <DeleteModal
          show={deleteModalConfig.show}
          hideModal={() => {
            deleteModalConfig.hideModal();
            hideModal();
            if (refreshData) {
              refreshData();
            }
          }}
          deleteMspFunction={deleteModalConfig.deleteFunction}
          ID={deleteModalConfig.ID}
          deleteMessage={deleteModalConfig.deleteMessage}
          deleteType={deleteModalConfig.deleteType}
        />
        <CustomDeleteModal
          show={warningModalConfig?.showWarningModal}
          deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
          hideModal={() => {
            setWarningModalConfig((prev) => ({
              ...prev,
              showWarningModal: false,
            }));
          }}
          deleteButtonClass={"hg-blue-btn"}
          deleteButtonText={"Close"}
          deleteHeaderText={"Warning"}
          deleteBodyText={"Closing this modal will remove all changes."}
          deleteFooterText={"Are you sure you want to close this modal?"}
          deleteFunction={() => {
            // refreshData();
            hideModal();
          }}
          deleteParams={{}}
          deleteType={"warningClose"}
        />
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <div
          className={`  ${
            // productData?.IntegrationId > 0 || loading ? "d-none" :
            "d-flex"
          }    align-items-center justify-content-between`}
        >
          {productData?.IntegrationId > 0 ? (
            <div className="d-flex align-items-center">
              {productData?.IntegrationId > 0 && (
                <img
                  src={`/images/attack-surface/integrations/${productData?.IntegrationId}.webp`}
                  alt=""
                  className="rounded-full mr-1"
                  width={16}
                  height={16}
                  style={{}}
                />
              )}
              <div className="d-flex align-items-center f-500 f-darkgrey">
                {" "}
                {productData?.IntegrationName}
              </div>
            </div>
          ) : (
            <div
              style={{
                visibility:
                  productData?.IntegrationId > 0 || loading
                    ? "hidden"
                    : "visible",
              }}
            >
              {selectedProductId > 0 && (
                <div
                  className="d-flex align-items-center f-500 f-grey pointer"
                  onClick={() => {
                    setChanged(true);
                    setDeleteModalConfig({
                      ...deleteModalConfig,
                      show: true,
                      ID: selectedProductId,
                    });
                  }}
                >
                  <img
                    alt=""
                    className="mx-2"
                    src="/images/actioncenter/delete.svg"
                  />
                  {deleting ? "Deleting..." : "Delete"}
                </div>
              )}
            </div>
          )}

          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                if (!changed) {
                  hideModal();
                  setProductPageErrors({});
                  setChanged(false);
                } else {
                  setWarningModalConfig((prev) => ({
                    ...prev,
                    showWarningModal: true,
                  }));
                }
              }}
            >
              Cancel
            </Button>
            <Button
              // disabled={saveButtonDisabled()}
              className="hg-blue-btn"
              onClick={async () => {
                if (page === "home") {
                  let errors = await CheckValidationForProductsPage();

                  if (errors > 0) {
                    let _modal = document.querySelector(
                      ".msp-product-add-edit-modal"
                    );

                    let top_error =
                      _modal.getElementsByClassName("input-error")[0];
                    if (top_error) {
                      top_error.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "end",
                      });
                    }
                  } else {
                    setSaving(true);
                    createProduct()
                      .then(() => {
                        setChanged(false);
                        setSaving(false);
                        setPage("home");
                        hideModal();
                        setProductPageErrors({});
                        if (refreshData) refreshData();
                        if (refreshService && serviceId)
                          refreshService(serviceId);
                      })
                      .catch((err) => {
                        setSaving(false);
                      });
                  }
                }
              }}
            >
              {saving ? (
                <>
                  {/* <WhiteLoader width={13} /> */}
                  Saving..
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </SlidingModal.Footer>
    </SlidingModal>
  );
};

export default MspAddEditProductModal;
