import React, { Component } from "react";
import "./Notification.css";
import { Row, Col, Spinner } from "react-bootstrap";
import CC from "../CyberCompliance/CyberCompliance";
import ThreatLevel from "../ThreatLevel/ThreatLevel";
import CyberScore from "../CyberScore/CyberScore";
import ClipLoader from "react-spinners/ClipLoader";
import { getRequestOptions } from "../../Utils/commonApi";
import { getAPIData } from "../../Utils/commonApi";

const hexRgb = require("hex-rgb");
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: props.heading,
      text: props.text,
      color: props.color,
      type: props.type,
      value: props.value,
      time: props.time ? props.time : "",
      notificationResponse: false,
      sending: false,
      role: props.role,
      company: props.company,
      index: props.index,
      device: props.device,
      authTokens: window.localStorage.getItem("tokens").replaceAll('"', ""),
    };
    this.sendNotification = this.sendNotification.bind(this);
  }
  componentWillReceiveProps(props) {
    this.setState({
      heading: props.heading,
      text: props.text,
      color: props.color,
      type: props.type,
      value: props.value,
      notificationResponse: false,
      role: props.role,
      index: props.index,
      company: props.company,
      device: props.device,
    });
  }

  // async sendNotification() {
  //     this.setState({ sending: true, notificationResponse: "Notification is being sent. Please wait..." });
  //     const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/CC_API/SendDemoNotification?orgid=${this.state.company}&roleId=${this.state.role}&selection=${this.state.index}`, getRequestOptions(this.state.authTokens)).then(response => response.json());
  //     setTimeout(() => {
  //         this.setState({sending: false, notificationResponse: data.Message})
  //     }, 200)
  // }
  async sendNotification() {
    const result = await getAPIData(104, this.state.authTokens, {
      optionEnum1: this.state.device,
      optionEnum2: this.state.index,
    });
    if (result.Success == true) {
      this.setState({ notificationResponse: result.Message });
    } else {
      this.setState({ notificationResponse: "Notification Pending" });
    }
  }
  render() {
    const colour = hexRgb(this.state.color, { format: "object" });
    return (
      <React.Fragment>
        <div
          className="notification"
          style={{
            background: this.state.sending
              ? `rgba(${colour.red}, ${colour.green}, ${colour.blue}, 0.4)`
              : this.state.color,
          }}
        >
          <div className="notificationBody">
            <Row>
              <Col xs={9} className="notificationText">
                <span className="logoContainer">
                  <img src="/images/logo/Favicon-m3.svg" />{" "}
                  <span className="main-heading">HIGH GROUND</span>
                </span>
                <h2>{this.state.heading}</h2>
                <p>{this.state.text}</p>
              </Col>
              <Col xs={3} className="notificationIcon">
                {/* <div className="greyText">{this.state.time}</div> */}
                {this.state.type.toLowerCase() == "compliancetab" ? (
                  <CC
                    key={this.state.value}
                    ccNumber={this.state.value}
                    whpx={50}
                    wh={50}
                    dataStroke={6}
                    fontSize={9}
                  />
                ) : this.state.type.toLowerCase() == "threattab" ? (
                  <ThreatLevel
                    key={this.state.value}
                    threatNumber={this.state.value}
                    threatHeight={"50px"}
                    removeBottom={true}
                    removeText={true}
                    threatWidth={"20px"}
                  />
                ) : this.state.type.toLowerCase() == "scoretab" ? (
                  <CyberScore
                    key={this.state.value}
                    scoreNumber={this.state.value}
                    Width={50}
                    Height={50}
                    bottomText={false}
                    dataStroke={7}
                    hideLabels={true}
                    fontSize={11}
                    position={"absolute"}
                    margin="3em 2em 6px 11px"
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          <div className="notificationSend" onClick={this.sendNotification}>
            {this.state.sending ? (
              <ClipLoader size={30} color={this.state.color} />
            ) : (
              <img src="/images/icons/plane.svg" />
            )}
          </div>
        </div>
        {this.state.notificationResponse ? (
          <p className="notificationSubMessage">
            {this.state.notificationResponse}
          </p>
        ) : (
          ""
        )}
        <hr />
      </React.Fragment>
    );
  }
}

export default Notification;
