import React, { Fragment, useMemo } from "react";

import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Tab,
  Nav,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Header from "../Header";
import SecurityPacksModal from "./modals/SecurityPacksModal";
import QuestionnaireModal from "./modals/QuestionnaireModal";
import PrintSendModal from "./modals/PrintSendModal";
import SubmitQuoteModal from "./modals/SubmitQuoteModal";
import Scenario from "./Scenario";
import CreateScenario from "./CreateScenario";
import "./modals/style.scss";

import { Radar } from "react-chartjs-2";
import ContentLoader from "react-content-loader";
import AssetModal from "./modals/AssetModal";
import UpdateScenarioModal from "./modals/UpdateScenarioModal";
import {
  CreateNewScenario,
  getAPIData,
  getScenarioPacks,
  postRequest as UpdateScenarioName,
} from "../../Utils/commonApi";
import { AuthContext } from "../context/Auth";
import { initial_Tab_Data } from "./Data";
import { CallToast } from "./modals/CustomToast";
import ToastMessage from "../ToastMessage";
import SkipWelcomeModal from "./modals/SkipWelcomeModal";

import { toast, ToastContainer } from "react-toastify";
import CircularProgressBar from "../CircularProgressBar";
import { FiCheck } from "react-icons/fi";
import QuestionaireModal from "./modals/QuestionaireModal";

import { TrackUser } from "../../Utils/SegmentFunctions";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import MspClientPriceCalculator from "../MSPv2/mspClientPriceCalculator/MspClientPriceCalculator";
import { getMenuItemId } from "../SideNav/SideNav";
import { NoAccessUI, viewOnlyAccess } from "../App/App";

// Start Adding color to localStorage scenarios scenarios
class PriceCalculator extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      reDrawGraph: true,
      colors: [
        "#008FD4",
        "#5244B3",
        "#40D88E",
        "#F36060",
        "#DF6844",
        "#99D2EE",
        "#BAB4E1",
        "#B3EFD2",
        "#FABFBF",
        "#F2C3B4",
      ],
      dummyDataSet: [],
      currentPosture: {},
      ScenarioIndex: 0,
      scenarioLoadingText: "Loading Scenarios..",
      showDeleteModal: false,
      isSavingName: false,
      newScenarioToast: false,
      skipmodal: false,
      autoSelected: undefined,
      testComplete: true,
      showError: false,
      newScenarioCreating: false,
      dummyData: {},
      scenarioData: [],
      showToast: false,
      showQuestionModal: false,
      assetModalValues: {
        users: "",
        workstations: "",
        servers: "",
        mobiledevices: "",
        domains: "",
      },
      showAssetModal: false,
      searchScenario: "",
      updateScenarioModal: false,
      activeTabPosture: [],
      test: false,
      activeTab: 0,
      isLoading: true,
      currentScenario: 1,
      scenarioName: "",
      nameIsEdit: false,
      staticData: {
        objId_Scenario: 0,
        Name: "Untitled",
        Created: "0001-01-01T00:00:00",
        Expiration: "2022-04-28T00:00:00",
        Expired: false,
        Cost: 0,
        QuoteRequested: false,
        BillingPeriod: 0,
        InspectScore: 0,
        ProtectScore: 0,
        DetectScore: 0,
        RespondScore: 0,
        RecoverScore: 0,
        UserAndDeviceCount: {
          NoOfUsers: 0,
          NoOfWorkstation: 0,
          NoOfServices: 0,
          NoOfMobileDevices: 0,
          NoOfDomains: 0,
        },
        SecurityPack_List: null,
        NameEdit: false,
      },
      chartList: [
        {
          title: "Your current posture",
          amt: "£145/mo",
          cc: 10,
          cs: 50,
          clr: "#FFAC2C",
          txt: "Current Scores",
        },
        {
          title: "Name of Scenario",
          amt: "£355/mo",
          cc: 85,
          cs: 45,
          clr: "#008FD4",
          txt: "Predited Scores",
        },
        {
          title: "My Scenario",
          amt: "£935/mo",
          cc: 15,
          cs: 33,
          clr: "#40D88E",
          txt: "Predited Scores",
        },
      ],
      quesModal: false,
      secModal: false,
      users: "",
      devices: "",
      domains: "",
      workstations: "",
      activePosture: false,
      scenario: false,
      printModal: false,
      quoteModal: false,
      printState: "Send",
      labels: [],
      datasets: [],
      servers: "",
      postures: [
        {
          icon: "/images/calculator/sub.svg",
          title: "Capture from Highground",
          select: true,
        },
        {
          icon: "/images/calculator/quest.svg",
          title: "Quick Questionnaire",
          select: false,
        },
        {
          icon: "/images/calculator/cross.svg",
          title: "Don't plot security posture",
          select: false,
        },
      ],
      tabs: initial_Tab_Data,
      menuItemData: (JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List)?.
        find(obj => obj?.MenuItemId === parseInt(getMenuItemId(window.location.pathname))),
      userPortalRole: JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role,
      accessState: window.localStorage.getItem("object_Data") ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null,
      emailValue: "",
      emailSaved: false,
      userRole: "saas",
    };
  }

  // To Control the Redraw Issue

  DrawGraph(value) {
    this.setState({ reDrawGraph: value });
  }
  // function to update Outdated price of scenarios

  async updateOutdatedPrice(scenarioId) {
    let updated_data = [...this.state.tabs];
    let Index = updated_data.findIndex(
      (item) => item?.ScenarioHeader?.objId_Scenario === scenarioId
    );
    updated_data[Index].updatingPrice = true;
    this.setState({ tabs: updated_data });

    await getAPIData(226, this.context.authTokens, {
      optionEnum1: scenarioId || 3331025,
    })
      .then((response) => {
        let updated_data = [...this.state.tabs];
        let Index = updated_data.findIndex(
          (item) => item?.ScenarioHeader?.objId_Scenario === scenarioId
        );
        updated_data[Index].updatingPrice = false;
        updated_data[Index].ScenarioHeader.Expired = false;
        this.setState({ tabs: updated_data });
        if (response.Success) {
          this.refreshScenarioList();
          CallToast("Scenario price updated successfully");
        }
      })
      .catch((err) => {
        let updated_data = [...this.state.tabs];
        let Index = updated_data.findIndex(
          (item) => item?.ScenarioHeader?.objId_Scenario === scenarioId
        );
        updated_data[Index].updatingPrice = false;
        this.setState({ tabs: updated_data });
        console.log(err);
        CallToast("Something went wrong");
      });
  }

  // LOAD THE CHART AFTER QUESTIONAIRE MODAL SUBMITTED
  async IntialLoadChart(authtoken, SetValues) {
    const response = await getAPIData(211, this.context.authTokens);

    if (response?.mr?.Success) {
      this.setState({ scenarioData: response });
      // this.setState({ test: false });
      this.loadChart();
      // this.setState({ test: true });
    } else {
      // CallToast("Something went wrong");
      this.setState({ showError: true });
      setTimeout(() => this.setState({ showError: false }), 3000);
    }
  }

  async getAllScenarioList() {
    //--------------------------------------------------------------
    const response = await getAPIData(211, this.context.authTokens);

    if (response?.mr?.Success) {
      this.setState({ scenarioData: response });
      this.setState({ currentPosture: response?.CurrentPosture });
      this.loadChart();
      if (this.state?.scenarioData?.ShowModals === true) {
        this.setState({ secModal: true });
      }
    } else {
      // CallToast("Something went wrong");
      this.setState({ showError: true });
      setTimeout(() => this.setState({ showError: false }), 3000);
    }
  }

  async refreshScenarioList() {
    const response = await getAPIData(211, this.context.authTokens);

    if (response?.mr?.Success) {
      this.setState({ scenarioData: response });
      this.DrawGraph(true);
    } else {
      this.setState({ showError: true });
      setTimeout(() => this.setState({ showError: false }), 3000);
    }
  }
  async getUserRole() {
    const response = await getAPIData(684, this.context.authTokens);

    if (response?.Success) {
      this.setState({ userRole: "saas" });
    } else {
      this.setState({ userRole: "msp" });
      // setTimeout(() => this.setState({ showError: false }), 3000);
    }
  }

  async inviteToMSP() {
    this.setState({ emailSaving: true });
    const response = await getAPIData(686, this.context.authTokens, {
      optionStrEnum1: this.state.emailValue,
    });

    if (response?.Success) {
      this.setState({ emailSaved: true, emailValue: "", emailSaving: false });
    } else {
      this.setState({ emailSaved: false });
      setTimeout(
        () => this.setState({ showError: false, emailSaving: false }),
        3000
      );
    }
  }

  async updateScenarioData(scenarioId, activeTabIndex) {
    await getScenarioPacks(this.context.authTokens, 213, scenarioId)
      .then((response) => {
        if (response?.mr?.Success) {
          let all_tabs = [...this.state.tabs];
          let new_dataset = [...this.state.datasets];
          new_dataset[activeTabIndex].data = [
            response?.ScenarioHeader?.InspectScore > 35
              ? 35
              : response?.ScenarioHeader?.InspectScore,
            response?.ScenarioHeader?.ProtectScore > 35
              ? 35
              : response?.ScenarioHeader?.ProtectScore,
            response?.ScenarioHeader?.DetectScore > 35
              ? 35
              : response?.ScenarioHeader?.DetectScore,
            response?.ScenarioHeader?.RespondScore > 35
              ? 35
              : response?.ScenarioHeader?.RespondScore,
            response?.ScenarioHeader?.RecoverScore > 35
              ? 35
              : response?.ScenarioHeader?.RecoverScore,
          ];

          for (let i = 0; i < all_tabs.length; i++) {
            if (all_tabs[i]?.ScenarioHeader?.objId_Scenario === scenarioId) {
              //  let updated_tab= {...all_tabs[i]}
              //  updated_tab.ScenarioHeader={
              //   ...updated_tab?.ScenarioHeader,

              //  }
              all_tabs[i].ScenarioHeader = response?.ScenarioHeader;
            }
          }
          this.setState({ datasets: new_dataset, tabs: all_tabs });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async componentDidMount() {
    // this.loadChart();
    // await this.getScenarioListData();
    let openedScenarios = window.localStorage.getItem("openedScenarios");
    if (!openedScenarios) {
      window.localStorage.setItem("openedScenarios", JSON.stringify([]));
    }

    this.getAllScenarioList();
    this.getUserRole();
  }

  loadChart() {
    if (this.state.scenarioData?.CurrentPostureExists) {
      let all_datasets = [...this.state.datasets];
      all_datasets[0] = {
        data: [
          this.state.scenarioData?.CurrentPosture?.Identity,
          this.state.scenarioData?.CurrentPosture?.Protect,
          this.state.scenarioData?.CurrentPosture?.Detect,
          this.state.scenarioData?.CurrentPosture?.Respond,
          this.state.scenarioData?.CurrentPosture?.Recover,
        ],
        datalabels: {
          display: false,
        },
        backgroundColor: "rgba(255, 172, 44, 0.1)",
        borderColor: "rgba(255, 172,44, 1)",
        pointBackgroundColor: "rgba(255, 172,44, 1)",
        borderWidth: 2,
        borderDash: [8],
        pointLabelFontSize: 0,
        scaleShowLabels: false,
        label: "Current Posture",
        // pointRadius: 0
      };
      setTimeout(() => {
        this.setState({
          test: true,
          datasets: all_datasets,
        });
      }, 100);
      setTimeout(() => {
        let openedScenarios = JSON.parse(
          window.localStorage.getItem("openedScenarios")
        );
        if (openedScenarios?.length > 0) {
          this.CreateScenarioOnLoading(openedScenarios);
        }

        this.setState({
          isLoading: false,
        });
      }, 1000);
    } else {
      let all_datasets = [...this.state.datasets];
      all_datasets[0] = {
        data: [20, 20, 20, 20, 20],
        datalabels: {
          display: false,
        },
        backgroundColor: "rgba(187, 190, 201, 0.1)",
        borderColor: "rgba(187, 190, 201, 1)",
        pointBackgroundColor: "rgba(187, 190, 201, 1)",
        borderWidth: 2,
        borderDash: [8],
        pointLabelFontSize: 0,
        scaleShowLabels: false,
        label: "Current Posture",
        // pointRadius: 0
      };

      setTimeout(
        () =>
          this.setState({
            test: true,
            datasets: all_datasets,
          }),
        100
      );
      setTimeout(() => {
        let openedScenarios = JSON.parse(
          window.localStorage.getItem("openedScenarios")
        );
        if (openedScenarios?.length > 0) {
          this.CreateScenarioOnLoading(openedScenarios);
        }

        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  }
  showNewScenarioToast = () => {
    var time = setInterval(() => {
      this.setState({ newScenarioToast: true });

      toast.dark(
        <span className="d-flex">
          <FiCheck style={{ marginRight: "5px" }} />
          <div className="text-center">
            Hang on, it's taking longer than usual to process your request,
            please wait...
          </div>
          <span className="float-right">
            <CircularProgressBar durationInSec="30" />
          </span>
        </span>
      );

      // setTimeout(() => this.setState({ newScenarioToast: false }), 3000);
      return clearInterval(time);
    }, 8000);
  };
  closeAssetModal = () => {
    this.setState({ showAssetModal: false /*test: false*/ });
    // setTimeout(() => this.setState({ test: true }), 2000);
  };
  openAssetModal = () => this.setState({ showAssetModal: true });
  handlePosture = (index) => {
    const { postures } = this.state;
    postures.map((item, k) => {
      if (k == index) {
        item.select = true;
      } else {
        item.select = false;
      }
      if (index == 1) {
        this.setState({ quesModal: true });
      }
      if (index == 0) {
        this.setState({ secModal: true });
      }
    });
    this.setState({ postures: postures });
  };

  hideQtyUsers = (e) => {
    e.preventDefault();
    this.setState({ activePosture: false });
  };
  removeScenario = (key) => {
    let arr = [...this.state.tabs];

    arr.splice(key, 1);

    this.setState({ tabs: arr });

    this.setState({ test: false });
    const datasets = [...this.state.datasets];
    datasets.splice(key, 1);

    setTimeout(() => {
      const navItem = document.querySelectorAll(".navlinks");

      if (navItem.length == 1) {
        navItem[0].click();
      } else {
        navItem[navItem.length - 1].click();
      }
      this.setState({ datasets, test: true });
    }, 1);
  };

  // Auto load scenario
  // Auto load scenario
  // Auto load scenario
  // Auto load scenario
  LoadData = async (objectIds) => {
    if (this.state.ScenarioIndex >= objectIds.length) {
      this.setState({ newScenarioCreating: false });
      let allscenariobtn = document.querySelector(".all-scenario-navlink");
      allscenariobtn.click();

      return false;
    }
    if (this.state.ScenarioIndex >= objectIds.length) return;

    await getScenarioPacks(
      this.context.authTokens,
      213,
      objectIds[this.state.ScenarioIndex]?.id
    )
      .then((res) => {
        if (!res?.mr?.Success) {
          CallToast("Something went wrong");
          this.setState({ newScenarioCreating: false });
          return false;
        }
        if (res?.mr?.Success) {
          if (
            res?.Nist_Identify == null ||
            res?.Nist_Detect == null ||
            res?.Nist_Protect == null ||
            res?.Nist_Recover == null ||
            res?.Nist_Respond == null
          ) {
            this.setState({ newScenarioCreating: false });
            CallToast("Something went wrong");
            return false;
          }
        }
        let dummyData = {
          PackList: [
            res?.Nist_Identify,
            res?.Nist_Protect,
            res?.Nist_Detect,
            res?.Nist_Respond,
            res?.Nist_Recover,
          ],
          ScenarioHeader: res?.ScenarioHeader,
          SecurityPackList: res?.SecurityPackList,
          SecurityPack_CurrentSelections_List:
            res?.SecurityPack_CurrentSelections_List,
        };

        this.setState({ dummyData });

        const InspectScore = res?.ScenarioHeader?.InspectScore;
        const ProtectScore = res?.ScenarioHeader?.ProtectScore;
        const DetectScore = res?.ScenarioHeader?.DetectScore;
        const RespondScore = res?.ScenarioHeader?.RespondScore;
        const RecoverScore = res?.ScenarioHeader?.RecoverScore;

        let scenarioData = {
          InspectScore,
          ProtectScore,
          DetectScore,
          RespondScore,
          RecoverScore,
        };

        let arr = [...this.state.tabs];

        var circleColour = objectIds[this.state.ScenarioIndex]?.color;
        // if (this.state.tabs.length == 0) {
        //   circleColour = "#cc2db7";
        // } else if (this.state.tabs.length == 1) {
        //   circleColour = "#008FD4";
        // } else if (this.state.tabs.length == 2) {
        //   circleColour = "#3D42DE";
        // } else if (this.state.tabs.length == 3) {
        //   circleColour = "#40D88E";
        // } else {
        //   circleColour = "#008FD4";
        // }

        arr.push({
          ...this.state.dummyData,
          ...scenarioData,
          circleColour,
          updatingPrice: false,
        });
        this.setState({ test: false, tabs: arr });
        // this.setState({  tabs: arr });
        // let datasets =[]
        // let dummy= [...this.state.datasets]
        let datasets = [...this.state.datasets];

        // if(this.state.ScenarioIndex==0){
        //   datasets = [...dummy];
        // }else{
        //   datasets = [...this.state.dummyDataSet];
        // }
        let newChart = {};

        newChart = {
          data: [
            res?.ScenarioHeader?.InspectScore > 35
              ? 35
              : res?.ScenarioHeader?.InspectScore,
            res?.ScenarioHeader?.ProtectScore > 35
              ? 35
              : res?.ScenarioHeader?.ProtectScore,
            res?.ScenarioHeader?.DetectScore > 35
              ? 35
              : res?.ScenarioHeader?.DetectScore,
            res?.ScenarioHeader?.RespondScore > 35
              ? 35
              : res?.ScenarioHeader?.RespondScore,
            res?.ScenarioHeader?.RecoverScore > 35
              ? 35
              : res?.ScenarioHeader?.RecoverScore,
          ],
          datalabels: {
            display: false,
          },
          backgroundColor: `${circleColour}00`,
          borderColor: `${circleColour}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: "rgba(0, 143, 212, 1)",
          borderWidth: 2,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          label: "Current Posture",
        };

        datasets.push(newChart);

        // console.log( " before  datasets",   datasets)
        // setTimeout(() => {
        // this.setState({ datasets: datasets, test: true });
        this.setState({ datasets: datasets, test: true }, () => {
          this.setState({ ScenarioIndex: this.state.ScenarioIndex + 1 }, () => {
            this.LoadData(objectIds);
          });
        });
        // }, 1);

        // this.setState({ ScenarioIndex:this.state.ScenarioIndex+1,},()=>{
        //   this.LoadData(objectIds);
        //  });
        //  console.log( " after  datasets",   datasets)
      })
      .catch((err) => {
        this.setState({ newScenarioToast: false });
        CallToast("Something went wrong");
      });
  };
  CreateScenarioOnLoading = async (objectIds) => {
    if (this.state.ScenarioIndex >= objectIds.length) return;
    this.setState({ newScenarioCreating: true });
    this.LoadData(objectIds);
  };

  // //////////////////////////////////////
  // ////////////////////////////////
  // ////////////////////////////////
  // /////////////////////////
  // //////////////////////////////

  newScenario = async (scenarioData, objectId, color) => {
    if (this.state.tabs.length >= 11) {
      return false;
    }
    if (this.state.newScenarioCreating) {
      return false;
    }
    this.setState({ newScenarioCreating: true });

    if (!scenarioData) {
      await CreateNewScenario(this.context.authTokens, "POST", {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          this.setState({ newScenarioToast: false });
          if (!response?.mr?.Success) {
            CallToast("Something went wrong");
            return false;
          }

          if (response?.mr?.Success) {
            TrackUser("Pricing Calculator Building Security Scenario");
            if (
              response?.Nist_Identify == null ||
              response?.Nist_Detect == null ||
              response?.Nist_Protect == null ||
              response?.Nist_Recover == null ||
              response?.Nist_Respond == null
            ) {
              this.setState({ newScenarioCreating: false });
              CallToast("Something went wrong");
              return false;
            }

            let dummyData = {
              PackList: [
                response?.Nist_Identify,
                response?.Nist_Protect,
                response?.Nist_Detect,
                response?.Nist_Respond,
                response?.Nist_Recover,
              ],
              ScenarioHeader: response?.ScenarioHeader,
              SecurityPackList: response?.SecurityPackList,
              SecurityPack_CurrentSelections_List:
                response?.SecurityPack_CurrentSelections_List,
            };
            this.setState({ dummyData });

            let arr = [...this.state.tabs];
            var circleColour = color;

            arr.push({
              ...this.state.dummyData,
              ...this.state.staticData,
              circleColour,
              updatingPrice: false,
            });
            this.setState({ test: false, tabs: arr });
            let new_datasets = [...this.state.datasets];

            let _newChart = {};

            _newChart = {
              data: [
                response?.ScenarioHeader?.InspectScore,
                response?.ScenarioHeader?.ProtectScore,
                response?.ScenarioHeader?.DetectScore,
                response?.ScenarioHeader?.RespondScore,
                response?.ScenarioHeader?.RecoverScore,
              ],
              datalabels: {
                display: false,
              },
              backgroundColor: `${circleColour}00`,
              borderColor: `${circleColour}`, // "rgba(61, 66, 222, 1)",
              pointBackgroundColor: "rgba(61, 66, 222, 1)",
              borderWidth: 2,
              // borderDash: [8],
              pointLabelFontSize: 0,
              scaleShowLabels: false,
              label: "Current Posture",
              // pointRadius: 0
            };

            new_datasets.push(_newChart);

            setTimeout(() => {
              this.setState({ datasets: new_datasets, test: true });
              const navItem = document.querySelectorAll(".navlinks");

              navItem[navItem.length - 1].click();
            }, 1);
            this.setState({ newScenarioCreating: false });

            // to store opened scenarios in localStorage
            let openedScenarios = JSON.parse(
              window.localStorage.getItem("openedScenarios")
            );
            openedScenarios.push({
              id: response?.ScenarioHeader?.objId_Scenario,
              color: circleColour,
            });
            window.localStorage.setItem(
              "openedScenarios",
              JSON.stringify(openedScenarios)
            );
          }

          this.refreshScenarioList();
        })
        .catch((err) => {
          this.setState({ newScenarioToast: false });
          CallToast("Something went wrong");
          return false;
        });
    } else {
      await getScenarioPacks(this.context.authTokens, 213, objectId)
        .then((res) => {
          this.setState({ newScenarioToast: false });
          if (!res?.mr?.Success) {
            CallToast("Something went wrong");
            this.setState({ newScenarioCreating: false });
            return false;
          }
          if (res?.mr?.Success) {
            if (
              res?.Nist_Identify == null ||
              res?.Nist_Detect == null ||
              res?.Nist_Protect == null ||
              res?.Nist_Recover == null ||
              res?.Nist_Respond == null
            ) {
              this.setState({ newScenarioCreating: false });
              CallToast("Something went wrong");
              return false;
            }
          }
          let dummyData = {
            PackList: [
              res?.Nist_Identify,
              res?.Nist_Protect,
              res?.Nist_Detect,
              res?.Nist_Respond,
              res?.Nist_Recover,
            ],
            ScenarioHeader: res?.ScenarioHeader,
            SecurityPackList: res?.SecurityPackList,
            SecurityPack_CurrentSelections_List:
              res?.SecurityPack_CurrentSelections_List,
          };

          this.setState({ dummyData });

          if (scenarioData) {
            let {
              InspectScore,
              ProtectScore,
              DetectScore,
              RespondScore,
              RecoverScore,
            } = scenarioData;

            let arr = [...this.state.tabs];

            var circleColour = color;
            // if (this.state.tabs.length == 0) {
            //   circleColour = "#cc2db7";
            // } else if (this.state.tabs.length == 1) {
            //   circleColour = "#008FD4";
            // } else if (this.state.tabs.length == 2) {
            //   circleColour = "#3D42DE";
            // } else if (this.state.tabs.length == 3) {
            //   circleColour = "#40D88E";
            // } else {
            //   circleColour = "#008FD4";
            // }

            arr.push({
              ...this.state.dummyData,
              ...scenarioData,
              circleColour,
              updatingPrice: false,
            });
            this.setState({ test: false, tabs: arr });

            const datasets = [...this.state.datasets];
            let newChart = {};
            newChart = {
              data: [
                InspectScore > 35 ? 35 : InspectScore,
                ProtectScore > 35 ? 35 : ProtectScore,
                DetectScore > 35 ? 35 : DetectScore,
                RespondScore > 35 ? 35 : RespondScore,
                RecoverScore > 35 ? 35 : RecoverScore,
              ],
              datalabels: {
                display: false,
              },
              backgroundColor: `${circleColour}00`,
              borderColor: `${circleColour}`, // "rgba(0, 143, 212, 1)",
              pointBackgroundColor: "rgba(0, 143, 212, 1)",
              borderWidth: 2,
              pointLabelFontSize: 0,
              scaleShowLabels: false,
              label: "Current Posture",
            };

            datasets.push(newChart);

            setTimeout(() => {
              this.setState({ datasets: datasets, test: true });
              const navItem = document.querySelectorAll(".navlinks");
              navItem[navItem.length - 1].click();
            }, 1);
            // setup to store opened scenarios
            let openedScenarios = JSON.parse(
              window.localStorage.getItem("openedScenarios")
            );
            openedScenarios.push({
              id: res?.ScenarioHeader?.objId_Scenario,
              color: circleColour,
            });
            window.localStorage.setItem(
              "openedScenarios",
              JSON.stringify(openedScenarios)
            );
            this.setState({ newScenarioCreating: false });

            this.setState({ newScenarioCreating: false });
          } else {
            if (this.state.tabs.length == 4) {
              return false;
            }
            let arr = [...this.state.tabs];
            var circleColour = color;
            // if (this.state.tabs.length == 0) {
            //   circleColour = "#cc2db7";
            // } else if (this.state.tabs.length == 1) {
            //   circleColour = "#008FD4";
            // } else if (this.state.tabs.length == 2) {
            //   circleColour = "#3D42DE";
            // } else if (this.state.tabs.length == 3) {
            //   circleColour = "#40D88E";
            // } else {
            //   circleColour = "#008FD4";
            // }

            arr.push({
              ...this.state.dummyData,
              ...this.state.staticData,
              circleColour,
              updatingPrice: false,
            });
            this.setState({ test: false, tabs: arr });
            const datasets = [...this.state.datasets];
            let newChart = {};
            if (datasets.length == 1) {
              newChart = {
                data: [0, 0, 0, 0, 0],
                datalabels: {
                  display: false,
                },
                backgroundColor: "rgba(0, 143, 212, 0.1)",
                borderColor: "rgba(0, 143, 212, 1)",
                pointBackgroundColor: "rgba(0, 143, 212, 1)",
                borderWidth: 2,
                pointLabelFontSize: 0,
                scaleShowLabels: false,
                label: "Current Posture",
              };
            } else if (datasets.length == 2) {
              newChart = {
                data: [0, 0, 0, 0, 0],
                datalabels: {
                  display: false,
                },
                backgroundColor: "rgba(64, 216, 142, 0.1)",
                borderColor: "rgba(64, 216, 142, 1)",
                pointBackgroundColor: "rgba(64, 216, 142, 1)",
                borderWidth: 2,
                pointLabelFontSize: 0,
                scaleShowLabels: false,
                label: "Current Posture",
              };
            } else {
              newChart = {
                data: [0, 0, 0, 0, 0],
                datalabels: {
                  display: false,
                },
                backgroundColor: "rgba(61, 66, 222, 0.1)",
                borderColor: "rgba(61, 66, 222, 1)",
                pointBackgroundColor: "rgba(61, 66, 222, 1)",
                borderWidth: 2,
                borderDash: [8],
                pointLabelFontSize: 0,
                scaleShowLabels: false,
                label: "Current Posture",
                // pointRadius: 0
              };
            }

            datasets.push(newChart);

            setTimeout(() => {
              this.setState({ datasets: datasets, test: true });
              const navItem = document.querySelectorAll(".navlinks");

              navItem[navItem.length - 1].click();
            }, 1);

            // Setup to store opened scenarios
            let openedScenarios = JSON.parse(
              window.localStorage.getItem("openedScenarios")
            );
            openedScenarios.push({
              id: res?.ScenarioHeader?.objId_Scenario,
              color: circleColour,
            });
            window.localStorage.setItem(
              "openedScenarios",
              JSON.stringify(openedScenarios)
            );
            this.setState({ newScenarioCreating: false });
          }
        })
        .catch((err) => {
          this.setState({ newScenarioToast: false });
          CallToast("Something went wrong");
        });
    }
  };

  render() {
    const data = {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: this.state.datasets,
    };
    const { datasets, activeTab, tabs } = this.state;

    const ListBelowPostureGraph =
      activeTab === 0 ? tabs : [tabs[0], tabs[activeTab]];
    const { scenarioData } = this.state;
    const options = (tab) => {
      let dataLabelsValue = [];
      let dataLabelColor = "#101010";
      // console.log(datasets,"dataset")

      if (typeof tabs[activeTab] != "undefined") {
        dataLabelColor = tabs[activeTab]["clr"];
      }

      if (activeTab != "allscenario") {
        if (typeof datasets[activeTab] != "undefined") {
          dataLabelsValue = datasets[activeTab]["data"];
        } else {
          dataLabelsValue = datasets[0]["data"];
        }
      } else {
        dataLabelsValue = datasets[0]["data"];
      }
      if (
        dataLabelsValue.length > 0 &&
        this.state?.scenarioData?.CurrentPostureExists
      ) {
        dataLabelsValue.forEach((val, i) => {
          data.labels[i] = data.labels[i] + ` - ${val}`;
        });
      }

      let opts = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        animation: {
          duration: 0,
        },

        tooltips: {
          enabled: true,
        },
        scale: {
          display: true,
          pointLabels: {
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
          ticks: {
            beginAtZero: false,
            stepSize: 5,
            max: 35,
            min: 0,
            display: false,
            backdropColor: "rgba(0, 0, 0, 0)",
            // callback: function () {
            //   return "";
            // },
          },
        },
        elements: {
          point: {
            radius: 2,
            hoverRadius: 4,
          },
        },
      };
      return opts;
    };

    const CompanyInfo = (props) => {
      return (
        <div
          className="managedSecurityPacksInner"
          style={{ marginBottom: "8px" }}
        >
          <div
            style={{ paddingBottom: "12px" }}
            className={` ${!this.state.activePosture ? "dropDownActive" : ""}`}
          >
            <p className="title pt16">
              {this.state.activeTab === 0 && "Default"} Quantity of Assets
            </p>
            <div className="">
              <p
                className={`clrgry position-relative ${
                  this.state.activePosture ? "d-none" : ""
                }`}
              >
                Users:{props?.scenarioDetails?.NoOfUsers || 0}, Workstations:
                {props?.scenarioDetails?.NoOfWorkstation || 0}, Servers:
                {props?.scenarioDetails?.NoOfServices || 0},Mobile Devices:
                {props?.scenarioDetails?.NoOfMobileDevices || 0},Domains:
                {props?.scenarioDetails?.NoOfDomains || 0}
                <img
                  src={`/images/calculator/edit.svg`}
                  onClick={(e) =>
                    this.setState({
                      showAssetModal: true,
                      // test: false,
                    })
                  }
                  className="mb-2 arrowImg arrowImg2 crsrpoint"
                  alt=""
                />
              </p>
            </div>
          </div>
          {/* hide content close */}
        </div>
      );
    };
    return (
      <>
      {
        this.state.menuItemData?.disableState || (this.state.accessState?.Override === true && this.state.accessState?.AccessState_ManagedSecurity === 0)  ? <>
        {
          this.state.userPortalRole === 'MSP' ? 
            <NoAccessUI/> : <NoAccessUI type={this.state.userPortalRole}/>
        }
        </> : <>
          <Row
            className="fullHeight priceCalculatorWrapper"
            // style={{ overflow: "hidden" }}
          >
            {this.state.showError && (
              <ToastMessage message={"Something went wrong"} />
            )}

            {this.state.newScenarioToast && (
              <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar
              />
            )}

        <Col xs={12} className="px-0">
          {this.state.userRole == "msp" ? (
            <>
              <header
                className="d-flex justify-content-between navbar sticky-top pb-0  pl-5 pr-3"
                style={{ paddingTop: "1em", background: "#FFFFFF" }}
              >
                <h4>Managed Security</h4>

                <Header />
              </header>
              <MspClientPriceCalculator isMspClientView={true} />
            </>
          ) : (
            <div className="dashboard fullHeight">
              <header
                className="d-flex justify-content-between navbar sticky-top pb-0"
                style={{ paddingTop: "1em", background: "#FFFFFF" }}
              >
                <h1>Managed Security</h1>

                <Header />
              </header>

              {this.state.isLoading ? (
                <ContentLoader
                  speed={2}
                  width={"100%"}
                  height={"100%"}
                  viewBox="0 0 1500 900"
                  backgroundColor="#F6F8FB"
                  foregroundColor="#ecebeb"
                >
                  <rect
                    x="20"
                    y="40"
                    rx="12"
                    ry="12"
                    width="48%"
                    height="850"
                  />
                  <rect
                    x="760"
                    y="40"
                    rx="12"
                    ry="12"
                    width="48%"
                    height="650"
                  />
                </ContentLoader>
              ) : (
                <React.Fragment>
                  <Fragment>
                    <Row
                      style={{
                        padding: "80px 0px 80px 100px",
                        width: "102%",
                      }}
                      className="price_calulator_wrapper hide-scrollbar border-top"
                    >
                      <Col xs={5} className="hide-scrollbar">
                        <Tab.Content style={{ maxWidth: "400px" }}>
                          <div className="f-24 f-black f-600 mb-3">
                            Invite your MSP to HighGround
                          </div>
                          <div className=" f-grey  mb-3">
                            Working with an MSP or MSSP for your IT and
                            Cybersecurity needs? Invite them!
                          </div>
                          <div className=" f-grey  mb-3">
                            HighGround helps MSPs to build their security
                            offerings in a way their SME clients can understand.
                          </div>
                          <div className=" f-grey  mb-4">
                            From simplified pricing to benchmarking against
                            security best practices and much more, HighGround
                            helps MSPs and their clients go on the security
                            journey together!
                          </div>
                          <div className="d-flex align-items-center w-100 mt-2">
                            <div
                              className={`d-flex align-items-center radius-4 no-highlight-input-with-grey-border p-2 w-100 mr-1`}
                            >
                              <input
                                tabIndex={0}
                                type="email"
                                placeholder="Email"
                                className="flex-grow-1 pl-3 pr-2"
                                value={this.state.emailValue}
                                onChange={(e) => {
                                  this.setState({
                                    emailValue: e.target.value?.trim(),
                                    emailSaved: false,
                                  });
                                }}
                              />
                            </div>
                            <OverlayTrigger
                              placement="bottom"
                              trigger={
                                viewOnlyAccess("AccessState_ManagedSecurity")
                                  ? ["hover", "focus"]
                                  : []
                              }
                              delay={{ show: 200, hide: 250 }}
                              overlay={
                                <Tooltip id={`tooltip-1365`}>
                                  This feature is managed by your service provider
                                </Tooltip>
                              }
                            >
                              <Button
                                className={`${
                                  this.state.emailValue === "" ||
                                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                    this.state.emailValue
                                  )
                                    ? " hg-grey-btn"
                                    : "hg-blue-btn"
                                }
                                
                                ${
                                  viewOnlyAccess("AccessState_ManagedSecurity") && 'cursor-default hg-grey-btn'
                                }  
                                w-fit-content`}
                                onClick={async () => {
                                  if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                                    const emailPattern =
                                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    if (emailPattern.test(this.state.emailValue)) {
                                      if (this.state.emailValue) {
                                        this.inviteToMSP();
                                      }
                                    }
                                  }
                                }}
                              >
                                <div className="d-flex align-items-center">
                                {
                                  viewOnlyAccess("AccessState_ManagedSecurity") &&
                                  <img
                                    alt=''
                                    className='lockerDarkGreyImage mr-2 '
                                    src='/images/settings-v2/locker-grey.svg'
                                    />
                                }
                                {this.state.emailSaving
                                  ? "Inviting..."
                                  : "Invite"}
                                  </div>
                              </Button>
                            </OverlayTrigger>
                          </div>
                          {this.state.emailSaved && (
                            <div className="f-12 mt-1 f-green">
                              Invite sent.
                            </div>
                          )}
                        </Tab.Content>
                      </Col>

                      <Col xs={5}>
                        <div className="m-auto w-fit-content mt-4">
                          {/* <Radar
                            id="actial-radar"
                            // redraw={this.state.test}
                            redraw={this.state.reDrawGraph}
                            height={170}
                            style={{ margin: "auto" }}
                            data={
                              data
                              // this.state.activeTab == 0
                              //   ? data
                              //   : {
                              //       ...data,
                              //       datasets: [
                              //         data.datasets[0],
                              //         data.datasets[this.state.activeTab],
                              //       ],
                              //     }
                            }
                            options={options(this.state.activeTab)}
                          /> */}
                          <img
                            width={480}
                            src={`/images/calculator/msSaasPreview.webp`}
                            className=""
                            alt=""
                          />
                        </div>
                      </Col>
                    </Row>
                    <SecurityPacksModal
                      openAssetModal={this.openAssetModal}
                      isOpen={this.state.secModal}
                      isClose={() => this.setState({ secModal: false })}
                      openSkipModal={() => this.setState({ skipmodal: true })}
                    />
                    <SkipWelcomeModal
                      isOpen={this.state.skipmodal}
                      isClose={() => this.setState({ skipmodal: false })}
                    />
                    <QuestionnaireModal
                      isOpen={this.state.quesModal}
                      isClose={() => this.setState({ quesModal: false })}
                    />
                    <PrintSendModal
                      setValues={this}
                      scenarioData={this.state.scenarioData}
                      isOpen={this.state.printModal}
                      isClose={() => this.setState({ printModal: false })}
                      modalState={this.state.printState}
                      colors={this.state.colors}
                    />
                    <SubmitQuoteModal
                      tabs={this.state.tabs}
                      activeTab={this.state.activeTab}
                      setValues={this}
                      isOpen={this.state.quoteModal}
                      isClose={() => this.setState({ quoteModal: false })}
                    />
                  </Fragment>
                </React.Fragment>
              )}
            </div>
          )}
        </Col>

        <AssetModal
          updatesScenarioData={() => {
            this.updateScenarioData(
              this.state.tabs[this.state.activeTab]?.ScenarioHeader
                ?.objId_Scenario,

              this.state.activeTab
            );
          }}
          DrawGraph={this.DrawGraph.bind(this)}
          newScenario={() =>
            this.newScenario(
              false,
              0,
              this.state.colors[
                this.state.scenarioData?.ScenarioHheaderList?.length % 10
              ]
            )
          }
          showAssetModal={this.state.showAssetModal}
          // getScenarioListData={this.getScenarioListData}
          closeAssetModal={() => {
            this.closeAssetModal();
          }}
          values={
            this.state.tabs[this.state.activeTab]?.ScenarioHeader
              ?.UserAndDeviceCount
          }
          activeTab={this.state.activeTab}
          setValues={this}
        />
        <UpdateScenarioModal
          // getScenarioListData={this.getScenarioListData}
          activeTab={this.state.activeTab}
          showAssetModal={this.state.showAssetModal}
          closeAssetModal={() => {
            this.closeAssetModal();
            this.setState({
              assetModalValues: {
                users: "",
                workstations: "",
                servers: "",
                mobiledevices: "",
                domains: "",
              },
            });
          }}
          isOpen={this.state.updateScenarioModal}
          setValues={this}
        />

            <QuestionaireModal
              refreshScenarioList={this.refreshScenarioList.bind(this)}
              currentPostureStatus={scenarioData?.CurrentPostureExists}
              loadChart={this.IntialLoadChart.bind(this)}
              setValues={this}
              showQuestionModal={this.state.showQuestionModal}
              hideQuestionModal={() => {
                this.setState({ showQuestionModal: false });
              }}
            />
          </Row>
        </>
      }
    </>
    );
  }
}

export default PriceCalculator;
