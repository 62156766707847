import React, { Component } from "react";
import "./ChangeCompanyAndRole.css";
import { Col, Form, InputGroup, Button, Alert, Spinner } from "react-bootstrap";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Notification from "../../Notification/Notification";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import LoadingSpinner from "../../Spinner/Spinner";
import { CgTrash } from "react-icons/cg";
import { MdChevronLeft } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";
import SendSystemEmail from "../SendSystemEmail";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
class ChangeCompanyAndRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      container1Collapsed: false,
      container2Collapsed: true,
      container3Collapsed: true,
      container4Collapsed: true,
      activeCompany: window.localStorage.getItem("activeCompany") || 237004,
      companyHashTable: {
        237004: "Super Scientific",
        237005: "Median Corp",
        237006: "Stark Operation",
      },
      activeRole: window.localStorage.getItem("userType") || 111111,
      activeUserRole: window.localStorage.getItem("userType") || 111111,
      notificationsObj: false,
      authTokens: window.localStorage.getItem("tokens").replaceAll('"', ""),
      addFullName: "",
      addCompanyName: "",
      addEmail: "",
      demoUsers: [],
      viewingUser: false,
      alert: false,
      isRequestSend: false,
    };
    this.updateDashboard = this.updateDashboard.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.setUsers = this.setUsers.bind(this);
  }

  //     {
  //     name: "Sergey Brin",
  //         email: "sergeybrin@gmail.com",
  //             demoCompany: "Google Ink.",
  //                 company: {
  //         id: 237004,
  //             text: "Stark Industries",
  //                 color: "red"
  //     },

  // }

  toggleChevron(element) {
    if (element === 1) {
      if (this.state.container1Collapsed)
        return this.setState({ container1Collapsed: false });
      return this.setState({ container1Collapsed: true });
    } else if (element === 2) {
      if (this.state.container2Collapsed)
        return this.setState({ container2Collapsed: false });
      return this.setState({ container2Collapsed: true });
    } else if (element === 3) {
      if (this.state.container3Collapsed)
        return this.setState({ container3Collapsed: false });
      return this.setState({ container3Collapsed: true });
    } else if (element === 4) {
      if (this.state.container4Collapsed)
        return this.setState({ container4Collapsed: false });
      return this.setState({ container4Collapsed: true });
    }
  }

  toggleCompany(companyInt) {
    if (this.state.activeCompany !== companyInt) {
      this.setState({ activeCompany: companyInt });
      window.localStorage.setItem("activeCompany", companyInt);
      this.getNotifications(companyInt);
    }
  }
  toggleActiveRole(roleInt, userRole) {
    if (userRole) return this.setState({ activeUserRole: roleInt });
    if (this.state.activeRole !== roleInt)
      this.setState({ activeRole: roleInt });
  }

  async getNotifications(orgId) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetNotificationTabs?orgId=${orgId}`,
      getRequestOptions(this.state.authTokens)
    ).then((response) => response.json());
    this.setState({ notificationsObj: data });
  }

  componentDidMount() {
    this.setUsers();
    this.getNotifications(237004);
  }

  async updateDashboard() {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetDemoOrgAndRoleAccessToken?orgId=${this.state.activeCompany}&roleId=${this.state.activeRole}`,
      getRequestOptions(this.state.authTokens)
    ).then((response) => response.json());
    if (data.AccessToken) {
      // console.log(data.mr.EntityId);
      // window.localStorage.clear();
      window.localStorage.setItem("tokens", `"${data.AccessToken}"`);
      window.localStorage.setItem("userType", data.mr.EntityId);
      window.localStorage.setItem("userAuthenticated", true);
      window.localStorage.setItem("activeCompany", this.state.activeCompany);
     
      updateAccessKeyInLocalStorage(data?.mr?.Message)
    }
    // window.location.replace("/");
  }

  async setUsers() {
    const result = await getAPIData(
      "57",
      JSON.parse(localStorage.getItem("tokens"))
    );
    var tempUserList = [];
    var innerObj = {};
    result.UserList.map((element) => {
      innerObj = {};
      innerObj.id = element.objId_UserSettings;
      innerObj.name = element.Name;
      innerObj.email = element.Email;
      innerObj.demoCompany = element.Company;
      innerObj.company = {
        id: 237004,
        text: element.DemoOrg,
        color: element.DemoOrgColour,
      };
      innerObj.devices = element.MobileList;
      tempUserList.push(innerObj);
    });
    console.log("tempUserList", tempUserList);
    this.setState({ demoUsers: tempUserList });
  }
  async addNewUser() {
    if (
      this.state.addFullName &&
      this.state.addCompanyName &&
      this.state.addCompanyName
    ) {
      this.setState({ isRequestSend: true });
      const result = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateCCUser`,
        getRequestOptions(JSON.parse(localStorage.getItem("tokens")), "POST", {
          Name: this.state.addFullName,
          Company: this.state.addCompanyName,
          Email: this.state.addEmail,
          RoleId: this.state.activeUserRole,
        })
      ).then((response) => response.json());

      this.setState({ isRequestSend: false });
      if (!result.Success) {
        this.setState({ alert: result.Message });
      }

      // console.log(result)
      this.setUsers();
      // console.log("this.state", this.state)
      // let tempUsers = this.state.demoUsers;
      // tempUsers.push({
      //     name: this.state.addFullName,
      //     email: this.state.addEmail,
      //     demoCompany: this.state.addCompanyName,
      //     company: {
      //         id: this.state.activeCompany,
      //         text: this.state.companyHashTable[this.state.activeCompany],
      //         color: "red"
      //     },
      //     devices: [
      //         {
      //             name: "Samsung Galaxy, Android 8.1",
      //             date: "12/03/2020"
      //         }
      //     ]
      // })
      // console.log("tempUsers", tempUsers)
      // this.setState({ demoUsers: tempUsers, addFullName: "", addEmail: "", addCompanyName: "" });
    }
  }

  updateUser() {}

  async deleteUserApi(userId) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/DeleteDemoUser?ObjId_UserSettings=${userId}`,
      getRequestOptions(this.state.authTokens)
    ).then((response) => response.json());
    return data;
  }

  async deleteUsersDevice(deviceId) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/DeleteDemoUserDevice?deviceId=${deviceId}`,
      getRequestOptions(this.state.authTokens)
    ).then((response) => response.json());
    return data;
  }

  async deleteItem(index, type = "user", deviceIndex, deviceId = 0) {
    console.log("this.state.demoUsers", this.state.demoUsers);
    let tempUsers = this.state.demoUsers;
    //Delete User's Device
    if (type == "device") {
      console.log(tempUsers[index].devices, deviceId, index);
      await this.deleteUsersDevice(deviceId);
      tempUsers[index].devices.splice(deviceIndex, 1);
    }
    //Delete User
    else {
      await this.deleteUserApi(tempUsers[index].id);
      tempUsers.splice(index, 1);
    }
    this.setState({ demoUsers: tempUsers });
  }

  render() {
    if (this.state.viewingUser !== false) {
      let user = this.state.demoUsers[this.state.viewingUser];
      if (this.state.activeCompany !== user.company.id)
        this.setState({ activeCompany: user.company.id });
      return (
        <div className="companyContainer">
          <Col xs={12} className="collapsabileContainer companyModal">
            <div className="containerHeaders">
              <span className="containerLeftSide">
                <span
                  className="imageContainer"
                  style={{ marginLeft: "-5px", paddingRight: ".5em" }}
                  onClick={() => this.setState({ viewingUser: false })}
                >
                  {" "}
                  <MdChevronLeft size={30} />
                </span>
                <h1>Setup Demo Users</h1>
              </span>
              <span>
                <span
                  onClick={() => {
                    this.deleteItem(this.state.viewingUser);
                    this.setState({ viewingUser: false });
                  }}
                  style={{ fontSize: "0.85em", fontWeight: 500 }}
                >
                  Delete <CgTrash />
                </span>
              </span>
            </div>
            <div style={{ padding: "1.5em" }}>
              <React.Fragment>
                <Form className="incidentResponseForm">
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Full Name: </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        required
                        onChange={(word) =>
                          this.setState({ addFullName: word.target.value })
                        }
                        defaultValue={user.name}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Company Name: </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        required
                        onChange={(word) =>
                          this.setState({ addCompanyName: word.target.value })
                        }
                        defaultValue={user.company.text}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Email: </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        required
                        onChange={(word) =>
                          this.setState({ addEmail: word.target.value })
                        }
                        defaultValue={user.email}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div
                    className="companyModalContent"
                    style={{ padding: ".4em" }}
                  >
                    <div className="greyContainer">
                      <p
                        onClick={() => this.toggleActiveRole(111111, true)}
                        style={
                          this.state.activeUserRole == 111111
                            ? { background: "#008FD4", color: "white" }
                            : { color: "black" }
                        }
                      >
                        CEO
                      </p>
                      <p
                        onClick={() => this.toggleActiveRole(222222, true)}
                        style={
                          this.state.activeUserRole == 222222
                            ? { background: "#008FD4", color: "white" }
                            : { color: "black" }
                        }
                      >
                        CTO
                      </p>
                      <p
                        onClick={() => this.toggleActiveRole(333333, true)}
                        style={
                          this.state.activeUserRole == 333333
                            ? { background: "#008FD4", color: "white" }
                            : { color: "black" }
                        }
                      >
                        CFO
                      </p>
                    </div>
                    <p class="informationMessage">Demo Company</p>
                  </div>
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "#008FD4",
                      padding: ".5em 0",
                    }}
                    onClick={this.updateUser}
                  >
                    Save Changes
                  </Button>
                </Form>
              </React.Fragment>
              <div style={{ marginTop: "2em", paddingBottom: "2em" }}>
                {user.devices.length > 0 ? (
                  <React.Fragment>
                    <p style={{ fontWeight: 500, margin: "0.8em 0" }}>
                      Devices
                    </p>
                    {user.devices.map((element, index) => {
                      return (
                        <React.Fragment>
                          <div className="">
                            <div
                              onClick={() =>
                                this.setState({ viewingUser: index })
                              }
                              style={{ width: "70%" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  paddingBottom: ".5em",
                                }}
                              >
                                <IoIosPhonePortrait size={20} color="#B6B6BF" />
                                <p style={{ paddingLeft: ".5em" }}>
                                  {element.Detail}
                                </p>
                              </div>
                              <div>
                                <p style={{ fontSize: "0.8em" }}>
                                  <span style={{ color: "#B6B6BF" }}>
                                    Date of added:
                                  </span>
                                  {element.DateAdded}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  flex: 1,
                                  textAlign: "center",
                                  cusor: "pointer",
                                }}
                                onClick={() =>
                                  this.deleteItem(
                                    this.state.viewingUser,
                                    "device",
                                    index,
                                    element.DeviceId
                                  )
                                }
                              >
                                <p
                                  style={{
                                    marginTop: "-.5em",
                                    color: "#F36060",
                                    fontSize: "0.9em",
                                    textAlign: "right",
                                  }}
                                >
                                  Remove Device
                                </p>
                              </div>
                            </div>
                          </div>
                          {index < user.devices.length - 1 ? <hr /> : ""}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </div>
      );
    }

    return (
      <div
        className={`companyContainer  ${
          !this.state.container4Collapsed && "hauto"
        }`}
      >
        <Col xs={12} className="collapsabileContainer companyModal">
          <div className="containerHeaders">
            <span className="containerLeftSide">
              <span className="imageContainer">
                {" "}
                <img src="/images/icons/chart.svg" />
              </span>
              <h1>Change Role and Company</h1>
            </span>
            <span>
              {!this.state.container1Collapsed ? (
                <span>
                  <p className="updateButton" onClick={this.updateDashboard}>
                    Update
                  </p>
                </span>
              ) : (
                ""
              )}
              <span onClick={() => this.toggleChevron(1)}>
                {this.state.container1Collapsed ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronUp />
                )}
              </span>
            </span>
          </div>
          <div>
            {this.state.container1Collapsed ? (
              ""
            ) : (
              <React.Fragment>
                <div className="companyModalContent">
                  <div className="greyContainer">
                    <p
                      onClick={() => this.toggleCompany(237004)}
                      style={
                        this.state.activeCompany == 237004
                          ? { background: "#40D88E", color: "white" }
                          : { color: "#40D88E" }
                      }
                    >
                      Super Scientific
                    </p>
                    <p
                      onClick={() => this.toggleCompany(237005)}
                      style={
                        this.state.activeCompany == 237005
                          ? { background: "#FFAC2C", color: "white" }
                          : { color: "#FFAC2C" }
                      }
                    >
                      Median Corp
                    </p>
                    <p
                      onClick={() => this.toggleCompany(237006)}
                      style={
                        this.state.activeCompany == 237006
                          ? { background: "#F36060", color: "white" }
                          : { color: "#F36060" }
                      }
                    >
                      Stark Operation
                    </p>
                  </div>
                  <p class="informationMessage">Change Company</p>
                </div>
                <div className="companyModalContent">
                  <div className="greyContainer">
                    <p
                      onClick={() => this.toggleActiveRole(111111)}
                      style={
                        this.state.activeRole == 111111
                          ? { background: "#008FD4", color: "white" }
                          : { color: "black" }
                      }
                    >
                      CEO
                    </p>
                    <p
                      onClick={() => this.toggleActiveRole(222222)}
                      style={
                        this.state.activeRole == 222222
                          ? { background: "#008FD4", color: "white" }
                          : { color: "black" }
                      }
                    >
                      CTO
                    </p>
                    <p
                      onClick={() => this.toggleActiveRole(333333)}
                      style={
                        this.state.activeRole == 333333
                          ? { background: "#008FD4", color: "white" }
                          : { color: "black" }
                      }
                    >
                      CFO
                    </p>
                  </div>
                  <p class="informationMessage">Change Role</p>
                </div>
              </React.Fragment>
            )}
          </div>
        </Col>

        <Col xs={12} className="collapsabileContainer companyModal">
          <div className="containerHeaders crsrpoint">
            <span className="containerLeftSide">
              <span className="imageContainer">
                {" "}
                <img src="/images/icons/people.svg" />
              </span>
              <h1>Setup Demo Users</h1>
            </span>
            <span>
              <span onClick={() => this.toggleChevron(3)}>
                {this.state.container3Collapsed ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronUp />
                )}
              </span>
            </span>
          </div>
          <div>
            {this.state.container3Collapsed ? (
              ""
            ) : (
              <React.Fragment>
                {this.state.alert ? (
                  <Alert
                    variant={"danger"}
                    style={{ marginTop: "1em" }}
                    onClose={() => this.setState({ alert: false })}
                    dismissible
                  >
                    {this.state.alert}
                  </Alert>
                ) : (
                  ""
                )}

                <Form
                  className="incidentResponseForm"
                  style={{ padding: "1.5em" }}
                >
                  <p style={{ fontWeight: 500, margin: "0.8m 0" }}>
                    Add new user
                  </p>
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Full Name: </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        required
                        onChange={(word) =>
                          this.setState({ addFullName: word.target.value })
                        }
                        value={this.state.addFullName}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Company Name: </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        required
                        onChange={(word) =>
                          this.setState({ addCompanyName: word.target.value })
                        }
                        value={this.state.addCompanyName}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Email: </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        required
                        onChange={(word) =>
                          this.setState({ addEmail: word.target.value })
                        }
                        value={this.state.addEmail}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div
                    className="companyModalContent"
                    style={{ padding: ".4em" }}
                  >
                    <div className="greyContainer">
                      <p
                        onClick={() => this.toggleActiveRole(111111, true)}
                        style={
                          this.state.activeUserRole == 111111
                            ? { background: "#008FD4", color: "white" }
                            : { color: "black" }
                        }
                      >
                        CEO
                      </p>
                      <p
                        onClick={() => this.toggleActiveRole(222222, true)}
                        style={
                          this.state.activeUserRole == 222222
                            ? { background: "#008FD4", color: "white" }
                            : { color: "black" }
                        }
                      >
                        CTO
                      </p>
                      <p
                        onClick={() => this.toggleActiveRole(333333, true)}
                        style={
                          this.state.activeUserRole == 333333
                            ? { background: "#008FD4", color: "white" }
                            : { color: "black" }
                        }
                      >
                        CFO
                      </p>
                    </div>
                    <p class="informationMessage" style={{ margin: ".2em 0" }}>
                      Demo user is linked to Super Scientific
                    </p>
                  </div>
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "#008FD4",
                      padding: ".5em 0",
                    }}
                    onClick={this.addNewUser}
                  >
                    {this.state.isRequestSend ? (
                      <span>
                        <Spinner animation="border" variant="light" size="sm" />{" "}
                        Registering
                      </span>
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Form>
                <div style={{ paddingBottom: "2em", padding: "1.5em" }}>
                  <p style={{ fontWeight: 500, margin: "0.4em 0" }}>
                    Registered Demo Users
                  </p>
                  {this.state.demoUsers.map((element, index) => {
                    return (
                      <React.Fragment>
                        <div className="demoUsersContainer">
                          <div
                            onClick={() =>
                              this.setState({ viewingUser: index })
                            }
                          >
                            <div>
                              <span className="userInitials">
                                <p>{element.name.slice(0, 2).toUpperCase()}</p>
                              </span>
                            </div>
                            <div>
                              <p>{element.name}</p>
                              <p className="secondaryText">{element.email}</p>
                            </div>
                          </div>
                          <div>
                            <div style={{ width: "75%" }}>
                              <p>{element.demoCompany}</p>
                              <p
                                className="secondaryText"
                                style={{ color: element.company.color }}
                              >
                                {element.company.text}
                              </p>
                            </div>
                            <div
                              style={{
                                flex: 1,
                                textAlign: "center",
                                cusor: "pointer",
                              }}
                              onClick={() => this.deleteItem(index)}
                            >
                              <CgTrash size={20} />
                            </div>
                          </div>
                        </div>
                        {index < this.state.demoUsers.length - 1 ? <hr /> : ""}
                      </React.Fragment>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </div>
        </Col>

        <Col xs={12} className="collapsabileContainer companyModal">
          <div className="containerHeaders crsrpoint">
            <span className="containerLeftSide">
              <span className="imageContainer">
                {" "}
                <img src="/images/icons/bell.svg" />
              </span>
              <h1>Send Notification</h1>
            </span>
            <span>
              <span onClick={() => this.toggleChevron(2)}>
                {this.state.container2Collapsed ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronUp />
                )}
              </span>
            </span>
          </div>
          <div>
            {this.state.container2Collapsed ? (
              ""
            ) : this.state.notificationsObj ? (
              <div className="companyModalContent">
                {Object.keys(this.state.notificationsObj)
                  .filter((key) => key !== "mr")
                  .map((element, index) => {
                    return (
                      <Notification
                        index={index}
                        role={this.state.activeRole}
                        type={element}
                        company={this.state.activeCompany}
                        value={this.state.notificationsObj[element].TabValue}
                        heading={
                          this.state.notificationsObj[element].TabHeading
                        }
                        text={this.state.notificationsObj[element].TabText}
                        color={`#${this.state.notificationsObj[element].TabColour}`}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="companyModalContent">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </Col>

        {/* Send Notification  */}
        <Col
          xs={12}
          className="collapsabileContainer companyModal"
          style={{ position: "unset" }}
        >
          <div className="containerHeaders crsrpoint">
            <span className="containerLeftSide">
              <span className="imageContainer">
                {" "}
                <img src="/images/icons/msg.svg" />
              </span>
              <h1>Send System Email</h1>
            </span>
            <span>
              <span onClick={() => this.toggleChevron(4)}>
                {this.state.container4Collapsed ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronUp />
                )}
              </span>
            </span>
          </div>
          <div>
            {this.state.container4Collapsed ? (
              ""
            ) : this.state.notificationsObj ? (
              <div className="companyModalContent">
                <SendSystemEmail />
              </div>
            ) : (
              <div className="companyModalContent">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </Col>
      </div>
    );
  }
}

export default ChangeCompanyAndRole;
