import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./style.scss";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";

const SendSystemContent = (props) => {
  const { setLoading, setResponse, setCount, failCount, msgRes } = props;
  const [selectedOption, setSelectedOption] = useState([]);
  const [email, setEmail] = useState("");
  const { authTokens } = useAuth();

  const selectOptionHandler = (id) => {
    const arr = [...selectedOption];
    if (arr.indexOf(id) === -1) {
      arr.push(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
    }
    setSelectedOption(arr);
  };
  async function Submithandler() {
    setLoading(true);
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/SendDemoEmail`,
      getRequestOptions(authTokens, "POST", {
        EmailAddress: email,
        EmailEnumIds: selectedOption,
      })
    ).then((response) => response.json());
    if (data.Success) {
      setLoading(false);
      setResponse(true);
      setCount(data.SuccessCount);
      msgRes(data.Message);
      failCount(data.FaliureCount);
    } else {
      setLoading(false);
      msgRes(data.Message);
    }
  }
  return (
    <>
      <div className="sendNotificationContent">
        {props.data.map((val, i) => (
          <Fragment key={i}>
            {val.title && <p className="lbltitle">{val.title}</p>}
            {val["data"].map((v, key) => (
              <div key={key} className="compsalewrapper">
                <div className="d-flex flex-direction-row justify-content-start mb-2">
                  <Form.Check
                    type="switch"
                    id={`switch${v.id}`}
                    checked={selectedOption.indexOf(v.id) !== -1 ? true : false}
                    className="m-0"
                    onClick={() => selectOptionHandler(v.id)}
                  />
                  <p className="lbltxt">{v.title}</p>
                </div>
              </div>
            ))}

            <div className="brddiv" />
          </Fragment>
        ))}
      </div>
      <div className="sendNotificationFooter pt-4">
        <Form.Group>
          <InputGroup className="grey-input footer_inpt">
            <InputGroup.Prepend>
              <InputGroup.Text className="greyText">Email:</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              name="IntegrationName"
              value={email}
              onChange={(v) => setEmail(v.target.value)}
            />
          </InputGroup>
        </Form.Group>

        <Button
          className={`${
            (selectedOption.length == 0 || email == "") && "disabled_btn"
          } w-100 submit-btn mt-3`}
          onClick={() => Submithandler()}
          disabled={selectedOption.length > 0 && email ? false : true}
          style={{ bottom: 0 }}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default SendSystemContent;
