import React, { useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./settingsSideNav.scss";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { useDb } from "../context/db";
import { accessAllowed } from "../App/App";
import { TrimString } from "../../Utils/StringTrimmer";
 
const SettingsSideNav = () => {
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const [isUpgradeProCollapse, setIsUpgradeProCollapse] = useState(true);
  const upgradeProHandler = (event) => {
    setIsUpgradeProCollapse(true);
  };
  const upgradePopupData = useSelector(
    (state) =>
      state?.user?.userDetail?.AccessObjList?.filter(
        (item) => item?.Question === "Request Demo Visibility"
      )?.[0]?.UpgradeOptions
  );

  const PERSONAL_SETTINGS = {
    HEADER: "Personal Settings",

    TABS: [
      {
        tabName: "My Profile",
        tabImage: `${
          userDetails.Avatar == "No URL"
            ? "/images/settings-v2/profile-pic.svg"
            : userDetails.Avatar
        }`,
        tabURL: "/settings/v2/my_profile",
      },
      {
        tabName: "Notifications",
        tabImage: "/images/settings-nav/notifications.svg",
        tabURL: "/settings/v2/notifications",
      },
      {
        tabName: "C-suite Report",
        tabImage: "/images/settings-nav/c-suite.svg",
        tabURL: "/settings/v2/c-suite",
      },
      {
        tabName: "Active Sessions",
        tabImage: "/images/settings-nav/active_sessions.svg",
        tabURL: "/settings/v2/active_sessions",
      },
    ],
  };
  const URLpath = window.location.pathname.split("/")[3];

  const accessData = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData
        ?.Override === true
      ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData
      : null
    : null;

  const SYSTEM_SETTINGS = {
    HEADER: "System Settings",

    TABS: [
      {
        tabName: "Company Settings",
        tabImage: "/images/settings-nav/company_settings.svg",
        tabURL: "/settings/v2/company-settings",
      },
      // {
      //   tabName: "Team",
      //   tabImage: "/images/settings-nav/members.svg",
      //   tabURL: "/settings/v2/team",
      //   enabled: accessData?.AccessState_UserManagement===0 ? false : true,
      // },
      {
        tabName: "Members and access",
        tabImage: "/images/settings-nav/members.svg",
        tabURL: "/settings/v2/memberaccess",
      },
      // {
      //   tabName: "Rewards",
      //   tabImage: "/images/settings-nav/rewards.svg",
      //   tabURL: "/settings/v2/rewards",
      //   enabled: accessData?.AccessState_Reward_Settings===0 ? false : true,
      // },
      {
        tabName: "Subscriptions",
        tabImage: "/images/settings-nav/subscriptions.svg",
        tabURL: "/settings/v2/subscriptions",
      },
      {
        tabName: "Billing",
        tabImage: "/images/settings-nav/billing.svg",
        tabURL: "/settings/v2/billing",
      },
      {
        tabName: "Action Centre",
        tabImage: "/images/settings-nav/action_centre.svg",
        tabURL: "/settings/v2/action_centre",
        enabled: accessData?.AccessState_ActionCentre_Settings===0 ? false : true,
      },
    ],
  };



const mspDetail = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.mspDetail
       
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.mspDetail
    : null
  : null;


  return (
    <div className="settings-v2-sidenav">
      <Row>
        <Col xs={12}>
          <div className="tab-heading mt-2"> {PERSONAL_SETTINGS.HEADER} </div>
        </Col>
        {PERSONAL_SETTINGS.TABS.map((tab, index) => {
          return (
            <Col key={index} xs={12}>
              <Link to={`${tab.tabURL}`}>
                <div
                  className={`d-flex align-items-center tab-wrapper my-1   ${
                    URLpath == tab.tabURL.split("/")[3] ? "active-tab" : ""
                  }`}
                >
                  {index === 0 ? (
                    <>
                      {userDetails.Avatar === "No URL" ? (
                        <span
                          className="d-inline-flex"
                          style={{
                            marginRight: "8px",
                            marginLeft: "-2px",
                            fontSize: "9px",
                            transform: "scale(0.85)",
                          }}
                        >
                          {" "}
                          <ImageWithInitials
                            width={16}
                            initials={userDetails.Initials}
                            background="purple"
                          />
                        </span>
                      ) : (
                        <img
                          alt=""
                          src={tab.tabImage}
                          className={`tab-icon  ${
                            index === 0 ? "profile-thumbnail" : ""
                          }`}
                          width={15}
                          height={15}
                        />
                      )}
                    </>
                  ) : (
                    <img
                      alt=""
                      src={tab.tabImage}
                      className={`tab-icon  `}
                      width={15}
                      height={15}
                    />
                  )}
                  <div className="tab-name">{tab.tabName}</div>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>

      <Row>
        <Col xs={12}>
          <div className="tab-heading mt-2"> {SYSTEM_SETTINGS.HEADER} </div>
        </Col>
        {accessAllowed("AccessState_SystemSettings") ? (
          <>
            {SYSTEM_SETTINGS.TABS.map((tab, index) => {
              return (
                <Col key={index} xs={12}>
                  <OverlayTrigger
                    trigger={tab?.enabled === false ? ["hover", "focus"] : []}
                    key={index}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-1365`}>
                        This feature is managed by your service provider
                      </Tooltip>
                    }
                  >
                    {tab?.enabled === false ? (
                      <div
                        className={`d-flex align-items-center   my-1  `}
                        style={{
                          padding: "0.5rem 0.6rem",
                        }}
                      >
                        <img
                          alt=""
                          src={
                            tab?.enabled === false
                              ? "/images/msp/lock-icon.svg"
                              : tab.tabImage
                          }
                          className="tab-icon"
                          width={15}
                          height={15}
                        />
                        <span
                          className="f-darkgrey"
                          style={{
                            paddingTop: "2px",
                          }}
                        >
                          {" "}
                          {tab.tabName}
                        </span>
                      </div>
                    ) : (
                      <Link
                        to={tab?.enabled === false ? "" : `${tab.tabURL}`}
                        onClick={() => {
                          if (tab.tabName === "Rewards") {
                            TrackUser("Rewards button clicked");
                          }
                        }}
                      >
                        <div
                          className={`d-flex align-items-center tab-wrapper my-1    
                     ${URLpath === tab.tabURL.split("/")[3] ? "active-tab" : ""}
                `}
                        >
                          <img
                            alt=""
                            src={
                              tab?.enabled === false
                                ? "/images/msp/lock-icon.svg"
                                : tab.tabImage
                            }
                            className="tab-icon"
                            width={15}
                            height={15}
                          />
                          <span
                            className="tab-name"
                            style={{
                              paddingTop: "2px",
                            }}
                          >
                            {" "}
                            {tab.tabName}
                          </span>
                        </div>
                      </Link>
                    )}
                  </OverlayTrigger>
                </Col>
              );
            })}
          </>
        ) : (
          <>
            <div className="f-darkgrey d-flex align-items-start m-3">
              <div className="mr-2">
                <img src="/images/msp/lock-icon.svg" alt="" />
              </div>
              <div
                className=""
                style={{
                  lineHeight: "22px",
                }}
              >
                Your system settings are managed by your service provider
              </div>
            </div>
          </>
        )}
      </Row>

      <Row className="d-none">
        <Col xs={12}>
          {/* <hr className="m-0 mt-2" /> */}
          <Link to="/settings/v2/action_centre">
            <div
              className={`d-flex align-items-center tab-wrapper my-1    ${
                URLpath == "action_centre" ? "active-tab" : ""
              } `}
            >
              <img
                alt=""
                src="/images/settings-nav/action_centre.svg"
                className="tab-icon"
                width={15}
                height={15}
              />
              <div className="tab-name"> Action Centre</div>
            </div>
          </Link>
          {/* <hr className="m-0 mb-2" /> */}
        </Col>

        <Col xs={12} className="d-none">
          <Link to="/settings/v2/reset_settings">
            <div
              className={`d-flex align-items-center tab-wrapper  ${
                URLpath == "reset_settings" ? "active-tab" : ""
              }  `}
            >
              <img
                alt=""
                src="/images/settings-nav/reset_settings.svg"
                className="tab-icon"
                width={15}
                height={15}
              />
              <div className="tab-name"> Reset settings</div>
            </div>
          </Link>
        </Col>
      </Row>

      {/* upgarde Button */}

      <div className="upgrade-popup-settingsv2">
        {mspDetail ? (
          <Row>
            <Col className="upgradePro border-none-imp">
              <div className="d-flex align-items-center">
                <div className="position-relative mr-3">
                  <img
                    alt=""
                    src={mspDetail?.logo}
                    className=" rounded-full"
                    width={24}
                    height={24}
                  />
                  <img
                    alt=""
                    src="/images/msp/access-lock.svg"
                    width={13}
                    height={13}
                    className="position-absolute "
                    style={{
                      top: "100%",
                      transform: "translate(-50%,-50%)",
                      zIndex: 2,
                    }}
                  />
                </div>
                <div>
                  <div className="f-12 f-darkgrey">Managed by</div>
                  <div className="f-500 f-black"> {TrimString(mspDetail?.name,20)} </div>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {(upgradePopupData && false) ? (
              <Row>
                {isUpgradeProCollapse ? (
                  <Col className="upgradePro">
                    <div className="d-flex justify-content-between">
                      <div
                        className="text-center"
                        onClick={() => TrackUser("Upgrade Now button clicked")}
                      >
                        <Link
                          to={upgradePopupData?.SubmitButton?.ButtonUrl}
                          className="pl-4 ml-1 urgradeTextSidebar new-upgrade"
                        >
                          <span className="pl-4">
                            <img
                              alt=""
                              src={upgradePopupData?.SubmitButton?.ButtonImage}
                              height="20"
                              className="mr-2"
                            />
                          </span>
                          {upgradePopupData?.SubmitButton?.ButtonText}
                        </Link>
                      </div>
                      <div>
                        <img
                          alt=""
                          src="/images/dashboard/upgradeCollapse.svg"
                          onClick={() => setIsUpgradeProCollapse(false)}
                        />
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col className="upgradePro mt-4">
                    <div className="d-flex justify-content-between">
                      {isUpgradeProCollapse ? (
                        <div className="mt16px mb-2 text-center">
                          <img alt="" src="/images/upgradeBell.svg" />
                          <Link
                            // to="/system_settings?tab=subscriptions"
                            to="/settings/v2/subscriptions"
                          >
                            <img
                              alt=""
                              src="/images/upgradeNow.svg"
                              className="upgradeText"
                            />
                          </Link>
                        </div>
                      ) : (
                        <div
                          className="w-fit-content pointer p-1"
                          onClick={(event) => {
                            TrackUser("Upgrade button clicked off");
                            upgradeProHandler(event);
                          }}
                        >
                          <img
                            alt=""
                            src="/images/attack-surface/chevron-down.svg"
                            className="closeButton"
                            onClick={(event) => {
                              TrackUser("Upgrade button clicked off");
                              upgradeProHandler(event);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="text-center">
                        <img
                          alt=""
                          width={25}
                          src={upgradePopupData?.LogoImageUrl}
                          className=""
                        />
                      </div>

                      <p className="text-center my-1 upgrade_pro_title">
                        {upgradePopupData?.Title}
                      </p>
                      {upgradePopupData?.SubTitle && (
                        <div className="f-12 f-500 text-center f-grey">
                          {upgradePopupData?.SubTitle}
                        </div>
                      )}
                      {upgradePopupData?.FeatureHeader && (
                        <div className="f-12 f-400  ">
                          {upgradePopupData?.FeatureHeader}
                        </div>
                      )}

                      {upgradePopupData?.Features?.map((_data) => {
                        return (
                          <div className="d-flex align-self-center mt-2">
                            <img
                              width={12}
                              height={12}
                              alt=""
                              src={_data?.IconUrl}
                            />
                            <span
                              className="linkGreyText"
                              style={{ paddingLeft: "5px" }}
                            >
                              {_data?.Feature}
                            </span>
                          </div>
                        );
                      })}

                      {upgradePopupData?.FeatureFooter && (
                        <div className="f-12 f-400  mt-2">
                          {upgradePopupData?.FeatureFooter}
                        </div>
                      )}

                      <div
                        className="mt16px mb-2 text-center"
                        onClick={() => TrackUser("Upgrade Now button clicked")}
                      >
                        {/* <img src="/images/upgradeBell.svg" /> */}
                        <Link
                          // to="/system_settings?tab=subscriptions"
                          to={upgradePopupData?.SubmitButton?.ButtonUrl}
                          className="urgradeTextSidebar"
                        >
                          <img
                            alt=""
                            src={upgradePopupData?.SubmitButton?.ButtonImage}
                            className="mr-2"
                            width={20}
                            height={20}
                          />

                          {upgradePopupData?.SubmitButton?.ButtonText}
                        </Link>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SettingsSideNav;
