// import { setMaxListeners } from 'gulp';
import React, { Fragment } from "react";
import { useAuth } from "../context/Auth";
import { useState } from "react";
import { useEffect } from "react";
import { getAPIData } from "../../Utils/commonApi";
import { Col, Row, Accordion, } from "react-bootstrap";
import "./CollapsabileTable.css";
import ContentLoader from "react-content-loader";

const CollapsabileTable = (props) => {

  const pageType = props.pageType;
  const { authTokens, setAuthTokens } = useAuth();

  const [tableHeaders, settableHeaders] = useState(false);
  const [tableRows, settableRows] = useState(false);
  const [tableHeaderColour, settableHeaderColour] = useState(false);
  const [bottomLabel, setbottomLabel] = useState(false);
  const [preReqObj, setpreReqObj] = useState();
  const [activeKey, setActiveKey] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  let bannerAlreadySet = false;
  function getPageNumberFromPageType(pageType) {
    switch (pageType) {
      case "Technical Drilldown Cyber Score":
        settableHeaderColour("#008FD4");
        setbottomLabel("CyberScore");
        return "16";
      case "Technical Drilldown CyberThreat Level":
        settableHeaderColour("#FFAC2C");
        setbottomLabel("CyberThreat");
        return "17";
      case "Technical Drilldown Cyber Compliance":
        settableHeaderColour("#008FD4");
        setbottomLabel("Cyber Compliance");
        return "53";
      case "Active Risks":
        settableHeaderColour("#008FD4");
        setbottomLabel("CyberScore");
        return "54";
      case "Active Threats":
        settableHeaderColour("#008FD4");
        setbottomLabel("CyberScore");
        return "55";
      default:
        break;
    }
  }

  async function generateFormObject(page) {
    let data = await getAPIData(page, authTokens, {
      optionEnum1: props.clientId ?? 0
    });
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      data = await getAPIData(page, data.authToken, {
        optionEnum1: props.clientId ?? 0
      });
    }
    if (props.changeHeading) {
      props.changeHeading(data.PageTitle);
    }

    if (page === "16") {
      settableHeaders([
        { name: "Technology", space: "20%" },
        { name: "Impact on CyberScore", space: "20%" },
        { name: "Running CyberScore", space: "20%" },
        { name: "Details", space: "35%" },
      ]);
    } else if (page === "17") {
      settableHeaders([
        { name: "Technology", space: "20%" },
        { name: "Impact on CyberThreat Level", space: "20%" },
        { name: "Running CyberThreat Level", space: "20%" },
        { name: "Details", space: "35%" },
      ]);
    } else if (page === "53") {
      settableHeaders([
        { name: "Compliance Component", space: "20%" },
        { name: "Impact on Score", space: "20%" },
        { name: "Running Score", space: "20%" },
        { name: "Details", space: "35%" },
      ]);
    } else if (page === "55") {
      settableHeaders([
        { name: "Technology", space: "20%" },
        { name: "Impact on CyberScore", space: "20%" },
        { name: "Running CyberScore", space: "20%" },
        { name: "Details", space: "35%" },
      ]);
    } else if (page === "54") {
      settableHeaders([
        { name: "Technology", space: "20%" },
        { name: "Impact on CyberThreat Level", space: "20%" },
        { name: "Running CyberThreat Level", space: "20%" },
        { name: "Details", space: "35%" },
      ]);
    }
    var dataFiltered = data.dataRows.filter((obj) => obj.PreRequisite == true);
    if (dataFiltered !== undefined && dataFiltered.length > 0) {
      setpreReqObj(dataFiltered[0].Component);
    }
    settableRows(data);
  }

  useEffect(() => { }, [preReqObj]);

  useEffect(() => {
    generateFormObject(getPageNumberFromPageType(pageType));
  }, []);

  const handleAccordion = (e, index, data) => {
    if (data.ShowExpandIcon == false) {
      return;
    }
    if (e.currentTarget.nextSibling.classList.contains("show")) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
    setActiveKey(index);
  };
  if (tableRows && tableRows.authToken === undefined) {
    return (
      <div className="collapContainer collapContainer80">
        <div
          className="collapHeading d-flex"
          style={
            tableHeaderColour
              ? { background: tableHeaderColour }
              : { color: "inherit" }
          }
        >
          {tableHeaders
            ? tableHeaders.map((element) => {
              return (
                <div style={{ width: element.space }}>
                  <p>{element.name}</p>
                </div>
              );
            })
            : ""}
          <div style={{ width: "5%" }}>
            <p></p>
          </div>
        </div>
        {tableRows ? (
          <React.Fragment>
            <div>
              <Accordion className="table-rows">
                {tableRows.dataRows.map((element, i) => {
                  if (element.PreRequisite || !bannerAlreadySet) {
                    bannerAlreadySet = true;
                  }

                  return (
                    <Fragment>
                      {tableRows.ShowPrerequisites ? (
                        element.Component == preReqObj ? (
                          <Row className="collapContent p-0">
                            <Col
                              xs={12}
                              className="table-banner d-flex align-items-center justify-content-center"
                            >
                              <h1 className="text-center align-middle preReqText">
                                The following are Pre-requisites for CyberCare.
                                If not maintained, they can reduce your
                                CyberScore.
                              </h1>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <div className="collapContent">
                        <Accordion.Toggle
                          as={Row}
                          variant="link"
                          eventKey={i + 1}
                          className="w-100 accordin-row d-flex cursor-pointer"
                          onClick={(e) => {
                            handleAccordion(e, i + 1, element);
                          }}
                        >
                          <div style={{ width: tableHeaders[0].space }}>
                            <p>{element.Component}</p>
                          </div>
                          <div
                            style={
                              element.Color
                                ? {
                                  color: element.Color,
                                  width: tableHeaders[1].space,
                                }
                                : {
                                  color: "inherit",
                                  width: tableHeaders[1].space,
                                }
                            }
                          >
                            <p>{element.ImpactOnScore}</p>
                          </div>
                          <div style={{ width: tableHeaders[2].space }}>
                            <p>{element.RemainingScore}</p>
                          </div>
                          <div style={{ width: tableHeaders[3].space }}>
                            <div className="panel-heading d-flex align-items-center">
                              <p className="mr-4">
                                {" "}
                                <img src={element.ComponentIcon} alt="" />
                              </p>
                              <p class="collapDetails">{element.Details}</p>
                            </div>
                          </div>
                          <div style={{ width: "5%" }}>
                            {element.ShowExpandIcon && (
                              <div className="float-right align-self-center">
                                {activeKey == i + 1 && isCollapsed ? (
                                  <img src="/images/icons/arrowUp.svg" alt="" />
                                ) : (
                                  <img
                                    src="/images/icons/arrowDown.svg"
                                    alt=""
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </Accordion.Toggle>
                        {element.ShowExpandIcon && (
                          <Accordion.Collapse eventKey={i + 1}>
                            <div className="accordion-collapse">
                              <hr />
                              {element.ComplianceRowDetailList &&
                                element.ComplianceRowDetailList.length > 0 ? (
                                element.ComplianceRowDetailList.map(
                                  (detail) => {
                                    return (
                                      <>
                                        <div className="d-flex">
                                          <img
                                            className="align-self-center"
                                            src={detail.Icon}
                                          />
                                          <p
                                            className="ml-2"
                                            style={{ maxWidth: "94%" }}
                                          >
                                            {detail.Description}
                                          </p>

                                          <p
                                            className="ml-2"
                                            style={{
                                              color: detail.ScoreValueColour,
                                            }}
                                          >
                                            {detail.ScoreValue}
                                          </p>
                                        </div>
                                        <div>
                                          {detail.HasTooltip ? (
                                            <p
                                              className="ml-2 greyText pt-1 pb-1"
                                              style={{ paddingLeft: "15px" }}
                                            >
                                              {detail.TooltipText}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <p>{element.DrillDown}</p>
                              )}
                              {element.ComplianceInfo ? (
                                <div className="d-flex mt24px">
                                  <img
                                    src={element.ComplianceInfoIcon}
                                    type="image/svg+xml"
                                  />
                                  <p className="ml-2 align-self-center txt-blue">
                                    {element.ComplianceInfo}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </Accordion.Collapse>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
                <div className="collapBottomDiv">
                  <p>
                    <span>Your </span>
                    {tableRows.ScoreText
                      ? tableRows.ScoreText + " "
                      : bottomLabel + " Level "}
                    <span style={{ paddingRight: ".7em" }}>is </span>{" "}
                    {tableRows.ScoreValue}{" "}
                    <span style={{ color: tableRows.ScoreRatingColour }}>
                      {" "}
                      &nbsp;&nbsp;{tableRows.ScoreRating}
                    </span>
                  </p>
                </div>
              </Accordion>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return (
      <div className="collapContainer collapContainer50">
        <ContentLoader
          speed={2}
          width={900}
          height={500}
          viewBox="0 0 900 500"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="13" y="16" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="90" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="164" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="238" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="312" rx="0" ry="0" width="900" height="52" />
        </ContentLoader>
      </div>
    );
  }
};
export default CollapsabileTable;
