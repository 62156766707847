import React, { useState, useEffect } from "react";
import "./IRNotesChatroom.css";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { FiPaperclip } from "react-icons/fi";
import { Alert, Button, Spinner } from "react-bootstrap";
import { generateGravatar, getRequestOptions } from "../../../Utils/commonApi";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useAuth } from "../../context/Auth";
import { useDb } from "../../context/db";
import LoadingSpinner from "../../Spinner/Spinner";

const IRNotesChatroom = (props) => {
  var convertTime = require("convert-time");
  const BizObjectId = props.bizObjectId;
  const [currentTicketId, setCurrentTicketId] = useState(props.currentId || 0);
  const [allTicketIds, setAllTicketIds] = useState(props.allIds || 0);
  const [currentTicketNotes, setCurrentTicketNotes] = useState(
    props.notes || 0
  );
  const incidentStatus = props.title || "";
  const { authTokens } = useAuth();
  const { dbObj } = useDb();
  const [alertDialog, setalertDialog] = useState(false);
  const [downloadImage, setDownloadImage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [sendMsg, setSendMsg] = useState(true);
  //data for filling out form
  let prevDate = false;
  const genericImage =
    "https://cybercareimages.blob.core.windows.net/icons/DefaultAvatar.png";
  const [userImages, setUserImages] = useState(false);

  //form data (user sending message)
  const [file, setFile] = useState();
  const [message, setMessage] = useState();
  const [base64Img, setBase64Img] = useState(false);

  const onFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
    };
    reader.readAsDataURL(file);
    setFile(file);
  };
  async function DownloadFileHandler(id, FileName) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/DownloadConnectwiseFile?id=${id}&fileName=${FileName}`,
      getRequestOptions(authTokens)
    ).then((response) => {
      response.blob().then((blob) => {
        const image = new Blob([blob], { type: "image/png" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(image);
        link.href = url;
        link.download = "image.png";
        link.click();
      });
    });
  }

  useEffect(() => {
    updateCurrentTicketNotes();
    setGravatarArray();

    // refresh every 10 seconds
    var refreshInterval = setInterval(() => {
      updateCurrentTicketNotes();
    }, 7000);

    return function cleanup() {
      clearInterval(refreshInterval);
    };
  }, []);

  async function setGravatarArray() {
    let gravatarImagesList = [];

    for (let i = 0; i < currentTicketNotes.length; i++) {
      const element = currentTicketNotes[i];

      const result = await generateGravatar(element.userDetails.Avatar);
      if (result) gravatarImagesList.push(element.userDetails.Avatar);
      else gravatarImagesList.push("genericAvatar");
    }

    setUserImages(gravatarImagesList);
  }

  async function updateCurrentTicketNotes() {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetIncidentReportNotes?ticketId=${currentTicketId}&bizObjectId=${BizObjectId}&accessKey=${JSON.parse(
        localStorage.getItem("user_MessageObj")
      )}`,
      getRequestOptions(authTokens)
    ).then((response) => response.json());
    setCurrentTicketNotes(data.cwTicketNotes);
  }

  async function sendMessage() {
    setSendMsg(false);
    setIsSending(true);
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/AddNewNoteToTicket`,
      getRequestOptions(authTokens, "POST", {
        ticketId: currentTicketId,
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Note: message
          ? "*Username -" + dbObj.UserDetail.FullName + "*" + message
          : "na",
        Files: file,
      })
    ).then((response) => response.json());
    setMessage("");
    // setalertDialog(
    //   <Alert
    //     variant={data.Success ? "success" : "danger"}
    //     onClose={() => setalertDialog()}
    //     style={{ position: "absolute", left: "5%", width: "90%" }}
    //     dismissible
    //   >
    //     {data.Message}
    //   </Alert>
    // );

    if (data.Success) {
      setIsSending(false);
      setIsSent(true);
      updateCurrentTicketNotes();
    }
  }

  useEffect(() => {
    if (message) {
      setIsSent(false);
      setSendMsg(true);
    }
  }, [message]);

  if (currentTicketNotes !== undefined) {
    return (
      <React.Fragment>
        <div className="messageContainer">
          {/* {alertDialog} */}
          {/* <div className="messageTop">
            <span className="messageHeading"> */}
          {/* <GoChevronLeft /> */}
          {/* <GoChevronRight /> */}

          {/* <span>
                <p>#{currentTicketId}</p>
                <p>{incidentStatus}</p>
              </span>
            </span>
            <hr />
          </div> */}
          <div className="messageBody">
            {currentTicketNotes.map((element, index) => {
              if (prevDate === false) prevDate = "next";
              else if (prevDate !== element.date.split("T")[0]) {
                prevDate = element.date.split("T")[0];
              }
              return (
                <div className="message">
                  {prevDate === false ||
                    prevDate !== element.date.split("T")[0] ? (
                    <span className="messageDate">
                      <p>{element.date.split("T")[0]}</p>
                    </span>
                  ) : (
                    ""
                  )}
                  <span className="messageUserInfo">
                    <span>
                      {/* USER DETAILS WILL BE DISPLAYED HERE */}
                      {/* {userImages && userImages[index] != "genericAvatar" ? (
                        <img
                          className="techtableImageInline userAvatar"
                          src={userImages[index]}
                          alt="users' avatar image"
                        />
                      ) : (
                        <img
                          className="techtableImageInline userAvatar"
                          src={genericImage}
                          alt="generic avatar placeholder"
                        />
                      )} */}

                      {!element.userDetails.Avatar ? (
                        <img
                          className="techtableImageInline userAvatar"
                          src={element.userDetails.Avatar}
                          alt="profile"
                        />
                      ) : (
                        <img
                          className="techtableImageInline userAvatar"
                          src={element.userDetails.Initials}
                          alt="profile"
                        />
                      )}
                      <p className="userName">{element.userDetails.FullName}</p>
                      <p className="userType">{element.userDetails.Role}</p>
                    </span>
                    <p className="messageTime">
                      {convertTime(element.date.split("T")[1])}
                    </p>
                  </span>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: element.text.replaceAll(("<br>", "\n"), "<br>"),
                    }}
                  />
                  {element.Document_Thumbnails !== null &&
                    element.Document_Thumbnails.length ? (
                    <>
                      <div
                        onClick={() => {
                          DownloadFileHandler(
                            element.Document_Thumbnails &&
                            element.Document_Thumbnails[0].DocumentId,
                            element.Document_Thumbnails &&
                            element.Document_Thumbnails[0].DocumentFileName
                          );
                        }}
                      >
                        <div className="messageFileContainer">
                          <img
                            className="techtableImageInline messageFile"
                            src={
                              element.Document_Thumbnails &&
                              element.Document_Thumbnails[0] &&
                              element.Document_Thumbnails[0].ThumbnailImage
                            }
                            alt="generic avatar placeholder"
                          />
                          <div class="middle">
                            <img src="/images/icons/download.svg" alt="" />
                          </div>
                        </div>
                      </div>

                      <p>
                        {element.Document_Thumbnails &&
                          element.Document_Thumbnails[0] &&
                          element.Document_Thumbnails[0].DocumentFileName}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
          <div className="messageBottom">
            <div class="messageInput">
              <div className="fileIcon">
                <FiPaperclip />
                <input
                  type="file"
                  name="file"
                  onChange={(event) => {
                    onFileUpload(event);
                  }}
                />
              </div>
              <input
                type="text"
                value={message}
                placeholder="Type your message..."
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
              />
              <Button
                onClick={sendMessage}
                style={{ marginLeft: "8px", float: "right" }}
                className={` ${isSent ? "btn-disable bgIRBtn" : ""}`}
              >
                {sendMsg ? (
                  "Send"
                ) : isSending ? (
                  <span>
                    <Spinner
                      animation="border"
                      variant="light"
                      className="mr8px"
                      size="sm"
                    />
                    Sending
                  </span>
                ) : isSent ? (
                  "Sent!"
                ) : (
                  ""
                )}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <LoadingSpinner />;
  }
};

export default IRNotesChatroom;
