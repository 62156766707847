import React, { useState, useEffect } from "react";
import { getAPIData, SubmitQuestionaire } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./cdsa.scss";
import CDSAmainpageloader from "../AttackSurfaceLoaders/CDSAmainpageloader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { AttackSurfaceResetModal } from "../AttackSurfaceResetModal";
import { viewOnlyAttackSurface } from "../../../Utils/AccessLevelFunctions";
import { viewOnlyAccess } from "../../App/App";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
const VulnerbilityManagement = ({ refreshMenuItems }) => {
  const { authTokens } = useAuth();
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [vulnerbilityQuestions, setVulnerbilityQuestions] = useState([]);
  const [showResetModal, setShowResetModal] = useState(false);
  const [originalVulnerbilityQuestions, setOriginalVulnerbilityQuestions] =
    useState([]);
  const [penetrationTestQuestions, setPenetrationTestQuestions] = useState([]);
  const [
    originalPenetrationTestQuestoins,
    setOriginalPenetrationTestQuestoins,
  ] = useState([]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setMainPageLoading(true);
    getPageData();
  }, []);
  function getPageData(success = false) {
    getAPIData(265, authTokens, {
      optionEmun1: 0
    }).then((response) => {
      setMainPageLoading(false);

      if (response?.mr?.Success) {
        // Vulnerbility Setup
        setOriginalVulnerbilityQuestions(
          response?.QuestionnaireSection_List?.[0]?.Question_List
        );

        if (success) {
          let updated_data = [...vulnerbilityQuestions].map((item, index) => {
            return {
              ...item,
              AnswerId:
                response?.QuestionnaireSection_List?.[0]?.Question_List[index]
                  .AnswerId,
            };
          });

          setVulnerbilityQuestions(updated_data);
        } else {
          let updated_data = [
            ...response?.QuestionnaireSection_List?.[0]?.Question_List,
          ].map((item) => {
            return {
              ...item,
              selectedDropdownValue: item?.AnswerText
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                  return {
                    id: index + 1,
                    category: listItem,
                  };
                }).filter(
                  (filterItem) => filterItem.category === item?.AnswerText
                )[0]
                : null,
              dropDowndata: item?.QuestionDropdownListMembers
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                  return {
                    id: index + 1,
                    category: listItem,
                  };
                })
                : [],
              loading: false,
            };
          });

          setVulnerbilityQuestions(updated_data);
        }

        //  For Penetration Test Setup
        setOriginalPenetrationTestQuestoins(
          response?.QuestionnaireSection_List?.[1]?.Question_List
        );

        if (success) {
          let updated_data = [...penetrationTestQuestions].map(
            (item, index) => {
              return {
                ...item,
                AnswerId:
                  response?.QuestionnaireSection_List?.[1]?.Question_List[index]
                    .AnswerId,
              };
            }
          );

          setPenetrationTestQuestions(updated_data);
        } else {
          let updated_data_pen = [
            ...response?.QuestionnaireSection_List?.[1]?.Question_List,
          ].map((item) => {
            return {
              ...item,
              selectedDropdownValue: item?.AnswerText
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                  return {
                    id: index + 1,
                    category: listItem,
                  };
                }).filter(
                  (filterItem) => filterItem.category === item?.AnswerText
                )[0]
                : null,
              dropDowndata: item?.QuestionDropdownListMembers
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                  return {
                    id: index + 1,
                    category: listItem,
                  };
                })
                : [],
              loading: false,
            };
          });

          setPenetrationTestQuestions(updated_data_pen);
        }
      }
    });
  }

  const SubmitQuestions = async (data_to_send) => {
    await SubmitQuestionaire(authTokens, "POST", {
      ObjectId: 0,
      QuestionnaireTypeEnum: 8,
      QuestionList: JSON.stringify(data_to_send),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (response?.Success) {
        CallToast("Updated successfully", false, false, "v2style");
        updateAccessKeyInLocalStorage(response?.Message)
         
        // getPageData(response?.Success);
        // refreshMenuItems();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };
  return (
    <>
      {mainPageLoading ? (
        <CDSAmainpageloader />
      ) : (
        <div className="cdsa-wrapper"
          style={{
            pointerEvents: viewOnlyAttackSurface() ? "none" : "all"
          }}

        >
          {/* Vulnerbility Management */}
          <div className="font-weight-bold mt-3 mb-2">
            Vulnerbility Management
          </div>
          <div className="bg-grey radius-8 border-parent">
            {vulnerbilityQuestions?.map((question, questionIndex) => {
              return (
                <div
                  className="d-flex flex-column border-item"
                  onClick={() => {
                    // goTo(vulnerbility.name);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100  px-4  py-3">
                    <div
                      className="d-flex align-align-items-baseline "
                      style={{ width: "75%" }}
                    >
                      <div>
                        <img
                          alt=""
                          src="/images/attack-surface/yellow-dot.svg"
                          className="mr-2"
                          style={{
                            visibility: `${question?.AnswerId === 0 ? "visible" : "hidden"
                              }`,
                          }}
                        />
                      </div>
                      <p className="f-500 m-0">{question?.QuestionText}</p>
                      {/* <p className="f-12 f-darkgrey"> {service.noemptyStatus} </p> */}
                    </div>
                    <div>
                      {
                        viewOnlyAccess("AccessState_AttackSurface") ?
                          <div className="f-darkgrey f-500 mx-3 important-width">
                            {question?.selectedDropdownValue?.category ?? "No Selected"}
                          </div>
                          :
                          <CompanySettingsDropdown
                            fieldId={"id"}
                            fieldValue={"category"}
                            selected={question.selectedDropdownValue}
                            placeholder="Select"
                            //  showid={true}
                            onClick={(id, val) => {
                              //   To send Data to API
                              let data_to_send = [...originalVulnerbilityQuestions];
                              data_to_send[
                                questionIndex
                              ].AnswserSelectedDropdownItems = [val];
                              data_to_send[questionIndex].QuestionStatus = 3;
                              data_to_send[questionIndex].QuestionStatusText =
                                "Answered";
                              data_to_send[questionIndex].AnswerText = val;
                              setOriginalVulnerbilityQuestions(data_to_send);
                              SubmitQuestions(data_to_send);

                              // To Update the drop down value
                              let updated_data = [...vulnerbilityQuestions];
                              updated_data[questionIndex].selectedDropdownValue = {
                                id,
                                category: val,
                              };
                              updated_data[questionIndex].AnswerId = 123;
                              setVulnerbilityQuestions(updated_data);
                            }}
                            data={question?.dropDowndata}
                          />
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* penetration tests */}

          <div className="font-weight-bold mt-3 mb-2">Penetration Tests</div>
          <div className="bg-grey radius-8 border-parent">
            {penetrationTestQuestions.map((question, questionIndex) => {
              return (
                <div
                  className="d-flex flex-column border-item"
                  onClick={() => {
                    // goTo(vulnerbility.name);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100  px-4  py-3">
                    <div
                      className="d-flex align-align-items-baseline "
                      style={{ width: "75%" }}
                    >
                      <div>
                        <img
                          alt=""
                          src="/images/attack-surface/yellow-dot.svg"
                          className="mr-2"
                          style={{
                            visibility: `${question?.AnswerId === 0 ? "visible" : "hidden"
                              }`,
                          }}
                        />
                      </div>
                      <p className="f-500 m-0">{question?.QuestionText}</p>
                      {/* <p className="f-12 f-darkgrey"> {service.noemptyStatus} </p> */}
                    </div>
                    <div>
                      {
                        viewOnlyAccess("AccessState_AttackSurface") ?
                          <div className="f-darkgrey f-500 mx-3 important-width">
                            {question?.selectedDropdownValue?.category ?? "No Selected"}
                          </div>
                          :
                          <CompanySettingsDropdown
                            fieldId={"id"}
                            fieldValue={"category"}
                            selected={question.selectedDropdownValue}
                            placeholder="Select"
                            //  showid={true}
                            onClick={(id, val) => {
                              //   To send Data to API
                              let data_to_send = [
                                ...originalPenetrationTestQuestoins,
                              ];
                              data_to_send[
                                questionIndex
                              ].AnswserSelectedDropdownItems = [val];
                              data_to_send[questionIndex].QuestionStatus = 3;
                              data_to_send[questionIndex].QuestionStatusText =
                                "Answered";
                              data_to_send[questionIndex].AnswerText = val;
                              setOriginalPenetrationTestQuestoins(data_to_send);
                              SubmitQuestions(data_to_send);

                              // To Update the drop down value
                              let updated_data = [...penetrationTestQuestions];
                              updated_data[questionIndex].selectedDropdownValue = {
                                id,
                                category: val,
                              };
                              updated_data[questionIndex].AnswerId = 123;
                              setPenetrationTestQuestions(updated_data);
                            }}
                            data={question?.dropDowndata}
                          />
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Reset button */}

          <div
            className={`f-grey  mt-4 ml-2 ${viewOnlyAccess("AccessState_AttackSurface") ? 'cursor-default' : 'pointer'} `}
            onClick={() => {
              if (!viewOnlyAccess("AccessState_AttackSurface")) {
                setShowResetModal(true);
              }
            }}
          >
            {
              viewOnlyAccess("AccessState_AttackSurface") ?
                <img
                  alt=''
                  className='lockerDarkGreyImage mr-2 mb-1'
                  src='/images/settings-v2/locker-grey.svg'
                />
                :
                <img
                  alt=""
                  src="/images/attack-surface/loader-icon.svg"
                  className="mr-2"
                />
            }
            Reset answers
          </div>
        </div>
      )}
      <AttackSurfaceResetModal
        refreshData={getPageData}
        refreshMenuItems={refreshMenuItems}
        resetPageEnum={269}
        optionEnum1={20}
        show={showResetModal}
        hideModal={() => {
          setShowResetModal(false);
        }}
      />
    </>
  );
};

export default VulnerbilityManagement;
