import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PackageListRadarUI } from "./MspClientPriceCalculator";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

export const SendPackageModal = ({
    show,
    pageData,
    clientId,
    hideModal
}) => {
    const { authTokens } = useAuth();
    const [selectedPackages, setSelectedPackages] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [emailErrors, setEmailErrors] = useState({
        emptyEmail: false,
        formatEmail: false
    })
    const [isValidationChecked, setIsvalidationChecked] = useState(false)
    const handleEmailValidations = () => {
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (inputValue == "") {
            setEmailErrors((prev) => ({
                ...prev,
                emptyEmail: true
            }));
        } else if (inputValue && !re.test(inputValue)) {
            setEmailErrors((prev) => ({
                ...prev,
                formatEmail: true
            }));
        }
        setIsvalidationChecked(true);
    }

    async function sharePackage() {
        await getAPIData(687, authTokens, {
            optionEnum2: clientId,
            optionStrEnum1: inputValue,
            optionStrEnum2: selectedPackages.join(',')
        }).then((response) => {
            if (response) {
                CallToast(`Pricing was sent to "${inputValue}"`, false, false, "v2style");
            } else {
                CallToast("Something went wrong", false, false, "v2style");
            }
        })
            .catch((err) => {
                console.log(err);
                CallToast("Something went wrong", false, false, "v2style");
            });
    }


    const addOrRemoveFromArray = (itemId, isChecked) => {
        if (isChecked) {
            // Add item to selectedPackages array
            setSelectedPackages(prevState => [...prevState, itemId]);
        } else {
            // Remove item from selectedPackages array
            setSelectedPackages(prevState => prevState.filter(item => item !== itemId));
        }
    };

    const graphColors = [
        "#008FD4",
        "#5244B3",
        "#40D88E",
        "#F36060",
        "#DF6844",
        "#99D2EE",
        "#BAB4E1",
        "#B3EFD2",
        "#FABFBF",
        "#F2C3B4",
    ];

    return (
        <Modal
            show={show}

            dialogClassName="sendd-package-modal"
            className=" "
            aria-labelledby="sendd-package-modal"
            centered
            onHide={() => {
                hideModal();
                setIsvalidationChecked(false);
                setEmailErrors({
                    emptyEmail: false,
                    formatEmail: false
                })
                setInputValue('');
                setSelectedPackages([]);
            }}
        >
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="">
                        <div className="f-darkgrey f-400 f-12">Share scenarios:</div>
                        <div className="scroll">

                            {pageData?.ScenarioHheaderList?.map((_package, _packageIndex) => {

                                return (
                                    <div
                                        className="content-block border-item d-flex align-items-center justify-content-between  bg-hover-dark-grey hover-on-show-parent"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="msp-package-list-checkbox position-relative   w-fit-content mr-4"
                                                style={{ height: "20px" }}
                                            >
                                                <Form.Group
                                                    controlId={"check" + _package.objId_Scenario}
                                                    className="round-checkbox blue-checkbox checkboxstyle"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        name={"check" + _package.objId_Scenario}
                                                        label=""
                                                        checked={selectedPackages.includes(_package.objId_Scenario)}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            addOrRemoveFromArray(_package.objId_Scenario, isChecked);
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="rounded-full mx-2"
                                                    style={{
                                                        width: "8px",
                                                        height: "8px",
                                                        backgroundColor: graphColors[_packageIndex % graphColors.length],
                                                    }}
                                                />
                                                <div>
                                                    <div className="f-500 f-14">{_package?.Name} </div>
                                                    <div
                                                        style={{
                                                            color: '#008FD4'
                                                        }}>{_package?.MonthlyPrice_Text}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/* Radar Chart */}
                                        <div className="mr-2">
                                            <PackageListRadarUI
                                                dataList={[
                                                    _package?.InspectScore,
                                                    _package?.ProtectScore,
                                                    _package?.DetectScore,
                                                    _package?.RespondScore,
                                                    _package?.RecoverScore,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </div>



                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className="d-flex ">
                    <div
                        className={` d-flex align-items-center radius-4 grey-border   flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
                    >
                        <div
                            className="d-flex align-items-center bg-transparent w-100 border-none pl-2"
                            tabIndex={-1}
                        >
                            <div className="f-darkgray"
                                style={{
                                    width: '52px'
                                }}>Mail to:</div>
                            <input
                                placeholder="example@mail.com"
                                type="text"
                                className={`flex-grow-1 border-none `}
                                value={inputValue}
                                onChange={(e) => {
                                    setEmailErrors({
                                        emptyEmail: false,
                                        formatEmail: false
                                    })
                                    setIsvalidationChecked(false)
                                    setInputValue(e.target.value);
                                }}
                                onBlur={(e) => {
                                    handleEmailValidations(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <Button
                        className={"hg-blue-btn"}
                        disabled={!isValidationChecked ? true : (!emailErrors.emptyEmail && !emailErrors.formatEmail) ? false : true}
                        style={{ height: "fit-content", alignSelf: "end" }}
                        onClick={() => {
                            sharePackage().then(() => {
                            })
                            hideModal();
                            setIsvalidationChecked(false);
                            setEmailErrors({
                                emptyEmail: false,
                                formatEmail: false
                            })
                            setInputValue('');
                            setSelectedPackages([]);
                        }}
                    >
                        Send
                    </Button>
                </div>
                {emailErrors?.emptyEmail && (
                    <div className="f-danger f-12">
                        Email cannot be empty
                    </div>
                )}
                {!emailErrors?.emptyEmail && emailErrors?.formatEmail && (
                    <div className="f-danger f-12">
                        Invalid email address format
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SendPackageModal;







// import React, { useMemo, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
// import { getAPIData } from "../../../Utils/commonApi";
// import { CallToast } from "../../PriceCalculator/modals/CustomToast";
// import { useAuth } from "../../context/Auth";
// import MspClientPriceCalculatorRadarChart from "./MspClientPriceCalculatorRadarChart";
// import MspClientPriceCalculatorRadarChartSmall from "./MspClientPriceCalculatorRadarChartSmall";

// export const SendPackageModal = ({
//     show,
//     packageData,
//     counts,
//     clientId,
//     activeChart,
//     tabsData,
//     hideModal
// }) => {
//     console.log('packageData --------- ', packageData)
//     const { authTokens } = useAuth();
//     const [inputValue, setInputValue] = useState('')
//     const [emailErrors, setEmailErrors] = useState({
//         emptyEmail: false,
//         formatEmail: false
//     })
//     const [isValidationChecked, setIsvalidationChecked] = useState(false)
//     const handleEmailValidations = () => {
//         const re =
//             /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

//         if (inputValue == "") {
//             setEmailErrors((prev) => ({
//                 ...prev,
//                 emptyEmail: true
//             }));
//         } else if (inputValue && !re.test(inputValue)) {
//             setEmailErrors((prev) => ({
//                 ...prev,
//                 formatEmail: true
//             }));
//         }
//         setIsvalidationChecked(true);
//     }

//     async function sharePackage() {
//         await getAPIData(687, authTokens, {
//             optionEnum1: packageData?.ScenarioHeader?.objId_Scenario,
//             optionEnum2: clientId,
//             optionStrEnum1: 'polaivanec@gmail.com'   ///!!!!!!!!
//         }).then((response) => {
//             if (response?.mr?.Success) {
//                 CallToast("Email send to ", false, false, "v2style");
//             } else {
//                 CallToast("Something went wrong", false, false, "v2style");
//             }
//         })
//             .catch((err) => {
//                 console.log(err);
//                 CallToast("Something went wrong", false, false, "v2style");
//             });
//     }

//     const labels = ["Identity", "Protect", "Detect", "Respond", "Recover"]

//     const ACTIVE_CHART = useMemo(
//         () => activeChart,
//         [activeChart]
//     );

//     const TABS_DATASETS = useMemo(
//         () => tabsData.map((item) => item.data),
//         [tabsData.length]
//     );


//     return (
//         <Modal
//             show={show}

//             dialogClassName="share-package-modal"
//             className=" "
//             aria-labelledby="share-package-modal"
//             centered
//             onHide={hideModal}
//         >
//             <Modal.Header>
//                 <div className="d-flex align-items-center justify-content-between w-100">
//                     <div></div>
//                     <div className="f-400 ml-3 f-14">Submit Quote Enquiry</div>
//                     <img
//                         alt=""
//                         src="/images/user-detail-close.svg"
//                         className="mr-3 pointer"
//                         onClick={hideModal}
//                     />
//                 </div>
//             </Modal.Header>
//             <Modal.Body>
//                 <div>
//                     <div className="d-flex justify-content-between align-items-start">
//                         <div className="left-block">
//                             <div className="f-darkgrey f-400 f-12">Details</div>
//                             <div className="counts-block">{`Users: ${counts.NoOfUsers}, Workstations: ${counts.NoOfWorkstation}, Mobile Devices: ${counts.NoOfMobileDevices}, 
//                         Servers: ${counts.NoOfServices}, Routers/Firewalls: ${counts.NoOfRouters}, Network Devices: ${counts.NoOfNetworkDevices}, Domains: ${counts.NoOfDomains}`}</div>
//                             <div className="share_table border-bottom">
//                                 <div className="d-flex align-items-center circleHeader  mr-2">
//                                     <div
//                                         className="circleDiv"
//                                         style={{
//                                             background: `${packageData.circleColour}`,
//                                         }}
//                                     />
//                                     <span
//                                         style={{
//                                             color: "#008FD4"
//                                         }}
//                                         className="ml-2 mr-2"
//                                     >
//                                         {packageData?.Name}
//                                     </span>
//                                 </div>
//                                 <div className="f-darkgrey f-400 f-12">Points</div>
//                                 <div className="f-darkgrey f-400 f-12">Anount</div>
//                             </div>
//                             <div>
//                                 {
//                                     packageData?.pillarList?.map((service, index) => {
//                                         console.log('service?.dataList[0]?.BillingPeriod_Selected?.DDLColour', service?.dataList[0]?.BillingPeriod_Selected?.DDLColour)
//                                         return (
//                                             <div className="share_table h-22px">
//                                                 <div className="f-black">
//                                                     {service?.dataList[0]?.Headline ?? labels[index]}
//                                                 </div>
//                                                 <div
//                                                     style={{
//                                                         color: service?.dataList[0]?.[`Delta_${labels[index]}`] ? `${packageData.circleColour}` : '#BBBEC9'
//                                                     }}
//                                                 >
//                                                     {`${service?.dataList[0]?.[`Delta_${labels[index]}`] || 'None'}`}
//                                                 </div>
//                                                 <div className="f-black">
//                                                     {`${service?.dataList[0]?.MonthlyPrice_Text ?? ''}${service?.dataList[0]?.BillingPeriod_Selected?.DDLColour ? ' /' : ''}${service?.dataList[0]?.BillingPeriod_Selected?.DDLColour ?? ''}`}
//                                                 </div>
//                                             </div>
//                                         )
//                                     }
//                                     )
//                                 }
//                             </div>
//                             <div className="share_table border-top ">
//                                 <div className="f-black f-500 f-14">Total</div>
//                                 <div> </div>
//                                 <div className="txt-blue f-400 f-14">{packageData?.MSPPackagePrice?.TotalCost_Text}</div>
//                             </div>



//                         </div>

//                         <div className="chart-size">
//                             <MspClientPriceCalculatorRadarChartSmall
//                                 activeChart={ACTIVE_CHART}
//                                 _datasets={
//                                     TABS_DATASETS
//                                 }
//                             />
//                         </div>
//                     </div>
//                     <div className="bottom-block">
//                         <div className="f-black f-500 f-14">Prefered contact method</div>
//                         <div className="selected-method radius-4 d-flex align-items-center justify-content-center f-500 f-14 pointer">Email</div>
//                         <div
//                             className={` d-flex align-items-center radius-4 grey-border   flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
//                             style={{
//                                 height: '40px'
//                             }}
//                         >
//                             <div
//                                 className="bg-transparent w-100 border-none pl-2 d-flex align-items-center"
//                                 tabIndex={-1}
//                             >
//                                 <div className="f-darkgrey">Email:</div>
//                                 <input
//                                     placeholder="Enter email here"
//                                     type="text"
//                                     className={`border-none w-100 h-100 `}
//                                     value={inputValue}
//                                     onChange={(e) => {
//                                         setEmailErrors({
//                                             emptyEmail: false,
//                                             formatEmail: false
//                                         })
//                                         setIsvalidationChecked(false)
//                                         setInputValue(e.target.value);
//                                     }}
//                                     onBlur={(e) => {
//                                         handleEmailValidations(e.target.value)
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                         <div
//                             className={` d-flex align-items-start radius-4 grey-border mt-3  flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
//                             style={{
//                                 height: '120px'
//                             }}
//                         >
//                             <div
//                                 className="bg-transparent w-100 border-none pl-2 d-flex align-items-center"
//                                 tabIndex={-1}
//                             >
//                                 <div className="f-darkgrey">Note:</div>
//                                 <input
//                                     placeholder="Add description..."
//                                     type="text"
//                                     className={`border-none w-100`}
//                                     value={inputValue}
//                                     onChange={(e) => {
//                                         setEmailErrors({
//                                             emptyEmail: false,
//                                             formatEmail: false
//                                         })
//                                         setIsvalidationChecked(false)
//                                         setInputValue(e.target.value);
//                                     }}
//                                     onBlur={(e) => {
//                                         handleEmailValidations(e.target.value)
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Modal.Body>

//             <Modal.Footer>
//                 <div className="d-flex align-items-center w-100 mx-2"
//                     style={{
//                         height: '48px'
//                     }}
//                 >
//                     <Button
//                         className="hg-blue-btn w-100"
//                         onClick={() => {
//                             sharePackage();
//                             hideModal();
//                         }}
//                     >
//                         Submit
//                     </Button>
//                 </div>
//             </Modal.Footer>
//         </Modal>
//     );
// };

// export default SendPackageModal;
