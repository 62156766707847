import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./style.scss";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";

const SendSystemContent = (props) => {
  const { setLoading, setResponse, setCount, failCount, msgRes } = props;
  const [selectedOption, setSelectedOption] = useState([]);
  const [email, setEmail] = useState("");
  const { authTokens } = useAuth();

  const selectOptionHandler = (val, checked) => {
    if (val.data !== undefined) {
      var array = [...selectedOption];
      if (checked) {
        array.push(val.id);
        val.data.forEach((child) => {
          if (array.indexOf(child.id) === -1) {
            array.push(child.id);
          }
        });
        setSelectedOption(array);
      }
      if (!checked) {
        array.splice(array.indexOf(val.id), 1);
        val.data.forEach((child) => {
          if (array.indexOf(child.id) !== -1) {
            array.splice(array.indexOf(child.id), 1);
          }
        });
        setSelectedOption(array);
      }
    } else {
      var arr = [...selectedOption];
      if (arr.indexOf(val.id) === -1) {
        arr.push(val.id);
      } else {
        arr.splice(arr.indexOf(val.id), 1);
      }
      setSelectedOption(arr);
      setCount(arr.length);
    }
  };
  async function Submithandler() {
    setLoading(true);
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/SendDemoEmail`,
      getRequestOptions(authTokens, "POST", {
        EmailAddress: email,
        EmailEnumIds: selectedOption,
      })
    ).then((response) => response.json());
    if (data.Success) {
      setLoading(false);
      setResponse(true);
      setCount(data.SuccessCount);
      msgRes(data.Message);
      failCount(data.FaliureCount);
    } else {
      setLoading(false);
      msgRes(data.Message);
    }
  }

  const isValidEmail = () => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="sendNotificationContent">
        {props.data.map((val, i) => (
          <Fragment key={i}>
            <div className="compsalewrapper pl-1">
              <div className="d-flex flex-direction-row justify-content-start mb-2">
                <Form.Check
                  type="switch"
                  id={`switch${val.id}`}
                  checked={selectedOption.indexOf(val.id) !== -1 ? true : false}
                  className="m-0"
                  onChange={(e) => {
                    selectOptionHandler(val, e.target.checked);
                  }}
                />
                <p className="lbltxt font-weight-bold">{val.title}</p>
              </div>
            </div>
            {val["data"].map((v, key) => (
              <div key={key} className="compsalewrapper">
                <div className="d-flex flex-direction-row justify-content-start mb-2">
                  <Form.Check
                    type="switch"
                    id={`switch${v.id}`}
                    checked={selectedOption.indexOf(v.id) !== -1 ? true : false}
                    className="m-0"
                    onChange={() => selectOptionHandler(v)}
                  />
                  <p className="lbltxt">{v.title}</p>
                </div>
              </div>
            ))}

            <div className="brddiv" />
          </Fragment>
        ))}
      </div>
      <div className="sendNotificationFooter pt-4">
        <Form.Group>
          <InputGroup className="grey-input footer_inpt">
            <InputGroup.Prepend>
              <InputGroup.Text className="greyText">Email:</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              name="IntegrationName"
              value={email}
              onChange={(v) => setEmail(v.target.value)}
            />
          </InputGroup>
        </Form.Group>

        <Button
          className={`${
            (selectedOption.length == 0 || email == "" || !isValidEmail()) &&
            "disabled_btn"
          } w-100 submit-btn mt-3`}
          onClick={() => Submithandler()}
          disabled={selectedOption.length > 0 && isValidEmail() ? false : true}
          style={{ bottom: 0 }}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default SendSystemContent;
