import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/Auth";
import { getAPIData } from "../../../../Utils/commonApi";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { Status } from "../../../AttackSurface/Index";
import Accreditations from "../accreditations/Accreditations";
import TrainingCertificates from "../trainingCertificates/TrainingCertificates";
import OperationalSecurityAssetsScreen from "../frameworks/MspFrameworks";

const AccreditationsTrainingFrameworks = ({isAudit = false}) => {
    const { authTokens } = useAuth();
    const [loading, setLoading] = useState(true);
    const [showSidePanel, setShowSidePanel] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [sideMenu, setSideMenu] = useState([]);
    const [selectedSideBarTab, setSelectedSideBarTab] = useState(0);


    useEffect(() => {
        if (loading) {
            getMenuData()
        }
    })

    async function getMenuData() {
        await getAPIData("658", authTokens, {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        })
            .then((response) => {
                if (response?.mr?.Success) {
                    setSideMenu(response?.SecOpsDashboardData_List[0]?.SecOpsData);
                    setSelectedSideBarTab(response?.SecOpsDashboardData_List[0]?.SecOpsData[0])
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                CallToast("Something went wrong", false, false, "v2style");
                setLoading(false);
            });
    }

    return (
        <>
            <div
                className={`secops-sidebar bg-white transparent-scroller ${showSidePanel === false
                    ? "bar-expand"
                    : sideBarOpen
                        ? "bar-expand"
                        : "bar-collapse"
                    }`}
                style={{
                    position: "fixed",
                }}
            >
                <div className="f-500 f-darkgrey d-flex align-items-center justify-content-between position-relative my-2">
                    {sideBarOpen && showSidePanel ? (
                        <div>
                            <div>Accreditations, Training &</div>
                            <div>{' '}Frameworks</div>
                        </div>
                    ) : sideBarOpen ? (
                        <div>
                            <div>Accreditations, Training &</div>
                            <div>{' '}Frameworks</div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className={` ${sideBarOpen
                            ? "position-absolute right-0"
                            : " d-flex align-items-center justify-content-center w-100 mt-2"
                            }                `}
                    >
                        {showSidePanel && (
                            <img
                                src="/images/msp/sidebar-expanding-arrow.svg"
                                alt=""
                                className="trigger-icon pointer z-Index-1"
                                onClick={() => {
                                    setSideBarOpen(!sideBarOpen);
                                    setShowSidePanel(false);
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="d-flex flex-column">
                    {sideMenu?.map((item, menuIndex) => {
                        return (
                            <div
                                className={` align-items-center justify-content-between px-2 pointer menu-item py-2 mt-1
                    ${item?.DevelpomentStage?.toLowerCase() === "hide"
                                        ? "d-none"
                                        : "d-flex"
                                    }
                    
                    ${selectedSideBarTab?.MSPSecOpsUpdateAuditEnum == item?.MSPSecOpsUpdateAuditEnum
                                        ? "selected-menu-item"
                                        : ""
                                    }   `}
                                onClick={() => {
                                    setSelectedSideBarTab(item);         
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <div>
                                        {item?.Title?.toLowerCase() === "overview" && (
                                            <img
                                                alt=""
                                                src="/images/attack-surface/overview-icon.svg"
                                                className="ml-1"
                                            />
                                        )}

                                        {item?.Title?.toLowerCase() !== "overview" &&
                                            item?.DevelpomentStage?.toLowerCase() ===
                                            "coming soon" && (
                                                <img
                                                    alt=""
                                                    src="/images/attack-surface/grey-thunder-icon.svg"
                                                    className="ml-1"
                                                    style={{ width: "14px", marginRight: "3px" }}
                                                />
                                            )}

                                        {
                                            item?.Percentage == 0 && (
                                                <img
                                                    alt=""
                                                    src="/images/attack-surface/thunder-icon.svg"
                                                    className="ml-1"
                                                    style={{ width: "14px", marginRight: "3px" }}
                                                />
                                            )}

                                        {
                                            item?.Percentage > 0 &&
                                            item?.Percentage < 100 && (
                                                <Status value={100 - item?.Percentage} />
                                            )}

                                        {
                                            item?.Percentage == 100 && (
                                                <img
                                                    alt=""
                                                    src="/images/attack-surface/menu-completed.svg"
                                                    className="ml-1"
                                                    style={{ width: "14px", marginRight: "3px" }}
                                                />
                                            )}
                                    </div>

                                    <div className="ml-2 d-flex align-items-center justify-content-center">
                                        {item?.Title}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    {(item?.DevelpomentStage?.toLowerCase() === "live" ||
                                        true) && <>{item?.Percentage}%</>}
                                    {item?.DevelpomentStage?.toLowerCase() ===
                                        "coming soon" && (
                                            <span
                                                className="attack-menu-tag"
                                                style={{
                                                    width: `${item?.Title.toLowerCase() == "directory services"
                                                        ? "51px"
                                                        : "51px"
                                                        }`,
                                                }}
                                            >
                                                {item?.Title.toLowerCase() == "directory services"
                                                    ? "COMING"
                                                    : "COMING"}
                                            </span>
                                        )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Page Body */}
            <div className="slidable-body-new position-relative">
                {
                    selectedSideBarTab?.MSPSecOpsUpdateAuditEnum == 0 &&
                    <Accreditations
                        setSideBarOpen={setSideBarOpen}
                        setShowSidePanelPassed={setShowSidePanel}
                        showSidePanelPassed={showSidePanel}
                        isAudit={true}
                    />
                }
                {
                    selectedSideBarTab?.MSPSecOpsUpdateAuditEnum == 1 &&
                    <TrainingCertificates
                        setSideBarOpen={setSideBarOpen}
                        setShowSidePanelPassed={setShowSidePanel}
                        showSidePanelPassed={showSidePanel}
                        isAudit={true}
                    />
                }
                {
                    selectedSideBarTab?.MSPSecOpsUpdateAuditEnum == 2 &&
                    <OperationalSecurityAssetsScreen
                        setSideBarOpen={setSideBarOpen}
                        setShowSidePanelPassed={setShowSidePanel}
                        showSidePanelPassed={showSidePanel}
                        isAudit={true}
                    />
                }
            </div>
        </>
    );
};

export default AccreditationsTrainingFrameworks;
