import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Modal, InputGroup, Button, Spinner } from "react-bootstrap";
import "./billing-modals.scss";

import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
const RemovePaymentModal = ({ show, hideModal, payment, Refreshpage }) => {
  const { authTokens } = useAuth();
  const [page, setPage] = useState("initial");
  const [cardNumber, setCardNumber] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [CVV, setCVV] = useState("");
  const [showMethod, setShowMethod] = useState(true);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [pageData, setPageData] = useState({});
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);

  async function getCardDetails() {
    const getCard = await getAPIData(195, authTokens, {
      optionEnum1: payment.Id,
    }).then((response) => {
      setPageData(response);
      setActiveSubscriptions(response?.ActiveSub_List);
      setLoading(false);
    });
  }
  async function RemovePayment() {
    setRemoving(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/DeletePaymentMethod?objId_PaymentMethod=${payment?.Id}&StripeId_NewPaymentMethod=${payment?.StripeId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
        // body: JSON.stringify(data)
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          setRemoving(false);
          hideModal();
          Refreshpage();
          CallToast("Removed successfully", false, false, "v2style");
        } else {
          setRemoving(false);
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
  }

  useEffect(() => {
    if (show) {
      setLoading(true);
      getCardDetails();
    }
  }, [show]);

   
  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="billing-payment-modal remove-payment-modal"
      className=""
      aria-labelledby="billing-payment-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="px-4">
        {loading && (
          <div
            className="w-100  d-flex justify-content-center align-items-center"
            // style={{ height: "420px" }}
          >
            {/* <Loader /> */}
            <ContentLoader
              speed={2}
              width={"100%"}
              height={"100%"}
              viewBox="0 0 1500 650"
              backgroundColor="#F6F8FB"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="6" ry="6" width="60%" height="40" />
              <rect x="0" y="110" rx="8" ry="8" width="98%" height="150" />
              <rect x="0" y="350" rx="6" ry="6" width="80%" height="40" />
              <rect x="0" y="400" rx="6" ry="6" width="30%" height="40" />
              <rect x="0" y="460" rx="6" ry="6" width="70%" height="120" />
              <rect x="0" y="600" rx="6" ry="6" width="70%" height="120" />
            </ContentLoader>
          </div>
        )}
        {!loading && (
          <>
            <div className="heading my-3">{pageData?.Title}</div>
            <div className="option-wrapper d-flex align-items-center p-3">
              <img alt="" src={`/images/${pageData?.CardIcon}`} className="mr-2" />
              <div>
                <p className="title m-0">
                  {" "}
                  {pageData?.CardType?.charAt(0).toUpperCase() +
                    pageData?.CardType?.slice(1)}{" "}
                  &nbsp; *{pageData?.CardLastFourDigits}
                </p>
                <p className="subtitle">
                  {/* Jane Cooper, Cyber Care ltd., United...{" "} */}
                  {/*Kingdom, 2972 Westheimer Rd. Santa Ana, Illinois 85486, 9012345678, FR94390203993*/}
                </p>
              </div>
            </div>
            {false && (
              <>
                <hr />
                <div className="d-flex ">
                  <img alt=""
                    src="/images/settings-v2/green-tick.svg"
                    className="mr-2"
                  />
                  <p className="green-text m-0">
                    No active subscriptions are associated with this payment
                    method.
                  </p>
                </div>
              </>
            )}
            <>
              <div className="d-flex  mt-3 align-items-start">
                {/* <img
                  src="/images/settings-v2/orange-info.svg"
                  className="mr-2 mt-1"
                />
                <p className="orange-text m-0">
                  This payment method is used to pay for subscriptions. You must
                  re-assign billing for these subscriptions to a different
                  payment method.
                </p> */}

                <img alt="" src={pageData?.DescriptionIcon} className="mr-2 mt-1" />
                <p
                  className="m-0"
                  style={{ color: pageData?.DescriptionColour }}
                >
                  {pageData?.Description}
                </p>
              </div>
              <hr />
              {activeSubscriptions.length > 0 && (
                <>
                  <div className="title">Active subscriptions</div>
                  {activeSubscriptions.map((sub, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex align-items-center my-2"
                      >
                        <img alt=""
                          src={`/images/ProServices/${sub?.Icon}`}
                          className="mr-2"
                          style={{
                            width: "30px",
                            objectFit: "contain",
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <p className="title m-0">{sub?.Name} </p>
                          <p className="subtitle"> {sub?.Cost} </p>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {/* <div className="title my-3">Choose Payment Method</div> */}

              {/* {showMethod ? (
                <div className="d-flex justify-content-between align-items-center amount-details">
                  <div className="d-flex align-items-center">
                    <img alt=""
                      src="/images/settings-v2/avatar-image.svg"
                      className="mr-2 "
                    />
                    <div>
                      <p className="title mb-1">Monthly Premium Plan</p>
                      <p className="subtitle">£395 / month · Billed Monthly</p>
                    </div>
                  </div>
                  <img alt=""
                    src="/images/chevron-down.svg"
                    className="pointer"
                    onClick={() => setShowMethod(false)}
                  />
                </div>
              ) : (
                <>
                  <div className="title mb-2">Payment Information</div>

                  <div className="card-details d-flex align-items-center w-100">
                    <img alt="" src="/images/settings-v2/card.svg" />
                    <input
                      className="card-number "
                      type="number"
                      placeholder="Card Number"
                      value={cardNumber}
                      onChange={(e) => setCardNumber(e.target.value)}
                    />
                    <input
                      className="card-month "
                      value={monthYear}
                      onChange={(e) => setMonthYear(e.target.value)}
                      type="text"
                      placeholder="MM/YY"
                      autoComplete="new-password"
                    />
                    <input
                      className="card-cvv "
                      value={CVV}
                      onChange={(e) => setCVV(e.target.value)}
                      type="password"
                      placeholder="CVV"
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="card-details my-1">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Name on card"
                    />
                  </div>
                  <div className="title mt-3">Billing Information</div>
                  <div className="card-details my-1">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Company name"
                    />
                  </div>
                  <Form.Group>
                    <InputGroup className="mt-10px grey-select customerStyle border-4">
                      <Form.Control
                        style={{ height: "40px" }}
                        as="select"
                        className={"pt-0 pb-0"}
                        defaultValue={"GB"}
                        onChange={{}}
                      >
                        <option>United Kingdom</option>
                        <option>Spain</option>
                      </Form.Control>
                    </InputGroup>
                  </Form.Group>
                  <div className="card-details my-1">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Company address"
                    />
                  </div>
                  <div className="card-details my-1">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Company registration"
                    />
                  </div>
                  <div className="card-details my-1">
                    <input type="text" className="w-100" placeholder="VAT" />
                  </div>
                </>
              )} */}
            </>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            // height={"100%"}
            viewBox="0 0 1500 100"
            backgroundColor="#F6F8FB"
            foregroundColor="#ecebeb"
          >
            <rect x="62%" y="0" rx="6" ry="6" width="14%" height="80" />
            <rect x="79%" y="0" rx="6" ry="6" width="20%" height="80" />
          </ContentLoader>
        ) : (
          <div className="d-flex justify-content-end">
            <Button className="add-cancel-btn" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              disabled={removing}
              className="remove-paymnt-btn"
              onClick={() => {
                RemovePayment();
              }}
            >
              {removing ? (
                <>
                  <Spinner
                    size="sm"
                    className="mr8px ml-2 mr-2"
                    animation="border"
                    variant="light"
                  />
                  Removing...
                </>
              ) : (
                "Remove"
              )}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RemovePaymentModal;
