import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import CC from "../../CC_Container/CC_Container";
import { useAuth } from "../../context/Auth";
import CyberCompliance from "../../CyberCompliance/CyberCompliance";
import CyberScore from "../../CyberScore/CyberScore";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import ThreatLevel from "../../ThreatLevel/ThreatLevel";
import { ToastsV2 } from "../ToastsV2";
import "./active-sessions.scss";
const TestNotificationModal = ({ show, hideModal, cyberData, deviceId, isForMember, passedOptionEnum99 }) => {
  const { authTokens } = useAuth();
  const [done, setDone] = useState(false);
  const [toast, setToast] = useState(false);

  const UpdateTestNotifications = () => {
    setToast(true);
    setTimeout(() => setDone(true), 1500);
    setTimeout(() => {
      setToast(false);
      setDone(false);
    }, 3000);
  };

  async function sendNotification(index) {
    const result = await getAPIData(104, authTokens, isForMember ? {
      optionEnum1: deviceId,
      optionEnum2: index,
      optionEnum99: passedOptionEnum99 ? passedOptionEnum99 : 42,
    } : {
      optionEnum1: deviceId,
      optionEnum2: index,
    });
    if (result.Success == true) {
      CallToast("Notfication successfully sent", false, false, "v2style");
    } else {
      CallToast("Something went wrong", false, false, "v2style");
    }
  }

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="test-notification-modal"
      className=" "
      aria-labelledby="test-notification-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          Test Notifications
        </div>
        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex justify-content-between align-items-center p-3 option-wrapper"
          onClick={() => {
            sendNotification(0);
          }}
        >
          <div className="d-flex align-items-center">
            <div className="cyberscore-noti">
              <CyberScore
                key={0}
                size="small"
                scoreNumber={cyberData?.CyberScore?.CyberScoreNumber}
                dataStroke={7}
                bottomText={false}
                Width="50"
                Height="50"
                hideLabels={false}
              />
            </div>

            <div style={{ marginLeft: "4rem" }}>
              <p className="title m-0">CyberScore</p>
              <p className="subtitle m-0">{`Your CyberScore has decreased to   ${cyberData?.CyberScore?.CyberScoreNumber}%`}</p>
            </div>
          </div>
          <img src="/images/settings-v2/plane.svg" />
        </div>
        {/* part 2 */}
        <div
          className="d-flex justify-content-between align-items-center p-3 option-wrapper"
          onClick={() => {
            sendNotification(1);
          }}
        >
          <div className="d-flex align-items-center">
            <div className="threat-noti">
              <ThreatLevel
                padding={false}
                threatHeight="160px"
                threatText={10}
                removeBottom={true}
                threatNumber={cyberData?.CyberScore?.CyberThreatNumber}
              />
            </div>
            <div>
              <p className="title m-0">CyberThreat Level</p>
              <p className="subtitle m-0">
                {`Your CyberThreat Level has increased to   ${cyberData?.CyberScore?.CyberThreatNumber}%`}
              </p>
            </div>
          </div>
          <img src="/images/settings-v2/plane.svg" />
        </div>
        {/* part 3 */}
        <div
          className="d-flex justify-content-between align-items-center p-3 option-wrapper"
          onClick={() => {
            sendNotification(2);
          }}
        >
          <div className="d-flex align-items-center">
            <div className="">
              <CyberCompliance
                ccNumber={
                  cyberData?.CyberScore?.CyberComplianceNumber
                  // compData.ComplianceScore == 0
                  //   ? "-2"
                  //   : compData.ComplianceScore
                }
                whpx="50"
                wh="50"
                dataStroke={7}
                fontSize={12}
              />
            </div>
            <div className="ml-2">
              <p className="title m-0">CyberCompliance</p>
              <p className="subtitle m-0">{`Your CyberCompliance has decreased to   ${cyberData?.CyberScore?.CyberComplianceNumber}%`}</p>
            </div>
          </div>
          <img src="/images/settings-v2/plane.svg" />
        </div>
      </Modal.Body>
      <ToastsV2
        done={done}
        showToast={toast}
        successText="Notification successfully sent"
      />
    </Modal>
  );
};

export default TestNotificationModal;
