import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";

const CardDetails = (props) => {

  return (
    <div>
       <div className="d24" />
        <p className="gtxt m-0">Company Name</p>
        <p className="m-0">Cyber Care Ltd</p>
        <br/>
        <p className="gtxt m-0">Company address</p>
        <p className="m-0">2972 Westheimer Rd. Santa Ana, Illinois 85486</p>
        <br/>
        <p className="gtxt m-0">Country</p>
        <p className="m-0">Germany</p>
    </div>
  );
};

export default CardDetails;
