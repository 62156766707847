import React, { useEffect, useMemo, useState } from "react";
import "./mspClientPriceCalculator.scss";
import { Row, Col, Button, Tab, Spinner, Nav, Modal } from "react-bootstrap";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { MspSingleTagUI } from "../integrations/mspIntegrationdetailScreen/MspIntegrationCard";
import { Radar } from "react-chartjs-2";
import { TrimString } from "../../../Utils/StringTrimmer";
import CyberScoreGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberScoreGauge";
import CyberComplianceGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import MspClientPriceCalculatorRadarChart from "./MspClientPriceCalculatorRadarChart";
import ContentLoader from "react-content-loader";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { GreyLoader } from "../../Theme/APILoaders";
import Loader from "../../Common/loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MspScoreModal from "../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";
import { services_titles } from "../mspSell/mspPriceCalculator";
import SwitchButtonMulti from "../../Theme/switchButtonMulti";
// import { AddEditTaxRateModal, TaxRateDropdown, deleteTaxRate } from "../MspAttackSurface.js/addItemInSpendModal/AddItemInSpendModal";
import CyberGroupModal from "../../AttackSurface/CyberGroupModal";
import ExploreMappingModal from "../../SecurityAssessments/ExploreMappingModal";
import { HGHighlightText, viewOnlyAccess } from "../../App/App";
import SendPackageModal from "./SendPackageModal";
import PrintPackageModal from "./PrintPackageModal";
import { getMenuItemId } from "../../SideNav/SideNav";
import { NoAccessUI } from "../../App/App";

import MspFeaturesModal from "../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspFeaturesModal";
const MspClientPriceCalculator = ({ isMspClientView }) => {
  const { authTokens } = useAuth();
  const clientId = window.location.pathname?.split("/")?.[4] ?? -1;
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [openedPackagesIds, setOpenedPackagesIds] = useState([]);
  // const [taxList, setTaxList] = useState([]);
  // const [selectedTax, setSelectedTax] = useState();
  const [showCyberGroupModal, setShowCyberGroupModal] = useState(false);
  const [userSeatingsLoader, setUserSeatingsLoader] = useState(false);
  const [switchVal, setSwitchVal] = useState(false);

  useEffect(() => {
    setLoading(true);

    getPageData();
  }, []);

  const graphColors = [
    "#008FD4",
    "#5244B3",
    "#40D88E",
    "#F36060",
    "#DF6844",
    "#99D2EE",
    "#BAB4E1",
    "#B3EFD2",
    "#FABFBF",
    "#F2C3B4",
  ];

  const chartData = {
    data: [30, 5, 34, 20, 15],
    pointRadius: [0, 0, 0, 0, 0],
    datalabels: {
      display: false,
    },
    backgroundColor: `${"#FFAC2C"}`,
    borderColor: `${"#FFAC2C"}`,
    pointBackgroundColor: "#FFAC2C",
    borderWidth: 1,
    pointLabelFontSize: 0,
    scaleShowLabels: false,
    label: "Current Posture",
  };
  const [defaultUserDeviceDetails, setDefaultUserDeviceDetails] = useState([]);
  const [priceCalculatorState, setPriceCalculatorState] = useState({
    activeTab: 0,
    activeChart: 0,
    clickOnLastTab: false,
    tabsData: [
      {
        circleColour: "#FFAC2C",
        Name: "Client current posture ",
        data: { ...chartData, data: randomChartData() },
      },
    ],
  });
  const [isSmthOverriden, setIsSmthOverriden] = useState(false);


  const menuItemId = getMenuItemId('/' + window.location.pathname.split('/')[5])
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  function activateChartIndex(_index) {
    setPriceCalculatorState((prev) => ({ ...prev, activeChart: _index }));
  }

  function AddScenario(_packageData, key) {
    let updated_data = { ...priceCalculatorState };
    if (!key) {
      updated_data.tabsData = [
        ...updated_data.tabsData,
        {
          ..._packageData,
          circleColour:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
          Name: `${_packageData?.ScenarioHeader?.Name}`,
          data: {
            ...chartData,
            data: [
              _packageData?.ScenarioHeader?.InspectScore,
              _packageData?.ScenarioHeader?.ProtectScore,
              _packageData?.ScenarioHeader?.DetectScore,
              _packageData?.ScenarioHeader?.RecoverScore,
              _packageData?.ScenarioHeader?.RespondScore,
            ],
            backgroundColor:
              graphColors[
              priceCalculatorState.tabsData.length % graphColors.length
              ],
            borderColor:
              graphColors[
              priceCalculatorState.tabsData.length % graphColors.length
              ],
            pointBackgroundColor:
              graphColors[
              priceCalculatorState.tabsData.length % graphColors.length
              ],
          },
        },
      ];
      updated_data.clickOnLastTab = true;
      updated_data.loadingScenarioID = 0;

      updated_data.newScenarioCreating = false;
      updated_data.scenarioLoadingText = "";
      updated_data.loadingScenarioID = 0;
    } else {
      updated_data.tabsData[key] = {
        ..._packageData,
        circleColour: priceCalculatorState?.tabsData[key]?.circleColour,
        Name: `${_packageData.ScenarioHeader.Name}`,
        data: {
          ...chartData,
          data: [
            _packageData.ScenarioHeader.InspectScore,
            _packageData.ScenarioHeader.ProtectScore,
            _packageData.ScenarioHeader.DetectScore,
            _packageData.ScenarioHeader.RecoverScore,
            _packageData.ScenarioHeader.RespondScore,
          ],
          backgroundColor:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
          borderColor:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
          pointBackgroundColor:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
        },
      };
    }
    setPriceCalculatorState(updated_data);
  }

  useEffect(() => {
    if (priceCalculatorState.clickOnLastTab) {
      let updated_data = { ...priceCalculatorState };
      let nav_header = document.getElementById("navHeader");

      let all_links = nav_header.getElementsByClassName("navlinks");

      all_links[all_links.length - 1].click();

      updated_data.activeTab = all_links.length - 1;
      updated_data.activeChart = all_links.length - 1;

      updated_data.clickOnLastTab = false;
      setPriceCalculatorState(updated_data);
    }
  }, [priceCalculatorState]);

  const [showCreateCustomPackageModal, setShowCreateCustomPackageModal] =
    useState(false);
  function removePackage(_key) {
    let updated_data = { ...priceCalculatorState };
    let nav_header = document.getElementById("navHeader");
    let all_links = nav_header.getElementsByClassName("navlinks");
    if (_key <= priceCalculatorState.activeTab) {
      updated_data.activeTab = _key - 1;
      updated_data.activeChart = _key - 1;
      all_links[_key - 1].click();
    }
    updated_data.tabsData.splice(_key, 1);
    const updatedOpenedPackagesIds = Object.values(updated_data?.tabsData)
      .map(
        (item) => item?.ScenarioHeader && item?.ScenarioHeader?.objId_Scenario
      )
      ?.filter((id) => id !== undefined);
    setPriceCalculatorState(updated_data);
    setOpenedPackagesIds(updatedOpenedPackagesIds);
  }

  function randomChartData() {
    return [
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
    ];
  }

  const ACTIVE_CHART = useMemo(
    () => priceCalculatorState.activeChart,
    [priceCalculatorState.activeChart]
  );

  const TABS_DATASETS = useMemo(
    () => priceCalculatorState.tabsData.map((item) => item.data),
    [priceCalculatorState.tabsData.length]
  );

  function setCurrentPostureData(_data) {
    let updated_data = { ...priceCalculatorState };
    updated_data.tabsData[0].data.data = _data;
    if (isSmthOverriden) {
      updated_data.activeChart = 0;
      updated_data.activeTab = 0;
    }
    setPriceCalculatorState(updated_data);
  }

  function setCurrentPostureGraphData(
    _data = { CyberCompliance: 0, CyberScore: 0 }
  ) {
    let updated_data = { ...priceCalculatorState };
    updated_data.tabsData[0].ScenarioHeader = {
      CyberCompliance: _data.CyberCompliance,
      CyberScore: _data.CyberScore,
    };
    if (isSmthOverriden) {
      updated_data.activeChart = 0;
      updated_data.activeTab = 0;
    }
    setPriceCalculatorState(updated_data);
  }

  // API Implementation
  async function getPageData() {
    return new Promise((resolve, reject) => {
      getAPIData(647, authTokens, {
        optionEnum1: clientId,
      })
        .then((response) => {
          setCurrentPostureData([
            response?.CurrentPosture?.Identity ?? 0,
            response?.CurrentPosture?.Protect ?? 0,
            response?.CurrentPosture?.Detect ?? 0,
            response?.CurrentPosture?.Respond ?? 0,
            response?.CurrentPosture?.Recover ?? 0,
          ]);
          setCurrentPostureGraphData({
            CyberCompliance: response?.CurrentPosture?.CyberCompliance ?? 0,
            CyberScore: response?.CurrentPosture?.CyberScore ?? 0,
          });
          setPageData(response);
          setDefaultUserDeviceDetails(response?.DefaultPackageAsset_List ?? []);
          setLoading(false);
          setIsSmthOverriden(false);
          resolve(response);
          setUserSeatingsLoader(false);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  }

  function updateQuantityOfAssets(_userDeviceDetails) {
    setUserSeatingsLoader(true);

    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateSnapToUnitAssetCounts", {
        DefaultPackageAsset_List: JSON.stringify(_userDeviceDetails),
        // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        UpdateTarget: 3,
        objId_Customer: clientId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

    // return new Promise((resolve, reject) => {
    //   postData(authTokens, "UpdateUserSettingsAssetCounts", {
    //     NoOfDomains: _userDeviceDetails.NoOfDomains,
    //     NoOfWorkstation: _userDeviceDetails.NoOfWorkstation,
    //     NoOfUsers: _userDeviceDetails.NoOfUsers,
    //     NoOfServers: _userDeviceDetails.NoOfServices,
    //     NoOfMobileDevices: _userDeviceDetails.NoOfMobileDevices,
    //     accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    //     UpdateTarget: 3,
    //     NoOfRouters: _userDeviceDetails.NoOfRouters,
    //     NoOfNetworkDevices: _userDeviceDetails.NoOfNetworkDevices,
    //     objId_Customer: clientId,
    //   })
    //     .then((response) => {
    //       resolve(response);
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
    // });
  }

  const [packageDetails, setPackageDetails] = useState([]);

  async function getPackageDetails(_packageId = 3955510) {
    setPriceCalculatorState({
      ...priceCalculatorState,
      loadingScenarioID: _packageId,
      newScenarioCreating: priceCalculatorState?.tabsData?.find(
        (obj) => obj?.ScenarioHeader?.objId_Scenario === _packageId
      )
        ? false
        : true,
      scenarioLoadingText: "Loading...",
    });

    await getAPIData(619, authTokens, {
      optionEnum1: _packageId,
      optionEnum2: clientId,
    })
      .then((response) => {
        let packageData = interpolatePackageResponse(response);
        packageData.MSPPackagePrice.PackagePerAsset_List.forEach(asset => {
          asset.UnitSell = Number(asset?.UnitSell)?.toFixed(2);
        });

        if (
          priceCalculatorState?.tabsData?.find(
            (obj) => obj?.ScenarioHeader?.objId_Scenario === _packageId
          )
        ) {
          const key = priceCalculatorState?.tabsData?.findIndex(
            (obj) => obj?.ScenarioHeader?.objId_Scenario === _packageId
          );
          AddScenario(packageData, key);
        } else {
          AddScenario(packageData);
        }
        setPackageDetails(response);
        //   setTaxList(response?.TaxRates_List);
        //   setSelectedTax(response?.TaxRates_Selected?.TaxName !== '' ? response?.TaxRates_Selected : {
        //     "ObjectId": 0,
        //     "TaxCode": "Not Taxable",
        //     "TaxName": "Not Taxable",
        //     "TaxRate": 0,
        //     "IsDefault": true,
        //     "IntegrationId": 0
        // });

        // setTimeout(() => {
        //   setPriceCalculatorState({
        //     ...priceCalculatorState,
        //    loadingScenarioID: 0,
        //   });
        // }, 100);
      })
      .catch((err) => {
        console.log(err);
        setPriceCalculatorState({
          ...priceCalculatorState,
          loadingScenarioID: 0,
        });
      });
  }

  function interpolatePackageResponse(_ApiResponse) {
    let ApiResponse = { ..._ApiResponse };

    let pillarList = [
      {
        pillarName: "Identify services",
        dataList:
          ApiResponse?.Nist_Identify?.filter(
            (item) => item.Selected === true
          ) ?? [],
        showCount: 3,
      },
      {
        pillarName: "Protection services",
        dataList:
          ApiResponse?.Nist_Protect?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
      {
        pillarName: "Detection services",
        dataList:
          ApiResponse?.Nist_Detect?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
      {
        pillarName: "Response services",
        dataList:
          ApiResponse?.Nist_Respond?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
      {
        pillarName: "Recover services",
        dataList:
          ApiResponse?.Nist_Recover?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
    ];
    delete ApiResponse["Nist_Identify"];
    delete ApiResponse["Nist_Protect"];
    delete ApiResponse["Nist_Detect"];
    delete ApiResponse["Nist_Respond"];
    delete ApiResponse["Nist_Recover"];

    return {
      ...ApiResponse,
      pillarList,
    };
  }

  function startLoadingNewPackage(_loadingText = "Loading...") {
    setPriceCalculatorState({
      ...priceCalculatorState,
      newScenarioCreating: true,
      scenarioLoadingText: _loadingText,
    });
  }

  function stopLoadingNewPackage() {
    setPriceCalculatorState({
      ...priceCalculatorState,
      newScenarioCreating: false,
      scenarioLoadingText: "",
    });
  }

  function closeScenario(_scenarioId) {
    let updated_data = { ...priceCalculatorState };
    let index = updated_data?.tabsData?.findIndex(
      (tab) => tab?.ScenarioHeader?.objId_Scenario === _scenarioId
    );
    if (index > -1) {
      let nav_header = document.getElementById("navHeader");
      let all_links = nav_header.getElementsByClassName("navlinks");
      all_links[index].getElementsByTagName("img")[0].click();
    }
  }

  /**
   *
   * @param
   * {
   * objId_PackagePrice,
   * Discount,
   * Cost,
   * SellOverridden,
   * Tax,
   * PackagePerAsset_List,
   * PackagePerSeat_List,
   * PackagePeriodBreakdown_List,
   * RequiredSeats
   * }
   * @returns
   */
  function updateSeatAssetDataApi(_MSPPackagePrice, _packageType) {
    //kwargs = {}
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateMSPPackagePrice", {
        objId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        MSPPackagePrice: JSON.stringify(_MSPPackagePrice),
        PackageType: _packageType,
        objId_PricingScenario: Number(
          priceCalculatorState?.tabsData[priceCalculatorState?.activeChart]
            ?.ScenarioHeader?.objId_Scenario
        ),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getAssetSeatDataApi(_scenarioId = 3955510) {
    return new Promise((resolve, reject) => {
      getAPIData(618, authTokens, {
        optionEnum1: _scenarioId,
        optionEnum2: 0,
        optionEnum3: 0,
        optionEnum4: 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            // let updated_data = { ...priceCalculatorState };
            // let scenarioIndex = updated_data?.tabsData?.findIndex(
            //   (tab) => tab?.ScenarioHeader?.objId_Scenario === _scenarioId
            // );

            // if (scenarioIndex > -1) {
            //   updated_data.tabsData[scenarioIndex].MSPPackagePrice = {
            //     ...response.MSPPackagePrice,
            //   };
            //   setPriceCalculatorState(updated_data);
            // }
            getAPIData(619, authTokens, {
              optionEnum1: _scenarioId,
              optionEnum2: clientId,
            })
              .then((response) => {
                setPackageDetails(response);
                //   setTaxList(response?.TaxRates_List);
                //   setSelectedTax(response?.TaxRates_Selected?.TaxName !== '' ? response?.TaxRates_Selected : {
                //     "ObjectId": 0,
                //     "TaxCode": "Not Taxable",
                //     "TaxName": "Not Taxable",
                //     "TaxRate": 0,
                //     "IsDefault": true,
                //     "IntegrationId": 0
                // });
              })
              .catch((err) => {
                console.log(err);
                setPriceCalculatorState({
                  ...priceCalculatorState,
                  loadingScenarioID: 0,
                });
              });
            resolve(response);
          } else {
            reject("error");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function resetAssetSeatDataApi(_packageObjId, _packageType) {
    return new Promise((resolve, reject) => {
      getAPIData(653, authTokens, {
        optionEnum1: _packageObjId,
        optionEnum2: _packageType,
        optionEnum3: clientId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function duplicatePackage(_scenarioId) {
    return new Promise((resolve, reject) => {
      getAPIData(620, authTokens, {
        optionEnum1: _scenarioId,
        optionEnum2: clientId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const [showMspScoreModal, setShowMspScoreModal] = useState(false);
  const [showMspFeaturesModal, setShowMspFeaturesModal] = useState(false);
  const [showExploreMappingModal, setShowExploreMappingModal] = useState(false);
  const [showRadar, setShowRadar] = useState(false);
  const [serviceModalData, setServiceModalData] = useState({});

  async function updateRadarChart() {
    setLoading(true);
    await getAPIData(611, authTokens, {
      // optionEnum1: selectedServiceId,
    }).then((response) => {
      setShowRadar(response?.ShowNistGraph);
      setServiceModalData(response);
      setLoading(false);
    });
  }

  const [showSharePackageModal, setShowSharePackageModal] = useState(false);
  const [showPrintPackageModal, setShowPrintPackageModal] = useState(false);

  return (
    <>
    {
      menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_ManagedSecurity === 0) ? <>
      {
        userPortalRole === 'MSP' ? 
          <NoAccessUI/> : <NoAccessUI type={userPortalRole}/>
      }
      </> : <>
    <div className="msp_client_price_calculator">
      {loading ? (
        <div className="p-4">
          <MspClientPriceCalculatorLoader />
        </div>
      ) : (
        <>
          <Row className="m-0">
            <Tab.Container
              onSelect={(v) => { }}
              id="left-tabs-example"
              defaultActiveKey={0}
            >
              <div
                className="scenarioName bg-white d-flex align-items-start justify-content-between px-5 pt-2 position-sticky"
                style={{ top: isMspClientView ? "58px" : "101px", zIndex: 2 }}
                id="navHeader"
              >
                <div className="d-flex align-items-center circleHeader flex-wrap">
                  {priceCalculatorState?.tabsData?.map((tab, key) => (
                    <Nav.Item
                      key={key}
                      className={`${key === 0 ? "" : ""} mt-2`}
                    >
                      {key === 0 && (
                        <Nav.Link
                          eventKey={key}
                          className={`navlinks all-scenario-navlink ${priceCalculatorState.activeTab === 0 ? "active" : ""
                            }`}
                          onClick={() => {
                            let updated_data = { ...priceCalculatorState };
                            updated_data.activeTab = key;
                            updated_data.activeChart = key;

                            setPriceCalculatorState(updated_data);
                            if (isSmthOverriden) {
                              setLoading(true);
                              getPageData();
                            }
                          }}
                        >
                          <div className="d-flex align-items-center circleHeader  mr-2">
                            <span
                              style={{
                                color: `${priceCalculatorState.activeTab === 0
                                  ? "#008FD4"
                                  : ""
                                  }`,
                              }}
                              className="ml-2- mr-2"
                            >
                              <img
                                src={`/images/calculator/${priceCalculatorState.activeTab === 0
                                  ? "allscenariob.svg"
                                  : "allscenariog.svg"
                                  }`}
                                // onClick={() => removeScenario(key)}
                                className="ml-1- mr-2"
                                alt=""
                              />
                              All packages
                            </span>
                          </div>
                        </Nav.Link>
                      )}

                      {key > 0 && (
                        <Nav.Link
                          href={"javascript:void(0)"}
                          className={`navlinks ${priceCalculatorState.activeTab === key
                            ? "active"
                            : ""
                            }`}
                          eventKey={key}
                          onClick={() => {
                            let updated_data = { ...priceCalculatorState };
                            updated_data.activeTab = key;
                            updated_data.activeChart = key;
                            setPriceCalculatorState(updated_data);
                          }}
                        >
                          <div className="d-flex align-items-center circleHeader  mr-2">
                            <div
                              className="circleDiv"
                              style={{
                                background: `${tab.circleColour}`,
                              }}
                            />
                            <span
                              style={{
                                color: `${priceCalculatorState.activeTab === key
                                  ? "#008FD4"
                                  : ""
                                  }`,
                              }}
                              className="ml-2 mr-2"
                            >
                              {tab?.Name}
                              <img
                                src={`/images/calculator/close1.svg`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removePackage(key);
                                }}
                                className="ml-3 close-icon"
                                alt=""
                              />
                            </span>
                          </div>
                        </Nav.Link>
                      )}
                    </Nav.Item>
                  ))}
                  <Nav.Item className={"disabled mt-2"}>
                    <Nav.Link
                      className={`${priceCalculatorState.tabsData.length === 11
                        ? "disabled"
                        : ""
                        } mt-`}
                      onClick={() => {
                        AddScenario();
                      }}
                    >
                      <div className="d-flex align-items-center circleHeader mr-4 addscn crsrpoint">
                        {priceCalculatorState?.newScenarioCreating ? (
                          <span className="d-inline-flex align-items-center">
                            <Spinner
                              animation="border"
                              variant="secondary"
                              size="sm"
                              className="mx-2"
                              style={{
                                width: "15px",
                                height: "15px",
                                marginBottom: "2px",
                              }}
                            />
                            <span className="d-inline-flex align-items-center">
                              {priceCalculatorState?.scenarioLoadingText}
                            </span>
                          </span>
                        ) : (
                          <>
                            {/* <img
                              src="/images/calculator/plus.svg"
                              className=""
                              alt=""
                            />
                            <span className="ml-2 mr-2">Add Scenario</span> */}
                          </>
                        )}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </div>
                <div className="rightSideBtn mt-2">
                  <span
                    className="mr-3 crsrpoint"
                    onClick={() => {
                      setShowPrintPackageModal(true);
                    }}
                  >
                    <img alt="" src="/images/calculator/shareicon.svg" />
                  </span>
                  <div
                    className="printScenarioBtn crsrpoint"
                    onClick={() => {
                      setShowSharePackageModal(true);
                    }}
                  >
                    <img
                      alt=""
                      src="/images/calculator/sharew.svg"
                      className="mr-2"
                    />
                    Share
                  </div>
                </div>
              </div>

              <Row
                style={{}}
                className="price_calulator_wrapper hide-scrollbar"
              >
                <Col xs={6} className="hide-scrollbar" style={{}}>
                  <Tab.Content>
                    {priceCalculatorState?.tabsData?.map((tab, key) => {
                      return (
                        <Tab.Pane eventKey={key} key={key}>
                          {key === 0 && (
                            <PriceCalculatorDashBoard
                              isMspClientView={isMspClientView}
                              showCreateCustomPackageModal={
                                showCreateCustomPackageModal
                              }
                              setShowCreateCustomPackageModal={
                                setShowCreateCustomPackageModal
                              }
                              defaultUserDeviceDetails={
                                defaultUserDeviceDetails
                              }
                              setDefaultUserDeviceDetails={
                                setDefaultUserDeviceDetails
                              }
                              updateQuantityOfAssets={updateQuantityOfAssets}
                              pageData={pageData}
                              getPackageDetails={getPackageDetails}
                              closeScenario={closeScenario}
                              priceCalculatorState={priceCalculatorState}
                              refreshData={getPageData}
                              _openedPackagesIds={openedPackagesIds}
                              _setOpenedPackagesIds={setOpenedPackagesIds}
                              userSeatingsLoader={userSeatingsLoader}
                            />
                          )}
                          {key > 0 && (
                            <PackageData
                              packageName={tab.Name}
                              _packageData={priceCalculatorState.tabsData[key]}
                              switchVal={switchVal}
                            />
                          )}
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>

                <Col xs={6}>
                  <div className="m-auto w-fit-content mt-4">
                    {pageData?.CurrentPosture?.Identify !== 0 ||
                      pageData?.CurrentPosture?.Protect !== 0 ||
                      pageData?.CurrentPosture?.Detect !== 0 ||
                      pageData?.CurrentPosture?.Response !== 0 ||
                      pageData?.CurrentPosture?.Recover !== 0 ||
                      priceCalculatorState?.tabsData?.length > 1 ? (
                      <MspClientPriceCalculatorRadarChart
                        activeChart={ACTIVE_CHART}
                        _datasets={TABS_DATASETS}
                      />
                    ) : (
                      <MspClientPriceCalculatorEmptyRadarChart
                        activeChart={ACTIVE_CHART}
                        _datasets={TABS_DATASETS}
                      />
                    )}
                    {priceCalculatorState.activeTab !== 0 ? (
                      <div
                        className="d-flex flex-row mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <div
                          className="bg-grey-imp greyText2 p-2 pointer mr-1"
                          onClick={() => {
                            setShowMspScoreModal(true);
                          }}
                        >
                          View Alignment to NIST CSF
                        </div>
                        <div
                          className="bg-grey-imp greyText2 p-2 pointer"
                          onClick={() => {
                            setShowMspFeaturesModal(true);
                          }}
                        >
                          View Features
                        </div>
                      </div>
                    ) : (
                      <div className="p-3"></div>
                    )}
                  </div>

                  {priceCalculatorState.activeTab === 0 && (
                    <>
                      <PriceCalculatorPostureData
                        setShowCyberGroupModal={setShowCyberGroupModal}
                        tabsData={priceCalculatorState.tabsData}
                        activateChartIndex={activateChartIndex}
                        priceCalculatorState={priceCalculatorState}
                      />
                    </>
                  )}
                  {priceCalculatorState.activeTab > 0 && (
                    <>
                      {/* <PerSeatBreakdown /> */}
                      {priceCalculatorState.tabsData[
                        priceCalculatorState.activeTab
                      ]?.ScenarioHeader?.PackageType_Enum === 0 && (
                          <PerSeatBreakdown
                            isMspClientView={isMspClientView}
                            data={
                              priceCalculatorState.tabsData[
                              priceCalculatorState.activeTab
                              ]
                            }
                            packageDetails={packageDetails}
                            updateSeatAssetDataApi={updateSeatAssetDataApi}
                            getAssetSeatDataApi={getAssetSeatDataApi}
                            resetAssetSeatDataApi={resetAssetSeatDataApi}
                            getPackageDetails={getPackageDetails}
                            setIsSmthOverriden={setIsSmthOverriden}
                            setSwitchValPassed={setSwitchVal}
                          // _selectedTax={selectedTax}
                          // _taxList={taxList}
                          />
                        )}

                      {priceCalculatorState.tabsData[
                        priceCalculatorState.activeTab
                      ]?.ScenarioHeader?.PackageType_Enum === 1 && (
                          <>
                            <PerAssetBreakdown
                              isMspClientView={isMspClientView}
                              data={
                                priceCalculatorState.tabsData[
                                priceCalculatorState.activeTab
                                ]
                              }
                              // _selectedTax={selectedTax}
                              // _taxList={taxList}
                              packageDetails={packageDetails}
                              updateSeatAssetDataApi={updateSeatAssetDataApi}
                              getAssetSeatDataApi={getAssetSeatDataApi}
                              resetAssetSeatDataApi={resetAssetSeatDataApi}
                              getPackageDetails={getPackageDetails}
                              setIsSmthOverriden={setIsSmthOverriden}
                              setSwitchValPassed={setSwitchVal}
                            />
                          </>
                        )}
                    </>
                  )}
                  <div className="p-5" />
                </Col>
              </Row>
            </Tab.Container>
          </Row>
        </>
      )}
      <PrintPackageModal
        show={showPrintPackageModal}
        pageData={pageData}
        clientId={clientId}
        hideModal={() => {
          setShowPrintPackageModal(false);
        }}
      />
      <SendPackageModal
        show={showSharePackageModal}
        pageData={pageData}
        clientId={clientId}
        hideModal={() => {
          setShowSharePackageModal(false);
        }}
      />
      <CreateCustomPackageModal
        show={showCreateCustomPackageModal}
        hideModal={() => {
          setShowCreateCustomPackageModal(false);
        }}
        packageList={pageData?.ScenarioHheaderList}
        updatePageData={getPageData}
        duplicatePackage={duplicatePackage}
      />
      <MspScoreModal
        isForPackageDetail={true}
        packageId={Number(
          priceCalculatorState?.tabsData[priceCalculatorState?.activeChart]
            ?.ScenarioHeader?.objId_Scenario
        )}
        show={showMspScoreModal}
        updateRadarChart={updateRadarChart}
        hideModal={() => {
          setShowMspScoreModal(false);
        }}
        selectedNistPiller={Object.values(services_titles)[0]}
        HideMspSecopsLink={true}
        hideServiceModal={() => { }}
        showExploreMappingModal={showExploreMappingModal}
        setShowExploreMappingModal={setShowExploreMappingModal}
        existServices={[
          packageDetails?.Nist_Identify?.length,
          packageDetails?.Nist_Protect?.length,
          packageDetails?.Nist_Detect?.length,
          packageDetails?.Nist_Recover?.length,
          packageDetails?.Nist_Respond?.length,
        ]}
      />
      <MspFeaturesModal
        packageId={Number(
          priceCalculatorState?.tabsData[priceCalculatorState?.activeChart]
            ?.ScenarioHeader?.objId_Scenario
        )}
        show={showMspFeaturesModal}
        hideModal={() => {
          setShowMspFeaturesModal(false);
        }}
        identify={packageDetails?.Nist_Identify}
        protect={packageDetails?.Nist_Protect}
        detect={packageDetails?.Nist_Detect}
        recover={packageDetails?.Nist_Recover}
        respond={packageDetails?.Nist_Respond}
      />
      <ExploreMappingModal
        show={showExploreMappingModal}
        hideModal={() => {
          setShowExploreMappingModal(false);
        }}
      />
      <CyberGroupModal
      AccessStateLabel={'AccessState_ManagedSecurity'}
        isSaas={false} //msp client
        show={showCyberGroupModal}
        hideModal={() => {
          setShowCyberGroupModal(false);
          getPageData();
        }}
        clientId={clientId}
        parentID={"mainBody"}
        passedOptionEnum99={1}
      />
      </div>
            </>
      }
    </>
  );
};

export default MspClientPriceCalculator;

const PriceCalculatorDashBoard = ({
  setShowCreateCustomPackageModal,
  defaultUserDeviceDetails,
  setDefaultUserDeviceDetails,
  updateQuantityOfAssets,
  pageData,
  getPackageDetails,
  priceCalculatorState,
  closeScenario,
  refreshData,
  isMspClientView,
  _openedPackagesIds,
  _setOpenedPackagesIds,
  userSeatingsLoader,
}) => {
  const [showUptoStandard, setShowUptoStandard] = useState(5);
  const [showUptoCustom, setShowUptoCustom] = useState(5);

  const [searchString, setSearchString] = useState("");
  const [savingAssets, setSavingAssets] = useState(false);
  // const [tempUserDetailsData, setTempUserDetailsData] = useState({
  //   NoOfUsers: 22,
  //   NoOfWorkstation: 25,
  //   NoOfServices: 23,
  //   NoOfMobileDevices: 29,
  //   NoOfDomains: 12,
  //   NoOfRouters: 26,
  //   NoOfNetworkDevices: 26,
  // });
  const [tempUserDetailsData, setTempUserDetailsData] = useState([]);
  const [openedPackages, setOpenedPackages] = useState(_openedPackagesIds);
  const preDataState = tempUserDetailsData.every((item) => item.Qty === 0);

  useEffect(() => {
    setOpenedPackages(_openedPackagesIds);
  }, [_openedPackagesIds]);

  useEffect(() => {
    setTempUserDetailsData(defaultUserDeviceDetails);
  }, [defaultUserDeviceDetails]);

  function saveAssets() {
    setSavingAssets(true);
    let userDetails = [...tempUserDetailsData];

    userDetails.forEach((item) => {
      if (item.Qty === "") {
        item.Qty = 0;
      }
    });

    updateQuantityOfAssets(userDetails)
      .then((res) => {
        document.getElementById("quantyAssetOnDashboard").click();
        setSavingAssets(false);
        refreshData();
        CallToast("Updated successfully", false, false, "v2style");
      })
      .catch((err) => {
        setSavingAssets(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function replaceEmptyWithZero(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          obj[key] = 0;
        }
      }
    }
    return obj;
  }

  function ToggleAccordion(e, work = true) {
    if (!work) return;
    var parent = e.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  return (
    <div
      className="p-5"
      style={{
        minWidth: "600px",
        maxWidth: "600px",
      }}
    >
      {/* Quantity of Assets Block */}
      <div className="bg-grey radius-8 point-row p-3">
        <div
          className="d-flex align-items-center justify-content-between pointer"
          onClick={ToggleAccordion}
          id="quantyAssetOnDashboard"
        >
          <div>
            <div className="f-500f-14 f-black">Quantity of Assets</div>
            <div className="f-14 f-darkgrey">
              {preDataState
                ? `Provide us with how many users, workstations/laptops and servers
              you have in your organisation.`
                : TrimString(
                  tempUserDetailsData
                    .map(
                      (item) =>
                        `${item.Name?.replace("Per ", "")}: ${item?.Qty}`
                    )
                    .join(", "),
                  65
                )}
            </div>
          </div>
          <div>
            <img
              alt=""
              src="/images/calculator/edit.svg"
              className="ml-3 mr-2"
            />
          </div>
        </div>
        <div
          className="panel"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="d-flex flex-wrap   msp_assets_inputs_wrapper"
            style={{
              pointerEvents: isMspClientView ? "none" : "auto",
            }}
          >
            {tempUserDetailsData?.map((item) => {
              return (
                <div
                  className={`highlight-input-with-grey-border d-flex align-items-center       p-2 radius-4 mt-2   ${false ? "input-error" : ""
                    } `}
                >
                  <div className="f-darkgrey flex-shrink-0">
                    {item?.Name?.replace("Per ", "")}:
                  </div>
                  <input
                    tabIndex={0}
                    disabled={viewOnlyAccess("AccessState_ManagedSecurity")}
                    type="number"
                    placeholder="0"
                    className=" pl-1 pr-2"
                    value={item?.Qty}
                    onChange={(e) => {
                      if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                        const updatedData = tempUserDetailsData.map(
                          (dataItem) => {
                            if (dataItem.ObjectId === item.ObjectId) {
                              return {
                                ...dataItem,
                                Qty: e.target.value?.trim(),
                              };
                            }
                            return dataItem;
                          }
                        );
                        setTempUserDetailsData(updatedData);
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
          {!isMspClientView && (
            <div className="d-flex align-items-center mt-2">
              <Button
                className={` mr-2 ${viewOnlyAccess("AccessState_ManagedSecurity") ? 'btn-disable cursor-default' : 'hg-cancel-btn'}`}
                onClick={(e) => {
                  if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                    saveAssets(e);
                  }
                }}
              >
                 {
                  viewOnlyAccess("AccessState_ManagedSecurity") &&
                  <img
                  alt=''
                  className='lockerDarkGreyImage mx-2 mb-1'
                  src='/images/settings-v2/locker-grey.svg'
                />
                }
                {savingAssets ? "Saving.." : "Save"}
              </Button>
              <Button
                className="hg-cancel-btn"
                onClick={(e) => {
                    ToggleAccordion(e);
                    setTempUserDetailsData(defaultUserDeviceDetails);
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* search Input */}
      <div className="highlight-input d-flex align-items-center mt-3 p-2 bg-grey radius-8">
        <div>
          <img
            src="/images/attack-surface/search-icon.svg"
            className="m-1"
            alt=""
          />
        </div>

        <input
          className="pl-2 flex-grow-1"
          placeholder="Search.."
          type="text"
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
        />
        {searchString !== "" && (
          <img
            src="/images/settings-v2/header-search-cross.svg"
            className="mx-1 pointer"
            alt=""
            onClick={() => {
              setSearchString("");
            }}
          />
        )}
      </div>

      {userSeatingsLoader ? (
        <div className="d-flex align-items-center bg-grey radius-8 p-3 mt-3 -mb-1">
          <div className="msp-service-update-loader ml-1 mr-2 "></div>
          Please wait until we update your packages
        </div>
      ) : (
        <></>
      )}

      {/* Msp Packages List */}
      <div className="f-500 f-14 f-black mt-4 mb-2">Custom Packages</div>
      <div className="bg-grey radius-8 border-parent">
        {!isMspClientView && (
          <div
            className={`border-item d-flex align-items-center p-3 f-grey f-500 ${viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointe'}`}
            onClick={() => {
              if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                setShowCreateCustomPackageModal(true);
              }
            }}
          >
             {
                        viewOnlyAccess("AccessState_ManagedSecurity") ?
                        <img
                        alt=''
                        className='lockerDarkGreyImage mr-4 mb-1'
                        src='/images/settings-v2/locker-grey.svg'
                      />
                      :
            <img
              alt=""
              src="/images/settings-v2/create-task-icon.svg"
              className=""
            />
             }
            Create custom package
          </div>
        )}
        {pageData?.ScenarioHheaderList.filter(
          (item) => item.IsCustomPackage === true
        ).length > 0 ? (
          pageData?.ScenarioHheaderList.filter(
            (item) => item.IsCustomPackage === true
          )
            ?.filter((item) =>
              item.Name.toLowerCase().includes(searchString.toLowerCase())
            )
            ?.slice(0, showUptoCustom)
            .map((_package, _packageIndex) => {
              const isPackageOpen = openedPackages.includes(
                _package.objId_Scenario
              );

              return (
                <div
                  className={`border-item d-flex align-items-center justify-content-between row-padding bg-hover-dark-grey hover-on-show-parent
                    ${viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointer'}
                  `}
                  onClick={(e) => {
                    if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                      if (openedPackages.length > 0) {
                        const updatedData = isPackageOpen
                          ? openedPackages.filter(
                            (item) => item !== _package.objId_Scenario
                          )
                          : [...openedPackages, _package.objId_Scenario];
  
                        setOpenedPackages(updatedData);
                        _setOpenedPackagesIds(updatedData);
  
                        if (isPackageOpen) {
                          closeScenario(_package.objId_Scenario);
                        } else {
                          getPackageDetails(_package.objId_Scenario);
                        }
                      } else {
                        setOpenedPackages([_package.objId_Scenario]);
                        getPackageDetails(_package.objId_Scenario);
                      }
                    }
                  }}
                >
                  {/* Radar Chart */}
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      <PackageListRadarUI
                        dataList={[
                          _package?.InspectScore,
                          _package?.ProtectScore,
                          _package?.DetectScore,
                          _package?.RespondScore,
                          _package?.RecoverScore,
                        ]}
                        colorPassed={priceCalculatorState.tabsData?.find(obj => obj.ScenarioHeader.objId_Scenario === _package?.objId_Scenario)?.circleColour ?? '#9499AA'}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <div
                      className="msp-package-list-checkbox position-relative   w-fit-content mr-4"
                      style={{ height: "20px" }}
                    >
                      <input
                        type="checkbox"
                        disabled={true}
                        id={`myCheckbox${_packageIndex}`}
                        class="circular-checkbox"
                        checked={isPackageOpen}
                        onClick={(e) => {
                          if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                            if (openedPackages.length > 0) {
                              const updatedData = isPackageOpen
                                ? openedPackages.filter(
                                  (item) => item !== _package.objId_Scenario
                                )
                                : [...openedPackages, _package.objId_Scenario];
  
                              setOpenedPackages(updatedData);
                              _setOpenedPackagesIds(updatedData);
  
                              if (isPackageOpen) {
                                closeScenario(_package.objId_Scenario);
                              } else {
                                getPackageDetails(_package.objId_Scenario);
                              }
                            } else {
                              setOpenedPackages([_package.objId_Scenario]);
                              getPackageDetails(_package.objId_Scenario);
                            }
                          }
                        }}
                      />
                      <label
                        for={`myCheckbox${_packageIndex}`}
                        class="checkbox-label"
                      ></label>
                    </div> */}
                      <div className="d-flex align-items-center">
                        {/* <img
                        alt=""
                        className="mr-3"
                        src="/images/msp/msp-calculator-file-icon.svg"
                      /> */}
                        <div>
                          <div className="subclass">
                            {" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  _package?.Name,
                                  searchString
                                ),
                              }}
                            />
                          </div>
                          {/* <div className="f-500 f-14">{_package?.Name} </div> */}
                          <div className="f-12 f-darkgrey">
                            {TrimString(_package?.Description, 50) ?? ""}
                          </div>
                          <div className="d-flex align-items-center f-darkgrey">
                            {/* <MspSingleTagUI
                            itemName={_package?.PackageType_Text ?? ""}
                          /> */}
                            {_package?.MonthlyPrice_Text}
                            {/* <MspSingleTagUI
                            itemName={_package?.MonthlyPrice_Text}
                          /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end align-content-end" style={{
                    height: '100%',
                    marginTop: '40px'
                  }}>
                    {
                      priceCalculatorState.tabsData?.find(obj => obj.ScenarioHeader.objId_Scenario === _package?.objId_Scenario) && <div className="d-flex align-items-center">
                        <div className="f-400 f-12" style={{ color: "#9499AA" }}>On chart</div>
                        <div
                          className="rounded-full mx-2 f-400 f-12"
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: priceCalculatorState.tabsData?.find(obj => obj.ScenarioHeader.objId_Scenario === _package?.objId_Scenario)?.circleColour,
                            color: "#9499AA"
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
              );
            })
        ) : (
          <div className="border-item d-flex align-items-center justify-content-center p-4">
            No custom packages yet
          </div>
        )}
      </div>

      {pageData?.ScenarioHheaderList.filter(
        (item) => item.IsCustomPackage === true
      )?.length > 0 &&
        pageData?.ScenarioHheaderList.filter(
          (item) => item.IsCustomPackage === true
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length === 0 &&
        searchString !== "" && (
          <div className="f-grey f-500 w-100 text-center mt-5">
            No results found!
          </div>
        )}

      {pageData?.ScenarioHheaderList.filter(
        (item) => item.IsCustomPackage === true
      )?.length > 0 &&
        pageData?.ScenarioHheaderList.filter(
          (item) => item.IsCustomPackage === true
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length > 5 && (
          <>
            <Button
              className="hg-cancel-btn mt-3"
              onClick={() => {
                if (showUptoCustom === 5) {
                  setShowUptoCustom(10000000);
                } else {
                  setShowUptoCustom(5);
                }
              }}
            >
              {showUptoCustom === 5 ? "Show more" : "Show less"}
            </Button>
          </>
        )}

      <div className="f-500 f-14 f-black mt-4 mb-2">MSPs Standard Packages</div>
      <div className="bg-grey radius-8 border-parent">
        {/* {!isMspClientView && (
          <div
            className="border-item d-flex align-items-center p-3 f-grey f-500 pointer"
            onClick={() => {
              setShowCreateCustomPackageModal(true);
            }}
          >
            <img
              alt=""
              src="/images/settings-v2/create-task-icon.svg"
              className=""
            />
            Create custom package
          </div>
        )} */}
        {pageData?.ScenarioHheaderList.filter(
          (item) => item.IsCustomPackage === false
        ).length > 0 ? (
          pageData?.ScenarioHheaderList.filter(
            (item) => item.IsCustomPackage === false
          )
            ?.filter((item) =>
              item.Name.toLowerCase().includes(searchString.toLowerCase())
            )
            ?.slice(0, showUptoStandard)
            .map((_package, _packageIndex) => {
              const isPackageOpen = openedPackages.includes(
                _package.objId_Scenario
              );

              return (
                <div
                  className={`border-item d-flex align-items-center justify-content-between row-padding bg-hover-dark-grey hover-on-show-parent
                    ${viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointer'}
                  `}
                  onClick={(e) => {
                    if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                      if (openedPackages.length > 0) {
                        const updatedData = isPackageOpen
                          ? openedPackages.filter(
                            (item) => item !== _package.objId_Scenario
                          )
                          : [...openedPackages, _package.objId_Scenario];
  
                        setOpenedPackages(updatedData);
                        _setOpenedPackagesIds(updatedData);
  
                        if (isPackageOpen) {
                          closeScenario(_package.objId_Scenario);
                        } else {
                          getPackageDetails(_package.objId_Scenario);
                        }
                      } else {
                        setOpenedPackages([_package.objId_Scenario]);
                        getPackageDetails(_package.objId_Scenario);
                      }
                    }
                  }}
                >
                  {/* Radar Chart */}
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      <PackageListRadarUI
                        dataList={[
                          _package?.InspectScore,
                          _package?.ProtectScore,
                          _package?.DetectScore,
                          _package?.RespondScore,
                          _package?.RecoverScore,
                        ]}
                        colorPassed={priceCalculatorState.tabsData?.find(obj => obj.ScenarioHeader.objId_Scenario === _package?.objId_Scenario)?.circleColour ?? '#9499AA'}
                      />
                    </div>
                 
                    <div className="d-flex align-items-center">
                      {/* <div
                        className="msp-package-list-checkbox position-relative   w-fit-content mr-4"
                        style={{ height: "20px" }}
                      >
                        <input
                          type="checkbox"
                          disabled={true}
                          id={`myCheckbox${_packageIndex}`}
                          class="circular-checkbox"
                          checked={isPackageOpen}
                          onClick={(e) => {
                            if (openedPackages.length > 0) {
                              const updatedData = isPackageOpen
                                ? openedPackages.filter(
                                    (item) => item !== _package.objId_Scenario
                                  )
                                : [...openedPackages, _package.objId_Scenario];

                              setOpenedPackages(updatedData);
                              _setOpenedPackagesIds(updatedData);

                              if (isPackageOpen) {
                                closeScenario(_package.objId_Scenario);
                              } else {
                                getPackageDetails(_package.objId_Scenario);
                              }
                            } else {
                              setOpenedPackages([_package.objId_Scenario]);
                              getPackageDetails(_package.objId_Scenario);
                            }
                          }}
                        />
                        <label
                          for={`myCheckbox${_packageIndex}`}
                          class="checkbox-label"
                        ></label>
                      </div> */}
                      <div className="d-flex align-items-center">
                        {/* <img
                          alt=""
                          className="mr-3"
                          src="/images/msp/msp-calculator-file-icon.svg"
                        /> */}
                        <div>
                          <div className="subclass">
                            {" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  _package?.Name,
                                  searchString
                                ),
                              }}
                            />
                          </div>
                          <div className=" f-grey">
                            {_package?.Description ?? ""}
                          </div>
                          <div className="d-flex align-items-center f-darkgrey justify-content-between">
                            {/* <MspSingleTagUI
                              itemName={_package?.PackageType_Text ?? ""}
                            /> */}
                            {_package?.MonthlyPrice_Text}
                            {/* <MspSingleTagUI
                              itemName={_package?.MonthlyPrice_Text}
                            /> */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-content-end" style={{
                    height: '100%',
                    marginTop: '40px'
                  }}>
                    {
                      priceCalculatorState.tabsData?.find(obj => obj.ScenarioHeader.objId_Scenario === _package?.objId_Scenario) && <div className="d-flex align-items-center">
                        <div className="f-400 f-12" style={{ color: "#9499AA" }}>On chart</div>
                        <div
                          className="rounded-full mx-2 f-400 f-12"
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: priceCalculatorState.tabsData?.find(obj => obj.ScenarioHeader.objId_Scenario === _package?.objId_Scenario)?.circleColour,
                            color: "#9499AA"
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
              );
            })
        ) : (
          <div className="border-item d-flex align-items-center justify-content-center p-4">
            No MSPs standard packages yet
          </div>
        )}
      </div>

      {pageData?.ScenarioHheaderList.filter(
        (item) => item.IsCustomPackage === false
      )?.length > 0 &&
        pageData?.ScenarioHheaderList.filter(
          (item) => item.IsCustomPackage === false
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length === 0 &&
        searchString !== "" && (
          <div className="f-grey f-500 w-100 text-center mt-5">
            No results found!
          </div>
        )}

      {pageData?.ScenarioHheaderList.filter(
        (item) => item.IsCustomPackage === false
      )?.length > 0 &&
        pageData?.ScenarioHheaderList.filter(
          (item) => item.IsCustomPackage === false
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length > 5 && (
          <>
            <Button
              className="hg-cancel-btn mt-3"
              onClick={() => {
                if (showUptoStandard === 5) {
                  setShowUptoStandard(10000000);
                } else {
                  setShowUptoStandard(5);
                }
              }}
            >
              {showUptoStandard === 5 ? "Show more" : "Show less"}
            </Button>
          </>
        )}
    </div>
  );
};

const PriceCalculatorPostureData = ({
  tabsData,
  activateChartIndex,
  hideBanner,
  setShowCyberGroupModal,
  priceCalculatorState,
}) => {
  const [showBanner, setShowBanner] = useState(true);
  useEffect(() => {
    if (tabsData.length <= 1) {
      activateChartIndex(0);
    }
  }, [tabsData]);

  function ToggleAccordionPostureData(e, rowIndex) {
    let event = e;

    var parent = event.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = null;
      activateChartIndex(0);
    } else {
      activateChartIndex(rowIndex);
      let wrapper = event.target.closest(".posture-data-expandables");
      let rows = wrapper.getElementsByClassName("point-row");
      for (let index = 0; index < rows.length; index++) {
        rows[index].querySelector(".panel").style.maxHeight = null;
        rows[index].querySelector("#chevron").classList.remove("rotate180");
      }
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + 5 + "px";
    }
  }

  return (
    <div className="posture-data-expandables">
      {showBanner && (
        <div className="radius-8 bg-grey d-flex align-items-center justify-content-between p-3 mb-2">
          <div className="d-flex align-items-center">
            <img
              alt=""
              src="/images/msp/score-info-icon.svg"
              className="mr-2"
            />
            Improve your security posture by benchmarking it against your MSPs
            security offerings
          </div>
          <div className="d-flex align-items-center">
            <img
              alt=""
              className="ml-3 pointer"
              src="/images/attack-surface/cross-icon.svg"
              onClick={() => {
                setShowBanner(false);
                hideBanner();
              }}
            />
          </div>
        </div>
      )}
      {[...tabsData].map((item, itemDataIndex) => {
        return (
          <div
            className={`w-100 point-row mb-3 ${priceCalculatorState?.activeChart !== 0 &&
              priceCalculatorState?.activeChart === itemDataIndex &&
              " bg-grey radius-4  pb-3 pt-2"
              }`}
          >
            <div
              className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hover-grey radius-4"
              onClick={(e) => {
                ToggleAccordionPostureData(e, itemDataIndex);
                e.preventDefault();
              }}
            >
              <div className="d-flex align-items-center f-500 f-15">
                <div
                  className="rounded-full mx-2"
                  style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: item.circleColour,
                  }}
                />
                {item?.Name}
              </div>
              <div className="w-fit-content d-flex align-items-center">
                {item?.Name?.trim() == "Client current posture" && (
                  <div className="w-fit-content d-flex align-items-center">
                    <Button
                      onClick={(e) => {
                        if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                          // handleSave();
                          console.log("click map");
                          setShowCyberGroupModal(true);
                          e.stopPropagation();
                        }
                      }}
                      className={` f-12 f-500 mr-2 ${viewOnlyAccess("AccessState_ManagedSecurity") ? 'btn-disable cursor-default' : 'hg-blue-btn'}`}
                    >
                      Map security posture
                    </Button>
                  </div>
                )}

                <img
                  alt=""
                  id="chevron"
                  className="chevron"
                  src="/images/chevron-down.svg"
                />
              </div>
            </div>

            <div className="panel">
              <div className="f-darkgrey f-12 f-500 pl-4 py-2">
                {itemDataIndex === 0 ? "Current scores" : "Predicted scores"}
              </div>
              <div className="d-flex">
                <CyberComplianceGauge
                  value={item?.ScenarioHeader?.CyberCompliance}
                />

                <CyberScoreGauge value={item?.ScenarioHeader?.CyberScore} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const PackageListRadarUI = ({ dataList, colorPassed }) => {
  const updatedData = useMemo(() => {
    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: [
        {
          data: dataList ?? [0, 0, 0, 0, 0],
          pointRadius: [0, 0, 0, 0, 0],
          datalabels: {
            display: false,
          },
          backgroundColor: colorPassed ? hexToRGBA(colorPassed, 0.24) : "rgba(0, 143, 212, 0.24)",
          // backgroundColor: `${"#5244B3"}30`,
          borderColor: colorPassed ?? `${"#008FD4"}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: colorPassed ?? "rgba(0, 143, 212, 1)",
          borderWidth: 1,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          label: "Current Posture",
        },
      ],
    };
  }, [dataList]);
  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scale: {
        display: true,
        pointLabels: {
          display: false,
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: 5,
          max: 35,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
    // return opts;
  }, []);
  return (
    <div>
      <Radar
        id="actial-radar"
        redraw={true}
        height={80}
        width={80}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
    </div>
  );
};

const PerSeatBreakdown = ({
  data,
  updateSeatAssetDataApi,
  getAssetSeatDataApi,
  resetAssetSeatDataApi,
  isMspClientView,
  packageDetails,
  getPackageDetails,
  setIsSmthOverriden = () => {},
  setSwitchValPassed = () => {}
  // _selectedTax,
  // _taxList
}) => {
  const [editState, setEditState] = useState(false);

  const [resetting, setResetting] = useState(false);
  const [saving, setSaving] = useState(false);

  // Dynamic Implementation
  const [seatBreakDownData, setSeatBreakDownData] = useState({});
  const isTableEdited =
    seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited ||
    seatBreakDownData?.MSPPackagePrice?.IsSellEddited;
  const [switchVal, setSwitchVal] = useState(false);
  const [switchIsOn, setSwitchIsOn] = useState([]);
  // const [overridenTax, setOverridenTax] = useState([{
  //   packageId: 0,
  //   newTax: {
  //     // "ObjectId": 0,
  //     // "TaxCode": "",
  //     // "TaxName": "",
  //     // "TaxRate": 0,
  //     // "IsDefault": false,
  //     // "IntegrationId": 0
  // }}]);

  useEffect(() => {
    setSwitchValPassed(switchVal)
  }, [switchVal])

  useEffect(() => {
    setSeatBreakDownData(data);
  }, [data, data.MSPPackagePrice]);

  function ToggleAccordionSinglePostureData(e) {
    let event = e;

    var parent = event.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + 5 + "px";
    }
  }
  let totalTax =
    seatBreakDownData?.MSPPackagePrice?.Subtotal *
    seatBreakDownData?.MSPPackagePrice?.Tax *
    0.01;
  // let totalCost = (
  //   seatBreakDownData?.MSPPackagePrice?.Subtotal -
  //   seatBreakDownData?.MSPPackagePrice?.Discount +
  //   totalTax
  // ).toFixed(2);
  let totalCost = Number(seatBreakDownData?.MSPPackagePrice?.TotalCost)?.toFixed(2);

  // overridenTax.findIndex((item, index) =>  {
  //   if (item.packageId === data?.ScenarioHeader?.objId_Scenario) {
  //     return index;
  //   }
  // })
  function handleSave() {
    setSaving(true);

    updateSeatAssetDataApi(seatBreakDownData?.MSPPackagePrice, 0)
      .then((res) => {
        getPackageDetails(seatBreakDownData?.ScenarioHeader?.objId_Scenario)
          .then((res) => {
            setSaving(false);
            setEditState(false);
          })
          .catch((err) => {
            setSaving(false);

            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setSaving(false);

        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function handleReset() {
    setResetting(true);
    resetAssetSeatDataApi(seatBreakDownData?.ScenarioHeader?.objId_Scenario, 0)
      .then((res) => {
        getPackageDetails(seatBreakDownData?.ScenarioHeader?.objId_Scenario) //!test
          .then((res) => {
            setResetting(false);
          })
          .catch((err) => {
            setResetting(false);

            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setResetting(false);

        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  useEffect(() => {
    setSwitchVal(
      switchIsOn.find(
        (item) => item === data?.ScenarioHeader?.objId_Scenario
      ) !== undefined
    );
  }, [data, switchIsOn]);

  let user_data = localStorage.getItem("object_Data");
  let _data = JSON.parse(user_data);

  const toggleSwitch = (id) => {
    if (
      switchIsOn.find(
        (item) => item === data?.ScenarioHeader?.objId_Scenario
      ) !== undefined
    ) {
      setSwitchIsOn(switchIsOn.filter((itemId) => itemId !== id));
    } else {
      setSwitchIsOn([...switchIsOn, id]);
    }
  };

  // const existingTaxIndex = overridenTax.findIndex((item, index) =>  {
  //   console.log('item.packageId === 0 --- ', item.packageId === 0)
  //   if (item.packageId === data?.ScenarioHeader?.objId_Scenario) {
  //     return index;
  //   }
  // })

  // const updateTax = (id, newTax) => {
  //   setOverridenTax(prevState => {
  // //     console.log('existingTaxIndex', existingTaxIndex)
  // //     console.log('id ----      ', id)
  // // console.log('existingTaxIndex---', existingTaxIndex)
  //     if (existingTaxIndex !== -1) {
  //       // If the ID exists, check if the tax is the same
  //       if (prevState[existingTaxIndex].newTax.ObjectId !== newTax.ObjectId) {
  //         // If the tax is different, update the tax
  //         return [
  //           ...prevState.slice(0, existingTaxIndex),
  //           {
  //             ...prevState[existingTaxIndex],
  //             newTax: newTax
  //           },
  //           ...prevState.slice(existingTaxIndex + 1)
  //         ];
  //       } else {
  //         // If the tax is the same, return the previous state unchanged
  //         return prevState;
  //       }
  //     } else {
  //       // If the ID doesn't exist, add it with the new tax
  //       return [
  //         ...prevState,
  //         {
  //           packageId: id,
  //           newTax: newTax
  //         }
  //       ];
  //     }
  //   });
  // };

  //   const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  //   const [taxRateData, setTaxRateData] = useState({});
  //   const { authTokens } = useAuth();
  //   const [taxList, setTaxList] = useState(_taxList);
  //   const [selectedTax, setSelectedTax] = useState(_selectedTax);

  //   useEffect(() => {
  //     setTaxList(_taxList);
  //     setSelectedTax(_selectedTax);
  //   }, [_selectedTax, _taxList])

  //   console.log('_taxList >>>> ', _taxList)
  //   console.log('_selectedTax >>>> ', _selectedTax)

  //   function getTaxRateList() {
  //     return new Promise((resolve, reject) => {
  //       getAPIData("774", authTokens, {
  //         // ObjId_Customer: clientId,
  //         accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  //       })
  //         .then((response) => {
  //           resolve(response);
  //           setTaxList(response?.TaxRates_List ?? []);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           reject(err);
  //         });
  //     });
  //   }

  //   function updateTaxList(stoploading = false) {
  //     if (!stoploading) {
  //     }
  //     getTaxRateList()
  //       .then((response) => {
  //         setTaxList(response?.TaxRates_List);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }

  const [isFirstOpen, setIsFirstOpen] = useState([true, true, true, true]);
  function ToggleAccordion(e, blockId, work = true) {
    if (!work) return;
    var parent = e.target.closest(`.point-row-${blockId}`);
    console.log("e.targeta", e.targeta);
    var panel = parent.querySelector(`.panel-opened`);
    var chevron = parent.querySelector("#chevron");
    if (panel?.style?.maxHeight) {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    let updData = isFirstOpen;
    updData[blockId] = !updData[blockId];
    setIsFirstOpen(updData);
  }

  return (
    <div>
      <div className="w-100 point-row mb-3">
        <div
          className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hover-grey radius-4"
          onClick={(e) => {
            ToggleAccordionSinglePostureData(e);
          }}
        >
          <div className="d-flex align-items-center f-500 f-15">
            <div
              className="rounded-full mx-2"
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: data.circleColour,
              }}
            />
            {data?.Name}
          </div>
          <img
            alt=""
            id="chevron"
            className="chevron"
            src="/images/chevron-down.svg"
          />
        </div>

        <div className="panel">
          <div className="f-darkgrey f-12 f-500 pl-4 py-2">
            Predicted scores
          </div>
          <div className="d-flex">
            <CyberComplianceGauge
              value={data?.ScenarioHeader?.CyberCompliance}
            />

            <CyberScoreGauge value={data?.ScenarioHeader?.CyberScore} />
          </div>
        </div>
      </div>

      <div className="point-row-0">
        <div
          className="d-flex align-items-center justify-content-between pointer"
          style={{ height: "30px" }}
        >
          <div
            className="f-12 f-darkgrey w-100"
            onClick={(e) => {
              ToggleAccordion(e, 0);
            }}
          >
            Price Breakdown
          </div>
          <div className="d-flex align-items-center">
            {isMspClientView ? (
              <div></div>
            ) : (
              <div>
                {editState ? (
                  <>
                    <div className="d-flex align-items-center">
                      <Button
                        onClick={() => {
                          setEditState(false);
                          setSeatBreakDownData(data);
                        }}
                        className="hg-tranparent-grey-btn f-12 f-500 f-grey mr-2"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          handleSave();
                          setIsSmthOverriden(true);
                        }}
                        className="hg-blue-btn f-12 f-500"
                      >
                        {saving ? "Saving.." : "Save"}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    {isTableEdited && (
                      <div
                        className="f-blue f-500 mr-2 pointer d-flex"
                        onClick={() => {
                          handleReset();
                          setIsSmthOverriden(true);
                        }}
                      >
                        <img
                          alt=""
                          className={`mr-1 ${resetting && "rotate-full"}`}
                          src="/images/msp/blue-load-icon.svg"
                        />
                        {resetting ? "Resetting.." : "Reset"}
                      </div>
                    )}
                    <div
                      className="d-flex align-items-center f-darkgrey pointer"
                      onClick={() => {
                        setEditState(true);
                      }}
                    >
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/msp-grey-pencil.svg"
                      />
                      Override
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              onClick={(e) => {
                ToggleAccordion(e, 0);
              }}
            >
              <img
                alt=""
                id="chevron"
                className="chevron ml-2"
                src="/images/attack-surface/chevron-up.svg"
              />
            </div>
          </div>
        </div>
        <div
          className="panel-opened"
          style={{
            maxHeight: isFirstOpen[0] === true && "800px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* Req seats header */}
          <div
            className="d-flex align-items-center justify-content-between my-2  bg-grey radius-8"
            style={{
              height: "46px",
            }}
          >
            <div className="f-14 f-600 pl-3">Req. Seats</div>
            <div>
              {editState ? (
                <>
                  <div className="always-highlight-input p-1 mr-3 overflow-hidden radius-4">
                    <input
                      type="number"
                      className="border-none bg-transparent overflow-hidden"
                      value={
                        seatBreakDownData?.MSPPackagePrice
                          ?.RequiredSeatsOverriden
                      }
                      onChange={(e) => {
                        let updated_data = { ...seatBreakDownData };
                        updated_data.MSPPackagePrice.RequiredSeatsOverriden =
                          e.target.value;

                        setSeatBreakDownData(updated_data);
                      }}
                      autoFocus
                    />
                  </div>
                </>
              ) : (
                <>
                  {seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited ? (
                    <div className="f-blue position-relative pr-5">
                      <span className="blue-dot" />
                      {
                        seatBreakDownData?.MSPPackagePrice
                          ?.RequiredSeatsOverriden
                      }
                    </div>
                  ) : (
                    <div className="pr-5">
                      {
                        seatBreakDownData?.MSPPackagePrice
                          ?.RequiredSeatsOverriden
                      }{" "}
                    </div>
                  )}

                  {/* <div className="f-600 f-grey pr-5">{testData.reqSeats}</div> */}
                </>
              )}
            </div>
          </div>

          {/* Req seat table */}
          <div className="border-parent  radius-8">
            <div className="per_asset_period-breakdown_table border-item f-grey">
              <div className="f-12 f-500 f-darkgrey  pl-2">Asset Type</div>
              <div className="f-12 f-500 f-darkgrey">Inc. per Seat</div>
              <div className="f-12 f-500 f-darkgrey">Client Qty</div>
              <div className="f-12 f-500 f-darkgrey">Seats Req</div>
            </div>
            {seatBreakDownData?.MSPPackagePrice?.PackagePerSeat_List?.map(
              (data, dataIndex) => {
                return (
                  <div className="per_asset_period-breakdown_table  border-item">
                    <div className="f-500  pl-2">{data.Name} </div>
                    <div className="f-500 f-grey">{data.PerSeat} </div>
                    <div className="f-500 f-grey">{data.ClientQty} </div>
                    <div className="f-600 f-grey">{data.SeatRequired} </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* Unit Pricing Table */}

      <div className="point-row-1">
        <div
          className=" f-12 f-500 f-darkgrey my-2 mb-3 d-flex align-items-center justify-content-between pointer"
          onClick={(e) => {
            ToggleAccordion(e, 1);
          }}
        >
          Unit Pricing
          <img
            alt=""
            id="chevron"
            className="chevron ml-2"
            src="/images/attack-surface/chevron-up.svg"
          />
        </div>

        <div
          className="panel-opened"
          style={{
            maxHeight: isFirstOpen[1] === true && "120px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="d-flex w-100 mb-3">
            <div className="w-50">
              <div className="d-flex f-500">
                Billing period
                <div className="msp-service-tool-tip-parent w-fit-content">
                  <img
                    className="ml-2 pointer"
                    src="/images/msp/ques-info-icon.svg"
                    alt=""
                  />
                  {/* <MspServiceModalToolTip data={getToolTip(4)} /> */}
                </div>
              </div>
            </div>

            <div className="w-50 " style={{}}>
              <div className="w-fit-content f-darkgrey">
                {
                  seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                    ?.DDLText
                }
              </div>
            </div>
          </div>

          <div className="d-flex w-100 mb-3">
            <div className="w-50">
              <div className="d-flex f-500">Seats Required</div>
            </div>

            <div className="w-50 " style={{}}>
              <div className="w-fit-content">
                {seatBreakDownData?.MSPPackagePrice?.RequiredSeatsOverriden}
              </div>
            </div>
          </div>

          {/* {switchVal && (
        <div className="d-flex w-100 mb-3">
          <div className="w-50">
            <div className="d-flex f-500">Cost</div>
          </div>

          <div className="w-50 " style={{}}>
            <div className="w-fit-content f-darkgrey">
              {renderPriceText(
                seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                  ?.DDLSort,
                Number(seatBreakDownData?.MSPPackagePrice?.Cost)?.toFixed(2),
                packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
              )}
            </div>
          </div>
        </div>
      )} */}
          <div className="d-flex w-100 mb-3">
            <div className="w-50">
              <div className="d-flex f-500">Seat Price</div>
            </div>

            <div
              className="w-50 d-flex align-items-center justify-content-between"
              style={{}}
            >
              {editState ? (
                <div className="always-highlight-input p-1 mr-3 overflow-hidden radius-4 d-flex ">
                  <div className="ml-1 mr-2">
                    {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                      "£"}
                  </div>
                  <input
                    type="number"
                    className="border-none bg-transparent overflow-hidden"
                    value={seatBreakDownData?.MSPPackagePrice?.SellOverridden}
                    onChange={(e) => {
                      let updated_data = { ...seatBreakDownData };
                      updated_data.MSPPackagePrice.SellOverridden =
                        e.target.value;

                      setSeatBreakDownData(updated_data);
                    }}
                  />
                </div>
              ) : (
                <>
                  {seatBreakDownData?.MSPPackagePrice?.IsSellEddited ? (
                    <div className="f-blue position-relative pr-5">
                      <span className="blue-dot" />{" "}
                      {renderPriceText(
                        seatBreakDownData?.ScenarioHeader
                          ?.BillingPeriod_Selected?.DDLSort,
                          Number(seatBreakDownData?.MSPPackagePrice?.SellOverridden)?.toFixed(
                          2
                        ),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                  ) : (
                    <div className="pr-5">
                      {renderPriceText(
                        seatBreakDownData?.ScenarioHeader
                          ?.BillingPeriod_Selected?.DDLSort,
                          Number(seatBreakDownData?.MSPPackagePrice?.TotalSell)?.toFixed(
                          2
                        ),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}{" "}
                    </div>
                  )}
                </>
                // <div className="w-fit-content f-darkgrey">£{testData.sell}/mo</div>
              )}

              {/* {
            switchVal &&
            <div className="f-darkgrey">
              RRP:{" "}
              {renderPriceText(
                seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                  ?.DDLSort,
                Number(seatBreakDownData?.MSPPackagePrice?.RRP)?.toFixed(2),
                packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
              )}
            </div>
          } */}
            </div>
          </div>
        </div>
      </div>

      {/* Tota pricing Table */}
      <div className="point-row-2">
        <div
          className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between pointer"
          onClick={(e) => {
            ToggleAccordion(e, 2);
          }}
        >
          Summary
          <img
            alt=""
            id="chevron"
            className="chevron ml-2"
            src="/images/attack-surface/chevron-up.svg"
          />
        </div>
        <div
          className="border-parent  radius-8 panel-opened"
          style={{
            maxHeight: isFirstOpen[2] === true && "126px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="per_asset_total_pricing_table-updated border-item">
            <div className="f-500  pl-2">Package Price</div>
            <div></div>
            <div className="f-500 f-grey">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(seatBreakDownData?.MSPPackagePrice?.Subtotal)?.toFixed(2)
              )}
            </div>
          </div>
          {/* 
        <div className="per_asset_total_pricing_table  border-item">
          <div className="f-500  pl-2">Discount</div>
          <div className="f-500 f-grey">
            -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {Number(seatBreakDownData?.MSPPackagePrice?.Discount)?.toFixed(2)}
          </div>
          <div className="f-500 f-grey">
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {Number(
              seatBreakDownData?.MSPPackagePrice?.Subtotal -
              seatBreakDownData?.MSPPackagePrice?.Discount
            )?.toFixed(2)}
          </div>
        </div> */}

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-500 pl-2">
              {`
              Tax @ ${seatBreakDownData?.TaxRates_Selected?.TaxRate}% (${seatBreakDownData?.TaxName})
            ` ?? "Tax @ 0% (No Taxable)"}
              {/* <div className="d-flex">
            <div className="f-500 pl-2 mr-5">
                Tax Rate
            </div>
            <div>
              <TaxRateDropdown
                deleteTaxRate={deleteTaxRate}
                updateTaxList={updateTaxList}
                onChange={(tax) => {
                  updateTax(data?.ScenarioHeader?.objId_Scenario, tax)
                  // setSelectedTax(tax);
                }}
                taxList={taxList}
                selected={overridenTax[existingTaxIndex] ?? selectedTax}
                onClickEditTaxRate={(data) => {
                  setTaxRateData(data);
                  setShowAddEditTaxRateModal(true);
                }}
                onClickAddTaxRate={() => {
                  setTaxRateData({
                    ObjectId: 0,
                    TaxCode: "",
                    TaxName: "",
                    TaxRate: "",
                  });
                  setShowAddEditTaxRateModal(true);
                }}
              />
              <div
                className={`add-edit-tax-rate-modal-wrapper ${
                  showAddEditTaxRateModal ? "show mt-187px" : ""
                }`}
              >
                <AddEditTaxRateModal
                  show={showAddEditTaxRateModal}
                  hideModal={() => {
                    setShowAddEditTaxRateModal(false);
                  }}
                  taxRateData={taxRateData}
                  updateTaxList={updateTaxList}
                  deleteTaxRate={deleteTaxRate}
                />
              </div>
            </div>
          </div> */}
            </div>
            {/* <div className="f-500 f-grey">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{totalTax}</div> */}
            <div className="f-500 f-grey">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(seatBreakDownData?.MSPPackagePrice?.Tax)?.toFixed(2)
              )}
            </div>

            <div className="f-500 f-grey">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(
                  seatBreakDownData?.MSPPackagePrice?.Subtotal +
                  // seatBreakDownData?.MSPPackagePrice?.Discount +
                  // totalTax}
                  seatBreakDownData?.MSPPackagePrice?.Tax
                ).toFixed(2)
              )}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-600  pl-2">Total</div>
            <div></div>
            <div className="f-600 f-grey">
              {renderPriceText(
                seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                  ?.DDLSort,
                totalCost,
                packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Period Break down Table */}
      <div className="point-row-3">
        <div
          className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between pointer"
          onClick={(e) => {
            ToggleAccordion(e, 3);
          }}
        >
          Breakdown by period
          <img
            alt=""
            id="chevron"
            className="chevron ml-2"
            src="/images/attack-surface/chevron-up.svg"
          />
        </div>
        <div
          className="border-parent  radius-8 panel-opened"
          style={{
            maxHeight: isFirstOpen[3] === true && "168px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={` border-item f-grey 
          ${switchVal
                ? " per_asset_period-breakdown_table "
                : " per_asset_period-breakdown_table_only_sell "
              }
        `}
          >
            <div className="  pl-2">Period</div>
            {switchVal && <div>Cost</div>}
            <div>Sell</div>
            {switchVal && <div>Margin</div>}
          </div>
          {seatBreakDownData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
            (data, index) => {
              return (
                <div
                  className={` border-item 
                ${switchVal
                      ? " per_asset_period-breakdown_table "
                      : " per_asset_period-breakdown_table_only_sell "
                    }
              `}
                >
                  <div className="f-500  pl-2">{data?.Period}</div>
                  {switchVal && (
                    <div className="f-500 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Cost)?.toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                  )}
                  <div className="f-500 f-grey">
                    {renderPriceText(
                      99,
                      Number(data?.Sell)?.toFixed(2),
                      packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                      "£"
                    )}
                  </div>
                  {switchVal && (
                    <div className="f-500 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Margin)?.toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>

      {_data?.UserDetail?.Role?.toLowerCase() === "msp" && (
        <div
          style={{
            marginTop: "-20px",
          }}
        >
          <SwitchButtonMulti
            id={`switch_toggle_button_${data?.ScenarioHeader?.objId_Scenario}`}
            label={"Show extended details"}
            value={switchVal}
            onChange={(e) => {
              toggleSwitch(data?.ScenarioHeader?.objId_Scenario);
            }}
          />
        </div>
      )}
    </div>
  );
};

const PerAssetBreakdown = ({
  data,
  updateSeatAssetDataApi,
  getAssetSeatDataApi,
  resetAssetSeatDataApi,
  isMspClientView,
  packageDetails,
  getPackageDetails,
  setIsSmthOverriden = () => {},
  setSwitchValPassed = () => {}
  // _selectedTax,
  // _taxList
}) => {
  const [editState, setEditState] = useState(false);
  const [switchVal, setSwitchVal] = useState(false);
  const [switchIsOn, setSwitchIsOn] = useState([]);
  const tableData = [
    {
      name: "User",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Device",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Mobile Device",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Router/Firewall",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Server",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
  ];
  const [resetting, setResetting] = useState(false);
  const [priceTableData, setPriceTableData] = useState([...tableData]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSwitchValPassed(switchVal)
  }, [switchVal])

  useEffect(() => {
    setSwitchVal(
      switchIsOn.find(
        (item) => item === data?.ScenarioHeader?.objId_Scenario
      ) !== undefined
    );
  }, [data, switchIsOn]);

  const toggleSwitch = (id) => {
    if (
      switchIsOn.find(
        (item) => item === data?.ScenarioHeader?.objId_Scenario
      ) !== undefined
    ) {
      setSwitchIsOn(switchIsOn.filter((itemId) => itemId !== id));
    } else {
      setSwitchIsOn([...switchIsOn, id]);
    }
  };
  let user_data = localStorage.getItem("object_Data");
  let _data = JSON.parse(user_data);

  // Dynamic Implementation
  const [assetBreakDownData, setAssetBreakDownData] = useState({});
  const isTableEdited =
    assetBreakDownData?.MSPPackagePrice?.PackagePerAsset_List?.some(
      (item) => item.IsEditedUnitSell === true || item.IsEditedQuantity === true
    );

  useEffect(() => {
    setAssetBreakDownData(data);
  }, [data, data.MSPPackagePrice]);

  function ToggleAccordionSinglePostureData(e) {
    let event = e;
    var parent = event.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + 5 + "px";
    }
  }
  let totalTax =
    assetBreakDownData?.MSPPackagePrice?.Subtotal *
    assetBreakDownData?.MSPPackagePrice?.Tax *
    0.01;
  // let totalCost = (
  //   assetBreakDownData?.MSPPackagePrice?.Subtotal -
  //   assetBreakDownData?.MSPPackagePrice?.Discount +
  //   totalTax
  // ).toFixed(2);
  let totalCost = Number(assetBreakDownData?.MSPPackagePrice?.TotalCost)?.toFixed(2);

  function handleSave() {
    setSaving(true);

    updateSeatAssetDataApi(assetBreakDownData?.MSPPackagePrice, 1)
      .then((res) => {
        getPackageDetails(assetBreakDownData?.ScenarioHeader?.objId_Scenario)
          .then((res) => {
            setSaving(false);
            setEditState(false);
          })
          .catch((err) => {
            setSaving(false);

            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setSaving(false);

        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function handleReset() {
    setResetting(true);
    resetAssetSeatDataApi(assetBreakDownData?.ScenarioHeader?.objId_Scenario, 1)
      .then((res) => {
        getPackageDetails(assetBreakDownData?.ScenarioHeader?.objId_Scenario)
          .then((res) => {
            setResetting(false);
          })
          .catch((err) => {
            setResetting(false);
            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setResetting(false);

        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  // const [taxRateData, setTaxRateData] = useState({});
  // const { authTokens } = useAuth();
  // const [taxList, setTaxList] = useState(_taxList);
  // const [selectedTax, setSelectedTax] = useState(_selectedTax);

  // useEffect(() => {
  //   setTaxList(_taxList);
  //   setSelectedTax(_selectedTax);
  // }, [_selectedTax, _taxList])

  // console.log('_taxList >>>> ', _taxList)
  // // console.log('userData ---- ', userData)
  // console.log('_selectedTax >>>> ', _selectedTax)

  // function getTaxRateList() {
  //   return new Promise((resolve, reject) => {
  //     getAPIData("774", authTokens, {
  //       // ObjId_Customer: clientId,
  //       accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  //     })
  //       .then((response) => {
  //         resolve(response);
  //         setTaxList(response?.TaxRates_List ?? []);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // }

  // function updateTaxList(stoploading = false) {
  //   if (!stoploading) {
  //   }
  //   getTaxRateList()
  //     .then((response) => {
  //       setTaxList(response?.TaxRates_List);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  const [isFirstOpen, setIsFirstOpen] = useState([true, false, true, true]);
  function ToggleAccordion(e, blockId, work = true) {
    if (!work) return;
    var parent = e.target.closest(`.point-row-${blockId}`);
    console.log("e.targeta", e.targeta);
    var panel = parent.querySelector(`.panel-opened`);
    var chevron = parent.querySelector("#chevron");
    if (panel?.style?.maxHeight) {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    let updData = isFirstOpen;
    updData[blockId] = !updData[blockId];
    setIsFirstOpen(updData);
  }

  return (
    <div>
      <div className="w-100 point-row mb-3">
        <div
          className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hover-grey radius-4"
          onClick={(e) => {
            ToggleAccordionSinglePostureData(e);
          }}
        >
          <div className="d-flex align-items-center f-500 f-15">
            <div
              className="rounded-full mx-2"
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: data.circleColour,
              }}
            />
            {data?.Name}
          </div>
          <img
            alt=""
            id="chevron"
            className="chevron"
            src="/images/chevron-down.svg"
          />
        </div>

        <div className="panel">
          <div className="f-darkgrey f-12 f-500 pl-4 py-2">
            Predicted scores
          </div>
          <div className="d-flex">
            <CyberComplianceGauge
              value={data?.ScenarioHeader?.CyberCompliance}
            />

            <CyberScoreGauge value={data?.ScenarioHeader?.CyberScore} />
          </div>
        </div>
      </div>
      <div className="point-row-0">
        <div
          className="d-flex align-items-center justify-content-between pointer"
          style={{ height: "30px" }}
        >
          <div
            className="f-12 f-darkgrey w-100"
            onClick={(e) => {
              ToggleAccordion(e, 0);
            }}
          >
            Price Breakdown
          </div>
          <div className="d-flex align-items-center">
            {isMspClientView ? (
              <div></div>
            ) : (
              <div>
                {editState ? (
                  <>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => { }}
                    >
                      <Button
                        className="hg-tranparent-grey-btn f-12 f-500 f-grey mr-2"
                        onClick={() => {
                          setEditState(false);
                          setAssetBreakDownData(data);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="hg-blue-btn f-12 f-500"
                        onClick={() => {
                          handleSave();
                          setIsSmthOverriden(true);
                        }}
                      >
                        {saving ? "Saving.." : "Save"}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    {isTableEdited && (
                      <div
                        className="f-blue f-500 mr-2 pointer d-flex"
                        onClick={() => {
                          handleReset();
                          setIsSmthOverriden(true);
                        }}
                      >
                        <img
                          alt=""
                          className={`mr-1 ${resetting && "rotate-full"}`}
                          src="/images/msp/blue-load-icon.svg"
                        />
                        {resetting ? "Resetting.." : "Reset"}
                      </div>
                    )}
                    <div
                      className="d-flex align-items-center f-darkgrey pointer"
                      onClick={() => {
                        setEditState(true);
                      }}
                    >
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/msp-grey-pencil.svg"
                      />
                      Override
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              onClick={(e) => {
                ToggleAccordion(e, 0);
              }}
            >
              <img
                alt=""
                id="chevron"
                className="chevron ml-2"
                src="/images/attack-surface/chevron-up.svg"
              />
            </div>
          </div>
        </div>
        {/* Price breakdown table */}
        <div
          className="border-parent  radius-8 panel-opened"
          style={{
            maxHeight: isFirstOpen[0] === true && "800px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={`border-item f-12 f-500 f-darkgrey p-2 ${switchVal
              ? " per_asset_price_breakdown_table "
              : " per_asset_price_breakdown_table_switch "
              }`}
          >
            <div>Asset Type</div>
            <div>Qty</div>
            {switchVal && <div>Unit Cost</div>}
            {switchVal && <div>Unit RRP</div>}
            <div>Unit Sell</div>
            <div>Line Total</div>
          </div>
          {assetBreakDownData?.MSPPackagePrice?.PackagePerAsset_List
            ?.map((data, dataIndex) => {
              return (
                <>
                  {data.UnitCost !== 0 && (
                    <div
                      className={`border-item f-grey p-2 ${switchVal
                        ? " per_asset_price_breakdown_table "
                        : " per_asset_price_breakdown_table_switch "
                        }`}
                    >
                      <div className="f-black f-500">{data.Name}</div>
                      <div>
                        {editState ? (
                          <>
                            <div className="always-highlight-input p-1 mr-3 overflow-hidden">
                              <input
                                type="number"
                                className="border-none bg-transparent overflow-hidden"
                                value={data.Qty}
                                onChange={(e) => {
                                  let updated_data = { ...assetBreakDownData };
                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].IsEditedQuantity = true;
                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].Qty =
                                    e.target.value && Number(e.target.value);
                                  setAssetBreakDownData(updated_data);
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {data.IsEditedQuantity ? (
                              <div className="f-blue position-relative">
                                <span className="blue-dot" />
                                {data.Qty}
                              </div>
                            ) : (
                              <div>{data.Qty} </div>
                            )}
                          </>
                        )}
                      </div>
                      {switchVal && (
                        <div>
                          {" "}
                          <span>
                            {packageDetails?.CurrencyCode_Selected
                              ?.CurrencySymbol ?? "£"}
                          </span>
                          {Number(data.UnitCost).toFixed(2)}
                        </div>
                      )}
                      {switchVal && (
                        <div>
                          {packageDetails?.CurrencyCode_Selected
                            ?.CurrencySymbol ?? "£"}
                          {Number(data?.UnitRRP)?.toFixed(2)}{" "}
                        </div>
                      )}
                      <div>
                        {editState ? (
                          <>
                            <div className="always-highlight-input p-1 mr-3 overflow-hidden d-flex align-items-center">
                              <div className="ml-1 mr-2">{packageDetails?.CurrencyCode_Selected
                            ?.CurrencySymbol ?? "£"}</div>
                              <input
                                type="number"
                                className="border-none bg-transparent overflow-hidden"
                                value={data.UnitSell}
                                onChange={(e) => {
                                  let updated_data = { ...assetBreakDownData };
                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].IsEditedUnitSell = true;
                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].UnitSell = e.target.value;
                                  setAssetBreakDownData(updated_data);
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  if (!isNaN(value)) {
                                    if (value.includes(".")) {
                                      const decimalPartLength =
                                        value.split(".")[1].length;
                                      if (decimalPartLength === 1) {
                                        let updated_data = { ...assetBreakDownData };
                                        updated_data.MSPPackagePrice.PackagePerAsset_List[
                                          dataIndex
                                        ].UnitSell =
                                          value + "0";
                                          setAssetBreakDownData(updated_data);
                                      } else if (decimalPartLength > 2) {
                                        let updated_data = { ...assetBreakDownData };
                                        updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].UnitSell =
                                          Number(value).toFixed(2).toString();
                                          setAssetBreakDownData(updated_data);
                                      }
                                    } else {
                                      let updated_data = { ...assetBreakDownData };
                                      updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].UnitSell =
                                        value + ".00";
                                        setAssetBreakDownData(updated_data);
                                    }
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {data.IsEditedUnitSell ? (
                              <div className="f-blue position-relative">
                                <span className="blue-dot" />{" "}
                                <span>
                                  {" "}
                                  {packageDetails?.CurrencyCode_Selected
                                    ?.CurrencySymbol ?? "£"}
                                </span>
                                {Number(data.UnitSell)?.toFixed(2)}
                              </div>
                            ) : (
                              <div>
                                <span>
                                  {" "}
                                  {packageDetails?.CurrencyCode_Selected
                                    ?.CurrencySymbol ?? "£"}
                                </span>
                                {Number(data.UnitSell)?.toFixed(2)}{" "}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div>
                        <span>
                          {" "}
                          {packageDetails?.CurrencyCode_Selected
                            ?.CurrencySymbol ?? "£"}
                        </span>
                        {Number(data?.SellTotal)?.toFixed(2)}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>

      <div className="my-2 point-row d-none">
        <div
          className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hov er-grey radius-4"
          onClick={(e) => {
            ToggleAccordionSinglePostureData(e);
          }}
        >
          <div className="d-flex align-items-center f-500 f-12 f-darkgrey">
            {/* <div
                  className="rounded-full mx-2"
                  style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: data.circleColour,
                  }}
                /> */}
            Not included asset types
          </div>
          <img
            alt=""
            id="chevron"
            className="chevron"
            src="/images/chevron-down.svg"
          />
        </div>

        <div className="panel">
          <div className="border-parent radius-4">
            <div className="border-item f-12 f-500 p-2 f-darkgrey">
              Asset Type
            </div>
            <div className="border-item p-2 f-darkgrey">Per Network Device</div>
            <div className="border-item p-2 f-darkgrey">Per Domain</div>
          </div>
        </div>
      </div>

      {/* Not included assets Table */}
      <div className="point-row-1">
        <div
          className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between pointer"
          // onClick={ToggleAccordion}
          onClick={(e) => {
            ToggleAccordion(e, 1);
          }}
        >
          Not included asset types
          <img
            alt=""
            id="chevron"
            className="chevron"
            src="/images/chevron-down.svg"
          />
        </div>
        <div
          className="border-parent  radius-8 panel-opened"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* <div className="per_asset_total_pricing_table border-item">
          <div className="f-500 f-12 f-darkgrey pl-2">Asset Type</div>
          <div></div>
        </div> */}
          {assetBreakDownData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
            (item) => item.UnitCost == 0
          )?.map((data, dataIndex) => {
            return (
              <div className="per_asset_total_pricing_table  border-item">
                <div className="f-400 f-darkgrey pl-2">{data.Name}</div>
              </div>
            );
          })}
          {/* {packageDetails?.ScenarioHeader?.UserAndDeviceCount?.NoOfUsers ===
          0 && (
          <div className="per_asset_total_pricing_table  border-item">
            <div className="f-400 f-darkgrey pl-2">Per Users</div>
          </div>
        )}
        {packageDetails?.ScenarioHeader?.UserAndDeviceCount?.NoOfWorkstation ===
          0 && (
          <div className="per_asset_total_pricing_table  border-item">
            <div className="f-400 f-darkgrey pl-2">Per Devices</div>
          </div>
        )}
        {packageDetails?.ScenarioHeader?.UserAndDeviceCount
          ?.NoOfMobileDevices === 0 && (
          <div className="per_asset_total_pricing_table  border-item">
            <div className="f-400 f-darkgrey pl-2">Per Mobile Devices</div>
          </div>
        )}
        {packageDetails?.ScenarioHeader?.UserAndDeviceCount?.NoOfServices ===
          0 && (
          <div className="per_asset_total_pricing_table  border-item">
            <div className="f-400 f-darkgrey pl-2">Per Servers</div>
          </div>
        )}
        {packageDetails?.ScenarioHeader?.UserAndDeviceCount?.NoOfRouters ===
          0 && (
          <div className="per_asset_total_pricing_table  border-item">
            <div className="f-400 f-darkgrey pl-2">Per Routers/Firewalls</div>
          </div>
        )}
        {packageDetails?.ScenarioHeader?.UserAndDeviceCount
          ?.NoOfNetworkDevices === 0 && (
          <div className="per_asset_total_pricing_table  border-item">
            <div className="f-400 f-darkgrey pl-2">Per Network Devices</div>
          </div>
        )}
        {packageDetails?.ScenarioHeader?.UserAndDeviceCount?.NoOfDomains ===
          0 && (
          <div className="per_asset_total_pricing_table  border-item">
            <div className="f-400 f-darkgrey pl-2">Per Domains</div>
          </div>
        )} */}
        </div>
      </div>

      {/* Tota pricing Table */}
      <div className="point-row-2">
        <div
          className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between pointer"
          onClick={(e) => {
            ToggleAccordion(e, 2);
          }}
        >
          Summary
          <img
            alt=""
            id="chevron"
            className="chevron"
            src="/images/attack-surface/chevron-up.svg"
          />
        </div>
        <div
          className="border-parent  radius-8 panel-opened"
          style={{
            maxHeight: isFirstOpen[2] === true && "126px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="per_asset_total_pricing_table-updated border-item">
            <div className="f-500  pl-2">Package Price</div>
            <div></div>
            <div>
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number(assetBreakDownData?.MSPPackagePrice?.Subtotal)?.toFixed(2)}
            </div>
          </div>

          {/* <div className="per_asset_total_pricing_table  border-item">
          <div className="f-500  pl-2">Discount</div>
          <div className="f-500 f-grey">
            -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {assetBreakDownData?.MSPPackagePrice?.Discount.toFixed(2)}
          </div>
          <div className="f-500 f-grey">
            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
            {(
              assetBreakDownData?.MSPPackagePrice?.Subtotal -
              assetBreakDownData?.MSPPackagePrice?.Discount
            ).toFixed(2)}
          </div>
        </div> */}

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-500 pl-2">
              {`
              Tax @ ${assetBreakDownData?.TaxRates_Selected?.TaxRate}% (${assetBreakDownData?.TaxName})
            ` ?? "Tax @ 0% (No Taxable)"}
            </div>
            {/* <div className="d-flex">
            <div className="f-500 pl-2 mr-5">
                Tax Rate
            </div>
            <div>
              <TaxRateDropdown
                deleteTaxRate={deleteTaxRate}
                updateTaxList={updateTaxList}
                onChange={(data) => {
                  // let updated_data = { ...pageData };
                  // updated_data.response.TaxRates_Selected = data;
                  // setPageData(updated_data);
                  setSelectedTax(data);
                }}
                taxList={taxList}
                selected={selectedTax}
                onClickEditTaxRate={(data) => {
                  setTaxRateData(data);
                  setShowAddEditTaxRateModal(true);
                }}
                onClickAddTaxRate={() => {
                  setTaxRateData({
                    ObjectId: 0,
                    TaxCode: "",
                    TaxName: "",
                    TaxRate: "",
                  });
                  setShowAddEditTaxRateModal(true);
                }}
              />
              <div
                className={`add-edit-tax-rate-modal-wrapper ${
                  showAddEditTaxRateModal ? "show mt-187px" : ""
                }`}
              >
                <AddEditTaxRateModal
                  show={showAddEditTaxRateModal}
                  hideModal={() => {
                    setShowAddEditTaxRateModal(false);
                  }}
                  taxRateData={taxRateData}
                  updateTaxList={updateTaxList}
                  deleteTaxRate={deleteTaxRate}
                />
              </div>
            </div>
          </div> */}
            {/* <div className="f-500 f-grey">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{totalTax.toFixed(2)}</div> */}
            <div className="f-500 f-grey">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number(assetBreakDownData?.MSPPackagePrice?.Tax)?.toFixed(2)}
            </div>
            <div className="f-500 f-grey">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number(
                assetBreakDownData?.MSPPackagePrice?.Subtotal +
                // assetBreakDownData?.MSPPackagePrice?.Discount +
                // totalTax
                assetBreakDownData?.MSPPackagePrice?.Tax
              ).toFixed(2)}{" "}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-600  pl-2">Total</div>
            <div></div>
            <div className="f-600 f-grey">
              {renderPriceText(
                assetBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                  ?.DDLSort,
                totalCost,
                packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Period Break down Table */}
      <div className="point-row-3">
        <div
          className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between pointer"
          onClick={(e) => {
            ToggleAccordion(e, 3);
          }}
        >
          Breakdown by period
          <img
            alt=""
            id="chevron"
            className="chevron"
            src="/images/attack-surface/chevron-up.svg"
          />
        </div>
        <div
          className="border-parent  radius-8 panel-opened"
          style={{
            maxHeight: isFirstOpen[3] === true && "168px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={` border-item f-grey 
          ${switchVal
                ? " per_asset_period-breakdown_table "
                : " per_asset_period-breakdown_table_only_sell "
              }
        `}
          >
            <div className="  pl-2">Period</div>
            {switchVal && <div>Cost</div>}
            <div>Sell</div>
            {switchVal && <div>Margin</div>}
          </div>

          {assetBreakDownData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
            (data, index) => {
              return (
                <div
                  className={` border-item 
              ${switchVal
                      ? " per_asset_period-breakdown_table "
                      : " per_asset_period-breakdown_table_only_sell "
                    }  
              `}
                >
                  <div className="f-500  pl-2">{data?.Period} </div>
                  {switchVal && (
                    <div className="f-500 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Cost).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                  )}
                  <div className="f-500 f-grey">
                    {renderPriceText(
                      99,
                      Number(data?.Sell).toFixed(2),
                      packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                      "£"
                    )}
                  </div>
                  {switchVal && (
                    <div className="f-500 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Margin).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>

      {_data?.UserDetail?.Role?.toLowerCase() === "msp" && (
        <div
          style={{
            marginTop: "-20px",
          }}
        >
          <SwitchButtonMulti
            id={`switch_toggle_button_${data?.ScenarioHeader?.objId_Scenario}`}
            label={"Show extended details"}
            value={switchVal}
            onChange={(e) => {
              toggleSwitch(data?.ScenarioHeader?.objId_Scenario);
            }}
          />
        </div>
      )}
    </div>
  );
};

const CreateCustomPackageModal = ({
  show,
  hideModal,
  packageList,
  updatePageData,
  duplicatePackage,
}) => {
  const clientId = window.location.pathname.split("/")?.[4] ?? -1;
  const history = useHistory();
  const { authTokens } = useAuth();
  const [scenarioList, setScenarioList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setScenarioList(packageList);
  }, [packageList]);
  useEffect(() => {
    if (show) {
      setLoading(true);

      getPageData().then((response) => {
        setScenarioList(response?.ScenarioHheaderList);
      });
    }
  }, [show]);

  async function getPageData() {
    return new Promise((resolve, reject) => {
      getAPIData(667, authTokens, {
        optionEnum1: clientId,
      })
        .then((response) => {
          setLoading(false);
          resolve(response);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  }

  return (
    <Modal
      show={show}
      dialogClassName="create-custom-package-modal"
      className=""
      aria-labelledby="create-custom-package-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="hide-scrollbar p-3">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{}}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="f-16 f-500 my-2 mb-3 pl-3">
              Create custom package
            </div>

            <div
              className="f-grey p-3 radius-8 mb-3 pointer"
              style={{ border: "1px dashed #e2e2e5" }}
              onClick={() => {
                history.push("/mspv2/msp-price-calculator/0", {
                  backUrl: `/mspv2/clients/clientsView/${clientId}/managed-security`,
                  allPackagesList: packageList,
                  clientId: clientId,
                });
              }}
            >
              <img
                alt=""
                src="/images/actioncenter/plusgrey.svg"
                className="mr-3"
              />
              Create from scratch
            </div>
            <div className="f-12 f-500 f-darkgrey mb-">Make copy of</div>
            <div
              className="transparent-scroller"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {scenarioList?.map((_package, _packageIndex) => {
                return (
                  <div
                    className="d-flex align-items-center justify-content-between radius-4 p-2 pointer bg-hover-grey"
                    onClick={() => {
                      let updated_data = [...scenarioList];
                      updated_data[_packageIndex].loading = true;
                      setScenarioList(updated_data);
                      duplicatePackage(_package.objId_Scenario)
                        .then(() => {
                          updatePageData()
                            .then(() => {
                              hideModal();
                              CallToast(
                                "Updated successfully",
                                false,
                                false,
                                "v2style"
                              );
                            })
                            .catch(() => {
                              CallToast(
                                "Something went wrong",
                                false,
                                false,
                                "v2style"
                              );
                            });
                        })
                        .catch(() => {
                          CallToast(
                            "Something went wrong",
                            false,
                            false,
                            "v2style"
                          );
                        });
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/msp-calculator-file-icon.svg"
                      />
                      <div>
                        <div className="f-500 f-black">{_package.Name} </div>
                        <div className="f-12 f-darkgrey">
                          {_package?.Description}
                        </div>
                      </div>
                    </div>
                    <div>
                      {_package.loading ? <GreyLoader width={15} /> : <></>}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const PackageData = ({ _packageData, switchVal }) => {
  const [packageData, setPackageData] = useState(_packageData);
  useEffect(() => {
    setPackageData(_packageData);
  }, [_packageData]);

  return (
    <div
      className="p-5"
      style={{
        minWidth: "600px",
        maxWidth: "600px",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="f-14 f-600 f-black mr-4">
          {packageData?.ScenarioHeader?.Name}
        </div>
        <div>
          {packageData?.ScenarioHeader?.PackageType_Text && (
            <MspSingleTagUI
              itemName={packageData?.ScenarioHeader?.PackageType_Text}
            />
          )}
        </div>
      </div>

      <div className="my-2">
        {packageData?.pillarList?.map((_package, _packageIndex) => {
          return (
            <>
              {_package?.dataList?.length > 0 && (
                <div>
                  <div className="f-500 f-black my-3">
                    {_package?.pillarName}
                  </div>

                  <div className="w-100 bg-grey border-parent radius-8">
                    {/* First item */}
                    {_package?.dataList
                      ?.slice(0, _package?.showCount)
                      .map((serviceItem, serviceItemIndex) => {
                        return (
                          <>
                            <div
                              className="border-item d-flex align-items-center justify-content-between row-padding pointer bg-hover-dark-grey hover-on-show-parent"
                              onClick={() => { }}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt=""
                                  className="mr-2"
                                  src={
                                    serviceItem?.IsAddOn
                                      ? "/images/msp/msp-add-on-icon.svg"
                                      : "/images/msp/msp-package-icon.svg"
                                  }
                                />
                                <div className="d-flex flex-column">
                                  <div className="f-14 f-500 f-black">
                                    {serviceItem?.Headline}
                                    {/* {serviceItem?.Sku &&
                                      ` (${TrimString(serviceItem?.Sku, 25)})`} */}
                                  </div>
                                  <div className="f-12 f-darkgrey">
                                    {serviceItem?.IsAddOn
                                      ? "Add-on"
                                      : "Service"}{" "}
                                      {
                                        switchVal && <>
                                    • Cost •{" "}
                                    {
                                      serviceItem?.AnnualPrice_Text
                                    }{" "}
                                    {
                                      serviceItem
                                        ?.SnapToUnit_Selected
                                        ?.DDLColour
                                    }{" "}
                                    /
                                    {
                                      serviceItem
                                        ?.BillingPeriod_Selected
                                        ?.DDLColour
                                    }{" "}
                                        </> 
                                      }
                                    • Sell •{" "}
                                    {
                                      serviceItem?.MonthlyPrice_Text
                                    }{" "}
                                    {
                                      serviceItem
                                        ?.SnapToUnit_Selected
                                        ?.DDLColour
                                    }{" "}
                                    /
                                    {
                                      serviceItem
                                        ?.BillingPeriod_Selected
                                        ?.DDLColour
                                    }{" "}
                                  </div>
                                </div>
                              </div>
                              <div>
                                {/* {serviceItem?.loading ? (
                                      <div className="msp-service-update-loader mr-1"></div>
                                    ) : (
                                      <img
                                        alt=""
                                        className="mr-1 hover-on-show-child pointer"
                                        width={12}
                                        height={12}
                                        src="/images/attack-surface/cross-icon.svg"

                                      />
                                    )} */}
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>

                  {_package?.dataList?.length > 3 && (
                    <Button
                      className="hg-cancel-btn mt-3"
                      onClick={() => {
                        let updated_data = { ...packageData };
                        if (
                          updated_data?.pillarList[_packageIndex]?.showCount ===
                          3
                        ) {
                          updated_data.pillarList[
                            _packageIndex
                          ].showCount = 100000000;
                        } else {
                          updated_data.pillarList[_packageIndex].showCount = 3;
                        }
                        setPackageData({ ...packageData });
                      }}
                    >
                      {packageData.pillarList[_packageIndex].showCount === 3
                        ? "Show more"
                        : "Show less"}
                    </Button>
                  )}
                </div>
              )}
              {_package?.dataList?.length == 0 && (
                <div>
                  {" "}
                  <div className="f-500 f-black my-3">
                    {_package?.pillarName}
                  </div>
                  <div className="f-grey f-12">No services added</div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export const MspClientPriceCalculatorLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="1%" y="8%" rx="5" ry="5" width="45%" height="80" />
      <rect x="1%" y="20%" rx="5" ry="5" width="45%" height="40" />
      <rect x="1%" y="28%" rx="5" ry="5" width="20%" height="20" />

      <rect x="1%" y="32%" rx="5" ry="5" width="45%" height="70" />
      <rect x="1%" y="362" rx="5" ry="5" width="45%" height="100" />
      <rect x="1%" y="465" rx="5" ry="5" width="45%" height="100" />
      <rect x="1%" y="567" rx="5" ry="5" width="45%" height="100" />
      <rect x="1%" y="669" rx="5" ry="5" width="45%" height="100" />

      <rect x="55%" y="8%" rx="10" ry="10" width="40%" height="450" />

      <rect x="55%" y="60%" rx="10" ry="10" width="40%" height="50" />
      <rect x="55%" y="67%" rx="10" ry="10" width="40%" height="50" />
      <rect x="55%" y="74%" rx="10" ry="10" width="40%" height="50" />
      <rect x="55%" y="81%" rx="10" ry="10" width="40%" height="50" />
    </ContentLoader>
  );
};

const MspClientPriceCalculatorEmptyRadarChart = ({
  activeChart = 1,
  _datasets,
}) => {
  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      backgroundColor: `rgba(235, 237, 241, 0)`,
      borderColor: "rgba(235, 237, 241, 1)",
      pointBackgroundColor: "rgba(187, 190, 201, 1)",
      scaleShowLabels: false,
      borderWidth: 2,
    }));
    updatedDatasets.push({
      data: [15, 15, 15, 15, 15], // Replace this with your actual average values
      borderColor: "rgba(187, 190, 201, 1)",
      borderWidth: 2,
      pointBackgroundColor: "rgba(187, 190, 201, 1)",
      backgroundColor: `rgba(235, 237, 241, 0.35)`,
      pointRadius: [3, 3, 3, 3, 3],
      borderDash: [9, 9], // Dashed line for the average line
    });

    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],
      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart]);

  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scale: {
        // display: false,
        pointLabels: {
          display: false,
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: 5,
          max: 20,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);
  console.log(updatedData, updatedOptions);

  return (
    <div
      className="zoom-independent position-relative"
      style={{
        width: "375px",
        height: "375px",
        marginTop: "60px",
        marginBottom: "40px",
        // backgroundColor: "#f0f0f0", // Light gray background
      }}
    >
      <Radar
        id="actial-radar"
        redraw={true}
        height={375}
        width={375}
        style={{ margin: "auto", border: "1px dashed #808080" }} // Gray dashed border
        data={updatedData}
        options={updatedOptions}
      />
      {/* ... existing code */}
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-24px",
          left: "187px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-50px",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(58px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(356px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0{" "}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "calc(-13px - 3rem)",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>
    </div>
  );
};

export function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function renderPriceText(_billingPeriod, price, currency = "£") {
  // Monthly,    //............0
  // Quarterly,  //............1
  // Annual      //............2

  let old_price = numberWithCommas(price); // Math.abs(Number(price));
  var updated_price = 0;

  switch (_billingPeriod) {
    case 0:
      updated_price = `${currency}${old_price?.toString()} /month`;
      break;
    case 1:
      updated_price = `${currency}${old_price?.toString()} /quarter`;
      break;
    case 2:
      updated_price = `${currency}${old_price?.toString()} /year`;
      break;
    default:
      updated_price = `${currency}${numberWithCommas(price)}`;

      break;
  }
  if (price < 0) {
    updated_price = `-${updated_price}`;
  }
  return updated_price.replace(/(\d+\.\d)(?=\s|$)/g, "$10");
}

export function hexToRGBA(hex, opacity) {
  hex = hex.replace('#', '');
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}