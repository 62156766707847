
import React, { useEffect, useState } from 'react'
import {  Button, Col, Row, Spinner } from 'react-bootstrap'
import { validateEmail } from '../../Utils/CommonFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ForgotPassword = () => {
    const history = useHistory();
  const [pageData, setPageData] = useState({});
  const [email, setEmail] = useState('')
  const [formState, setFormState] = useState(0)
   
  const [timeState, setTimeState] = useState({
    seconds:0,
    startTimer:false
  })
  const [sendingEmail, setSendingEmail] = useState(false)
  const [errors, setErrors] = useState({
    emailError:""
  })

  const PAGE_STATE={
    FORGOT_PASSWORD:"FORGOT_PASSWORD",
    MAIL_SENT:"MAIL_SENT",
    RESET_PASSWORD:"RESET_PASSWORD"
  }

  useEffect(() => {
    if (timeState.startTimer) {
      countdownTimer();
      setTimeState({ ...timeState, startTimer: false });
    }
  }, [timeState]);
  

useEffect(() => {
  if (formState === 0) {
    setPagState(PAGE_STATE.FORGOT_PASSWORD);
  } else if (formState === 1) {
    setPagState(PAGE_STATE.MAIL_SENT, email);
  }
}, [formState]);


  function setPagState(pageType, email) {
    switch (pageType) {
      case PAGE_STATE.FORGOT_PASSWORD:
        setPageData({
          headerText: "Forgot your password?",
          subText:
            "Please enter an email from your account. We will send you password recovery instructions",
            state:"FORGOT_PASSWORD"
        });

        break;
      case PAGE_STATE.MAIL_SENT:
        setPageData({
          headerText: "Check your email",
          subText: `A reset password link has been sent to your email address ${email}. Kindly follow instructions to regain account access`,
          state:"MAIL_SENT"
        });

        break;
     

      default:
        break;
    }
  }

  async function sendPasswordResetEmail() {
    setSendingEmail(true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("emailAddress", email);
    urlencoded.append("baseCallingUrl", "http://localhost:3000");

    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/ResetPassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.Success) {
          setTimeState({ ...timeState, seconds: 10, startTimer: true });
          setFormState(1);
        } else {
          setErrors({ ...errors, emailError: response.ExMessage });
        }
        setSendingEmail(false);
      })
      .catch((err) => {
        setSendingEmail(false);
      });
  }


async function checkEmailvalidation(){
  if(email.length===0){
    setErrors({...errors,emailError:"Email is required"})
    return false
  }else if(!validateEmail(email)){
    setErrors({...errors,emailError:"Enter valid email address"})
    return false
  }else{
    return true
  }
}

 
function countdownTimer() {
  const timer = setInterval(() => {
    if (timeState.seconds > 0) {
      setTimeState((prev) => {
        if (prev.seconds >= 1) {
          return { ...prev, seconds: prev.seconds - 1 };
        } else {
          clearInterval(timer);
          return prev;
        }
      });
    }
  }, 1000);
}

 
  return (
    <div className="h-100 d-flex flex-column p-3">
      <div className=" w-25 m-auto">
        <Row className="align-items-center" style={{ paddingTop: "40px" }}>
          <Col md={12} className="text-center">
            <div>
              <img alt="" src="/images/logo-large.svg" className="icon" />
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "1.5em" }}>
          <Col md={12} className="text-center pt-3 pb-4">
            <div>
              <div className=" f-24 f-700 f-black text-center highground-royal-font mb-2">{pageData.headerText}</div>
              <p className="lgnGreyText pt-1">{pageData.subText}</p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center" style={{ paddingTop: "0.1em" }}>
          <Col className="mx-auto">
            <div className="f-500 f-black">Email</div>
            <div
              className={`highlight-input-with-grey-border  w-100  d-flex align-items-center p-2 radius-4 mt-1   ${
                errors?.emailError ? "input-error" : ""
              } `}
            >
              <input
                tabIndex={0}
                disabled={formState === 1 || timeState.seconds > 0}
                type="text"
                placeholder="Enter email"
                className="flex-grow-1 pl-1 pr-2 py-1"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors({ ...errors, emailError: "" });
                }}
              />
              {timeState.seconds > 0 && (
                <span className="f-grey">{timeState.seconds}s</span>
              )}
            </div>

            <div className="f-danger">{errors.emailError}</div>

            <div className="mt-4">
              <Button
                className={`form-control btnControl   ${
                  formState === 1 && timeState.seconds > 0 ? "disabled-btn" : ""
                } `}
                size="lg"
                variant="blue"
                type="submit"
                onClick={async () => {
                  let isValidEmail = await checkEmailvalidation();

                  if (isValidEmail) {
                    sendPasswordResetEmail();
                  }
                }}
              >
                {formState === 0 && (
                  <>
                    {sendingEmail ? (
                      <span>
                        <Spinner animation="border" variant="light" size="sm" />{" "}
                        Please wait...
                      </span>
                    ) : (
                      "Reset password"
                    )}
                  </>
                )}
                {formState === 1 && (
                  <>
                    {sendingEmail ? (
                      <span>
                        <Spinner animation="border" variant="light" size="sm" />{" "}
                        Please wait...
                      </span>
                    ) : (
                      "Resend email"
                    )}
                  </>
                )}
              </Button>
            </div>
            {/* </Form.Group> */}

            <div
              className="text-center pt-4 pointer"
              onClick={() => {
                if (formState === 1) {
                  setFormState(0);
                } else {
                  history.push("/login");
                }
              }}
            >
              Back
            </div>
          </Col>
        </Row>
      </div>

      <div className="f-12 f-500 f-darkgrey mt-auto mx-auto w-fit-content">
        © 2024 HighGround.
        &nbsp;
        &nbsp;
        <u
          className="pointer"
          onClick={() => {
            window.open(
              "https://www.highground.io/privacy_policy/",
              "_blank"
            );
          }}
        >
          Privacy Policy
        </u>
        &nbsp;
        &nbsp;
        <u
          className="pointer"
          onClick={() => {
            window.open(
              "https://www.highground.io/highground-terms-and-conditions/",
              "_blank"
            );
          }}
        >
          Terms & Conditions
        </u>
      </div>
    </div>
  );
}

export default ForgotPassword