import React, { useState, useEffect } from "react";
import { Form, Button, Row } from "react-bootstrap";

import "../../common.scss";

import SlidingModal from "../../../CustomModal/SlidingModal";
import { useAuth } from "../../../context/Auth";
import {
  AttackSurfaceGetApi,
  AttackSurfacePostApi,
} from "../../../../Utils/commonApi";
import { WhiteLoader } from "../../../Theme/APILoaders";
import { TrimString } from "../../../../Utils/StringTrimmer";
import MultiSelectDropdown from "../../AttackComponents/MultiSelectDropdown";
import AttackLocationDropdown from "../../AttackComponents/AttackLocationDropdown";
import HighgroundDatePicker from "../../../test/TestComponent";
import AttackSurfaceTooltip from "../AttackSurfaceTooltip";
import Loader from "../../../Common/loader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { cleanText } from "../../Index";
import { isValidEmail } from "../../../../Utils/utilityJsxElements";

const WorkstationModal = ({
  show,
  hideModal,
  header,
  assetModifierId,
  refreshData,
  dataToRender,
  BizObjectId,
  refreshMenuItems,
}) => {
  const { authTokens } = useAuth();

  const [randomId, setRandomId] = useState("");

  const [headerFieldList, setHeaderFieldList] = useState([]);
  const [fieldsData, setFieldsData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [autoGeneratingId, setAutoGeneratingId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // Type Enum mapping for Input types in Modal

  const TypeMapping = {
    "A_Text (Single Line)": 0, // Text input box
    "A_Drop Down List (Single)": 1, // Drop down single
    A_Date: 2, //Date Picker
    "A_Yes or No": 3, //Check Box
    "A_Drop Down List (Multi)": 4,
    "A_Text (Multi Line)": 5,
  };

  function getModalData(asset_id = "") {
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetObjectParameters?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: assetModifierId,
      }
    )
      .then((response) => {
        setLoading(false);
        if (!response?.FieldList?.length) {
          CallToast("Something went wrong", false, false, "v2style");
          return;
        }

        let modal_fields = [
          ...response?.FieldList?.sort(
            (a, b) =>
              Number(a.ASE_AttrDef_ColumnSort) -
              Number(b.ASE_AttrDef_ColumnSort)
          ),
        ];
        for (let i = 0; i < modal_fields?.length; i++) {
          // Auto fill Asset Id
          if (modal_fields[i]?.ASE_FieldName === "asset_id") {
            modal_fields[i].ASE_FieldValue = asset_id;
          }

          // Add error field to list
          modal_fields[i].error = false;

          // Value change of Switch to false if mandatory instead of Null(should be false or true)
          if (
            modal_fields[i]?.ASE_AttrDef_InputType === "A_Yes or No" &&
            modal_fields[i]?.ASE_FieldMandatory === 1
          ) {
            modal_fields[i].ASE_FieldValue = false;
          }

          // AND
          //  To set is_file_auditing_enabled_for_all_file_sharing_systems =TRUE

          if (
            modal_fields[i]?.ASE_FieldName ===
            "is_file_auditing_enabled_for_all_file_sharing_systems"
          ) {
            modal_fields[i].ASE_FieldValue = false;
          }
        }

        // AND

        for (let i = 0; i < modal_fields?.length; i++) {
          // To fill vaue in Text box if the user has already has any tool in CDSA
          if (
            modal_fields[i]?.ASE_AttrDef_InputType === "A_Text (Single Line)" &&
            modal_fields[i]?.AttributeDDLList &&
            Object.keys(modal_fields[i]?.AttributeDDLList).length > 0
          ) {
            modal_fields[i].ASE_FieldValue =
              modal_fields[i]?.AttributeDDLList[
                Object.keys(modal_fields[i]?.AttributeDDLList)[0]
              ][0]?.AttributeText;
          }
          // To Select Checkbox if the user has already connected to any tool CDSA
          if (
            modal_fields[i]?.ASE_AttrDef_InputType === "A_Yes or No" &&
            modal_fields[i]?.AttributeDDLList &&
            Object.keys(modal_fields[i]?.AttributeDDLList).length > 0
          ) {
            modal_fields[i].ASE_FieldValue = modal_fields[i]?.AttributeDDLList[
              Object.keys(modal_fields[i]?.AttributeDDLList)[0]
            ][0]?.AttributeText
              ? true
              : false;
          }
        }

        structureModal(modal_fields);
      })
      .catch((err) => {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to structure modal

  function structureModal(_modal_fields) {
    let modalFields = [..._modal_fields].filter(
      (field) => field.ASE_AttrDef_GroupName !== "Header"
    );
    let headerFields = [..._modal_fields].filter(
      (field) => field.ASE_AttrDef_GroupName === "Header"
    );

    setHeaderFieldList(headerFields);

    let fields_data = makeGroup(modalFields, "ASE_AttrDef_GroupName");
    setFieldsData(fields_data);
    setLoading(false);
  }

  useEffect(() => {
    if (show) {
      setRandomId(String(Date.now()));
      setLoading(true);
      if (header.split(" ")[0] === "Edit") {
        if (dataToRender) {
          structureModal(dataToRender);
        }
      } else {
        // getModalData();
        autoFillAssetIdAndgetModalData(assetModifierId);
      }
    }
  }, [assetModifierId, header, dataToRender, show]);

  // List of all Functions==================>>>>>>>>>>>>
  // Function to Make groups of data depending on ASE_AttrDef_GroupName

  // Function to filter out all key and value pairs to Upload

  async function uploadData(data) {
    setSaving(true);
    let updated_data = [...data];

    await AttackSurfacePostApi(
      authTokens,
      `AttackSurfaceAPI/UploadASEData?AssetIdentifier=${assetModifierId}&BizObjectId=${BizObjectId}${
        fieldsData?.[0]?.["data"]?.[0]?.ASE_FieldSource > 0
          ? `&IntegrationId=${fieldsData?.[0]?.["data"]?.[0]?.ASE_FieldSource}`
          : ""
      }`,
      updated_data
    )
      .then((response) => {
        if (response?.Success === true) {
          CallToast("Updated successfully", false, false, "v2style");

          hideModal();

          if (refreshData) {
            refreshData();
          }
          setSaving(false);
        } else {
          setSaving(false);
          CallToast(response?.Message, false, false, "v2style");
        }
      })
      .catch(() => {
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to get checkbox value
  function getCheckBoxValue(value) {
    switch (value) {
      case "True":
        return true;
      // break;
      case "False":
        return false;
      // break;
      case false:
        return false;
      // break;
      case true:
        return true;
      // break;
      default:
        return false;
    }
  }

  const isAnyValueVerified = fieldsData.every((group) => {
    return group.data.every((item) => {
      return item?.ASE_FieldVerified === true;
    });
  });
  function getAllIntegrationNames() {
    let IntegrationNamesArr = [];
    for (let i = 0; i < fieldsData.length; i++) {
      for (let j = 0; j < fieldsData[i].data.length; j++) {
        if (
          fieldsData[i].data[j].ASE_FieldVerified === true &&
          fieldsData[i].data[j].ASE_FieldSource !== "" &&
          IntegrationNamesArr.indexOf(fieldsData[i].data[j].ASE_FieldSource) ===
            -1
        ) {
          IntegrationNamesArr.push(fieldsData[i].data[j].ASE_FieldSource);
        }
      }
    }

    for (let i = 0; i < headerFieldList.length; i++) {
      if (
        headerFieldList[i].ASE_FieldVerified === true &&
        headerFieldList[i].ASE_FieldSource !== "" &&
        IntegrationNamesArr.indexOf(headerFieldList[i].ASE_FieldSource) === -1
      ) {
        IntegrationNamesArr.push(headerFieldList[i].ASE_FieldSource);
      }
    }
    return IntegrationNamesArr?.length > 0 ? IntegrationNamesArr.join(",") : "";
  }

  function deleteAsset() {
    setDeleting(true);
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/DeleteObject?`, //?OrgId=237003
      authTokens,
      {
        BizObjectId: BizObjectId,
        varified: false,
      }
    )
      .then((response) => {
        setDeleting(false);
        hideModal();
        CallToast("Deleted successfully", false, false, "v2style");
        if (refreshData) {
          refreshData();
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function removeSfromHeader(text) {
    if (text === "" || text === null || text === undefined) return "";
    if (text?.split(" ")[1] === "Switches") {
      return text.slice(0, -2);
    }
    if (text?.charAt(text?.length - 1) === "s") {
      return text.slice(0, -1);
    } else {
      return text;
    }
  }

  async function checkValidations(
    headerFieldList = [],
    setHeaderFieldList,
    fieldsData = [],
    setFieldsData
  ) {
    let errorCount = 0;

    let updated_headerFieldList = [...headerFieldList];
    for (let i = 0; i < updated_headerFieldList.length; i++) {
      // To check if the Mandatory field in an input and is empty
      if (
        (updated_headerFieldList[i].ASE_FieldMandatory === 1 &&
          updated_headerFieldList[i].ASE_FieldValue === "") ||
        (updated_headerFieldList[i].ASE_FieldName?.includes("email")&&
        
        updated_headerFieldList[i].ASE_AttrDef_InputType ===
        "A_Text (Single Line)" 
        
        &&
          updated_headerFieldList[i].ASE_FieldValue !== "" &&
          !isValidEmail(updated_headerFieldList[i].ASE_FieldValue))
      ) {
        updated_headerFieldList[i].error = true;
        errorCount += 1;
      }
      // To Check  if the mandatory field is an Multi dropdown and is empty
      if (
        updated_headerFieldList[i].ASE_FieldMandatory === 1 &&
        updated_headerFieldList[i].ASE_AttrDef_InputType ===
          "A_Drop Down List (Multi)" &&
        updated_headerFieldList[i].ASE_FieldValue?.length === 0
      ) {
        updated_headerFieldList[i].error = true;
        errorCount += 1;
      }
      // To Check  if the mandatory field is an Single dropdown and is empty
      if (
        updated_headerFieldList[i].ASE_FieldMandatory === 1 &&
        updated_headerFieldList[i].ASE_AttrDef_InputType ===
          "A_Drop Down List (Single)" &&
        Object.keys(updated_headerFieldList[i].ASE_FieldValue).length === 0
      ) {
        updated_headerFieldList[i].error = true;
        errorCount += 1;
      }
    }
    setHeaderFieldList(updated_headerFieldList);

    let updated_fieldsData = [...fieldsData];

    for (let i = 0; i < updated_fieldsData.length; i++) {
      for (let j = 0; j < updated_fieldsData[i].data.length; j++) {
        // To check if the Mandatory field in an input and is empty
        if (
          (updated_fieldsData[i].data[j].ASE_FieldMandatory === 1 &&
            updated_fieldsData[i].data[j].ASE_FieldValue === "") ||
          (updated_fieldsData[i].data[j].ASE_FieldName?.includes("email")&&
          
          
          updated_fieldsData[i].data[j].ASE_AttrDef_InputType ===
        "A_Text (Single Line)" 
          
          &&
            updated_fieldsData[i].data[j].ASE_FieldValue !== "" &&
            !isValidEmail(updated_fieldsData[i].data[j].ASE_FieldValue))
        ) {
          updated_fieldsData[i].data[j].error = true;
          errorCount += 1;
        }
        // To Check  if the mandatory field is an Multi dropdown and is empty
        if (
          updated_fieldsData[i].data[j].ASE_FieldMandatory === 1 &&
          updated_fieldsData[i].data[j].ASE_AttrDef_InputType ===
            "A_Drop Down List (Multi)" &&
          updated_fieldsData[i].data[j].ASE_FieldValue?.length === 0
        ) {
          updated_fieldsData[i].data[j].error = true;
          errorCount += 1;
        }
        // To Check  if the mandatory field is an Single dropdown and is empty
        if (
          updated_fieldsData[i].data[j].ASE_FieldMandatory === 1 &&
          updated_fieldsData[i].data[j].ASE_AttrDef_InputType ===
            "A_Drop Down List (Single)" &&
          Object.keys(updated_fieldsData[i].data[j].ASE_FieldValue).length === 0
        ) {
          updated_fieldsData[i].data[j].error = true;
          errorCount += 1;
        }
      }
    }
    setFieldsData(updated_fieldsData);

    return errorCount;
  }

  async function getBackupProducts(product_name) {
    await AttackSurfaceGetApi(
      `AttackSurfaceAPI/GetBackupProducts?`, //?OrgId=237003
      authTokens,
      {
        ProductName: product_name,
      }
    ).then((response) => {
      if (!response?.Success) return;

      let fields_data = [...fieldsData];
      for (let i = 0; i < fields_data?.length; i++) {
        for (let j = 0; j < fields_data[i].data.length; j++) {
          if (fields_data[i].data[j].ASE_FieldName === "protected_assets") {
            if (response?.BackupProducts?.length > 0) {
              // Rename fields names in the BackUp Products
              let backup_products = [...response?.BackupProducts].map(
                (product) => {
                  return {
                    AttributeId: product?.BizObjectId,
                    AttributeText: product?.ProductName,
                  };
                }
              );
              fields_data[i].data[j].ASE_FieldValue = [...backup_products];
              setFieldsData(fields_data);
            } else {
              fields_data[i].data[j].ASE_FieldValue = [];
              setFieldsData(fields_data);
            }
          }
        }
      }
    });
  }
  // Function to Autofill Asset Id
  function autoFillAssetIdAndgetModalData(assetModifierId) {
    AttackSurfaceGetApi(
      `AttackSurfaceAPI/AutoGenID?`, //?OrgId=237003
      authTokens,
      {
        ObjDefId: assetModifierId,
      }
    )
      .then((response) => {
        getModalData(response?.AutoGenResp);
      })
      .catch(() => {
        getModalData();
      });
  }

  // Toggle Secondary field of FieldsData
  function toggleSecondaryField(groupIndex, field_name, visibility) {
    let updated_fieldsData = [...fieldsData];
    for (let i = 0; i < updated_fieldsData[groupIndex]["data"].length; i++) {
      if (
        updated_fieldsData[groupIndex]["data"][i].ASE_AttrDef_PrimaryField ===
          field_name &&
        updated_fieldsData[groupIndex]["data"][i].ASE_AttrDef_IsSecondary ===
          true
      ) {
        updated_fieldsData[groupIndex]["data"][i].ASE_AttrDef_Visibility =
          visibility;
      }
    }
    setFieldsData(updated_fieldsData);
  }

  function ThrowExceptionIfOnSiteWorkIsSelected() {
    let updated_fieldsData = [...fieldsData];
    for (let i = 0; i < updated_fieldsData.length; i++) {
      for (let j = 0; j < updated_fieldsData[i]["data"].length; j++) {
        if (
          updated_fieldsData[i]["data"][j].ASE_FieldName === "work_type" &&
          updated_fieldsData[i]["data"][j].ASE_FieldValue?.filter(
            (item) => item?.AttributeText?.toLowerCase() === "onsite"
          )?.length > 0
        ) {
          throw new Error("Onsite value is selected!");
        }
      }
    }
  }
  function ThrowExceptionIfPermanentHomeIsSelected(row) {
    let updated_fieldsData = [...fieldsData];
    for (let i = 0; i < updated_fieldsData.length; i++) {
      for (let j = 0; j < updated_fieldsData[i]["data"].length; j++) {
        if (
          updated_fieldsData[i]["data"][j].ASE_FieldName === "permanent_work_from_home_position" &&
          updated_fieldsData[i]["data"][j].ASE_FieldValue===true&&
          row?.ASE_FieldName === "work_type"
        ) {
          return true
          
        }
      }
    }
  }

  return (
    <SlidingModal
      show={show}
      className="common-big-modal  "
      parentID="assets-list-full-screen-modal-parent"
      id="acsdseag"
      width="32vw"
      onHide={() => {
        hideModal();
      }}
    >
      <SlidingModal.Header className="text-center d-flex p-3 border-bottom">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {cleanText(removeSfromHeader(header))}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>

      <SlidingModal.Body className="p-4 ">
        {/* Asset name input */}
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "89vh" }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {/* Header List */}
            {headerFieldList
              ?.sort(
                (a, b) =>
                  Number(a.ASE_AttrDef_ColumnSort) -
                  Number(b.ASE_AttrDef_ColumnSort)
              )
              ?.map((row, Index) => {
                return (
                  <div
                    className={` align-items-center justify-content-between mb-1
                    
                    ${
                      row?.ASE_AttrDef_Visibility === false
                        ? "d-none"
                        : "d-flex"
                    }
                    `}
                    id={`${
                      row?.error ? `error-element${assetModifierId}` : ""
                    }`}
                  >
                    <div
                      className={`f-black w-50 ${
                        TypeMapping[row["ASE_AttrDef_InputType"]] === 0 ||
                        TypeMapping[row["ASE_AttrDef_InputType"]] === 1
                          ? "d-none"
                          : ""
                      }`}
                      tabIndex={-1}
                    >
                      {cleanText(row?.ASE_AttributeName)}
                    </div>
                    {/* For Input Field */}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 0 && (
                      <>
                        <div
                          className={`asset-name-wrapper w-100  d-flex align-items-center p-2 radius-4 mt-2   ${
                            row?.error ? "input-error" : ""
                          } `}
                          id={`${
                            row?.error ? `error-element${assetModifierId}` : ""
                          }`}
                        >
                          <div className="f-darkgrey  ">
                            {cleanText(row?.ASE_AttributeName)}:
                          </div>
                          <input
                            tabIndex={0}
                            disabled={row?.ASE_FieldVerified}
                            type="text"
                            placeholder={`Enter ${cleanText(
                              row?.ASE_AttributeName
                            )}`}
                            className="flex-grow-1 pl-3 pr-2"
                            value={row?.ASE_FieldValue || ""}
                            onChange={(e) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] =
                                e.target.value;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);
                            }}
                          />
                          {!row?.ASE_FieldVerified &&
                            row?.ASE_FieldName === "asset_id" && (
                              <div
                                className="d-flex align-items-center f-blue pointer"
                                onClick={() => {
                                  if (row?.ASE_FieldVerified === true) return;
                                  setAutoGeneratingId(true);
                                  AttackSurfaceGetApi(
                                    `AttackSurfaceAPI/AutoGenID?`, //?OrgId=237003
                                    authTokens,
                                    {
                                      ObjDefId: assetModifierId,
                                    }
                                  )
                                    .then((response) => {
                                      setAutoGeneratingId(false);
                                      if (response?.AutoGenResp) {
                                        CallToast(
                                          "Id generated successfully",
                                          false,
                                          false,
                                          "v2style"
                                        );

                                        let fields_data = [...headerFieldList];
                                        fields_data[Index]["ASE_FieldValue"] =
                                          response.AutoGenResp;
                                        fields_data[Index]["error"] = false;
                                        setHeaderFieldList(fields_data);
                                      } else {
                                        CallToast(
                                          "Something went wrong",
                                          false,
                                          false,
                                          "v2style"
                                        );
                                      }
                                    })
                                    .catch(() => {
                                      CallToast(
                                        "Something went wrong",
                                        false,
                                        false,
                                        "v2style"
                                      );
                                    });
                                }}
                              >
                                {autoGeneratingId
                                  ? "Generating Id.."
                                  : "Auto-generate ID"}
                              </div>
                            )}
                        </div>
                      </>
                    )}
                    {/* For Drop down Field */}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 1 && (
                      <>
                        <div
                          className={`asset-name-wrapper w-100  d-flex align-items-center px-2 radius-4 mt-2 hide-dropdown-border  ${
                            row?.error ? "input-error" : ""
                          } `}
                          id={`${
                            row?.error ? `error-element${assetModifierId}` : ""
                          }`}
                        >
                          <div className="f-darkgrey  ">
                            {cleanText(row?.ASE_AttributeName)}:
                          </div>
                          <div
                            className="w-50 attack-bigmodal-dropdown"
                            tabIndex={-1}
                          >
                            <AttackLocationDropdown
                              refId={row?.["ASE_RelObjDefID"]}
                              // icon={
                              //   row?.ASE_AttrDef_Icon
                              //     ? `/images/attack-surface/${row?.ASE_AttrDef_Icon}.svg`
                              //     : "/images/attack-surface/attack_surface_dashboard_icon.svg"
                              // }
                              placeholder={row?.ASE_AttributeName?.replaceAll(
                                "_",
                                " "
                              )}
                              data={row?.AttributeDDLList}
                              selected={row?.ASE_FieldValue}
                              onChange={(val) => {
                                let fields_data = [...headerFieldList];
                                fields_data[Index]["ASE_FieldValue"] = val;
                                fields_data[Index]["error"] = false;
                                setHeaderFieldList(fields_data);
                                if (
                                  assetModifierId === 2246 &&
                                  val?.AttributeText &&
                                  row?.ASE_FieldName === "backup_product"
                                ) {
                                  // It will fill up prtected assets values
                                  getBackupProducts(val?.AttributeText);
                                }
                              }}
                              objectID={row?.ASE_RelObjDefID}
                              // isError={row?.error}
                              refreshMenuItems={refreshMenuItems}
                              attrKey={row?.ASE_AttributeName?.replaceAll(
                                "_",
                                " "
                              )}
                              assetId={assetModifierId}
                              groups={row?.["ASE_AttributeRelatedObj"]}
                              
                            />
                          </div>
                        </div>
                      </>

                      // <>
                      //   {row?.ASE_FieldVerified && row?.ASE_FieldValue ? (
                      //     <div className="w-50 my-1">
                      //       <div className="f-black d-flex align-items-center">
                      //         <AttackSurfaceTooltip
                      //           content={
                      //             <span>
                      //               Auto-generated cannot be edited
                      //               <RenderImagesGroup
                      //                 sourceName={row?.ASE_FieldSourceName}
                      //               />
                      //             </span>
                      //           }
                      //         >
                      //           <img
                      //             alt=""
                      //             src="/images/attack-surface/small-lock.svg"
                      //             className="mr-2"
                      //           />
                      //         </AttackSurfaceTooltip>
                      //         {TrimString(row?.ASE_FieldValue, 20)}
                      //       </div>
                      //     </div>
                      //   ) : (
                      //     <div
                      //       className="w-50 attack-bigmodal-dropdown"
                      //       tabIndex={-1}
                      //     >
                      //       <AttackLocationDropdown
                      //         refId={row?.["ASE_RelObjDefID"]}
                      //         icon={
                      //           row?.ASE_AttrDef_Icon
                      //             ? `/images/attack-surface/${row?.ASE_AttrDef_Icon}.svg`
                      //             : "/images/attack-surface/attack_surface_dashboard_icon.svg"
                      //         }
                      //         placeholder={row?.ASE_AttributeName?.replaceAll(
                      //           "_",
                      //           " "
                      //         )}
                      //         data={row?.AttributeDDLList}
                      //         selected={row?.ASE_FieldValue}
                      //         onChange={(val) => {
                      //           let fields_data = [...headerFieldList];
                      //           fields_data[Index]["ASE_FieldValue"] = val;
                      //           fields_data[Index]["error"] = false;
                      //           setHeaderFieldList(fields_data);
                      //           if (
                      //             assetModifierId === 2246 &&
                      //             val?.AttributeText &&
                      //             row?.ASE_FieldName === "backup_product"
                      //           ) {
                      //             // It will fill up prtected assets values
                      //             getBackupProducts(val?.AttributeText);
                      //           }
                      //         }}
                      //         objectID={row?.ASE_RelObjDefID}
                      //         isError={row?.error}
                      //         refreshMenuItems={refreshMenuItems}
                      //         attrKey={row?.ASE_AttributeName?.replaceAll(
                      //           "_",
                      //           " "
                      //         )}
                      //         assetId={assetModifierId}
                      //         groups={row?.["ASE_AttributeRelatedObj"]}
                      //       />
                      //     </div>
                      //   )}
                      // </>
                    )}

                    {/* For Date picker Field */}

                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 2 && (
                      <>
                        {row?.ASE_FieldVerified && row?.ASE_FieldValue ? (
                          <div className="w-50 my-1">
                            <div className="f-black d-flex align-items-center">
                              <AttackSurfaceTooltip
                                content={
                                  <span>
                                    Auto-generated cannot be edited
                                    <RenderImagesGroup
                                      sourceName={row?.ASE_FieldSource}
                                    />
                                  </span>
                                }
                              >
                                <img
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="mr-2"
                                />
                              </AttackSurfaceTooltip>
                              {TrimString(row?.ASE_FieldValue, 20)}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="w-50 attack-bigModal-datepicker"
                            tabIndex={-1}
                          >
                            {/* {row?.ASE_FieldValue && ( */}
                            <HighgroundDatePicker
                              selected={row?.ASE_FieldValue}
                              icon={"/images/attack-surface/date-icon.svg"}
                              onChange={(val) => {
                                let fields_data = [...headerFieldList];
                                fields_data[Index]["ASE_FieldValue"] = val;
                                fields_data[Index]["error"] = false;
                                setHeaderFieldList(fields_data);
                              }}
                              id={
                                row?.ASE_AttributeName.trim()
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll("/", "")
                                  ?.replaceAll("-", "")
                                  ?.replaceAll("_", "")
                                  ?.replaceAll("(", "")
                                  ?.replaceAll(")", "")
                                  ?.replaceAll(".", "")
                                  ?.replaceAll(",", "") +
                                Index +
                                header
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll("/", "")
                                  ?.replaceAll("-", "")
                                  ?.replaceAll("_", "")
                                  ?.replaceAll("(", "")
                                  ?.replaceAll(")", "")
                                  ?.replaceAll(".", "")
                                  ?.replaceAll(",", "") +
                                randomId
                                // +
                                // Date.now()
                              }
                              isError={row?.error}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {/* For Check Box Field */}

                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 3 && (
                      <div
                        className="w-50 my-2 d-flex"
                        style={{
                          paddingLeft: "2.9rem",
                        }}
                      >
                        <Form.Group
                          controlId={"check" + "" + Index}
                          className=""
                        >
                          <Form.Check
                            tabIndex={-1}
                            type="switch"
                            id={
                              "switch-ques1" +
                              "" +
                              Index +
                              Math.random() * 1000000
                            }
                            style={{
                              transform: "scale(1.6)",
                              paddingLeft: "0.75rem",
                            }}
                            className={`m-0 scale-switch switch-shadow-none ${
                              row?.error ? "attack-switch-error" : ""
                            }  `}
                            checked={Boolean(
                              getCheckBoxValue(row?.ASE_FieldValue)
                            )}
                            label=""
                            disabled={
                              row?.ASE_FieldVerified === ""
                                ? false
                                : row?.ASE_FieldVerified
                            }
                            onChange={(e) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] =
                                e.target.checked;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);

                              // Form Check
                            }}
                            onClick={(e) => {}}
                          />
                        </Form.Group>

                        {row?.ASE_FieldVerified && (
                          <AttackSurfaceTooltip
                            content={
                              <span>
                                Auto-generated cannot be edited
                                <RenderImagesGroup
                                  sourceName={row?.ASE_FieldSource}
                                />
                              </span>
                            }
                          >
                            <img
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                              className="mr-2"
                            />
                          </AttackSurfaceTooltip>
                        )}
                      </div>
                    )}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 4 && (
                      <>
                        <div className="w-50 my-1" tabIndex={-1}>
                          <MultiSelectDropdown
                            locked={
                              row?.ASE_FieldVerified &&
                              row?.ASE_FieldValue?.length > 0
                            }
                            refId={row?.["ASE_RelObjDefID"]}
                            groups={row?.["ASE_AttributeRelatedObj"]}
                            data={row?.AttributeDDLList}
                            selected={row?.ASE_FieldValue}
                            onChange={(val) => {
                              let fields_data = [...headerFieldList];
                              fields_data[Index]["ASE_FieldValue"] = val;
                              fields_data[Index]["error"] = false;
                              setHeaderFieldList(fields_data);
                            }}
                            assetKey={row?.ASE_AttributeName?.replaceAll(
                              "_",
                              " "
                            )}
                            assetId={assetModifierId}
                            isError={row?.error}
                            refreshMenuItems={refreshMenuItems}
                            
                          />
                        </div>
                      </>
                    )}
                    {TypeMapping[row["ASE_AttrDef_InputType"]] === 5 && (
                      <>
                        {row?.ASE_FieldVerified && row?.ASE_FieldValue ? (
                          <div className="w-50 my-1">
                            <div className="f-black d-flex align-items-center">
                              <AttackSurfaceTooltip
                                content={
                                  <span>
                                    Auto-generated cannot be edited
                                    <RenderImagesGroup
                                      sourceName={row?.ASE_FieldSource}
                                    />
                                  </span>
                                }
                              >
                                <img
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="mr-2"
                                />
                              </AttackSurfaceTooltip>
                              {TrimString(row?.ASE_FieldValue, 20)}
                            </div>
                          </div>
                        ) : (
                          <div className="w-50 my-1">
                            <div
                              className={`attack-bigmodal-textarea-wrapper
                            ${row?.error ? "input-error radius-4" : ""} 
                            
                            `}
                            >
                              <textarea
                                tabIndex={0}
                                className={`border-none radius-4  `}
                                placeholder="Type"
                                rows={3}
                                id="message"
                                name="message"
                                value={row?.ASE_FieldValue}
                                onChange={(e) => {
                                  let fields_data = [...headerFieldList];
                                  fields_data[Index]["ASE_FieldValue"] =
                                    e.target.value;
                                  fields_data[Index]["error"] = false;
                                  setHeaderFieldList(fields_data);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            {/* All the Groups rendering  */}
            {/* 


333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333
Group Data Rendering
333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333


*/}

 
            {fieldsData?.length > 0 &&
              fieldsData.map((group, groupIndex) => {
                return (
                  <>
                    {(headerFieldList?.length > 0 || groupIndex !== 0) && (
                      <hr className="line-grey" />
                    )}
                    <div className="f-12 f-darkgrey mb-2 " tabIndex={-1}>
                      {cleanText(group?.groupName)}
                    </div>
                    {/* Details */}

                    {group.data
                      ?.sort(
                        (a, b) =>
                          Number(a.ASE_AttrDef_ColumnSort) -
                          Number(b.ASE_AttrDef_ColumnSort)
                      )
                      ?.map((row, Index) => {
                        return (
                          <div
                            className={` align-items-center justify-content-between mb-1
                            
                            ${
                              row?.ASE_AttrDef_Visibility === false
                                ? "d-none"
                                : "d-flex"
                            }
                            
                            `}
                            id={`${
                              row?.error
                                ? `error-element${assetModifierId}`
                                : ""
                            }`}
                          >
                            <div
                              className="f-black w-50"
                              tabIndex={-1}
                              style={{
                                paddingLeft: `${
                                  row?.ASE_AttrDef_IsSecondary === true
                                    ? "1rem"
                                    : ""
                                }`,
                              }}
                            >
                              {cleanText(row?.ASE_AttributeName)}
                            </div>
                            {/* For Input Field */}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              0 && (
                              <>
                                {row?.ASE_FieldVerified &&
                                row?.ASE_FieldValue ? (
                                  <div className="w-50 my-1">
                                    <div className="f-black d-flex align-items-center">
                                      <AttackSurfaceTooltip
                                        content={
                                          <span>
                                            Auto-generated cannot be edited
                                            <RenderImagesGroup
                                              sourceName={row?.ASE_FieldSource}
                                            />
                                          </span>
                                        }
                                      >
                                        <img
                                          alt=""
                                          src="/images/attack-surface/small-lock.svg"
                                          className="mr-2"
                                        />
                                      </AttackSurfaceTooltip>
                                      {TrimString(row?.ASE_FieldValue, 20)}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="w-50">
                                    <div
                                      className={`attack-bigmodal-input-wrapper   ${
                                        row?.error ? "input-error" : ""
                                      }  `}
                                    >
                                      {ModalItemsData?.[
                                        row?.ASE_AttributeName
                                      ]?.["icon"] && (
                                        <img
                                          alt=""
                                          className="mr-2"
                                          src={
                                            ModalItemsData?.[
                                              row?.ASE_AttributeName
                                            ]?.["icon"]
                                          }
                                        />
                                      )}
                                      <input
                                        tabIndex={Number(0)}
                                        type="text"
                                        placeholder={`${
                                          ModalItemsData?.[
                                            row?.ASE_AttributeName
                                          ]?.["placeholder"] || "Type"
                                        }`}
                                        className="attack-bigmodal-input flex-grow-1"
                                        value={row?.ASE_FieldValue}
                                        onChange={(e) => {
                                          let fields_data = [...fieldsData];
                                          fields_data[groupIndex]["data"][
                                            Index
                                          ]["ASE_FieldValue"] = e.target.value;
                                          fields_data[groupIndex]["data"][
                                            Index
                                          ]["error"] = false;
                                          setFieldsData(fields_data);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            {/* For Drop down Field */}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              1 && (
                              <>
                                {row?.ASE_FieldVerified &&
                                row?.ASE_FieldValue?.AttributeText ? (
                                  <div className="w-50 my-1">
                                    <div className="f-black d-flex align-items-center">
                                      <AttackSurfaceTooltip
                                        content={
                                          <span>
                                            Auto-generated cannot be edited
                                            <RenderImagesGroup
                                              sourceName={row?.ASE_FieldSource}
                                            />
                                          </span>
                                        }
                                      >
                                        <img
                                          alt=""
                                          src="/images/attack-surface/small-lock.svg"
                                          className="mr-2"
                                        />
                                      </AttackSurfaceTooltip>
                                      {TrimString(
                                        row?.ASE_FieldValue?.AttributeText,
                                        20
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="w-50 attack-bigmodal-dropdown"
                                    tabIndex={-1}
                                  >
                                    <AttackLocationDropdown
                                      refId={row?.["ASE_RelObjDefID"]}
                                      icon={
                                        row?.ASE_AttrDef_Icon
                                          ? `/images/attack-surface/${row?.ASE_AttrDef_Icon}.svg`
                                          : "/images/attack-surface/attack_surface_dashboard_icon.svg"
                                      }
                                      placeholder={row?.ASE_AttributeName?.replaceAll(
                                        "_",
                                        " "
                                      )}
                                      data={row?.AttributeDDLList}
                                      selected={row?.ASE_FieldValue}
                                      onChange={(val) => {
                                        let fields_data = [...fieldsData];
                                        fields_data[groupIndex]["data"][Index][
                                          "ASE_FieldValue"
                                        ] = val;
                                        fields_data[groupIndex]["data"][Index][
                                          "error"
                                        ] = false;
                                        setFieldsData(fields_data);
                                      }}
                                      objectID={row?.ASE_RelObjDefID}
                                      isError={row?.error}
                                      refreshMenuItems={refreshMenuItems}
                                      attrKey={row?.ASE_AttributeName?.replaceAll(
                                        "_",
                                        " "
                                      )}
                                      assetId={assetModifierId}
                                      groups={row?.["ASE_AttributeRelatedObj"]}
                                      
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            {/* For Date picker Field */}

                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              2 && (
                              <>
                                {row?.ASE_FieldVerified &&
                                row?.ASE_FieldValue ? (
                                  <div className="w-50 my-1">
                                    <div className="f-black d-flex align-items-center">
                                      <AttackSurfaceTooltip
                                        content={
                                          <span>
                                            Auto-generated cannot be edited
                                            <RenderImagesGroup
                                              sourceName={row?.ASE_FieldSource}
                                            />
                                          </span>
                                        }
                                      >
                                        <img
                                          alt=""
                                          src="/images/attack-surface/small-lock.svg"
                                          className="mr-2"
                                        />
                                      </AttackSurfaceTooltip>
                                      {TrimString(row?.ASE_FieldValue, 20)}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="w-50 attack-bigModal-datepicker"
                                    tabIndex={-1}
                                  >
                                    {/* {row?.ASE_FieldValue && ( */}
                                    <HighgroundDatePicker
                                      selected={row?.ASE_FieldValue}
                                      icon={
                                        "/images/attack-surface/date-icon.svg"
                                      }
                                      onChange={(val) => {
                                        let fields_data = [...fieldsData];
                                        fields_data[groupIndex]["data"][Index][
                                          "ASE_FieldValue"
                                        ] = val;
                                        fields_data[groupIndex]["data"][Index][
                                          "error"
                                        ] = false;
                                        setFieldsData(fields_data);
                                      }}
                                      id={
                                        row?.ASE_AttributeName.trim()
                                          ?.replaceAll(" ", "")
                                          ?.replaceAll(" ", "")
                                          ?.replaceAll("/", "")
                                          ?.replaceAll("-", "")
                                          ?.replaceAll("_", "")
                                          ?.replaceAll("(", "")
                                          ?.replaceAll(")", "")
                                          ?.replaceAll(".", "")
                                          ?.replaceAll(",", "") +
                                        Index +
                                        header
                                          ?.replaceAll(" ", "")
                                          ?.replaceAll("/", "")
                                          ?.replaceAll("-", "")
                                          ?.replaceAll("_", "")
                                          ?.replaceAll("(", "")
                                          ?.replaceAll(")", "")
                                          ?.replaceAll(".", "")
                                          ?.replaceAll(",", "") +
                                        groupIndex +
                                        randomId
                                        // +
                                        // Date.now()
                                      }
                                      isError={row?.error}
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            {/* For Check Box Field */}

                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              3 && (
                              <div
                                className="w-50 my-2 d-flex"
                                style={{
                                  paddingLeft: "2.9rem",
                                }}
                              >
                                <Form.Group
                                  controlId={"check" + groupIndex + "" + Index}
                                  className=""
                                >
                                  <Form.Check
                                    tabIndex={-1}
                                    type="switch"
                                    id={
                                      "switch-ques1" +
                                      groupIndex +
                                      "" +
                                      Index +
                                      Math.random() * 1000000
                                    }
                                    style={{
                                      transform: "scale(1.6)",
                                      paddingLeft: "0.75rem",
                                    }}
                                    className={`m-0 scale-switch switch-shadow-none ${
                                      row?.error ? "attack-switch-error" : ""
                                    }  `}
                                    checked={Boolean(
                                      getCheckBoxValue(row?.ASE_FieldValue)
                                    )}
                                    label=""
                                    disabled={
                                      row?.ASE_FieldVerified === ""
                                        ? false
                                        : row?.ASE_FieldVerified
                                    }
                                    onChange={(e) => {

                                      try{
                                        ThrowExceptionIfOnSiteWorkIsSelected()

                                        let fields_data = [...fieldsData];
                                        fields_data[groupIndex]["data"][Index][
                                          "ASE_FieldValue"
                                        ] = e.target.checked;
                                        fields_data[groupIndex]["data"][Index][
                                          "error"
                                        ] = false;
                                        setFieldsData(fields_data);
  
                                        // Tocheck if its Primary and then Toggle Secondary field
                                        if (row?.ASE_AttrDef_IsPrimary === true) {
                                          toggleSecondaryField(
                                            groupIndex,
                                            row?.ASE_FieldName,
                                            e.target.checked
                                          );
                                          
                                        }





                                      }catch (err){
                                        console.log(err);



                                      }
                                     

                                      // Form Check
                                    }}
                                    onClick={(e) => {}}
                                  />
                                </Form.Group>

                                {row?.ASE_FieldVerified && (
                                  <AttackSurfaceTooltip
                                    content={
                                      <span>
                                        Auto-generated cannot be edited
                                        <RenderImagesGroup
                                          sourceName={row?.ASE_FieldSource}
                                        />
                                      </span>
                                    }
                                  >
                                    <img
                                      alt=""
                                      src="/images/attack-surface/small-lock.svg"
                                      className="mr-2"
                                    />
                                  </AttackSurfaceTooltip>
                                )}
                              </div>
                            )}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              4 && (
                              <>
                                <div className="w-50 my-1" tabIndex={-1}>
                                  <MultiSelectDropdown
                                    locked={
                                      row?.ASE_FieldVerified &&
                                      row?.ASE_FieldValue?.length > 0
                                    }
                                    disabled={ThrowExceptionIfPermanentHomeIsSelected(row)}
                                    refId={row?.["ASE_RelObjDefID"]}
                                    groups={row?.["ASE_AttributeRelatedObj"]}
                                    data={row?.AttributeDDLList}
                                    selected={row?.ASE_FieldValue}
                                    onChange={(val) => {
                                      try{
                                        ThrowExceptionIfPermanentHomeIsSelected()
                                        let fields_data = [...fieldsData];
                                        fields_data[groupIndex]["data"][Index][
                                          "ASE_FieldValue"
                                        ] = val;
                                        fields_data[groupIndex]["data"][Index][
                                          "error"
                                        ] = false;
                                        setFieldsData(fields_data);
                                      }catch(err){

console.log(err);

                                      }
                                      
                                    }}
                                    assetKey={row?.ASE_AttributeName?.replaceAll(
                                      "_",
                                      " "
                                    )}
                                    assetId={assetModifierId}
                                    isError={row?.error}
                                    refreshMenuItems={refreshMenuItems}
                                    
                                  />
                                </div>
                              </>
                            )}
                            {TypeMapping[row["ASE_AttrDef_InputType"]] ===
                              5 && (
                              <>
                                {row?.ASE_FieldVerified &&
                                row?.ASE_FieldValue ? (
                                  <div className="w-50 my-1">
                                    <div className="f-black d-flex align-items-center">
                                      <AttackSurfaceTooltip
                                        content={
                                          <span>
                                            Auto-generated cannot be edited
                                            <RenderImagesGroup
                                              sourceName={row?.ASE_FieldSource}
                                            />
                                          </span>
                                        }
                                      >
                                        <img
                                          alt=""
                                          src="/images/attack-surface/small-lock.svg"
                                          className="mr-2"
                                        />
                                      </AttackSurfaceTooltip>
                                      {TrimString(row?.ASE_FieldValue, 20)}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="w-50 my-1">
                                    <div
                                      className={`attack-bigmodal-textarea-wrapper
                                  ${row?.error ? "input-error radius-4" : ""} 
                                  
                                  `}
                                    >
                                      <textarea
                                        tabIndex={0}
                                        className={`border-none radius-4  `}
                                        placeholder="Type"
                                        rows={3}
                                        id="message"
                                        name="message"
                                        value={row?.ASE_FieldValue}
                                        onChange={(e) => {
                                          let fields_data = [...fieldsData];
                                          fields_data[groupIndex]["data"][
                                            Index
                                          ]["ASE_FieldValue"] = e.target.value;
                                          fields_data[groupIndex]["data"][
                                            Index
                                          ]["error"] = false;
                                          setFieldsData(fields_data);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                  </>
                );
              })}
            {/* ------------- */}
            {/* ---------------- */}
            {/*---------------- */}
            {/* ---------------- */}
          </>
        )}
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 border-top position-sticky ">
        <div className="blurred-div position-absolute"></div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {header.split(" ")[0] === "Edit" &&
              getAllIntegrationNames()?.length > 0 && (
                <div className="d-flex">
                  <AttackSurfaceTooltip
                    top
                    content={
                      <span>
                        Auto-generated data cannot be deleted. You must
                        disconnect all integrations creating the data or contact
                        HighGround support
                      </span>
                    }
                  >
                    <div className="d-flex">
                      <RenderImagesGroup
                        sourceName={getAllIntegrationNames()}
                      />
                      <div className="f-darkgrey ml-2">Auto generated data</div>
                    </div>
                  </AttackSurfaceTooltip>
                </div>
              )}

            {header.split(" ")[0] === "Edit" &&
              getAllIntegrationNames()?.length === 0 && (
                <div
                  className="d-flex align-items-center f-500 f-grey pointer"
                  onClick={deleteAsset}
                >
                  <img
                    alt=""
                    className="mx-2"
                    src="/images/actioncenter/delete.svg"
                  />
                  {deleting ? "Deleting.." : "Delete"}
                </div>
              )}
          </div>

          <div className="d-flex justify-content-end">
            <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              disabled={saving}
              className="hg-blue-btn"
              onClick={async () => {
                let errorCount = await checkValidations(
                  headerFieldList,
                  setHeaderFieldList,
                  fieldsData,
                  setFieldsData
                );

                if (errorCount > 0) {
                  let error_element = document.getElementById(
                    `error-element${assetModifierId}`
                  );
                  if (error_element) {
                    error_element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "nearest",
                    });
                  }

                  return;
                } else {
                  let Data = getDataToUpload([...fieldsData]);

                  let DataFromHeaders =
                    modifyHeaderListToUpload(headerFieldList);
                  let data_to_upload = [{ ...Data[0], ...DataFromHeaders }];

                  uploadData(data_to_upload);
                }
              }}
            >
              {saving ? (
                <>
                  <WhiteLoader width={13} />
                  Saving..
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </SlidingModal.Footer>
    </SlidingModal>
  );
};

export default WorkstationModal;

export function makeGroup(list, key) {
  let resultArr = [];
  let types = [];
  for (let i = 0; i < list.length; i++) {
    if (types.indexOf(list[i][key]) > -1) {
      let indexPosition = types.indexOf(list[i][key]);
      resultArr[indexPosition]["data"].push(list[i]);
      resultArr[indexPosition]["groupName"] = list[i][key];
    } else {
      types.push(list[i][key]);
      let indexPosition = types.indexOf(list[i][key]);
      if (typeof resultArr[indexPosition] === "object") {
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition]["groupName"] = list[i][key];
      } else {
        resultArr[indexPosition] = {};
        resultArr[indexPosition]["data"] = [];
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition]["groupName"] = list[i][key];
      }
    }
  }

  return resultArr;
}

export function getDataToUpload(inputData) {
  let output = [{}];
  for (let i = 0; i < inputData.length; i++) {
    for (let j = 0; j < inputData[i].data.length; j++) {
      output[0][inputData[i].data[j].ASE_FieldName] =
        inputData[i].data[j].ASE_FieldValue;
    }
  }

  return output;
}

export function modifyHeaderListToUpload(inputData) {
  let output = {};
  for (let i = 0; i < inputData.length; i++) {
    output[inputData[i].ASE_FieldName] = inputData[i].ASE_FieldValue;
  }

  return output;
}

const ModalItemsData = {
  Hostname: { placeholder: "Type", icon: false },
  Manufacturer: { placeholder: "Type", icon: false },
  Model: { placeholder: "Type", icon: false },
  Serial: { placeholder: "A0BCD1EFG2", icon: false },
  "Operating System": { placeholder: "Type", icon: false },
  "Operating System Feature Build": { placeholder: "v0.00", icon: false },
  "IP Address": { placeholder: "0.0.0.0", icon: false },
  "MAC Address": { placeholder: "00:00:00:00:00:00", icon: false },
  "Managed by": {
    placeholder: "Type",
    icon: "/images/attack-surface/attack-modal-managed-by.svg",
  },
  "Primary User": {
    placeholder: "Select user",
    icon: "/images/attack-surface/attack-modal-user-icon.svg",
  },
  "Secondary User": {
    placeholder: "Select user",
    icon: "/images/attack-surface/attack-modal-user-icon.svg",
  },
  "Endpoint Protection Software": { placeholder: "Type", icon: false },
  "Web Filtering Software": { placeholder: "Type", icon: false },
  "Patch Management": { placeholder: "Type", icon: false },
  "Last Patched Windows Updates": { placeholder: "Type", icon: false },
};

 

export const RenderImagesGroup = ({ sourceName }) => {
  let _sourceName = sourceName?.toString();
  let sources = _sourceName?.split(",");
  return (
    <div className="image-group">
      {sources.map((source) => {
        return (
          <img
            alt=""
            className="rounded-full"
            width={18}
            height={18}
            src={`/images/attack-surface/integrations/${source}.webp`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/attack-surface/generic.svg";
            }}
          />
        );
      })}
    </div>
  );
};
