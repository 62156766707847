import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { postData, getAPIData } from "../../../Utils/commonApi";
import "./connectToolModal.scss";
import {
  Button,
  Modal,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Fragment } from "react";
import FailDialog from "../../Spinner/FailDialog";
import SuccessDialog from "../../Spinner/SuccessDialog";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import OAuth2Login from "react-simple-oauth2-login/dist/OAuth2Login";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";

const ConnectionToolModal = (props) => {
  const { authTokens } = useAuth();
  const [integration, setIntegration] = useState(props.data);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [submitResponse, setSubmitResponse] = useState({});
  const [errorStructure, setErrorStructure] = useState([]);
  const [disconnectResponse, setDisconnectResponse] = useState(false);
  const [inputs, setInputs] = useState({});
  const [slideHideClass, setSlideHideClass] = useState("");
  const [isEdit, setIsEdit] = useState(
    props.data.IntegrationStatus ? false : true
  );
  const [isDisconnect, setIsDisConnect] = useState(false);
  const [testOrCreate, setTestOrCreate] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [usePreFilledDetails, setUsePreFilledDetails] = useState(false);
  const {
    IntegrationDetail,
    ConnectionStrings,
    ConnectedRelatedIntegrationDetail,
  } = integration;

  const [Oauthing, setOauthing] = useState(false);

  useEffect(() => {
    setIntegration(props.data);
    for (let i = 0; i < props.data.ConnectionStrings.length; i++) {
      setInputs((inputs) => ({
        ...inputs,
        ["KeyString" + props.data.ConnectionStrings[i].KeyNumber]: props.data
          .ConnectionStrings[i].KeyValue
          ? props.data.ConnectionStrings[i].KeyValue
          : "",
      }));
    }
    setIsEdit(props.data.IntegrationStatus ? false : true);
    setIsConfirm(false);
    setSubmitResponse({});
    if (props.data.ConnectedRelatedIntegrationDetail) {
      setUsePreFilledDetails(true);
    } else {
      setUsePreFilledDetails(false);
    }
  }, [props.data.ConnectionStrings, props.data.IntegrationStatus]);

  const toggleInputShow = (e) => {
    var doc = e.currentTarget;
    doc.querySelector(".showField").classList.toggle("d-none");
    doc.querySelector(".hideField").classList.toggle("d-none");
    if (doc.querySelector(".showField").classList.contains("d-none")) {
      doc.parentNode.previousElementSibling.setAttribute("type", "password");
    } else {
      doc.parentNode.previousElementSibling.setAttribute("type", "text");
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.type == "checkbox") {
      setIsConfirm(!isConfirm);
      errorStructure["eIsConfirm"] = false;
    } else {
      setErrorStructure([]);
      setInputs((inputs) => ({
        ...inputs,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleEdit = (value) => {
    setIsEdit(value);
    if (value == true) {
      setTestOrCreate(2);
    }
  };

  async function handleSave() {
    setIsSubmit(true);
    const prefilledData = {
      IntegrationId: integration.IntegrationDetail.IntegrationId,

      KeyString0: ConnectedRelatedIntegrationDetail?.IntegrationId,
      TestOrCreate: 1,
    };
    if (usePreFilledDetails) {
      setIsRequestSend(true);
    } else {
      const { is_error } = handleValidations(true);
      setErrorStructure(handleValidations(true));
      if (is_error) return;
      setIsRequestSend(true);
      inputs.IntegrationId = integration.IntegrationDetail.IntegrationId;
      inputs.TestOrCreate = testOrCreate;
    }

    postData(
      authTokens,
      "SubmitIntegrationKeys",
      usePreFilledDetails ? prefilledData : inputs
    )
      .then((response) => {
        setSubmitResponse(response.mr);
        setIsSubmit(false);
        setIsRequestSend(false);
        if (response.mr.Success == false) {
          TrackUser("Integration failed");
          setTimeout(() => {
            // setSubmitResponse(false);
          }, 5000);
        } else {
          if (testOrCreate == 1 || usePreFilledDetails) {
            updateAccessKeyInLocalStorage(response?.mr?.Message)
            
           

            TrackUser("Tool connected", {
              type: IntegrationDetail.IntegrationName,
            });
            setIntegration(response);
            setIsEdit(false);
            setTestOrCreate(2);
            props.handleIntegration();
            if (props.refreshData) {
              props.refreshData();
            }
          } else {
            setTestOrCreate(1);

            TrackUser("Tool connected", {
              type: IntegrationDetail.IntegrationName,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmit(false);
        setIsRequestSend(false);
        CallToast("Error, Please try again", false, false, "v2style");
        // setSubmitResponse(false);
      });
  }

  async function handleDisconnect() {
    const response = await getAPIData("65", authTokens, {
      OptionEnum1: integration.IntegrationDetail.IntegrationId,
      OptionEnum2: 6,
    });
     
    updateAccessKeyInLocalStorage(response?.Message)
   
     
    setDisconnectResponse(response);
    setIsSubmit(false);
    setTimeout(() => {
      setDisconnectResponse(false);
    }, 5000);
    if (response.Success == true) {
      setTimeout(() => {
        props.handleIntegration();
        handleClear();
      }, 5000);
    }
  }

  const handleClear = () => {
    setInputs({});
    setIsDisConnect(false);
    setSlideHideClass("slideHide");
    setTimeout(() => {
      props.onHide();
      setSlideHideClass("");
    }, 300);
  };
  /**
   * Handle validations
   */
  const handleValidations = (is_sumbit) => {
    if (usePreFilledDetails) return [];
    let error_structure = [];
    if (!is_sumbit) return error_structure;
    const empty_inputs = Object.keys(inputs).filter(
      (key) => inputs[key] === ""
    );
    for (let i = 0; i < empty_inputs.length; i++) {
      error_structure["e" + empty_inputs[i]] = true;
    }
    if (!isConfirm) {
      error_structure["eIsConfirm"] = true;
    }
    if (Object.keys(error_structure).length !== 0) {
      error_structure.is_error = true;
    }
    return error_structure;
  };

  // let errorStructure = handleValidations(isSubmit);

  // Reset modal state
  useEffect(() => {
    setTestOrCreate(0);
    setErrorStructure([]);
  }, [props.show]);

  return (
    integration && (
      <Modal
        show={props.show}
        dialogClassName="modal-50w"
        className={`userflow-issue suggestIntegrationModal integrationModal rightModal modal-medium right ${slideHideClass}`}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClear}
      >
        <Modal.Header>
          <span
            className="cursor-pointer"
            onClick={() => {
              //  connect tool track user
              // TrackUser(
              //   `${
              //     IntegrationDetail.IntegrationName +
              //     " - " +
              //     IntegrationDetail.TechnologyTitle
              //   } clicked off`
              // );

              handleClear();
            }}
          >
            <img src="/images/icons/close.svg" alt="" />
          </span>
          <h1 className="w-100 mb-0 mt-0 ml-4">
            {IntegrationDetail.IntegrationName +
              " - " +
              IntegrationDetail.TechnologyTitle}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex">
              <img
                className="border-grey mr-3"
                width="78"
                // src={`/images/TechnologyType/${IntegrationDetail.IntegrationFileName}.svg`}
                src={`/images/attack-surface/integrations/${IntegrationDetail?.IntegrationId}.webp`}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/attack-surface/generic.svg";
                }}
              />
              <div className="align-self-center">
                <h1>{IntegrationDetail.IntegrationName}</h1>
                <a
                  href={IntegrationDetail.IntegrationURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/link_icon.svg" alt="" />
                  <span className="link-txt">Developer's site</span>
                </a>
                <div>
                  <a
                    href={IntegrationDetail.Integration_KB_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/link_icon.svg" alt="" />
                    <span className="link-txt">Setup Instructions</span>
                  </a>
                </div>
              </div>
              {integration.IntegrationStatus && (
                <div
                  className="ml-auto align-self-center"
                  style={{ minWidth: "130px" }}
                >
                  <Button
                    className="align-self-center btn-red mr-1"
                    onClick={() => setIsDisConnect(true)}
                  >
                    Disconnect
                  </Button>
                  {!OAuthEnabled[
                    integration.IntegrationDetail.IntegrationId
                  ] && (
                    <Button
                      className="align-self-center btn-grey btn-light"
                      onClick={() => handleEdit(true)}
                    >
                      <img src="/images/icons/edit.svg" alt="" />
                    </Button>
                  )}
                </div>
              )}
            </div>
            {isDisconnect ? (
              <Fragment>
                {disconnectResponse &&
                  (disconnectResponse.Success == false ? (
                    <FailDialog text="Failed to disconnect" ContWidth="85%" />
                  ) : (
                    <SuccessDialog
                      text="Disconnect successfully"
                      ContWidth="85%"
                    />
                  ))}
                <span className="d-flex mb8px" style={{ marginTop: "56px" }}>
                  <img src="/images/icons/error.svg" alt="" />
                  <h1 className="redText pl-1 m-0">Disconnect integration</h1>
                </span>
                <p className="textBlackBold">
                  Are you sure that you want to disconnect this integration?
                </p>
                <Row style={{ marginTop: "76px" }}>
                  <Col className="pr-1">
                    <Button
                      className="btn-red mr-1 btn-block p16px"
                      onClick={() => {
                        TrackUser("Integration disabled");

                        handleDisconnect();
                      }}
                    >
                      Disconnect
                    </Button>
                  </Col>
                  <Col className="pl-1">
                    <Button
                      className="btn-grey btn-light btn-block p16px"
                      onClick={() => setIsDisConnect(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <>
                {OAuthEnabled[integration.IntegrationDetail.IntegrationId] &&
                isEdit ? (
                  <>
                    {OAuthEnabled[
                      integration.IntegrationDetail.IntegrationId
                    ] && (
                      <div
                        className="oauth2-btn mt-3"
                        onClick={() => {
                          setOauthing(true);
                        }}
                      >
                        <OAuth2Login
                          state="12345678"
                          extraParams={
                            OAuthEnabled[
                              integration.IntegrationDetail.IntegrationId
                            ].extraParams_
                          }
                          authorizationUrl={
                            OAuthEnabled[
                              integration.IntegrationDetail.IntegrationId
                            ].AUTH_URL
                          }
                          responseType="code"
                          scope={
                            OAuthEnabled[
                              integration.IntegrationDetail.IntegrationId
                            ].SCOPE
                          }
                          clientId={
                            OAuthEnabled[
                              integration.IntegrationDetail.IntegrationId
                            ].CLIENT_ID
                          }
                          clientSecret={
                            OAuthEnabled[
                              integration.IntegrationDetail.IntegrationId
                            ].CLIENT_SECRET
                          }
                          redirectUri={`${window.location.origin}/`}
                          onSuccess={(response) => {
                            //   {code: 'J5ayMuZGoVX-Sk-Ko2RpRQZXqTQGeGcKoNA6Nzwv_9w', scope: 'accounting.contacts.read offline_access'}
                            var realmId = {};
                            if (response?.realmId) {
                              realmId["KeyString2"] = response?.realmId;
                            }

                            let key0 = response?.code;
                            let key1 = `${window.location.origin}/`;

                            postData(authTokens, "SubmitIntegrationKeys", {
                              IntegrationId:
                                integration.IntegrationDetail.IntegrationId,
                              KeyString0: key0,
                              KeyString1: key1,
                              TestOrCreate: 3,
                              ...realmId,
                            })
                              .then((response) => {
                                setOauthing(false);
                                if (response?.mr?.Success) {
                                  handleClear();
                                  setIntegration(response);
                                  props.handleIntegration();
                                  if (props.refreshData) props.refreshData();
                                }
                              })
                              .catch((res) => {
                                console.log("fail", res);
                                setOauthing(false);
                                CallToast(
                                  "Error, Please try again",
                                  false,
                                  false,
                                  "v2style"
                                );
                              });
                          }}
                          onFailure={(res) => {
                            console.log("fail", res);
                            setOauthing(false);
                            CallToast(
                              "Error, Please try again",
                              false,
                              false,
                              "v2style"
                            );
                          }}
                        >
                          {Oauthing ? (
                            <>
                              <WhiteLoader width={13} />
                              Please wait...
                            </>
                          ) : (
                            "Authenticate to connect"
                          )}
                        </OAuth2Login>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {/* If other tool has your details- show this option */}
                    {ConnectedRelatedIntegrationDetail &&
                      !integration.IntegrationStatus && (
                        <div>
                          <Form.Check
                            className={`mt-16px  ${true}`}
                            checked={usePreFilledDetails}
                            type="checkbox"
                            label={`Use the existing ${ConnectedRelatedIntegrationDetail?.IntegrationName} credentials`}
                            onChange={(e) =>
                              setUsePreFilledDetails(e.target.checked)
                            }
                          />
                        </div>
                      )}
                    {/* Tabs */}
                    {"!usePreFilledDetails" && (
                      <Tabs
                        className="integration_tabs"
                        defaultActiveKey="api_connection"
                        transition={false}
                        style={{ paddingTop: "36px" }}
                      >
                        <Tab eventKey="api_connection" title="API Connection">
                          <Form className="pt-2">
                            <Form.Group>
                              {!isEdit && (
                                <div className="webfiltering_background p16px mt24px">
                                  <div className="d-flex mb16px">
                                    <img
                                      src={
                                        integration.IntegrationStatus
                                          .TitleIconURL
                                      }
                                      className="align-self-center"
                                      alt=""
                                    />
                                    <p
                                      className="pl-1"
                                      style={{
                                        color:
                                          integration.IntegrationStatus
                                            .TitleColour,
                                      }}
                                    >
                                      {integration.IntegrationStatus.Title}
                                    </p>
                                  </div>
                                  <p className="textBlackBold mb8px">
                                    {integration.IntegrationStatus.Message}
                                  </p>
                                  <hr />
                                  <p className="textBlackBold mb8px">
                                    Last Synced:{" "}
                                    {integration.IntegrationStatus.LastSync}
                                  </p>
                                  <p className="textBlackBold mb8px">
                                    Next Sync:{" "}
                                    {integration.IntegrationStatus.NextSync}
                                  </p>
                                </div>
                              )}
                              {submitResponse &&
                              submitResponse?.Success == true &&
                              testOrCreate == 1 ? (
                                <div>
                                  <h2 style={{ marginTop: "24px" }}>
                                    API Test Successfull
                                  </h2>
                                  <span className="d-flex">
                                    <img
                                      src="/images/icons/checkMark.svg"
                                      alt=""
                                    />
                                    <p className="greenText pl-1">
                                      User credetials correct
                                    </p>
                                  </span>
                                  <span className="d-flex mt16px">
                                    <img
                                      src="/images/icons/checkMark.svg"
                                      alt=""
                                    />
                                    <p className="greenText pl-1">
                                      API healthy
                                    </p>
                                  </span>
                                  <span
                                    className="d-flex mt16px"
                                    style={{ marginBottom: "8px" }}
                                  >
                                    <img
                                      src="/images/icons/checkMark.svg"
                                      alt=""
                                    />
                                    <p className="greenText pl-1">
                                      API connected
                                    </p>
                                  </span>
                                </div>
                              ) : (
                                <Fragment>
                                  {ConnectionStrings.map((connection) => (
                                    <InputGroup
                                      className={`mt-16px ${
                                        !isEdit && "notEditMode"
                                      } ${
                                        (isRequestSend ||
                                          usePreFilledDetails) &&
                                        "disabled"
                                      }`}
                                    >
                                      <InputGroup.Prepend>
                                        <InputGroup.Text
                                          className={`txt-grey ${
                                            errorStructure[
                                              "eKeyString" +
                                                connection.KeyNumber
                                            ] && "border-danger"
                                          }`}
                                        >
                                          {connection.Label}:
                                        </InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <FormControl
                                        autoComplete="new-password"
                                        name={
                                          "KeyString" + connection.KeyNumber
                                        }
                                        type={
                                          connection.ShowText == false
                                            ? "password"
                                            : "text"
                                        }
                                        onChange={(e) => handleInputChange(e)}
                                        className={
                                          errorStructure[
                                            "eKeyString" + connection.KeyNumber
                                          ] && "border-danger"
                                        }
                                        defaultValue={connection.KeyValue}
                                        readOnly={!isEdit}
                                        disabled={
                                          usePreFilledDetails ||
                                          (connection.Label.toLowerCase() ===
                                            "unique reference" &&
                                            connection.KeyValue !== null &&
                                            connection.KeyValue !== "")
                                        }
                                      />
                                      {connection.ShowText == false &&
                                        isEdit && (
                                          <InputGroup.Append>
                                            <InputGroup.Text
                                              className="passwordIcon"
                                              onClick={(e) =>
                                                toggleInputShow(e)
                                              }
                                            >
                                              <BsEyeSlashFill className="showField d-none" />
                                              <BsEyeFill className="hideField" />
                                            </InputGroup.Text>
                                          </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                  ))}
                                  {isEdit && (
                                    <Form.Check
                                      className={`mt-16px check-error ${
                                        true
                                        // isSubmit &&
                                        // !errorStructure["is_error"] &&
                                        // "disabled"
                                      }
                                  
                                  ${usePreFilledDetails ? "disabled" : ""}
                                  
                                  `}
                                      type="checkbox"
                                      label="I confirm I am the account owner and have legal permission to connect this product to HighGround."
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                  )}
                                  {errorStructure["eIsConfirm"] && (
                                    <p className="redText">
                                      Please check above to proceed
                                    </p>
                                  )}
                                  <p className="redText">
                                    {submitResponse &&
                                    submitResponse?.Success == false &&
                                    submitResponse?.ExThrown == false
                                      ? submitResponse?.Message
                                      : submitResponse?.ExMessage}
                                  </p>
                                </Fragment>
                              )}
                            </Form.Group>
                            {isEdit && (
                              <>
                                <Button
                                  className={`w-100 submit-btn mt-16px ${
                                    isRequestSend && "disabled"
                                  }`}
                                  onClick={handleSave}
                                >
                                  {submitResponse?.Success == true ? (
                                    isRequestSend ? (
                                      <span>
                                        <Spinner
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                        />{" "}
                                        Completing...
                                      </span>
                                    ) : (
                                      "Complete Integration"
                                    )
                                  ) : isRequestSend ? (
                                    <span>
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                      />
                                      {usePreFilledDetails
                                        ? "Creating..."
                                        : "Testing..."}
                                    </span>
                                  ) : usePreFilledDetails ? (
                                    "Create connection"
                                  ) : (
                                    "Test Connection"
                                  )}
                                </Button>
                              </>
                            )}
                            {submitResponse?.Success == true && isEdit && (
                              <Button
                                className="w-100 cancel-btn mt-16px"
                                onClick={handleClear}
                              >
                                Clear
                              </Button>
                            )}
                          </Form>
                        </Tab>
                      </Tabs>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  );
};

export default ConnectionToolModal;

const OAuthEnabled = {
  3417718: {
    AUTH_URL: "https://login.xero.com/identity/connect/authorize",
    TOKEN_URL: "https://identity.xero.com/connect/token",
    SCOPE: "accounting.contacts.read offline_access",
    CLIENT_ID: process.env.REACT_APP_XERO_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_XERO_CLIENT_SECRET,
  },
  3450675: {
    AUTH_URL: "https://www.sageone.com/oauth2/auth/central",
    TOKEN_URL: "https://oauth.accounting.sage.com/token",
    SCOPE: "full_access",
    CLIENT_ID: process.env.REACT_APP_SAGEONE_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_SAGEONE_CLIENT_SECRET,
  },
  3448056: {
    AUTH_URL: "https://appcenter.intuit.com/connect/oauth2",
    TOKEN_URL: "https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer",
    SCOPE: "com.intuit.quickbooks.accounting",
    CLIENT_ID: "ABfcdAqhFVFUMcuAj0etxZLGZGJ2mOhnAReDCFMVrvZFQUpTuM",
    CLIENT_SECRET: "Vo60TchtN95IOSWsGWXm1SHMdRpXzrQC6Z9xzGOg",
  },
  3544486: {
    AUTH_URL: "https://id.sage.com/authorize",
    TOKEN_URL: "https://id.sage.com/oauth/token",
    SCOPE: "openid profile email offline_access",
    CLIENT_ID: "9uesjyYoAtcyBtiJSNC0v09yfRxkktJI",
    CLIENT_SECRET:
      "8ITgoZbEpcWKMZJOWu_prkC6RSPpLlcFSzROI6MzQzkZtI9q7AgTrGFNnayEcwxo",
    extraParams_: { audience: "s200ukipd/sage200" },
  },
};
