import React, { useState, useEffect, Fragment } from "react";
import "./SideBar.css";

import { Col, Row, Container, Card, Button, Dropdown } from "react-bootstrap";
import $ from "jquery";
import ThreatLevel from "../ThreatLevel/ThreatLevel";
import "../../main.scss";
import { useDb } from "../context/db";
import CyberThreatDetails from "../CyberThreatDetails/CyberThreatDetails";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import { useAuth } from "../context/Auth";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";
import { getAPIData } from "../../Utils/commonApi";
// import { FiChevronRight } from "react-icons/fi";
import TicketsView from "../Support/TicketsView/TicketsView";
import ParentChildModal from "../ModalDynamic/ParentChildModal";
// import AddInvestmentForm from "../InvestmentDetails/AddInvestmentForm/AddInvestmentForm";
import CreditModal from "../ProServices/CreditModal";
import Header from "../Header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SideBar = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const cyberThreatValue = useSelector(
    (state) => state.user?.userDetail?.cyberThreatValue
  );
  const [modalData, setmodalData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportCounter, setReportCounter] = useState(0);
  const [showCreditModal, setShowCreditModal] = useState(false);

  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [supportCounter, setSupportCounter] = useState(0);

  const { dbObj } = useDb();
  const [threatNumber, setThreatNumber] = useState(props.threatNum || 0);
  useEffect(() => {
    setThreatNumber(cyberThreatValue);
  }, [cyberThreatValue]);

  function setStateModal(event) {
    event.preventDefault();
    setModalOpen(true);
  }

  // function setReportState(event) {
  //   event.preventDefault();
  //   setReportModalOpen(true);
  // }
  // function setSupportState(event) {
  //   event.preventDefault();
  // }
  useEffect(() => {
    if (dbObj.PostLoadAction == 1) {
      setSupportModalOpen(true);
    }
  }, []);

  async function callScoreAPI(num) {
    let data = await getAPIData(num, authTokens);
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setmodalData();
      data = await getAPIData(num, data.authToken);
    }
    setmodalData(data);
  }
  useEffect(() => {
    if (modalData && modalOpen && !counter) {
      setModalOpen(false);
      setInterval(() => {
        setModalOpen(true);
      }, 100);
    }
  }, [modalData]);

  useEffect(() => {
    var bar = $(".bar__inner");
    var barPercent = bar.attr("data-percent");
    function refreshColorsFromAll() {
      $(".none").removeClass("color");
      $(".low").removeClass("color");
      $(".moderate").removeClass("color");
      $(".high").removeClass("color");
    }

    bar.animate(
      {
        height: 100 - barPercent + "%",
      },
      {
        easing: "linear",
        duration: 1640,
        step: function (currentPercent) {
          if (currentPercent < 100 && currentPercent > 95) {
            refreshColorsFromAll()
            animateColor($(".none"), $(".none"));
            $(".bar__status").css("color", "#40D88E").text("None");
          } else if (currentPercent <= 95 && currentPercent > 50) {
            refreshColorsFromAll()
            animateColor($(".none"), $(".low"));
            $(".bar__status").css("color", "#ffd11d").text("Low");
          } else if (currentPercent <= 50 && currentPercent > 25) {
            refreshColorsFromAll()
            animateColor($(".low"), $(".moderate"));
            $(".bar__status").css("color", "#FFAC2C").text("Moderate");
          } else if (currentPercent <= 25 && currentPercent > 0) {
            refreshColorsFromAll()
            animateColor($(".moderate"), $(".high"));
            $(".bar__status").css("color", "#F36060").text("High");
          } else if (currentPercent == 0) {
            animateColor($(".high"), $(".critical"));
            $(".bar__status").css("color", "red").text("Critical");
          }
        },
      }
    );

    $({ barChartCount: 0 }).animate(
      { barChartCount: barPercent },
      {
        easing: "swing",
        duration: 1640,
        step: function () {
          $(".bar-value").text(Math.ceil(this.barChartCount));
        },
        complete: function () {},
      }
    );

    function animateColor(remove, add) {
      remove.removeClass("color");
      add.addClass("color");
    }
  }, []);

  useEffect(() => {
    if (counter) {
      setModalOpen(false);
      setInterval(() => {
        setModalOpen(true);
      }, 100);
    }
  }, [counter]);

  const handleCreditModal = () => {
    setShowCreditModal(true);
  };

  // useEffect(() => {
  //   if (reportCounter > 1) {
  //     setReportModalOpen(false)
  //     setInterval(() => {
  //       setReportModalOpen(true)
  //     }, 100)
  //   }
  // }, [reportCounter])
  const setSupportState = () => {
    // if (supportCounter > 1) {
    setSupportModalOpen(false);
    setInterval(() => {
      setSupportModalOpen(true);
    }, 100);
    // }
  };
  return (
    <Fragment>
      {/* {reportModalOpen ? <ModalDynamic setShow={true} modalData={<IncidentResponseForm />} modalHeader={"Report an Incident"} /> : ""} */}

      {supportModalOpen ? (
        <ParentChildModal
          ParentComponent={<TicketsView />}
          ChildComponent={<IncidentResponseForm type={"support"} />}
          buttonText={"+ Create Ticket"}
          modalHeader={"Support Tickets"}
          childHeader={"Create Ticket"}
          setShow={true}
          showButton={true}
        />
      ) : (
        ""
      )}

      {modalOpen && modalData ? (
        <ModalDynamic
          setShow={true}
          modalData={<CyberThreatDetails details={modalData} />}
          modalHeader={"CyberThreat Level Details"}
        />
      ) : (
        ""
      )}

      <Row>
        <Container>
          <Header margin="8px" />

          <br />
          <br />
          <br />

          <Col xs={12}>
            <h1
              style={{
                paddingTop: "6px",
                fontFamily: "Archivo",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "17px",
                color: "#101010",
              }}
            >
              CyberThreat Level
            </h1>
            {
              window.location.pathname === "/ceo/dashboard" ? 
                  <ThreatLevel
                    threatNumber={threatNumber}
                    id="dashboard-cyber-threat"
                    showDrillDownModal={true}
                  /> 
                :
                  <ThreatLevel
                  threatNumber={threatNumber}
                  id="dashboard-cyber-threat"
                  showDrillDownModal={true}
              />
            }
          </Col>
        </Container>
        <Col xs={12} className=" btmSideBar" style={{ marginBottom: "1em" }}>
          {/* <Card border="" className="threat_card"> */}
          {/* <Card.Body>
              <Card.Title>Threat Details:</Card.Title>
              <ul>
                <li className="threat_crit">
                  <span className="threat_text crit">
                    Critical Error:{" "}
                    {dbObj != 0 ? dbObj.CommonComponents.BtnThreatCritical : 0}
                  </span>
                </li>
                <li className="threat_attention">
                  <span className=" threat_text needsatt">
                    Needs Attention:{" "}
                    {dbObj != 0 ? dbObj.CommonComponents.BtnThreatNeedsAtt : 0}
                  </span>
                </li>
                <li className="threat_good">
                  <span className=" threat_text looksgood">
                    Looks Good:{" "}
                    {dbObj != 0 ? dbObj.CommonComponents.BtnThreatLooksGood : 0}
                  </span>
                </li>
                <li>
                  <a
                    href=".."
                    className="threat_link pt-5"
                    onClick={(event) => {
                      callScoreAPI(4);
                      setCounter(counter + 1);
                      setStateModal(event);
                    }}
                  >
                    Threat Details <FiChevronRight />
                  </a>
                </li>
              </ul>
            </Card.Body> */}
          {/* </Card> */}
          {/* Pro service Block */}
          {/* <Card border="" className="threat_card mt-3">
            <Card.Body>
              <div className="d-flex justify-content-between proServiceCredit">
                <p>Pro Services Credit</p>
                <span onClick={handleCreditModal} style={{ cursor: "pointer" }}>
                  Free Credit{" "}
                  <img
                    alt=""
                    src="/images/dashboard/notification.svg "
                    className="mb-2"
                  />
                </span>
              </div>
              <p className="proServicePrice">
                {dbObj.SubscriptionDetail.ProServiceCredit}
              </p>
              <div className="mt-5">
                <p className="greyText">Now you can get for free</p>
                <div className="d-flex justify-content-between">
                  <img alt="" src="/images/dashboard/pro_1.svg" />
                  <img alt="" src="/images/dashboard/pro_2.svg" />
                  <img alt="" src="/images/dashboard/pro_3.svg" />
                  <img alt="" src="/images/dashboard/pro_4.svg" />
                  <img alt="" src="/images/dashboard/pro_5.svg" />
                  <img alt="" src="/images/dashboard/pro_6.svg" />
                  <img alt="" src="/images/dashboard/pro_7.svg" />
                </div>
              </div>
              <div className="proServiceBtn">
                <Link to="/pro_services">
                  <Button
                    className="w-100 submit-btn mt-16px "
                    href="/pro_services"
                    style={{ padding: "5px 23px", borderRadius: "4px" }}
                    type="submit"
                  >
                    Pro Services
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
      {showCreditModal ? (
        <CreditModal
          show={showCreditModal}
          closeModal={() => setShowCreditModal(false)}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default SideBar;
