import { param } from "jquery";
import "./deleteModal.scss";
import React, { useState } from "react";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";

export const DeleteModal = ({
  show,
  hideModal,
  deleteMessage,
  deleteTask,
  deleteSection,
  deleteType,
  deleteStatus,
  deleteComment,
  deleteScenario,
  archiveTask,
  archiveStatus,
  selectedTaskId,
  deleteSentQuestionnaire,
  deleteQuestionnaireTemplate,
  confirmQuestion,
  deleteMspFunction,
  deleteMspSellConfig,
  deleteCategorySubCategory,
  ID,
  deletePromise,
}) => {
  const [loading, setLoading] = useState(false);
  function ArchiveTaskToggle() {
    setLoading(true);
    archiveTask(archiveStatus, selectedTaskId).then((res) => {
      setLoading(false);
      hideModal();
    });
  }

  return (
    <Modal
      show={show}
      dialogClassName="delete-user-modal"
      className=" "
      aria-labelledby="delete-user-modal"
      centered
      onHide={hideModal}
    >
      {/* <Modal.Header>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div></div>
          <div className="f-500 ml-3 f-17">Are you sure?</div>
          <img
            alt=""
            src="/images/user-detail-close.svg"
            className="mr-3 pointer"
            onClick={hideModal}
          />
        </div>
      </Modal.Header> */}

      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="permission mb-1 mt-3">
              {confirmQuestion ?? "Are you sure ?"}
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex align-items-center my-4 f-500 f-15 justify-content-start">
              {deleteMessage}
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end pb-2">
          <Button className="cancel-btn" onClick={hideModal}>
            No, Cancel
          </Button>
          <Button
            className="delete-btn"
            disabled={loading}
            onClick={() => {
              if (deleteType == "task") {
                deleteTask();
                hideModal();
              }
              if (deleteType == "section") {
                deleteSection();
                hideModal();
              }
              if (deleteType == "status") {
                deleteStatus();
                hideModal();
              }
              if (deleteType == "comment") {
                deleteComment();
                hideModal();
              }
              if (deleteType == "deleteScenario") {
                setLoading(true);
                deleteScenario().then((response) => {
                  setLoading(false);
                  hideModal();
                });
              }

              if (deleteType == "archive") {
                ArchiveTaskToggle();
              }
              if (deleteType == "unarchive") {
                ArchiveTaskToggle();
              }
              if (deleteType === "deleteSentQuestionnaire") {
                deleteSentQuestionnaire();
                hideModal();
              }
              if (deleteType === "deleteQuestionnaireTemplate") {
                deleteQuestionnaireTemplate();
                hideModal();
              }
              if (deleteType === "mspSell") {
                setLoading(true);
                deleteMspFunction(
                  deleteMspSellConfig.deleteId,
                  deleteMspSellConfig.pageEnum
                ).then(() => {
                  setLoading(false);
                  hideModal();
                });
              }
              if (deleteType === "mspProduct") {
                setLoading(true);
                deleteMspFunction(ID, 663).then(() => {
                  setLoading(false);
                  hideModal();
                });
              }
              if (deleteType === "categoryOrSubcategory") {
                deleteCategorySubCategory();
                hideModal();
              }
              if (deleteType === "deleteInvoice") {
                setLoading(true);
                deletePromise(ID)
                  .then(() => {
                    setLoading(false);
                    hideModal();
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }
            }}
          >
            {/* For deleting things */}
            {deleteType !== "archive" &&
              deleteType !== "unarchive" &&
              (loading ? (
                <>
                  <Spinner
                    size="sm"
                    className="mr8px ml-2 mr-2"
                    animation="border"
                    variant="light"
                  />
                  Deleting
                </>
              ) : (
                "Yes, Delete"
              ))}
            {/* For Archiving */}
            {deleteType === "archive" &&
              (loading ? (
                <>
                  <Spinner
                    size="sm"
                    className="mr8px ml-2 mr-2"
                    animation="border"
                    variant="light"
                  />
                  Archiving..
                </>
              ) : (
                "Archive"
              ))}
            {/* For Unarchiving */}
            {deleteType === "unarchive" &&
              (loading ? (
                <>
                  <Spinner
                    size="sm"
                    className="mr8px ml-2 mr-2"
                    animation="border"
                    variant="light"
                  />
                  Unarchiving..
                </>
              ) : (
                "Unarchive"
              ))}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const CustomDeleteModal = ({
  show,
  hideModal,
  deleteHeaderIcon,
  deleteHeaderText,
  deleteBodyText,
  deleteFooterText,
  deleteButtonClass,
  deleteFunction,
  deleteParams,
  deleteType,
  deleteButtonText,
  customBody,
  refreshData,
  processingText,
  callBack,paramId,callBackData,
  cancelButtonTitle,
  cancelButtonFunction,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      dialogClassName="delete-user-modal"
      className=" "
      aria-labelledby="delete-user-modal"
      centered
      onHide={hideModal}
    >
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="f-16 f-500 mb-1 mt-1">
              {deleteHeaderIcon && (
                <img src={deleteHeaderIcon} alt="" className="mr-2" />
              )}

              {deleteHeaderText ?? "Are you sure ?"}
            </div>
          </Col>
          <Col xs={12}>
            {customBody ? (
              customBody
            ) : (
              <>
                <div className="d-flex align-items-center my-2 f-500 f-15 justify-content-start">
                  {deleteBodyText}
                </div>
                <div className="d-flex align-items-center m f-500 f-15 justify-content-start">
                  {deleteFooterText}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end ">
          <Button className="cancel-btn" onClick={() => {
            if (cancelButtonFunction) {
              cancelButtonFunction();
            }
              hideModal();
            }}>
            {cancelButtonTitle ? cancelButtonTitle : 'No, Cancel'}
          </Button>
          <Button
            className={deleteButtonClass ? deleteButtonClass : "delete-btn"}
            disabled={loading}
            onClick={() => {
              if (deleteType==="generic") {
                setLoading(true)
                deleteFunction(paramId).then(() => {
                  setLoading(false);
                  hideModal();
                  if(callBack){
                    callBack(callBackData ?? "")
                  }})
                
              }
              else if (deleteType === "warningClose") {
                deleteFunction(deleteParams.deleteId, deleteParams.deleteId2);
                // .then(() => {
                //   setLoading(false);
                //   hideModal();
                // });
                setLoading(false);
                hideModal();
              }
              if (deleteType === "unlinkMspService") {
                setLoading(true);

                deleteFunction(deleteParams.deleteId, deleteParams.deleteId2);
                // .then(() => {
                //   setLoading(false);
                //   hideModal();
                // });
                setLoading(false);
                hideModal();
              }

              if (deleteType === "deleteMspSerie") {
                setLoading(true);
                deleteFunction(deleteParams.deleteId);
                // .then(() => {
                //   setLoading(false);
                //   hideModal();
                // });
                setLoading(false);
                hideModal();
              }
              if (deleteType === "deleteLinkedCategory") {
                setLoading(true);
                deleteFunction(deleteParams.deleteId)
                  .then(() => {
                    setLoading(false);
                    hideModal();
                  })
                  .catch((err) => {
                    setLoading(false);
                  });
              }
              if (deleteType === "deleteCyberInsurancePolicy") {
                setLoading(true);
                deleteFunction(deleteParams.deleteId)
                  .then(() => {
                    setLoading(false);
                    hideModal();
                    if(callBack){
                      callBack()
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                  });
              }
              if (deleteType === "switchInvoicesTabInMspTechSpend") {
                deleteFunction(deleteParams);
                hideModal();
              }
              if (deleteType === "linkRelinkInMspTechSpend") {
                setLoading(true);
                deleteFunction({
                  linkStatus: deleteParams.linkStatus,
                  objId_invoice: deleteParams.objId_invoice,
                }).then((response)=>{
                  hideModal({...response,...deleteParams});
                  setLoading(false);

                })
              }
            }}
          >
            {/* For deleting things */}
            {loading ? (
              <>
                <Spinner
                  size="sm"
                  className="mr8px ml-2 mr-2"
                  animation="border"
                  variant="light"
                />
                {processingText ? processingText : "Deleting"}
              </>
            ) : deleteButtonText ? (
              deleteButtonText
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
