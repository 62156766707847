import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
const DowngradeModal = ({ show, hideModal, priceId, Refreshpage }) => {
  const { authTokens } = useAuth();
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [downgrading, setDowngrading] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      getData();
    }
  }, [show]);
  async function getData() {
    const result = await getAPIData("504", authTokens, {
      optionEnum1: priceId,
      optionEnum2: 0,
    });

    if (result.mr.Success) {
      setLoading(false);
      setModalData(result);
    }
  }

  async function DowngradePlan() {
    setDowngrading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authTokens}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
    };
    const result = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/DowngradeSubscription_v2?priceId=${priceId}&frequency=${
        modalData.IsMonthly ? 1 : 2
      }`,
      requestOptions
    ).then((response) => response.json());
    if (result?.mr?.Success) {
      if (result.AccessKey !== null) {
        localStorage.setItem(
          "user_MessageObj",
          JSON.stringify(result.AccessKey)
        );
      }
      setDowngrading(false);
      CallToast("Downgraded successfully", false, false, "v2style");
      Refreshpage();
      hideModal();
      
    } else {
      setDowngrading(false);
      CallToast("Something went wrong", false, false, "v2style");
      hideModal();
    }
  }
  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="downgrade-modal"
      className=""
      aria-labelledby="downgrade-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="p-5">
        {
          // loading
          loading && (
            <div className="d-flex align-items-center justify-content-center pt-3">
              {/* <Loader /> */}

              <ContentLoader
                speed={2}
                width={"100%"}
                height={"100%"}
                viewBox="0 0 1500 390"
                backgroundColor="#F6F8FB"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="6" ry="6" width="60%" height="40" />
                <rect x="0" y="110" rx="8" ry="8" width="98%" height="150" />
                <rect x="0" y="350" rx="6" ry="6" width="80%" height="40" />
                <rect x="0" y="400" rx="6" ry="6" width="30%" height="40" />
              </ContentLoader>
            </div>
          )
        }
        {!loading && (
          <>
            <div className="heading mb-3">{modalData.Title}</div>
            <div className="option-wrapper d-flex justify-content-between align-items-start">
              <div>
                <p className="title m-0">{modalData?.PackageName} </p>
                <p className="subtitle m-0"></p>
              </div>
              <div className="title">{modalData.Cost} </div>
            </div>

            <p className="title mt-3">{modalData?.Description}</p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            // height={"100%"}
            viewBox="0 0 1500 100"
            backgroundColor="#F6F8FB"
            foregroundColor="#ecebeb"
          >
            <rect x="65%" y="0" rx="6" ry="6" width="14%" height="80" />
            <rect x="82%" y="0" rx="6" ry="6" width="17%" height="80" />
          </ContentLoader>
        ) : (
          <div className="d-flex justify-content-end">
            <Button className="cancel-btn" onClick={hideModal}>
              Cancel
            </Button>
            <Button className="downgrade-btn" onClick={DowngradePlan}>
              {downgrading ? (
                <>
                  <Spinner
                    size="sm"
                    animation="border"
                    role="status"
                    className="mx-2"
                    variant="light"
                  />
                  Downgrading...
                </>
              ) : (
                "Downgrade"
              )}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DowngradeModal;
