import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { getAPIData, postData, postJsonData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import EnquiryModal from "../../SystemSettings/Subscriptions/EnquiryModal";
import DowngradeModal from "./subscriptionModals/DowngradeModal";
import PaymentModal from "./subscriptionModals/PaymentModal";
import PlansModal from "./subscriptionModals/PlansModal";
import SubscriptionPageLoader from "./SubscriptionPageLoader";
import "./subscriptions.scss";
import { viewOnlyAccess } from "../../App/App";
 

const SaasSubscriptionV2 = () => {
  const { authTokens } = useAuth();
  const [plansModal, setPlansModal] = useState(false);
  const [downgradeModal, setDowngradeModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [annual, setAnnual] = useState(false);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState("");
  const [isEnquiry, setIsEnquiry] = useState(false);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [priceId, setPriceId] = useState("");
  const [typeOfProduct, setTypeOfProduct] = useState("");
  const [showPlans, setShowPlans] = useState(false);

  let oldVersion = new URLSearchParams(window.location.search).get("old");

  useEffect(() => {
    try {
      var parentWindow = window.opener;

      // send message to parent window
      parentWindow.postMessage("3DS-secure", "*");
      const urlParams = new URLSearchParams(window.location.search);
      const paymentIntent = urlParams.get("payment_intent");
      if (paymentIntent) {
        window.close();
      }
    } catch (err) {}


  
  }, []);

  useEffect(() => {
    setMainPageLoading(true);
    // getPageData();
    getPageData1();
    getSubscriptionPageData()
  }, []);

  // API integration
  const [pageData, setPageData] = useState({
 
  });

  const hidePlansModal = () => {
    setPlansModal(false);
  };
  const showDowngradeModal = () => {
    setDowngradeModal(true);
  };
  const hideDowngradeModal = () => {
    setDowngradeModal(false);
  };

  const showPaymentModal = () => {
    setPaymentModal(true);
  };
  const hidePaymentModal = () => {
    setPaymentModal(false);
  };

  async function getPageData() {
    await getAPIData("193", authTokens).then((response) => {
      if (response.mr.Success) {
        setPageData(response);
      }
    });
  }

  // Subscription v2 setup
  const [planDetails, setPlanDetails] = useState([]);
  const [subscriptionData, setsubscriptionData] = useState([]);
const [firstLoad, setFirstLoad] = useState(true)
  async function getPageData1() {
    // await getAPIData("501", authTokens).then((response) => {
    //   setMainPageLoading(false);
    //   if (response?.mr?.Success) {
    //     setPageData(response)
    //     setPlanDetails(response?.planDetail?.features);
    //     setsubscriptionData(response?.subscriptions);
    //     const subscriptionBtn = document.querySelector(
    //       ".subscription-details-btn"
    //     );
    //     if (subscriptionBtn && firstLoad) {
    //       subscriptionBtn.click();
    //       setFirstLoad(false)
    //     }
    //   }
      
    // });
  }
  async function getSubscriptionPageData() {
    await getAPIData("521", authTokens).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        
        setPageData(response)
        setPlanDetails(response?.planDetail?.features);
        setsubscriptionData(response?.subscriptions);
        const subscriptionBtn = document.querySelector(
          ".subscription-details-btn"
        );
        if (subscriptionBtn && firstLoad) {
          subscriptionBtn.click();
          setFirstLoad(false)
        }
      }
      
    });
  }

  async function handleSubmitIntent(paymentIntent, paymentClientSecret) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "HandleStripePayment", {
        payment_intent: paymentIntent,
        payment_intent_client_secret: paymentClientSecret,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            if (response?.data?.requires_action) {
              openChildWindow(response?.data?.redirectUrl);
            } else {
              resolve(response);
            }
          } else {
            reject("error");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function openChildWindow(_url) {
    window.addEventListener("message", function redirectedToUrlEvent(event) {
      console.log(event?.source.location.href + event?.source.location.search);
      const urlParams = new URLSearchParams(event?.source.location.search);
      const paymentIntent = urlParams.get("payment_intent");
      const paymentClientSecret = urlParams.get("payment_intent_client_secret");

      handleSubmitIntent(paymentIntent, paymentClientSecret);
    });

    window.open(_url, "childWindow", "width=600,height=600");

    // listen for messages from child window
  }

  async function SubmitPaymentDetails(dataToPost) {
    return new Promise((resolve, reject) => {
      postJsonData(authTokens, "SubmitPaymentDetail", dataToPost)
        .then((response) => {
          if (response?.mr?.Success) {
            if (response?.data?.requires_action) {
              openChildWindow(response?.data?.redirectUrl);
            } else {
              resolve(response);
            }
          } else {
            reject("error");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  return (
    <>
      {mainPageLoading && <SubscriptionPageLoader />}

      <div
        className={`subscription-wrapper  ${mainPageLoading ? "d-none" : ""}  `}
        id="subscriptionWrappper"

       
        style={{
          pointerEvents:viewOnlyAccess("AccessState_SystemSettings") ? "none"  :"all"
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="heading">Subscriptions</div>
          <div className="d-flex align-items-center option-btn-wrapper">
            {/* <Button
              className={`${annual ? "deactive" : "active"}`}
              onClick={(e) => setAnnual(!annual)}
            >
              {annual ? "Pay" : ""} Monthly
            </Button>

            <Button
              className={`${annual ? "active" : "deactive"}`}
              onClick={(e) => setAnnual(!annual)}
            >
              {!annual ? "Pay" : ""} Annual&nbsp;<span>-10%</span>
            </Button> */}
          </div>
        </div>
        <p className=" f-600 mt-2">Subscription Plans</p>
        <p className="subtitle">


          
          {pageData?.planSubTitle}
          
          
          </p>

        {/* Coloumns */}
 
        <div className={`${ ""}`}>
          <div
            className="w-100 bg-grey radius-8 d-flex justify-content-between right-border-parent"
            style={{
              height: "144px",
            }}
          >
            {subscriptionData?.map((subscription, subscriptionIndex) => {
              return (
                <div
                  style={{
                    flex: 1,
                  }}
                  className="px-3 py-3 d-flex justify-content-between flex-column right-border-child"
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div
                      className={`f-600   ${
                        subscription?.selected ? "f-blue" : "f-black"
                      } `}
                    >
                      {subscription?.name}
                    </div>
                    <div>
                      {subscription.selected && (
                        <img alt="" src="/images/settings-v2/blue-tick.svg" />
                      )}
                    </div>
                  </div>

                  {subscription?.name?.toLowerCase() === "free trial" ? (
                    <div>
                    <span className="f-20 f-500">
                   {subscription?.monthlyPrice?.split(" of ")[0]}
                    </span>
                     
                    <span className="f-500 f-darkgrey">&nbsp;of&nbsp;
                   {subscription?.monthlyPrice?.split(" of ")[1]}
                    {/* of 14 days */}
                    </span>
                  </div>
                  ) : (
                    <div>
                      <span className="f-20 f-500">
                        {
                          subscription?.[
                            `${annual ? "annualPrice" : "monthlyPrice"}`
                          ]?.split("/")[0]
                        }
                      </span>
                      {subscription?.price !== "£0" && <></>}
                      <span className="f-500 f-darkgrey">
                        /
                        {
                          subscription?.[
                            `${annual ? "annualPrice" : "monthlyPrice"}`
                          ]?.split("/")[1]
                        }
                      </span>
                    </div>
                  )}

                  <div>
                    <Button
                      className={`hg-blue-btn w-100  ${
                        !subscription.IsEnabled ? "disabled-btn" : ""
                      }  
                      ${
                        viewOnlyAccess("AccessState_SystemSettings") ? "disabled-btn" : ""
                      }
                      `}
                      onClick={() => {
                        if (!viewOnlyAccess("AccessState_SystemSettings")) {
                          window.Intercom('showNewMessage');
                          return
                          if (
                            subscription?.action?.toLowerCase() === "downgrade"
                          ) {
                            setSubscriptionPlanId(subscription?.objId_Price);
                            showDowngradeModal();
                          }
                          if (subscription?.action?.toLowerCase() === "upgrade") {
                            setPriceId(subscription?.objId_Price);
                            showPaymentModal();
                            setTypeOfProduct("subscription");
                          }
  
                          if (subscriptionIndex === 3) {
                            setIsEnquiry(true);
                          }
                        }
                      }}
                      // disabled={
                      //   subscription.buttonText?.toLowerCase() === "downgrade"
                      // }
                      style={{
                        cursor: `${
                          subscription.buttonText?.toLowerCase() === "downgrade"
                            ? "no-drop"
                            : ""
                        }`,
                      }}
                    >
                      {
                        (subscription.IsEnabled && viewOnlyAccess("AccessState_SystemSettings")) && 
                        <img
                          alt=''
                          className='lockerDarkGreyImage mx-2 mb-1'
                          src='/images/settings-v2/locker-grey.svg'
                        />
                      }
                      {subscription.buttonText}{" "}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Plan details button UI */}

          <div className="w-100 d-flex align-items-center justify-content-between mt-4 mb-3">
            <div className="f-500">Plans details</div>
            <div>
              <Button
                className="hg-cancel-btn f-500 subscription-details-btn"
                onClick={() => {
                  setShowPlans(!showPlans);
                  let panel = document.querySelector(".plans-wrapper");
                  if (panel?.style?.maxHeight) {
                    panel.style.maxHeight = null;
                  } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                  }
                }}
              >
                {showPlans ? "Hide plans details" : "Show plans details"}
              </Button>
            </div>
          </div>

          {/*Plan details-UI*/}

          <div className="bg-grey radius-8 mb-4 plans-wrapper">
            <div className={`updated-plan-details-saas-grid ${planDetails?.[0]?.plans?.length===2?   "two" :"three"}`}>
              <div className="f-500 f-darkgrey">Features</div>

              {planDetails?.[0]?.plans?.length===3 &&<div className="f-600 d-flex align-items-center justify-content-center">
              Free Trial
              </div>}
              <div className="f-600 d-flex align-items-center justify-content-center">
              Standard License
              </div>
              <div className="f-600 d-flex align-items-center justify-content-center">
              Premium License
              </div>
              {/* <div className="f-600 d-flex align-items-center justify-content-center">
                Freemium
              </div>
              <div className="f-600 d-flex align-items-center justify-content-center">
                Enterprise
              </div> */}
            </div>
            {planDetails?.map((planOption, planOptionIndex) => {
              return (
                <div className={`updated-plan-details-saas-grid ${planDetails?.[0]?.plans?.length===2 ?   "two" :"three"}`}>
                  <div className="f-500 f-black d-flex align-items-center justify-content-start">
                    {planOption?.name}
                  </div>
                  <div className="f-600 d-flex align-items-center justify-content-center text-center">
                    {planOption?.plans[0]?.image ? (
                      <img alt="" src={planOption?.plans[0]?.image} />
                    ) : (
                      planOption?.plans[0]?.text
                    )}
                  </div>
                  <div className="f-600 d-flex align-items-center justify-content-center  text-center">
                    {planOption?.plans[1]?.image ? (
                      <img alt="" src={planOption?.plans[1]?.image} />
                    ) : (
                      planOption?.plans[1]?.text
                    )}
                  </div>
            {  planDetails?.[0]?.plans?.length===3 &&    <div className="f-600 d-flex align-items-center justify-content-center text-center">
                    {planOption?.plans[2]?.image ? (
                      <img alt="" src={planOption?.plans[2]?.image} />
                    ) : (
                      planOption?.plans[2]?.text
                    )}
                  </div>}
                  {/* <div className="f-600 d-flex align-items-center justify-content-center  text-center">
                    {planOption?.plans[3]?.image ? (
                      <img alt="" src={planOption?.plans[3]?.image} />
                    ) : (
                      planOption?.plans[3]?.text
                    )}
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>

        {/* 
        
        
        OLD DESIGN----->>>>>>
      

        
        */}
         

         

        <PaymentModal
          show={paymentModal}
          hideModal={hidePaymentModal}
          planId={priceId}
          Refreshpage={getPageData1}
          isAnnualy={annual}
          typeOfProduct={typeOfProduct}
          SubmitPaymentDetails={SubmitPaymentDetails}
        />
        <DowngradeModal
          show={downgradeModal}
          hideModal={hideDowngradeModal}
          priceId={subscriptionPlanId}
          Refreshpage={getPageData1}
        />
        <PlansModal show={plansModal} hideModal={hidePlansModal} />
        <EnquiryModal
          show={isEnquiry}
          title="Enquiry"
          closeModal={setIsEnquiry}
        />
      </div>
    </>
  );
};

export default SaasSubscriptionV2;
const SubscriptionData = [
  {
      "name": "Free Trial",
      "action": "upgrade",
      "objId_Price": 3068890,
      "selected": true,
      "buttonText": "Current",
      "monthlyPrice": "£395/mo",
      "annualPrice": "£4275/year",
      "IsEnabled": false
  },
  {
      "name": "Standard License",
      "action": "Upgrade",
      "objId_Price": 3652247,
      "selected": false,
      "buttonText": "Upgrade",
      "monthlyPrice": "£95/mo",
      "annualPrice": "£1270/year",
      "IsEnabled": true
  },
  {
      "name": "Premium License",
      "action": "Upgrade",
      "objId_Price": 3070303,
      "selected": false,
      "buttonText": "Upgrade",
      "monthlyPrice": "£195/mo",
      "annualPrice": "£2340/year",
      "IsEnabled": true
  },
  
];

const PlansData = [
  {
    name: "Cyber KPIs & Dashboards",
    plans: [
      {
        plan: "Premium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Basic",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Freemium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Enterprise",
        image: "/images/black-tic.svg",
        text: null,
      },
    ],
  },
  {
    name: "Security Reports (coming soon)",
    plans: [
      {
        plan: "Premium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Basic",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Freemium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Enterprise",
        image: "/images/black-tic.svg",
        text: null,
      },
    ],
  },
  {
    name: "Technology Spend",
    plans: [
      {
        plan: "Premium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Basic",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Freemium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Enterprise",
        image: "/images/black-tic.svg",
        text: null,
      },
    ],
  },
  
  {
    name: "Governance & Resilience",
    plans: [
      {
        plan: "Premium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Basic",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Freemium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Enterprise",
        image: "/images/black-tic.svg",
        text: null,
      },
    ],
  },
  {
    name: "Attack Surface Management",
    plans: [
      {
        plan: "Premium",
        image: null,
        text: "Read only",
      },
      {
        plan: "Basic",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Freemium",
        image: "/images/black-tic.svg",
        text: null,
      },
      {
        plan: "Enterprise",
        image: "/images/black-tic.svg",
        text: null,
      },
    ],
  },
  
   
  {
    name: "Integrations",
    plans: [
        {
          plan: "Premium",
          image: "/images/grey-cross.svg",
          text: null,
        },
        {
          plan: "Basic",
          image: "/images/black-tic.svg",
          text: null,
        },
        {
          plan: "Freemium",
          image: "/images/black-tic.svg",
          text: null,
        },
        {
          plan: "Enterprise",
          image: "/images/black-tic.svg",
          text: null,
        },
      ],
  },
  {
    name: "Progress Tracker",
    plans: [
        {
          plan: "Premium",
          image: "/images/grey-cross.svg",
          text: null,
        },
        {
          plan: "Basic",
          image: "/images/black-tic.svg",
          text: null,
        },
        {
          plan: "Freemium",
          image: "/images/black-tic.svg",
          text: null,
        },
        {
          plan: "Enterprise",
          image: "/images/black-tic.svg",
          text: null,
        },
      ],
  },
  {
    name: "Action Centre",
    plans: [
        {
          plan: "Premium",
          image: "/images/grey-cross.svg",
          text: null,
        },
        {
          plan: "Basic",
          image: "/images/black-tic.svg",
          text: null,
        },
        {
          plan: "Freemium",
          image: "/images/black-tic.svg",
          text: null,
        },
        {
          plan: "Enterprise",
          image: "/images/black-tic.svg",
          text: null,
        },
      ],
  },
  {
    name: "Supply Chain Management",
    plans: [
        {
          plan: "Premium",
          image: null,
          text: "Participate Only",
        },
        {
          plan: "Basic",
          image: null,
          text: "50 Suppliers",
        },
        {
          plan: "Freemium",
          image: "/images/black-tic.svg",
          text: null,
        },
        {
          plan: "Enterprise",
          image: "/images/black-tic.svg",
          text: null,
        },
      ],
  },
  
  
 
  
];
