import React, { useState, useEffect, Fragment } from "react";
import "./RiskyUsers.css";
import { Row, Col, Spinner } from "react-bootstrap";
import CC from "../CyberCompliance/CyberCompliance";
import ThreatLevel from "../ThreatLevel/ThreatLevel";
import CyberScore from "../CyberScore/CyberScore";
import TechTable from "../TechTable/TechTable";
import LoadingSpinner from "../Spinner/Spinner";
import ClipLoader from "react-spinners/ClipLoader";
import { getAPIData, getRequestOptions } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import ContentLoader from "react-content-loader";

const RiskyUsers = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const [apiData, setApiData] = useState();

  useEffect(() => {
    populateTechTable();
  }, []);
  async function populateTechTable() {
    let data = await getAPIData("28", authTokens, {
      optionEnum1:props.clientId ?? 0
    });
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setApiData();
      data = await getAPIData("28", data.authToken);
    } else {
      let processedData = [];
      for (let i = 0; i < data.RiskyUserList.length; i++) {
        const element = data.RiskyUserList[i];
        processedData.push([
          { text: element.User },
          {
            text: element.Campaigns.map((element) => {
              return { text: element };
            }),
          },
          {
            text: element.Training.map((element) => {
              return { text: element };
            }),
          },
          {
            text: element.Date.map((element) => {
              return { text: element };
            }),
          },
        ]);
      }

      // const processedData = data.RiskyUserList.map(element => {return [{text: element}]});
      setApiData([
        {
          header: [
            { text: "User Name" },
            { text: "Campaigns" },
            { text: "Training" },
            { text: "Date" },
          ],
        },
        {
          data: processedData,
        },
      ]);
    }
  }

  if (apiData) {
    return (
      <Fragment>
        <div className="csd_background fullHeight">
          <Row>
            <Col xs={12} style={{ padding: "1em 2em 0em 2em" }}>
              <TechTable
                bgColor={"#40D88E"}
                hdTextColor={"white"}
                data={apiData}
              />
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  } else {
    return (
      <div className="collapContainer collapContainer50">
        <ContentLoader
          speed={2}
          width={900}
          height={500}
          viewBox="0 0 900 500"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="13" y="16" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="90" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="164" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="238" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="312" rx="0" ry="0" width="900" height="52" />
        </ContentLoader>
      </div>
    );
  }
};

export default RiskyUsers;
