import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Header from "../Header";
import ConnectedTools from "./ConnectedTools";
import AppMarketPlace from "./AppMarketPlace";
import "./integrationsv2.scss";
import IntegrationDetailsPage from "./IntegrationDetailsPage";
import ConnectIntegrationModal from "../MSPv2/integrations/ConnectIntegrationModal";
import SuggestIntegrationModal from "./SuggestIntegrationModal";
import { useHistory } from "react-router-dom";
import { TrackUser } from "../../Utils/SegmentFunctions";

import { NoAccessUI, viewOnlyAccess } from "../App/App";
import { getMenuItemId } from "../SideNav/SideNav";

const IntegrationsV2 = () => {
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  // const [mainPageLoading, setMainPageLoading] = useState(false);
  const userDetails = useSelector((state) => state.user?.userDetail);
  let selectedTab = new URLSearchParams(window.location.search).get(
    "selectedTab"
  );
  const history = useHistory();
  const [sortAsc, setSortAsc] = useState(true);
  const [showRecommendedList, setShowRecommendedList] = useState(true);
  const [showComingSoonlist, setShowComingSoonlist] = useState(true);

  const appMarketPlace = useRef(null);
  const [connectIntegrationModal, setConnectIntegrationModal] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [suggestIntegrationModal, setSuggestIntegrationModal] = useState(false);
  const [defaultSelectedTab, setDefaultSelectedTab] =
    useState("appMarketPlace");
  const [page, setPage] = useState({
    type: "home",
    name: "",
    data: {},
  });
  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip_access" id="tooltip">
      This feature is managed by your service provider
    </Tooltip>
  );

  const menuItemId = getMenuItemId(window.location.pathname)
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  return (
    <>
    {
      menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_Integrations === 0) ? <>
      {
        userPortalRole === 'MSP' ? 
          <NoAccessUI/> : <NoAccessUI type={userPortalRole}/>
      }
      </> : <>
        <React.Fragment>
          <Row className="fullHeight integrationsV2 m-0" id="integration-v2-screen">
            <Col xs={12} className="px-0">
              <div>
                <header
                  className="d-flex justify-content-between navbar sticky-top pb-0"
                  style={{
                    paddingTop: ` ${
                      userDetails?.AccountVerified === false ||
                      userDetails?.ShowConfirmVerified === true
                        ? "3em"
                        : "0.8em"
                    } `,
                    background: "#FFFFFF",
                    position: "fixed",
                    width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                    zIndex: 6,

                    paddingLeft: "2rem",
                    // borderBottom: `1px solid #EBEDF1`,
                    paddingBottom: "8px",
                  }}
                >
                  <h3 className="f-16 f-600">Integrations</h3>

                  <Header />
                </header>
              </div>
              <div style={{ paddingTop: "5%" }}></div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={selectedTab ?? defaultSelectedTab}
              >
                <Row>
                  <Col
                    sm={12}
                    className={`tab-header d-flex  justify-content-between align-items-center  ${false}  `}
                  >
                    {/* Home screen left part */}
                    {page.type === "home" && (
                      <div className={`${true}`} style={{ marginLeft: "15px" }}>
                        <Nav
                          variant="pills"
                          className="flex-row"
                          onSelect={(e) => {
                            setDefaultSelectedTab(e);

                            history.push("/integrations");
                          }}
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="connectedTools" onClick={() => {}}>
                              Connected Tools
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item id="app-market-place">
                            <Nav.Link eventKey="appMarketPlace" onClick={() => {}}>
                              <img
                                alt=""
                                src="/images/icons/appmarketplace-icon.svg"
                                className="mr-1"
                              />{" "}
                              App Marketplace
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    )}
                    {page.type === "integrationDetails" && (
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex align-items-center pointer"
                          onClick={() => {
                            setPage({ type: "home", name: "" });
                          }}
                        >
                          <img
                            alt=""
                            src="/images/icons/arrowLeft.svg"
                            className="pointer ml-3"
                          />
                          <div className="f-grey f-500 ml-3 pointer">Back</div>
                        </div>

                        <div className="ml-4 f-16 f-500 f-black">{page.name}</div>
                      </div>
                    )}

                    {/* Right side items at top bar */}
                    {(defaultSelectedTab === "appMarketPlace" ||
                      selectedTab === "appMarketPlace") &&
                      page.type === "home" && (
                        <div className="d-flex align-items-center mr-3">
                          {/* Search input */}
                          <div className="mx-2 d-flex align-items-center">
                            <img
                              alt=""
                              src="/images/attack-surface/search-icon.svg"
                              className="pointer"
                              onClick={() => {}}
                            />
                            <input
                              type="text"
                              placeholder="Search.."
                              value={searchString}
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              onBlur={() => {
                                TrackUser("Tool Search Used");
                              }}
                              className={`border-none bg-tranparent pl-2`}
                            />
                          </div>

                          {/* Sort Dropdown */}
                          <div className="menu-dropdown header-menu-dropdown">
                            <Dropdown className="sort-dropdown">
                              <Dropdown.Toggle>
                                <div className="f-grey f-500">Sort</div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  {
                                    <Row>
                                      <Col xs={12} className=" pointer">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            setShowRecommendedList(
                                              !showRecommendedList
                                            );
                                            e.target
                                              .closest(".sort-dropdown")
                                              .click();
                                          }}
                                        >
                                          <img
                                            alt=""
                                            className={`mr-1 ${
                                              showRecommendedList
                                                ? ""
                                                : "visibility-hidden"
                                            }`}
                                            src="/images/attack-surface/grey-tik.svg"
                                          />
                                          Show Recommended
                                        </div>
                                      </Col>
                                      <Col xs={12} className="  pointer">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            setShowComingSoonlist(
                                              !showComingSoonlist
                                            );
                                            e.target
                                              .closest(".sort-dropdown")
                                              .click();
                                          }}
                                        >
                                          <img
                                            alt=""
                                            className={`mr-1 ${
                                              showComingSoonlist
                                                ? ""
                                                : "visibility-hidden"
                                            }`}
                                            src="/images/attack-surface/grey-tik.svg"
                                          />
                                          Show Coming soon
                                        </div>
                                      </Col>
                                      <hr
                                        className="m-0 mt-2 mb-2 w-75 mx-auto menu-hr "
                                        align="center"
                                      />
                                      <Col xs={12} className=" pointer ">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            setSortAsc(true);
                                            e.target
                                              .closest(".sort-dropdown")
                                              .click();
                                          }}
                                        >
                                          <img
                                            alt=""
                                            className={`mr-1 ${
                                              sortAsc ? "" : "visibility-hidden"
                                            }`}
                                            src="/images/attack-surface/grey-tik.svg"
                                          />
                                          Sort A to Z
                                        </div>
                                      </Col>
                                      <Col xs={12} className="pointer ">
                                        <div
                                          className="d-flex align-items-center justify-content-between drop-item"
                                          onClick={(e) => {
                                            setSortAsc(false);
                                            e.target
                                              .closest(".sort-dropdown")
                                              .click();
                                          }}
                                        >
                                          <div>
                                            <img
                                              alt=""
                                              className={`mr-1 ${
                                                !sortAsc ? "" : "visibility-hidden"
                                              }`}
                                              src="/images/attack-surface/grey-tik.svg"
                                            />
                                            Sort Z to A
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  }
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          {/* Request Tool Button */}
                          <OverlayTrigger
                            placement="left"
                            trigger={
                              viewOnlyAccess("AccessState_Integrations")
                                ? ["hover", "focus"]
                                : []
                            }
                            delay={{ show: 200, hide: 250 }}
                            overlay={
                              renderTooltip()
                              // <Tooltip id={`tooltip-1365`} className="custom_tooltip custom_tooltip_risk" style={{marginRight: '-650px'}} >
                              //   This feature is managed by your service provider
                              // </Tooltip>
                            }
                          >
                            <div>
                              <Button
                                className={`${
                                  viewOnlyAccess("AccessState_Integrations")
                                    ? "btn-disable"
                                    : "hg-cancel-btn"
                                }  `}
                                onClick={() => {
                                  setSuggestIntegrationModal(true);
                                  TrackUser("Suggest Integration clicked");
                                }}
                              >
                                {viewOnlyAccess("AccessState_Integrations") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/request-tool-icon.svg"
                                    className="mr-2"
                                  />
                                )}
                                Request a tool
                              </Button>
                            </div>
                          </OverlayTrigger>
                        </div>
                      )}
                  </Col>
                  {page.type === "home" && (
                    <Col sm={12}>
                      <Tab.Content>
                        {/* Suppliers */}
                        <Tab.Pane eventKey="connectedTools">
                          {(defaultSelectedTab === "connectedTools" ||
                            selectedTab === "connectedTools") && <ConnectedTools />}
                        </Tab.Pane>
                        <Tab.Pane eventKey="appMarketPlace">
                          {(defaultSelectedTab === "appMarketPlace" ||
                            selectedTab === "appMarketPlace") && (
                            <AppMarketPlace
                              searchString={searchString}
                              ref={appMarketPlace}
                              sortAsc={sortAsc}
                              showRecommendedList={showRecommendedList}
                              showComingSoonlist={showComingSoonlist}
                              
                            />
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  )}

                  {page.type === "integrationDetails" && (
                    <Col sm={12}>
                      <IntegrationDetailsPage />
                    </Col>
                  )}
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          <ConnectIntegrationModal
            show={connectIntegrationModal}
            hideModal={() => setConnectIntegrationModal(false)}
            
          />
          <SuggestIntegrationModal
            show={suggestIntegrationModal}
            hideModal={() => setSuggestIntegrationModal(false)}
            
          />
        </React.Fragment>
      </>
      }
    </>
  );
};

export default IntegrationsV2;
