import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAPIData, getRequestOptions } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { GreyLoader } from "../../../Theme/APILoaders";
import "./companySettingsModals.scss";
import SelectEmailUsersModal from "./SelectEmailUsersModal";

const ManageClientNotificationsModal = ({ show, hideModal, refresh }) => {
  const { authTokens } = useAuth();
  const [clientsList, setClientsList] = useState([]);
  const [selectEmailUsersModal, setSelectEmailUsersModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [lastLength, setLastLength] = useState(20);
  const [pageNumber, setpageNumber] = useState(1);
  const [noData, setnoData] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fetchingNewClients, setFetchingNewClients] = useState(false);
  const [listDataLoading, setListDataLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const modalBodyRef = useRef(null);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (show) {
      getGeneralInfo();
    }
  }, [show]);

  const handleScroll = (e) => {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        // if (clientsList.length === 0) return;
        if (noData) return;

        let element = modalBodyRef.current;

        // Remove the event listener
        element.removeEventListener("scroll", handleScroll);

        // Set the fetching state
        // You can use your setFetchingNewClients logic here

        // Fetch more clients
        getGeneralInfo().then(() => {
          // Reset the fetching state
          // You can use your setFetchingNewClients logic here
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("Error occurred:", error);
    }
  };
  useEffect(() => {
    if (show && modalBodyRef.current) {
      var element = modalBodyRef.current;
      // element.removeEventListener("scroll", handleScroll);
      element.addEventListener("scroll", handleScroll);

      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [modalBodyRef, clientsList, fetchingNewClients]);

  async function getMoreClients(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (clientsList.length === 0) return;

        let element = document.getElementsByClassName("modal-body");
        element.removeEventListener("scroll", getMoreClients);

        setFetchingNewClients(true);
        if (!noData) {
          getGeneralInfo().then(() => {
            setFetchingNewClients(false);
          });
        }

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  async function getGeneralInfo(params) {
    let search = searchString;
    let pageNo = firstLoad ? 1 : pageNumber + 1 ?? 1;
    setpageNumber((prev) => firstLoad ? prev : prev + 1);
    setFirstLoad(false);
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    console.log("tjjur");
    let data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetEmailSettingsMSPClients?accessKey=${message}&pageNo=${pageNo}&pageSize=20&CustomerId=0&search=${search}`,
      getRequestOptions(authTokens)
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result?.Success) {
          console.log(result);
          // setClientsList(result?.ObjectTemplateList);
          if (
            result?.ObjectTemplateList.length === 0 ||
            result?.ObjectTemplateList.length < 20
          ) {
            setnoData(true);
          } else {
            setnoData(false);
          }
          let new_output = result?.ObjectTemplateList;

          let updated_data = [...clientsList, ...new_output];

          setClientsList(updated_data);
          //
        } else {
          // setMainPageLoading(false);

          CallToast("Something went wrong", false, false, "v2style");
        }
      });
  }

  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setListDataLoading(true);
      getGeneralInfo({
        ClientNumber: 0,
        search: value,
      }).then(() => {
        setListDataLoading(false);
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };

  async function UpdateNotificationOptions(isReset) {
    if (!isReset) {
      setSaving(true);
    }
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    let data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdateNotificationOptions?accessKey=${message}`,
      getRequestOptions(authTokens, "POST", {
        userEmailDetailList: JSON.stringify(clientsList),
        isReset: isReset ?? false,
      })
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.Success) {
          console.log(result);
          if (refresh) {
            refresh();
          }
        } else {
          // setMainPageLoading(false);

          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
  }

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName=" company-settings-notifications-modal"
      className={` ${selectEmailUsersModal ? "dark-overlay" : ""
        } hide-scrollbar`}
      aria-labelledby=" company-settings-notifications-modal"
      centered
      scrollable
      onHide={() => {
        hideModal();
        setpageNumber(1);
        setFirstLoad(true);
        setClientsList([]);
      }}
    // style={{ opacity: selectEmailUsersModal ? 0.7 : 1 }}
    >
      <Modal.Header className="text-center border-bottom">
        <div className="w-100 mb-0 font-bold-14 f-500">
          Manage client email notifications
        </div>

        <span className="cursor-pointer" onClick={() => {
          hideModal();
          setpageNumber(1);
          setFirstLoad(true);
          setClientsList([]);
        }}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body ref={modalBodyRef} className="p-0 hide-scrollbar modal-body">
        <div className="mx-2 d-flex align-items-center w-100 search">
          <img
            alt=""
            src="/images/attack-surface/search-icon.svg"
            className=""
          />
          <input
            type="text"
            placeholder="Search"
            value={searchString}
            onChange={(e) => {
              // setSearchString(e.target.value);
              handleInputChange(e);
            }}
            className={`${
              //   searchInput ?
              "animated-search-input-open"
              // : "animated-search-input-close"
              } pl-2`}
            autoFocus
          />
        </div>
        {listDataLoading && (
          <div
            className="position-absolute w-100 h-100 bg-white"
            style={{
              opacity: "0.6",
              zIndex: "2",
            }}
          ></div>
        )}
        <Table bordered>
          <thead>
            <tr>
              <th
                className="w-th-larger"
                style={{ width: "224px", padding: "8px 8px 4px 24px" }}
              >
                Client
              </th>
              <th
                className="w-th-smaller"
                style={{ width: "168px", padding: "8px 8px 4px 8px" }}
              >
                Client Receives Emails
              </th>
              <th style={{ width: "168px", padding: "8px 8px 4px 8px" }}>
                Copy Emails to MSP
              </th>
            </tr>
          </thead>
          <tbody>
            {clientsList
              ?.filter((item) => {
                if (searchString) {
                  return (
                    item?.Name?.toLowerCase()?.includes(
                      searchString?.toLowerCase()
                    ) ||
                    item?.Email?.toLowerCase()?.includes(
                      searchString?.toLowerCase()
                    )
                  );
                } else {
                  return true;
                }
              })
              ?.map((row, index) => (
                <tr key={index}>
                  <td
                    className="w-th-larger"
                    style={{ width: "224px", padding: "8px 8px 8px 24px" }}
                  >
                    {row.Name && row.Name.trim() !== "" ? row.Name : row.Email}
                  </td>
                  <td
                    className="w-th-smaller txt-blue d-flex"
                    style={{
                      width: "168px",
                      justifyContent: "space-between",
                      padding: "8px",
                    }}
                  >
                    <div
                      className="d-flex w-100"
                      style={{ justifyContent: "space-between" }}
                    >
                      <Form.Group className="square-checkbox  blue-checkbox titleCheckbox pointer-checkbox">
                        <Form.Check
                          type="checkbox"
                          label=""
                          name={`checkbox`}
                          checked={row.isSelected ? 1 : 0}
                          onChange={(e) => {
                            let temp = clientsList.map((el) => {
                              if (el.objId_Customer == row.objId_Customer) {
                                el.isSelected = !row.isSelected;
                              }
                              return el;
                            });
                            setClientsList(temp);
                          }}
                        />
                      </Form.Group>
                      <div
                        className={`${row.MembersInfo == "No Members" ? "f-darkgrey" : ""
                          }`}
                        onClick={() => {
                          if (row.MembersInfo !== "No Members") {
                            setSelectedCustomerId(row.objId_Customer);
                            setSelectEmailUsersModal(true);
                          }
                        }}
                      >
                        {row.MembersInfo}
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "168px", padding: "8px" }}>
                    <Form.Check
                      className={`pointer-checkbox`}
                      checked={row.isSendCopyToMSP}
                      type="checkbox"
                      onChange={(e) => {
                        // setUsePreFilledDetails(e.target.checked)
                        let temp = clientsList.map((el) => {
                          if (el.objId_Customer == row.objId_Customer) {
                            el.isSendCopyToMSP = !row.isSendCopyToMSP;
                          }
                          return el;
                        });
                        setClientsList(temp);
                      }}
                    />
                    {row.isSelected}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {fetchingNewClients && (
          <div className="d-flex align-items-center f-darkgrey ml-3">
            <GreyLoader width={12} />
            Loading...
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="d-flex border-top">
        <div
          className="f-grey ml-2 pointer justify-content-start"
          onClick={() => {
            UpdateNotificationOptions(true);
            // setShowResetModal(true);
          }}
        >
          <img
            alt=""
            src="/images/attack-surface/loader-icon.svg"
            className="mr-2"
          />
          Reset to default
        </div>
        <div className="d-flex justify-content-end">
          <Button className="hg-cancel-btn" onClick={() => {
            hideModal()
            setpageNumber(1);
            setFirstLoad(true);
            setClientsList([]);
          }}>
            Cancel
          </Button>
          <Button
            className="hg-blue-btn"
            onClick={() => UpdateNotificationOptions()}
          >
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </Modal.Footer>
      <SelectEmailUsersModal
        show={selectEmailUsersModal}
        hideModal={() => {
          setSelectEmailUsersModal(false);
        }}
        clientsList={clientsList}
        customerId={selectedCustomerId}
      />
    </Modal>
  );
};

export default ManageClientNotificationsModal;
