import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  CallToast,
  CustomRightSideToast,
} from "../PriceCalculator/modals/CustomToast";

const ResetPassword = () => {
  let history = useHistory();
  let params = new URLSearchParams(document.location.search.substring(1));
  let ids = params.get("id");
  let tokens = params.get("token"); // is the string "Jonathan"

  const [processing, setProcessing] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [uid, setUid] = useState(ids || "");
  const [token, setToken] = useState(tokens || "");
   
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const strength = [
    {
      name: "Use A-Z uppercase, a-z lowercase, 0-9 number, symbols, 8-digits",
      maxValue: 0,
      color: "greyText",
      barColor: "bar-not-active",
    },
    {
      name: "Use A-Z uppercase, symbols, 0-9 number, 8-digits",
      maxValue: 1,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use symbols, 0-9 number, 8-digits",
      maxValue: 2,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use 0-9 number, 8-digits",
      maxValue: 3,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Use 8-digits",
      maxValue: 4,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Your password is strong",
      maxValue: 5,
      color: "greenText",
      barColor: "bar-filled",
    },
  ];

  const passwordChecker = (password) => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    // console.log(strongRegex.test(this.state.password))
    if (strongRegex.test(password)) {
      console.log("got here");
      return true;
    } else {
      console.log(password, "got here 2");

      return false;
    }
  };

  const handlePassword = (pwd) => {
    setPassword(pwd);
    var variations = {
      length: /^.{8,}$/.test(pwd),
      digits: /\d/.test(pwd),
      lower: /[a-z]/.test(pwd),
      upper: /[A-Z]/.test(pwd),
      nonWords: /\W/.test(pwd),
    };
    var strengthCount = 0;

    for (var check in variations) {
      strengthCount += variations[check] == true ? 1 : 0;
    }
    setPasswordStrength(strengthCount);
  };

  async function sendPasswordReset(event) {
    event.preventDefault();

    if (!password) {
      setPasswordError("Please enter Password");
      return false;
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Please enter Confirm Password");
      return false;
    }
    if (password && passwordStrength < 5) {
      setPasswordError(
        "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters"
      );
      return false;
    }
    if (password && confirmPassword && password !== confirmPassword) {
      setConfirmPasswordError(
        "Passwords don't match. Please make sure passwords are matching!"
      );
      return false;
    }

    setFormSubmit(true);
    setProcessing(true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("password", password);
    urlencoded.append("uid", uid);
    urlencoded.append("token", token);

    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow",
    };

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/SubmitNewPassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          CustomRightSideToast(
            "Password updated!",
            "You have succesfully reset your password! You will be redirected to the login screen!",
            () => {
              // history.push("/pro_services");
            },
            true
          );

          setTimeout(() => {
            history.push("/login");
          }, 3000);
        } else {
          setConfirmPasswordError(data.ExMessage);
        }
      })
      .catch((error) => {
        setProcessing(false);
        CallToast("Something went wrong", false, false, "v2style");
        console.log(error);
      });
  }

  /**
   * Handle validations
   */
  const handleValidations = (submit) => {
    let error_structure = {
      ePassword: false,
      eConfirmPassword: false,
    };
    if (submit == 0) return error_structure;
    if (!password) {
      error_structure.ePassword = "Please enter Password";
    }
    if (!confirmPassword) {
      error_structure.eConfirmPassword = "Please enter Confirm Password";
    }
    if (password && passwordStrength < 5) {
      error_structure.ePassword =
        "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters";
    }
    if (password && confirmPassword && password != confirmPassword) {
      error_structure.eConfirmPassword =
        "Passwords don't match. Please make sure passwords are matching!";
    }
    const { ePassword, eConfirmPassword } = error_structure;

    if (ePassword || eConfirmPassword) {
      error_structure.is_error = true;
    }
    return error_structure;
  };

  const { ePassword, eConfirmPassword } = handleValidations(formSubmit);

  return (
    <div className="d-flex flex-column p-3 h-100">
      <div className=" w-25 m-auto ">
        <Row className="align-items-center" style={{ paddingTop: "40px" }}>
          <Col md={12} className="text-center">
            <div>
              <img alt="" src="/images/logo-large.svg" className="icon"></img>
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={12} className="text-center pt-5 pb-4">
            <div>
              <h3 className="lgn_HeaderText2">Reset Password</h3>
              <p className="f-darkgrey">Create a new strong password</p>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col className="mx-auto">
            <Form
              className="userLoginForm"
              autoComplete="nope"
              onSubmit={(e) => sendPasswordReset(e)}
            >
              <div className="label-sign-up">New password</div>
              <Form.Group
                controlId="loginEmail"
                className={`mt- 
                     ${ePassword && "border-danger-"}
                     ${passwordError && "input-error"}
                     
                     `}
              >
                <InputGroup className="mr-sm-2">
                  <Form.Control
                    className="inputControl"
                    size="lg"
                    placeholder="Password"
                    type={passwordShow ? "text" : "password"}
                    onChange={(event) => {
                      handlePassword(event.target.value);

                      setPasswordError("");
                    }}
                    value={password}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      className="passwordIcon"
                      onClick={() => {
                        setPasswordShow(!passwordShow);
                      }}
                    >
                      {passwordShow ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>

              {passwordError && (
                <span className="error-text">{passwordError}</span>
              )}
              <Fragment>
                <div className="d-flex justify-content-between mt8px mb8px">
                  {[1, 2, 3, 4, 5].map((bar) => (
                    <div
                      className={
                        bar <= passwordStrength
                          ? strength.find(
                              (elmt) => elmt.maxValue >= passwordStrength
                            ).barColor
                          : "bar-not-active"
                      }
                    ></div>
                  ))}
                </div>
                <div
                  className={
                    strength.find((elmt) => elmt.maxValue >= passwordStrength)
                      .color
                  }
                >
                  {
                    strength.find((elmt) => elmt.maxValue >= passwordStrength)
                      .name
                  }
                </div>
              </Fragment>

              <div className="label-sign-up">Repeat password</div>
              <Form.Group
                controlId="loginEmail"
                className={`
                     ${eConfirmPassword && "border-danger"}
                     ${confirmPasswordError && "input-error"}
                     
                     
                     `}
              >
                <InputGroup className="mr-sm-2">
                  <Form.Control
                    className="inputControl"
                    size="lg"
                    placeholder="Repeat Password"
                    type={confirmPasswordShow ? "text" : "password"}
                    onChange={(event) => {
                      setConfirmPassword(event.target.value);

                      setConfirmPasswordError("");
                    }}
                    value={confirmPassword}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      className="passwordIcon"
                      onClick={() =>
                        setConfirmPasswordShow(!confirmPasswordShow)
                      }
                    >
                      {confirmPasswordShow ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              {confirmPasswordError && (
                <span className="error-text">{confirmPasswordError}</span>
              )}

              <div className="mt-4">
                <Button
                  size="lg"
                  variant="blue"
                  className={`form-control btnControl ${
                    processing && "disabled-btn"
                  }`}
                  type="submit"
                >
                  {processing ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : null}
                  Set new password
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>

      <div className="f-12 f-500 f-darkgrey mt-auto mx-auto w-fit-content">
        © 2024 HighGround.
        &nbsp;
        &nbsp;
        <u
          className="pointer"
          onClick={() => {
            window.open(
              "https://www.highground.io/privacy_policy/",
              "_blank"
            );
          }}
        >
          Privacy Policy
        </u>
        &nbsp; &nbsp;
        <u
          className="pointer"
          onClick={() => {
            window.open(
              "https://www.highground.io/highground-terms-and-conditions/",
              "_blank"
            );
          }}
        >
          Terms & Conditions
        </u>
      </div>
    </div>
  );
};

export default ResetPassword;
