import React, { useState, useEffect } from "react";
import { Button, Dropdown, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAPIData, postRequest } from "../../../Utils/commonApi";
import { TrimString } from "../../../Utils/StringTrimmer";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./members.scss";
import MembersPageLoader from "./MembersPageLoader";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import ModalDynamic from "../../ModalDynamic/ModalDynamic";
import AddNewUser from "../../AddNewUser/AddNewUser";
import { setuser } from "../../../redux/userState/actions";
import { useDb } from "../../context/db";
import { WhiteLoader } from "../../Theme/APILoaders";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { viewOnlyAccess } from "../../App/App";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";

const Members = () => {
  const { authTokens, setAuthTokens, setUserType } = useAuth();
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const userEmail = useSelector((state) => state?.user?.userDetail?.UserName);
  const history = useHistory();
  const dispatch = useDispatch();

  const [members, setMembers] = useState([]);

  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [userData, setUserData] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [otherRole, setOtherRole] = useState("");
  const { setdbObject } = useDb();
  const [otherRoleLoading, setOtherRoleLoading] = useState(false);

 
  useEffect(() => {
    setMainPageLoading(true);
    let isOpenTeamModal = new URLSearchParams(window.location.search).get(
      "openTeamModal"
    );
    getMembersDetails(isOpenTeamModal);
  }, []);

  function changeMemberRole(role, email, otherole = "", element = "") {
    postRequest(authTokens, "UpdateMemberUserRole", {
      Role: role,
      OtherRole: otherole,
      MemberUsername: email,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (element) {
        element.click();
      }
      setOtherRoleLoading(false);
      if (response?.Success) {
        CallToast("Role updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  function ChangeRole(role, roleType = "", element = "") {
    return new Promise((resolve, reject) => {
      postRequest(authTokens, "UpdateUserRole", {
        Role: role,
        OtherRole: roleType,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }).then((response) => {
        if (element) {
          element.click();
        }
        setOtherRoleLoading(false);
        if (response.Success) {
          resolve(response);
          dispatch(setuser({ Role: role }));
          CallToast("Role updated successfully", false, false, "v2style");
          window.localStorage.setItem(
            "userType",
            JSON.stringify(response?.EntityId)
          );
          updateAccessKeyInLocalStorage(response?.Message)
         
          setUserType(response?.EntityId);
          if (role == "CEO") {
            makeAPICall(0);
          } else if (role == "CTO") {
            makeAPICall(1);
          } else if (role == "CFO") {
            makeAPICall(2);
          } else {
            makeAPICall(0);
          }
        } else {
          reject(response);
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    });
  }

  async function makeAPICall(num) {
    let data = await getAPIData(num, authTokens, {
      // OptionEnum1: "99",
      // OptionEnum2: "99",
    });
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setdbObject();
      data = await getAPIData(num, data.authToken);
    } else {
      setdbObject(data);
      // console.log(data?.UserDetail);
      dispatch(setuser(data?.UserDetail));
    }
  }

  async function getMembersDetails(openTeamModal = null) {
    await getAPIData("101", authTokens).then((response) => {
      setMainPageLoading(false);
      if (response.mr.Success) {
        setMembers(response?.UserAndDevices);
        setPageData(response);
        let userDropDownData = [];
        for (var i = 0; i < response?.UserAndDevices.length; i++) {
          let statusData = [
            {
              id: `${response?.UserAndDevices[i].UserDetails?.objId_UserSettings}1`,
              status: "Enabled",
            },
            {
              id: `${response?.UserAndDevices[i].UserDetails?.objId_UserSettings}2`,
              status: "Disabled",
            },
          ];

          let userId =
            response?.UserAndDevices[i]?.UserDetails?.objId_UserSettings;
          let selectedRole = response?.UserAndDevices[i]?.UserDetails?.Role;

          let selectedStatus = statusData.filter(
            (item) => item.status == response?.UserAndDevices[i].User_Status
          )[0];

          let otherRoleDescription =
            response?.UserAndDevices[i]?.UserDetails?.OtherRoleDesc;

          let data = {
            statusData: statusData,
            userId: userId,
            selectedRole: selectedRole,
            selectedStatus: selectedStatus,
            otherRoleEdit: false,
            otherRoleDesc: otherRoleDescription,
          };
          userDropDownData.push(data);
        }

        setUserData(userDropDownData);

        // Open Add team modal if query param is coming True
        if (openTeamModal) {
          let AddTeamBtn = document.getElementById("add-team-btn");
          if (AddTeamBtn) AddTeamBtn.click();
        }
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function handleDeleteUser(deviceID) {
    await getAPIData("109", authTokens, {
      optionEnum1: deviceID,
    }).then((response) => {
      if (response.Success) {
        TrackUser("Account Removed User");
        getMembersDetails();
        CallToast(
          " <img src='/images/settings-v2/bin.svg' class='mr-2'/>&nbsp;&nbspAccount successfully deleted",
          false,
          false
        );
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function handleUserStatus(deviceID) {
    await getAPIData("106", authTokens, {
      optionEnum1: deviceID,
    }).then((response) => {
      if (response.Success) {
        CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  return (
    <>
      {mainPageLoading && <MembersPageLoader />}
      <div className={`${mainPageLoading ? "d-none" : ""}`}
      
   
      style={{
        pointerEvents:viewOnlyAccess("AccessState_UserManagement") ? "none"  :"all"
      }}
      
      
      >
        <div className="members-wrapper">
          <div className="d-flex justify-content-between">
            <div className="heading">Team</div>
            <div>
              {pageData?.ShowAddUserButton && (
                <Button
                  id={`add-team-btn`}
                  className={`d-flex align-items-center ${
                    pageData?.EnableAddUserButton
                      ? "add-member-btn"
                      : "disable-btn"
                  }`}
                  disabled={!pageData?.EnableAddUserButton}
                  onClick={() => {
                    setShowAddUserModal(false);
                    setTimeout(() => {
                      setShowAddUserModal(true);
                    }, 100);
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/plus-icon.svg"
                    className="mr-2"
                    style={{
                      filter: `${
                        pageData?.EnableAddUserButton ? "" : "invert(0.3)"
                      }`,
                    }}
                  />
                  <div>
                    Add Team
                    {/* {pageData?.UserAddMessage &&
                    pageData?.UserAddMessage?.match(/(\d+)/)?.[0]
                      ? `(0/${pageData?.UserAddMessage?.match(/(\d+)/)?.[0]})`
                      : " (1/3)"} */}
                  </div>
                </Button>
              )}
            </div>
          </div>

          <table className="w-100">
            <tr className="grid-container mb-2 pointer">
              <th>
                <span className="ml-3">
                  Name
                  <img
                    alt=""
                    src="/images/actioncenter/arrowdown.svg"
                    className="ml-2"
                  />
                </span>
              </th>
              <th>Devices</th>
              <th>
                <span className="ml-3">Role</span>
              </th>
              <th>
                <span className="ml-3">Status</span>
              </th>
              <th></th>
            </tr>
            {/* Rows wrapper */}
            <div
              className="border-parent"
              style={{
                borderRadius: "8px",
              }}
            >
              {members.map((member, memberIndex) => {
                const IsAdmin = member?.UserDetails?.UserName === userEmail;
                return (
                  <div className="table-data border-item">
                    <tr className="grid-container">
                      <td className="d-flex align-items-center">
                        {member?.UserDetails?.showInitials ? (
                          <ImageWithInitials
                            initials={member?.UserDetails?.Initials}
                            width={30}
                            background={"purple"}
                          />
                        ) : (
                          <img
                            alt=""
                            src={member?.UserDetails?.ImageURL}
                            className=""
                            style={{
                              width: "30px",
                              borderRadius: "50%",
                              objectFit: "contain",
                            }}
                          />
                        )}

                        <div className="ml-2">
                          <p className="user-name m-0 ">
                            {member?.UserDetails?.FullName}
                          </p>
                          <p className="user-email  m-0 ">
                            {TrimString(member?.UserDetails?.UserName, 20)}
                          </p>
                        </div>
                      </td>
                      <td className="d-flex align-items-center">
                        <img
                          alt=""
                          src="/images/settings-v2/desktop.svg"
                          className="mr-2"
                        />
                        <div>{member?.Device_Count}</div>
                      </td>
                      <td className="d-flex align-items-center dropdown-w215">
                        <Dropdown
                          className="hg-white-dropdown"
                          onToggle={() => {
                            let updated_data = [...userData].map((item) => {
                              return {
                                ...item,
                                otherRoleEdit: false,
                              };
                            });
                            setUserData(updated_data);
                            setOtherRole("");
                            setOtherRoleLoading(false);
                          }}
                        >
                          <Dropdown.Toggle className="custom-dropdown-toggle f-black-imp">
                            {userData?.[memberIndex]?.["selectedRole"] ===
                            "Other"
                              ? TrimString(
                                  userData?.[memberIndex]?.["otherRoleDesc"] ??
                                    "Other",
                                  15
                                )
                              : userData?.[memberIndex]?.["selectedRole"]}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div
                              className="p-2 pl-3 my-1 pointer f-black f-500"
                              onClick={(e) => {
                                let updated_data = [...userData];
                                updated_data[memberIndex]["selectedRole"] =
                                  "CEO";
                                setUserData(updated_data);
                                if (
                                  member?.UserDetails?.UserName ==
                                  userDetails?.UserName
                                ) {
                                  ChangeRole("CEO");
                                } else {
                                  changeMemberRole(
                                    "CEO",
                                    member?.UserDetails?.UserName
                                  );
                                }
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              CEO
                              {userData?.[memberIndex]?.["selectedRole"] ==
                                "CEO" && (
                                <img
                                  alt=""
                                  src="/images/settings-v2/black-tick.svg"
                                  style={{
                                    float: "right",
                                    marginRight: "10px",
                                    paddingTop: "3px",
                                  }}
                                />
                              )}
                            </div>
                            <div
                              className="p-2 pl-3 my-1 pointer f-black f-500"
                              onClick={(e) => {
                                let updated_data = [...userData];
                                updated_data[memberIndex]["selectedRole"] =
                                  "CFO";
                                setUserData(updated_data);
                                if (
                                  member?.UserDetails?.UserName ==
                                  userDetails?.UserName
                                ) {
                                  ChangeRole("CFO");
                                } else {
                                  changeMemberRole(
                                    "CFO",
                                    member?.UserDetails?.UserName
                                  );
                                }
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              CFO
                              {userData?.[memberIndex]?.["selectedRole"] ==
                                "CFO" && (
                                <img
                                  alt=""
                                  src="/images/settings-v2/black-tick.svg"
                                  style={{
                                    float: "right",
                                    marginRight: "10px",
                                    paddingTop: "3px",
                                  }}
                                />
                              )}
                            </div>
                            <div
                              className="p-2 pl-3 my-1 pointer f-black f-500"
                              onClick={(e) => {
                                let updated_data = [...userData];
                                updated_data[memberIndex]["selectedRole"] =
                                  "CTO";
                                setUserData(updated_data);
                                if (
                                  member?.UserDetails?.UserName ==
                                  userDetails?.UserName
                                ) {
                                  ChangeRole("CTO");
                                } else {
                                  changeMemberRole(
                                    "CTO",
                                    member?.UserDetails?.UserName
                                  );
                                }
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              CTO
                              {userData?.[memberIndex]?.["selectedRole"] ==
                                "CTO" && (
                                <img
                                  alt=""
                                  src="/images/settings-v2/black-tick.svg"
                                  style={{
                                    float: "right",
                                    marginRight: "10px",
                                    paddingTop: "3px",
                                  }}
                                />
                              )}
                            </div>
                            <div className="p-2 pl-3 my-1 pointer f-black f-500">
                              {userData?.[memberIndex]?.["otherRoleEdit"] ? (
                                <div className="d-flex algn-items-center">
                                  <div className="d-flex align-items-center other-role-editor">
                                    <input
                                      style={{
                                        maxWidth: "124px",
                                      }}
                                      value={otherRole}
                                      onChange={(e) =>
                                        setOtherRole(e.target.value)
                                      }
                                    />
                                    <Button
                                      className="d-flex align-items-center"
                                      onClick={(e) => {
                                        if (otherRole == "") return;
                                        setOtherRoleLoading(true);
                                        var element =
                                          e.target.closest(
                                            ".hg-white-dropdown"
                                          );

                                        if (
                                          member?.UserDetails?.UserName ==
                                          userDetails?.UserName
                                        ) {
                                          ChangeRole(
                                            "Other",
                                            otherRole,
                                            element
                                          );
                                        } else {
                                          changeMemberRole(
                                            "Other",
                                            member?.UserDetails?.UserName,
                                            otherRole
                                          );
                                        }
                                        let updated_data = [...userData];
                                        updated_data[memberIndex][
                                          "selectedRole"
                                        ] = "Other";
                                        setUserData(updated_data);
                                      }}
                                    >
                                      {otherRoleLoading && (
                                        <WhiteLoader width={11} />
                                      )}
                                      <div>Save</div>
                                    </Button>
                                  </div>
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-cross.svg"
                                    className="mx-1 pointer"
                                    onClick={() => {
                                      let updated_data = [...userData];
                                      updated_data[memberIndex][
                                        "otherRoleEdit"
                                      ] = false;
                                      setUserData(updated_data);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    let updated_data = [...userData];
                                    updated_data[memberIndex][
                                      "otherRoleEdit"
                                    ] = true;
                                    setUserData(updated_data);
                                  }}
                                >
                                  Other
                                  {userData?.[memberIndex]?.["selectedRole"] ==
                                    "Other" && (
                                    <img
                                      alt=""
                                      src="/images/settings-v2/black-tick.svg"
                                      style={{
                                        float: "right",
                                        marginRight: "10px",
                                        paddingTop: "3px",
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"role"}
                          selected={userData[memberIndex]?.selectedRole}
                          placeholder="Choose..."
                          //  showid={true}
                          onClick={(id, val) => {
                            let updated_data = [...userData];
                            updated_data[memberIndex].selectedRole = {
                              id,
                              role: val,
                            };
                            setUserData(updated_data);
                            // setPost({ id, category: val });
                          }}
                          data={userData[memberIndex]?.rolesData}
                        /> */}
                      </td>
                      <td
                        className="d-flex align-items-center dropdown-w200"
                        style={{
                          pointerEvents: `${IsAdmin ? "none" : "all"}`,
                        }}
                      >
                        <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"status"}
                          selected={userData[memberIndex]?.selectedStatus}
                          placeholder="Choose..."
                          //  showid={true}
                          onClick={(id, val) => {
                            let updated_data = [...userData];
                            updated_data[memberIndex].selectedStatus = {
                              id,
                              status: val,
                            };
                            setUserData(updated_data);

                            handleUserStatus(id.slice(0, -1));
                            // setStatus({ id, category: val });
                          }}
                          data={userData[memberIndex]?.statusData}
                        />
                      </td>
                      <td className="d-flex align-items-center user-detail-dropdown">
                        <Dropdown className="device-dropdown">
                          <Dropdown.Toggle>
                            <img
                              alt=""
                              src="/images/settings-v2/horizontal-dots.svg"
                              className="dots-icon"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="hg-bg-white">
                            <div>
                              <Row>
                                <Col xs={12}>
                                  <div
                                    style={{ marginTop: "8px" }}
                                    className="notification-btn bg-hover-grey-light"
                                    onClick={(e) =>
                                      history.push(
                                        `/settings/v2/team/${member?.UserDetails?.objId_UserSettings}`
                                      )
                                    }
                                  >
                                    View details
                                  </div>
                                </Col>
                                <Col xs={12}>
                                  <div
                                    className="notification-btn bg-hover-grey-light"
                                    onClick={(e) => {
                                      navigator.clipboard.writeText(
                                        member?.UserDetails?.UserName
                                      );
                                      CallToast(
                                        " <img src='/images/settings-v2/copy.svg' class='mr-2'/>&nbsp;&nbsp;Email Copied",
                                        false,
                                        false
                                      );
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Copy email
                                  </div>
                                </Col>
                                <hr
                                  className=" line-divider-light "
                                  style={{
                                    width: "80%",
                                    margin: "auto",
                                    /* margin-left: auto; */
                                    marginTop: "12px",
                                    marginBottom: "8px",
                                  }}
                                />
                                <Col
                                  xs={12}
                                  style={{
                                    cursor: `${
                                      IsAdmin ? "not-allowed" : "pointer"
                                    }`,
                                  }}
                                >
                                  <div
                                    style={{
                                      marginBottom: "8px",
                                      pointerEvents: `${
                                        IsAdmin ? "none" : "all"
                                      }`,
                                    }}
                                    className="remove-btn bg-hover-grey-light"
                                    onClick={(e) => {
                                      handleDeleteUser(
                                        member?.UserDetails?.objId_UserSettings
                                      );

                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Delete user
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  </div>
                );
              })}
            </div>
          </table>
        </div>
      </div>
      {showAddUserModal ? (
        <ModalDynamic
          setShow={true}
          modalHeader={"Add New User"}
          modalData={<AddNewUser refreshData={getMembersDetails} />}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Members;
