import React, { useEffect, useState, lazy, Suspense } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { getAPIData, postData } from "../../../Utils/commonApi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { TrimString } from "../../../Utils/StringTrimmer";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { WhiteLoader } from "../../Theme/APILoaders";
import AccessLevelModal from "../AccessLevelModal/AccessLevelModal";
// import AddComplianceProfileModal from "../AddComplianceProfileModal/AddComplianceProfileModal";
import "../RequestsModal/requestsModal.scss";
import SupplyPopUp from "../SupplyPopUp/SupplyPopUp";
const AddComplianceProfileModal = lazy(() => import("../AddComplianceProfileModal/AddComplianceProfileModal"));

const ManagePotentialSupplierModal = ({ show, hideModal,editSupplierData ,refreshData}) => {
    const {authTokens}= useAuth()
    const [page, setPage] = useState('addasasupplier')
    const [showAddComplianceModal, setShowAddComplianceModal] = useState(false);
    const [selectedComplianceProfile, setSelectedComplianceProfile] = useState();
    const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState();
    const [processing, setProcessing] = useState(false);
    const [complianceProfileList, setComplianceProfileList] = useState([]);
    const [accessLevelData, setAccessLevelData] = useState([]);
    const [potentialSupplierID, setPotentialSupplierID] = useState('')
    const [supplierId, setSupplierId] = useState('')
    const [supplierData, setSupplierData] = useState({
        supplierName: "",
        supplierEmail: "",
        supplierWebsite: "",
        supplierServicesProvided: [],
        nameError: "",
        emailError: "",
        serviceError: "",
      });
      useEffect(() => {
        if(show){
          setPage("addasasupplier")
          setSupplierData({
            ...supplierData,
            ...editSupplierData,
            supplierName: editSupplierData?.PotentialSupplierName,
            supplierEmail: editSupplierData?.PotentialSupplierEmail,
            supplierWebsite: editSupplierData?.PotentialSupplierWebsite,
            supplierServicesProvided:
              editSupplierData?.PotentialSupplierService_List,
            supplierId: editSupplierData?.PotentialSupplierId,
            supplierLogo: editSupplierData?.PotentialSupplierIcon,
            supplierMessage: editSupplierData?.Message,
            PotentialSupplierStatus: editSupplierData?.PotentialSupplierStatus,
            supplyChainId: "",
          });

        }
        
      }, [show ])
      

        // function to Invite Supplier to highground
  async function InviteSupplierToHighGround() {
    await postData(authTokens, "CreatePotentialSupplier", {
      ObjId_PotentialSupplier: supplierData?.supplierId,
      Email: supplierData?.supplierEmail,
      SupplierName: supplierData?.supplierName,
      Website: supplierData?.supplierWebsite,
      Message: supplierData?.supplierMessage,
      Type: 2
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          if (refreshData) {
            refreshData();
          }
          CallToast("Invited successfully", false, false, "v2style");
          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setProcessing(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

      async function sendRequest() {
        let _accessLevelList = [];
        for (let i = 0; i < accessLevelData.length; i++) {
          _accessLevelList.push(...accessLevelData[i].data);
        }
    
        await postData(authTokens, "SendRequestToSupplier", {
          ObjectId: 0,
          ObjId_AccessLevel: selectedAccessLevel?.ObjectId,
          ObjId_Supplier: potentialSupplierID,
          AccessLevelSelection_List: JSON.stringify(_accessLevelList),
          ObjId_PotentialSupplier:supplierData?.supplierId
        })
          .then((response) => {
            setProcessing(false);
            if (response?.Success) {
               CallToast("Request sent successfully", false, false, "v2style");
              if (refreshData) {
                refreshData();
              }
    hideModal()

              // setPage("requestSent");
            } else {
              CallToast("Something went wrong", false, false, "v2style");
              setProcessing(false);
            }
          })
          .catch(() => {
            CallToast("Something went wrong", false, false, "v2style");
            setProcessing(false);
          });
      }
      async function getAccessDetails(accessLevelId) {
        await getAPIData(`${344}`, authTokens, {
          optionEnum1: supplierData?.supplierId,
          optionEnum2: accessLevelId,
        }).then((response) => {
          if (response?.mr?.Success) {
            if (response?.AccessLevelSelection_List) {
              setAccessLevelData(
                makeGroup(response?.AccessLevelSelection_List || [], "Section")
              );
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          }
        });
      }
      async function updateComplianceProfile(complianceId) {
        await getAPIData(`${343}`, authTokens, {
          optionEnum1: supplierId,
          optionEnum2: complianceId,
        }).then((response) => {
          if (response?.Success) {
            // CallToast(
            //   "Compliance profile updated successfully",
            //   false,
            //   false,
            //   "v2style"
            // );
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        });
      }
      const [accessLevelList, setAccessLevelList] = useState([]);
      async function getAccessLevels() {
        await getAPIData(`${309}`, authTokens, {}).then((response) => {
          if (response?.length > 0) {
            setAccessLevelList(response);
            // setLoading(false);
          }
        });
      }
      async function getComplianceProfiles(supplierID) {
        await getAPIData(`${314}`, authTokens, {
          optionEnum1: supplierID,
        })
          .then((response) => {
            if (response?.mr?.Success) {
              setComplianceProfileList(response?.Profiles);
              getAccessLevels();
            } else {
              // CallToast("Something went wrong", false, false, "v2style");
            }
          })
          .catch(() => {
            CallToast("Something went wrong", false, false, "v2style");
          });
      }
      async function createSupplierRequest(supplierId) {
        await postData(authTokens, "CreateSupplierRequest", {
          objId_Supplier: supplierId,
          optionEnum1:1
        })
          .then((response) => {
            setProcessing(false);
            if (response?.mr?.Success) {
              if (response?.SubSuccess === false) {
                CallToast(
                  response?.mr?.Message,
                  false,
                  false,
                  "v2style"
                );
                return;
              }else{
    
                setPage("clientRequest");
                setPotentialSupplierID(supplierId)
    
              }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          })
          .catch(() => {
            CallToast("Something went wrong", false, false, "v2style");
          });
      }
        // Function to invite with supplier chain Id and send request
  async function InviteSupplierToSupplierChain(_supplyChainID) {
    await getAPIData(`${318}`, authTokens, {
      optionStrEnum1: _supplyChainID,
    })
      .then((response) => {
        setProcessing(false);
        if (!response?.SupplierDetail) {
          CallToast(response?.mr?.Message, false, false, "v2style");
          return;
        }
        if (response?.Toast_Message) {
          CallToast(response?.Toast_Message, false, false, "v2style");
          return;
        }
        if (response?.mr?.Success) {
          let SupplierId = response?.SupplierDetail?.ObjId_Customer;
          setSupplierId(SupplierId)
          getAccessLevels();
          getComplianceProfiles(SupplierId);
          // setPage("clientRequest");
          setAccessLevelData([...dummyData]);
          createSupplierRequest(SupplierId);
        } else {
          setProcessing(false);
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setProcessing(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  return (
    <Modal
      show={show}
      dialogClassName=" supplychain-requestsmodal"
      className=""
      aria-labelledby=" supplychain-requestsmodal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center"><div>
      {page !== "addasasupplier" && (
          <img
            alt=""
            src="/images/supply-chain/chevron-left.svg"
            className="mt-2 pointer"
            onClick={() => {
              if(page==="invitesuppliertohighground"){
                setPage("addasasupplier")
              }
              if(page==="clientRequest"){
                setPage("invitesuppliertohighground")
              }
            }}
          />
        )}



      </div>
      <div className="w-100 mb-0 font-bold-14">
      {editSupplierData?.PotentialSupplierName}
        </div>
      <span className="cursor-pointer" onClick={hideModal}>
     
          <img alt="" src="/images/icons/close.svg" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
      {page === "addasasupplier" && (
          <div className="pt-2 pl-2">
            <div className="d-flex align-items-center mb-2">
              {editSupplierData?.PotentialSupplierIcon?.toLowerCase() ===
              "noicon" ? (
                <ImageWithInitials
                  width={40}
                  background="purple"
                  initials={
                    editSupplierData?.PotentialSupplierName[0]?.toUpperCase() ??
                    "X"
                  }
                />
              ) : (
                <img
                  alt=""
                  src={editSupplierData?.PotentialSupplierIcon}
                  width={40}
                  height={40}
                  className=" rounded-full"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/user-logo.svg";
                  }}
                />
              )}
              <div className="ml-2">
                <p className="m-0 f-500 f-black">
                  {editSupplierData?.PotentialSupplierName}
                </p>
              </div>
            </div>

            <div className="my-2 mt-3">
              To add <strong>{editSupplierData?.PotentialSupplierName}</strong>{" "}
              to your supply chain, please enter their HighGround unique Supply
              Chain ID
            </div>

            <div className="d-flex align-items-center search-supplier-input highlight-input mt-2">
              <input
                type="text"
                className=" w-100 border-none bg-transparent"
                placeholder="Enter suppliers unique Supply Chain ID"
                value={supplierData?.supplyChainId}
                onChange={(e) => {
                  if (e.target.value?.length > 11) return;
                  let foo = e.target.value.split("-").join("");
                  if (foo.length > 0) {
                    foo = foo.match(new RegExp(".{1,3}", "g")).join("-");
                  }
                  setSupplierData({
                    ...supplierData,
                    supplyChainId: foo,
                  });
                  if (e.target.value?.length === 11) {
                    // InviteSupplierToSupplierChain(foo);
                  }
                }}
              />
              {false && (
                <img
                  src="/images/settings-v2/header-search-cross.svg"
                  alt=""
                  className="pointer mr-2"
                  onClick={() => {}}
                />
              )}
            </div>
            <div
              className="f-blue my-2 mt-3 pointer"
              onClick={() => {
                setPage("invitesuppliertohighground");
              }}
            >
              Request unique Supply Chain ID or Invite them to join HighGround
            </div>

            {supplierData?.supplyChainId !== "" &&
              supplierData?.supplyChainId?.length < 11 && (
                <div className="d-flex text-center flex-column mt-5">
                  <div className="d-flex align-items-center justify-content-center f-500 f-grey">
                    Enter in format XXX-XXX-XXX
                  </div>
                  <div className="d-flex align-items-center justify-content-center f-darkgrey">
                    Please enter suppliers supply-chain ID in order to find
                    them.
                  </div>
                </div>
              )}
          </div>
        )}
      
    

{page === "invitesuppliertohighground" && (
          <div className="pt-2 pl-2">
            <div className="d-flex align-items-center mb-2">
              {editSupplierData?.PotentialSupplierIcon?.toLowerCase() ===
              "noicon" ? (
                <ImageWithInitials
                  width={40}
                  background="purple"
                  initials={
                    editSupplierData?.PotentialSupplierName[0]?.toUpperCase() ??
                    "X"
                  }
                />
              ) : (
                <img
                  alt=""
                  src={editSupplierData?.PotentialSupplierIcon}
                  width={40}
                  height={40}
                  className=" rounded-full"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/user-logo.svg";
                  }}
                />
              )}
              <div className="ml-2">
                <p className="m-0 f-500 f-black">
                  {editSupplierData?.PotentialSupplierName}
                </p>
              </div>
            </div>

            <div className="my-2 mt-3">
              You are about to send request for
              <strong> {editSupplierData?.PotentialSupplierName}</strong> to
              join HighGround.
            </div>

            <div className="f-500 mt-3">Email</div>

            <div className="d-flex align-items-center search-supplier-input highlight-input ">
              <input
                tabIndex={0}
                type="text"
                placeholder="Supplier email"
                className="flex-grow-1 pl-1 pr-2"
                value={supplierData?.supplierEmail}
                disabled 
                onChange={(e) => {
                  let value = e.target.value;
                  if (
                    value.match(
                      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                    )
                  ) {
                    setSupplierData((prev) => ({
                      ...prev,
                      supplierWebsite: `www.${value?.split("@")[1]}`,
                      supplierEmail: value,
                      emailError: "",
                    }));
                  } else {
                    setSupplierData((prev) => ({
                      ...prev,
                      supplierEmail: value,
                      emailError: "",
                    }));
                  }
                }}
              />
              {false && (
                <img
                  src="/images/settings-v2/header-search-cross.svg"
                  alt=""
                  className="pointer mr-2"
                  onClick={() => {}}
                />
              )}
            </div>
            <div className="f-500 mt-3">Website</div>

            <div className="d-flex align-items-center search-supplier-input highlight-input ">
              <input
                tabIndex={0}
                type="text"
                placeholder="https://example.com"
                className="flex-grow-1 pl-1 pr-2"
                value={supplierData?.supplierWebsite}
                onChange={(e) => {
                  setSupplierData({
                    ...supplierData,
                    supplierWebsite: e.target.value,
                  });
                }}
              />
              {false && (
                <img
                  src="/images/settings-v2/header-search-cross.svg"
                  alt=""
                  className="pointer mr-2"
                  onClick={() => {}}
                />
              )}
            </div>
            <textarea
              placeholder="Type your message here..."
              className="w-100 p-2 bg-grey radius-4 highlight-input mt-2"
              rows={9}
              value={supplierData?.supplierMessage}
              onChange={(e) => {
                setSupplierData({
                  ...supplierData,
                  supplierMessage: e.target.value,
                });
              }}
            ></textarea>
          </div>
        )}
         {page === "clientRequest" && (
          <div className="client-request-data">
            {/* verify client alert */}
            <div className="verify-client-alert py-2 px-3 d-none">
              <div className="d-flex align-items-center justify-content-between">
                <div className="f-500 f-black d-flex align-items-center">
                  <img
                    alt=""
                    src="/images/supply-chain/alert-icon.svg"
                    className="mr-2"
                  />
                  <span className="pt-1">
                    Verify this clients Supply Chain ID: 182-773-628
                  </span>
                </div>
                <img
                  alt=""
                  src="/images/supply-chain/tiny-cross.svg"
                  className="pointer"
                />
              </div>
              <p className="f-grey">
                You should verify with your client outside of HighGround that
                this is their HIghGround Supply Chain ID and that they submitted
                this request before approving this.
              </p>
            </div>
            {/* Client name and logo wrapper */}
            <div className="d-flex align-items-center justify-content-between p-2">
              <div className="d-flex align-items-center">
                {editSupplierData?.PotentialSupplierIcon?.toLowerCase() ===
                "noicon" ? (
                  <ImageWithInitials
                    width={40}
                    background="purple"
                    initials={
                      editSupplierData?.PotentialSupplierName[0]?.toUpperCase() ??
                      "X"
                    }
                  />
                ) : (
                  <img
                    alt=""
                    src={editSupplierData?.PotentialSupplierIcon}
                    width={40}
                    height={40}
                    className=" rounded-full"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src =
                        "/images/user-logo.svg";
                    }}
                  />
                )}
                <div className="ml-2">
                  <p className="m-0 f-500 f-black">
                    {editSupplierData?.PotentialSupplierName}
                  </p>
                  <p className="m-0 f-14 f-grey">
                    Supply Chain ID:{supplierData?.supplyChainId}
                  </p>
                </div>
              </div>
              <div>
                <div className="requestlist-dropdown">
                  <Dropdown className="device-dropdown d-none">
                    <Dropdown.Toggle>
                      <img
                        alt=""
                        src="/images/supply-chain/horizontal-dots.svg"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div>
                        <Row>
                          <Col xs={12} className="  pointer">
                            <div
                              className="drop-item"
                              onClick={(e) => {
                                e.target.closest(".device-dropdown").click();
                              }}
                            >
                              Resume
                            </div>
                          </Col>

                          <Col xs={12} className=" pointer">
                            <div
                              className="drop-item"
                              onClick={(e) => {
                                e.target.closest(".device-dropdown").click();
                              }}
                            >
                              Remove
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            {/* Client Status with you */}
            <p className="f-500 p-2 d-none">
              <strong>Nike Inc. </strong> has suspended you from their account
              and are no longer sharing their HighGround data with you.
            </p>

            {/* Client response */}
            <div className="client-reponse d-none">
              <p className="f-500 mb-2">Client Response</p>
              <p>
                “You want a lot - you get little. We are not ready to provide
                access to our documents, please change your request”
              </p>
            </div>

            {/* Clients Complaine profile and access level option */}
            <div className="client-compliance-access d-none">
              <div className="d-flex align-items-center justify-content-between">
                <div className="f-500 f-black">Compliance profile</div>
                <div></div>
              </div>
            </div>

            {/* Complaince Profile dropdowns */}
            {true && (
              <>
                <div className="d-flex align-items-center justify-content-between my-1 mx-2 mb-2">
                  <div className="d-flex align-items-center">
                    <div className="f-500 mr-2">Compliance Profile</div>
                  </div>

                  <div className="compliance-filter-dropdow n user-detail-dropdown">
                    <Dropdown className="device-dropdown">
                      <Dropdown.Toggle>
                        <div className="d-flex align-items-center">
                          {selectedComplianceProfile ? (
                            <div className="f-black f-500 mr-2">
                              {selectedComplianceProfile?.ProfileName}
                            </div>
                          ) : (
                            <div className="f-grey mr-2">Choose...</div>
                          )}
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="hg-bg-white hide-scrollbar"
                        style={{
                          maxHeight: "225px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <div>
                          <Row>
                            {
                              // complianceProfileList
                              complianceProfileList?.map(
                                (profile, profileIndex) => {
                                  return (
                                    <Col xs={12}>
                                      <div
                                        style={{ marginTop: "3px" }}
                                        className={`notification-btn bg-hover-grey-light ${
                                          profile?.ObjectId ===
                                          "selectedComplianceProfile?.ObjectId"
                                            ? "bg-grey-light"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          setSelectedComplianceProfile(profile);
                                          updateComplianceProfile(
                                            profile?.ObjectId
                                          );
                                          // getAccessDetails(profile?.ObjectId);
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {profile?.ProfileName}
                                      </div>
                                    </Col>
                                  );
                                }
                              )
                            }

                            <hr
                              className=" line-divider-light "
                              style={{
                                width: "80%",
                                margin: "auto",

                                marginTop: "12px",
                                marginBottom: "8px",
                              }}
                            />
                            <Col xs={12}>
                              <div
                                style={{
                                  marginBottom: "8px",
                                }}
                                className="notification-btn bg-hover-grey-light"
                                onClick={(e) => {
                                  setShowAddComplianceModal(true);
                                  e.target.closest(".device-dropdown").click();
                                }}
                              >
                                Add compliance profile
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                {/* Access Level dropdown */}
                <div className="d-flex align-items-center justify-content-between my-1 mx-2 mb-2">
                  <div className="d-flex align-items-center">
                    <div className="f-500 mr-2">Access Level</div>
                  </div>

                  <div className="compliance-filter-dropdow n user-detail-dropdown">
                    <Dropdown className="device-dropdown">
                      <Dropdown.Toggle>
                        <div className="d-flex align-items-center">
                          {selectedAccessLevel ? (
                            <div className="f-black f-500 mr-2">
                              {selectedAccessLevel?.Name}
                            </div>
                          ) : (
                            <div className="f-grey mr-2">Choose...</div>
                          )}
                          <img
                            alt=""
                            className=""
                            src="/images/big-chevron-down.svg"
                          />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="hg-bg-white hide-scrollbar"
                        style={{
                          maxHeight: "225px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <div>
                          <Row>
                            {
                              // accessLevelList
                              accessLevelList?.map(
                                (accessLevel, acessLevelIndex) => {
                                  return (
                                    <Col xs={12}>
                                      <div
                                        style={{ marginTop: "3px" }}
                                        className={`notification-btn bg-hover-grey-light ${
                                          accessLevel?.ObjectId ===
                                          selectedAccessLevel?.ObjectId
                                            ? "bg-grey-light"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          setSelectedAccessLevel(accessLevel);
                                          getAccessDetails(
                                            accessLevel?.ObjectId
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {TrimString(accessLevel?.Name,35)}
                                      </div>
                                    </Col>
                                  );
                                }
                              )
                            }

                            <hr
                              className=" line-divider-light "
                              style={{
                                width: "80%",
                                margin: "auto",

                                marginTop: "12px",
                                marginBottom: "8px",
                              }}
                            />
                            <Col xs={12}>
                              <div
                                style={{
                                  marginBottom: "8px",
                                }}
                                className="notification-btn bg-hover-grey-light"
                                onClick={(e) => {
                                  e.target.closest(".device-dropdown").click();
                                  setShowAccessLevelModal(true);
                                }}
                              >
                                Add access level
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </>
            )}

            <hr color="#EBEDF1" />
            {/* General Permissions */}
            {
              // accessLevelData
              accessLevelData?.map((data, dataIndex) => {
                return (
                  <div className="general-permissions">
                    <div className="f-12 f-grey my-2 ml-2">{data?.Section}</div>

                    {data?.data?.map((row, rowIndex) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between my-1 mx-2">
                          <div className="d-flex align-items-center">
                            <div className="f-500 mr-2">{row?.SubSection}</div>
                            <span className="pointer">
                              <SupplyPopUp
                                header={row?.SubSection}
                                dataList={row?.Permission_List?.map(
                                  (permission) => {
                                    return {
                                      title: permission?.Permission,
                                      subtitle: permission?.Rights,
                                    };
                                  }
                                )}
                              >
                                <img
                                  alt=""
                                  src="/images/supply-chain/grey-info.svg"
                                />
                              </SupplyPopUp>
                            </span>
                          </div>

                          <div
                            className={`compliance-filter-dropdown   ${
                              "state.newRequest" ? "" : "disabled-dropdown"
                            } `}
                          >
                            <CompanySettingsDropdown
                              fieldId={"ObjectId"}
                              fieldValue={"Permission"}
                              selected={row?.PermissionSelected}
                              placeholder="Choose..."
                              //  showid={true}
                              onClick={(id, val) => {
                                let updated_data = [...accessLevelData];
                                let selected_value = updated_data[dataIndex][
                                  "data"
                                ][rowIndex].Permission_List.filter(
                                  (item) => item.ObjectId === id
                                )[0];
                                updated_data[dataIndex]["data"][
                                  rowIndex
                                ].PermissionSelected = selected_value;
                                setAccessLevelData(updated_data);
                                setSelectedAccessLevel({
                                  ObjectId:0,Name:"Custom Access Level"
                                })
                              }}
                              data={row?.Permission_List}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            }
          </div>
        )}

</Modal.Body>


        {/* FOOTERS SETUP */}
        {page === "addasasupplier" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              disabled={supplierData?.supplyChainId?.length < 11}
              onClick={() => {
                setProcessing(true);
                InviteSupplierToSupplierChain(supplierData?.supplyChainId);
              }}
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                Continue
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}
        {/* Footer for Client Request */}
        {page === "clientRequest" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              onClick={() => {
                if (!selectedComplianceProfile?.ProfileName) {
                  CallToast(
                    "Please select Compliance profile",
                    false,
                    false,
                    "v2style"
                  );
                  return;
                }

                // if (!selectedAccessLevel?.ObjectId) {
                //   CallToast(
                //     "Please select access level",
                //     false,
                //     false,
                //     "v2style"
                //   );
                //   return;
                // }
                setProcessing(true);
                sendRequest();
              }}
              disabled={false}
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                Request
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}
         {/* Footer for Invite Supplier to Highground */}
         {page === "invitesuppliertohighground" && (
        <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Cancel
            </Button>

            <Button
              className="hg-blue-btn"
              onClick={() => {
                setProcessing(true);

                InviteSupplierToHighGround();
              }}
              disabled={false}
            >
              <div className="d-flex align-items-center">
                {processing && <WhiteLoader width={12} />}
                Send
              </div>
            </Button>
          </div>
        </Modal.Footer>
      )}

      <Suspense fallback={<div>Loading...</div>}>
         <AddComplianceProfileModal
          show={showAddComplianceModal}
          complianceProfileId={0}
          hideModal={() => setShowAddComplianceModal(false)}
          refreshData={() => {
            getComplianceProfiles(editSupplierData?.PotentialSupplierId);
            if (refreshData) refreshData();
          }}
        />
      </Suspense>
      
         <AccessLevelModal
        show={showAccessLevelModal}
        hideModal={() => setShowAccessLevelModal(false)}
        refreshData={() => {
          getAccessLevels();
        }}
      />
    </Modal>
  );
};

export default ManagePotentialSupplierModal;
function makeGroup(list, key) {
    if (list?.length === 0) return;
    let resultArr = [];
    let types = [];
    for (let i = 0; i < list.length; i++) {
      if (types.indexOf(list[i][key]) > -1) {
        let indexPosition = types.indexOf(list[i][key]);
        resultArr[indexPosition]["data"].push(list[i]);
        resultArr[indexPosition][key] = list[i][key];
      } else {
        types.push(list[i][key]);
        let indexPosition = types.indexOf(list[i][key]);
        if (typeof resultArr[indexPosition] === "object") {
          resultArr[indexPosition]["data"].push(list[i]);
          resultArr[indexPosition][key] = list[i][key];
        } else {
          resultArr[indexPosition] = {};
          resultArr[indexPosition]["data"] = [];
          resultArr[indexPosition]["data"].push(list[i]);
          resultArr[indexPosition][key] = list[i][key];
        }
      }
    }
  
    return resultArr;
  }
const dummyData = [
    {
      data: [
        {
          Section: "General Permissions",
          SubSection: "Primary KPIs",
          Permission_List: [
            {
              ObjectId: 3346236,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346237,
              Level: 1,
              Permission: "View KPI",
              Rights: "View KPI Value Only",
            },
            {
              ObjectId: 3346238,
              Level: 2,
              Permission: "View Drilldown Summary",
              Rights: "View KPI and Drilldown Summary",
            },
            {
              ObjectId: 3346239,
              Level: 3,
              Permission: "View Detailed Drilldown",
              Rights: "View KPI, Drilldown Summary and Drilldown Details",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "General Permissions",
          SubSection: "Role Based Cyber Data\t",
          Permission_List: [
            {
              ObjectId: 3346240,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346241,
              Level: 1,
              Permission: "View KPI",
              Rights: "View KPI Value Only",
            },
            {
              ObjectId: 3346242,
              Level: 2,
              Permission: "View Drilldown Summary",
              Rights: "View KPI and Drilldown Summary",
            },
            {
              ObjectId: 3346244,
              Level: 1,
              Permission: "View Trend Data",
              Rights: "View Trend Data",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "General Permissions",
          SubSection: "Cyber Trend Data",
          Permission_List: [
            {
              ObjectId: 3346243,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "General Permissions",
          SubSection: "Technical Drilldown",
          Permission_List: [
            {
              ObjectId: 3346245,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346246,
              Level: 1,
              Permission: "View Health",
              Rights: "View health of the data for every technology",
            },
            {
              ObjectId: 3346247,
              Level: 2,
              Permission: "View Tools",
              Rights:
                "View health of the data for every technology and details of the connected tool",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "General Permissions",
          SubSection: "Integrations",
          Permission_List: [
            {
              ObjectId: 3346248,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346249,
              Level: 1,
              Permission: "View Health",
              Rights:
                "View health of every integration, inc. if a tool has been connected or not",
            },
            {
              ObjectId: 3346250,
              Level: 2,
              Permission: "View Tools",
              Rights:
                "View health of every integration, inc. if a tool has been connected or not, tool name, last synch date/time and sync health",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
      ],
      Section: "General Permissions",
    },
    {
      data: [
        {
          Section: "Compliance Manager",
          SubSection: "Cyber Essentials",
          Permission_List: [
            {
              ObjectId: 3346251,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346252,
              Level: 1,
              Permission: "View Status",
              Rights: "View overall status of your Cyber Essentials",
            },
            {
              ObjectId: 3346253,
              Level: 2,
              Permission: "View Certificate",
              Rights:
                "View overall status of your Cyber Essentials and view/download a copy of your Certificate",
            },
            {
              ObjectId: 3346254,
              Level: 3,
              Permission: "View Audit Summary",
              Rights:
                "View overall status of your Cyber Essentials,  view/download a copy of your Certificate and view your summary details for your Cyber Essential Audits",
            },
            {
              ObjectId: 3346255,
              Level: 4,
              Permission: "View Audit Details",
              Rights:
                "View overall status of your Cyber Essentials, view/download a copy of your Certificate and view your full Cyber Essentials Audits",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "Compliance Manager",
          SubSection: "Incident Response",
          Permission_List: [
            {
              ObjectId: 3346256,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346257,
              Level: 1,
              Permission: "View Status",
              Rights: "View overall status of your Incident Response",
            },
            {
              ObjectId: 3346258,
              Level: 2,
              Permission: "View IR Documents",
              Rights:
                "View overall status of your Incident Response and view/download a copy of your IR Documents",
            },
            {
              ObjectId: 3346259,
              Level: 3,
              Permission: "View Review Summary",
              Rights:
                "View overall status of your Incident Response, view/download a copy of your IR Documents and view summary details of your IR Reviews",
            },
            {
              ObjectId: 3346260,
              Level: 4,
              Permission: "View Review Details",
              Rights:
                "View overall status of your Incident Response, view/download a copy of your IR Documents and view your full IR Reviews",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "Compliance Manager",
          SubSection: "Information Security Policies",
          Permission_List: [
            {
              ObjectId: 3346261,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346262,
              Level: 1,
              Permission: "View Status",
              Rights: "View overall status of your Information Security Policies",
            },
            {
              ObjectId: 3346263,
              Level: 2,
              Permission: "View Recommended + Cyber Essentials Policies Summary",
              Rights:
                "View overall status of your Information Security Policies and summary details of your Recommended and Cyber Essentials Policies",
            },
            {
              ObjectId: 3346264,
              Level: 3,
              Permission: "View Recommended + Cyber Essentials Policies",
              Rights:
                "View overall status of your Information Security Policies and view/download a copy of your Recommended and Cyber Essentials Policies",
            },
            {
              ObjectId: 3346265,
              Level: 4,
              Permission: "View All Policies Summary",
              Rights:
                "View overall status of your Information Security Policies and summary details of your Recommended, Cyber Essentials and 'My Policies' Policies",
            },
            {
              ObjectId: 3346266,
              Level: 5,
              Permission: "View All Policies",
              Rights:
                "View overall status of your Information Security Policies and view/download a copy of your Recommended, Cyber Essentials and 'My Policies' Policies",
            },
            {
              ObjectId: 3346267,
              Level: 0,
              Permission: "0",
              Rights: "0",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "Compliance Manager",
          SubSection: "Risk Assessments",
          Permission_List: [
            {
              ObjectId: 3346268,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346269,
              Level: 1,
              Permission: "View Status",
              Rights: "View overall status of your Risk Assessments",
            },
            {
              ObjectId: 3346270,
              Level: 2,
              Permission: "View Assessment Summary",
              Rights:
                "View overall status of your Risk Assessments and summary details of your Risk Assessments",
            },
            {
              ObjectId: 3346271,
              Level: 3,
              Permission: "View Assessment Details",
              Rights:
                "View overall status of your Risk Assessments and your full Risk Assessments",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "Compliance Manager",
          SubSection: "Annual Cyber Reviews",
          Permission_List: [
            {
              ObjectId: 3346272,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346273,
              Level: 1,
              Permission: "View Status",
              Rights: "View overall status of your Annual Cyber Reviews",
            },
            {
              ObjectId: 3346274,
              Level: 2,
              Permission: "View Assessment Summary",
              Rights:
                "View overall status of your Annual Cyber Reviews and summary details of your Annual Cyber Reviews",
            },
            {
              ObjectId: 3346275,
              Level: 3,
              Permission: "View Assessment Details",
              Rights:
                "View overall status of your Annual Cyber Reviewss and your full Annual Cyber Reviews",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
        {
          Section: "Compliance Manager",
          SubSection: "BCDR",
          Permission_List: [
            {
              ObjectId: 3346276,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
            {
              ObjectId: 3346277,
              Level: 1,
              Permission: "View Status",
              Rights: "View overall status of your IT BCDR Planning",
            },
            {
              ObjectId: 3346278,
              Level: 2,
              Permission: "View BCDR Plan Documents",
              Rights:
                "View overall status of your IT BCDR Planning and view/download a copy of your IT BCDR Plan. Note: your SOPs are not visible to the client",
            },
            {
              ObjectId: 3346279,
              Level: 3,
              Permission: "View BCDR Test Summary",
              Rights:
                "View overall status of your IT BCDR Planning, view/download a copy of your IT BCDR Plan and view summary details of your IT BCDR Tests",
            },
            {
              ObjectId: 3346280,
              Level: 4,
              Permission: "View BCDR Test Details",
              Rights:
                "View overall status of your IT BCDR Planning, view/download a copy of your IT BCDR Plan and view your full IT BCDR Tests",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
      ],
      Section: "Compliance Manager",
    },
    {
      data: [
        {
          Section: "Attack Surface",
          SubSection: "Attack Surface",
          Permission_List: [
            {
              ObjectId: 3346281,
              Level: 0,
              Permission: "No Access",
              Rights: "No Access",
            },
          ],
          PermissionSelected: null,
          mr: null,
        },
      ],
      Section: "Attack Surface",
    },
  ];