import React, { Fragment } from 'react'
import "./sign-up.scss"
import { TrackUser } from '../../Utils/SegmentFunctions';
import { Button, Col,   Form, InputGroup,   Row, Spinner,   } from 'react-bootstrap';
import { useEffect } from 'react';
import { CallToast } from '../PriceCalculator/modals/CustomToast';
import { useState } from 'react';
import { useAuth } from '../context/Auth';
import { useDb } from '../context/db';
import { useHistory } from 'react-router-dom';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import CompanySettingsDropdown from '../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import * as animationData from "../Spinner/Icon-check.json";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useRef } from 'react';
import ImageCropperModal from '../settingsV2/personal-profile/personal-profile-modals/ImageCropperModal';
import { LeftSideWelcomeBannerText } from './Loginv2';
import { CustomGetApi, getAPIData, postData } from '../../Utils/commonApi';
import { setuser } from '../../redux/userState/actions';
import { useDispatch } from 'react-redux';
import { updateAccessKeyInLocalStorage } from '../../Utils/CommonFunctions';
const Registrationv2 = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
      setUserType,
      setAuthTokens,
      setAuth, 
    } = useAuth();
    const { setdbObject } = useDb();
    const {authTokens}= useAuth()
    const [passwordShow, setPasswordShow] = useState(false);
    const [redirectingMessage, setRedirectingMessage] = useState(
      "Creating your account, please wait.."
    );
    const [email, setEmail] = useState("");
    const [otherRole, setOtherRole] = useState("");
    
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [happyToRecieve, setHappyToReceive] = useState(false);
    const [fullName, setFullName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [role, setRole] = useState("CEO");
    const [formSubmit, setFormSubmit] = useState(0);
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [showAnimation, setShowAnimation] = useState(false);
    const [resendEmail, setResendEmail] = useState(false);
    const [resendEmailState, setResendEmailState] = useState(0);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [cropperModal, setCropperModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [linkValidation, setLinkValidation] = useState({valid:false, message:""})
    const fileUpload = useRef();
    const showCropperModal = () => {
        setCropperModal(true);
      };
 
    
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
 
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [userAccessKey, setUserAccessKey] = useState(null);
    // console.log(formSubmit);
  
    useEffect(() => {
      // setAuth(false);
      window.localStorage.clear()
      setdbObject();
      localStorage.setItem("dasboardLoader", true);
    }, []);
    let runOnce = 0;

    async function makeAPICall(link, userAccessKey) {
      await getAPIData(0, authTokens, {
      }).then((data) => {
          setdbObject(data);
          dispatch(
            setuser({
              ...data?.UserDetail,
              CommonComponents: data?.CommonComponents,
              ScoreAttestation_Icon:
                data?.CommonComponents?.ScoreAttestation_Icon,
  
              cyberScoreValue: data?.CommonComponents?.CyberScoreNumber,
              cyberThreatValue: data?.CommonComponents?.CyberThreatNumber,
              AccessObjList: data?.mr?.AccessObjList,
            })
          );
          if (data?.allowMSP) {
            history.push({
              pathname: `/mspv2/clients`,
              state: { isLogin: true },
            });
            return
          } else if (data?.allowSaaS) {
            history.push( 
              `/${link}/dashboard`, 
             {
                isLogin: true, 
                userAccessKey: userAccessKey, 
              }
            );
          }
      });
    }

  
    // Redirect User directly to Login Screen
     function RedirectToDashboard(email, password) {
      if (runOnce > 0) return;
      runOnce = 2;
      setTimeout(() => {
        runOnce = 0;
      }, 3000);
      setShowAnimation(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "password");
      urlencoded.append("username", clientData.email )
      // clientData.email  );

      urlencoded.append("password", confirmPassword)
      
      // password);
      urlencoded.append("ipaddress", "111.111.111.111");
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
  
      const data =  fetch(
        `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
        requestOptions
      )
        .then((response) => response.json())
        .then( (data) => {
          if (data.error) {
            if (
              data.error_description ==
              "The email address provided has not been confirmed."
            ) {
            
            }
          } else {
            //Set Token to Local Storage
            setAuthTokens(data.access_token);
            const token = data.access_token;
             localStorage.setItem("tokens", JSON.stringify(data.access_token));
           
            setRedirectingMessage(
              `Redirecting you to your dashboard, please wait . . .`
            );
            var myHeaders2 = new Headers();
            myHeaders2.append("Authorization", `Bearer ${data.access_token}`);
  
            let requestOptions = {
              method: "GET",
              headers: myHeaders2,
              redirect: "follow",
            };
  
            fetch(
              `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckGoogleAuth`,
              requestOptions
            )
              .then((response) => response.json())
              .then( (data) => {
                
                if (data?.ByPass2FA === true || true) {
                  window.localStorage.setItem(
                    "token_time",
                    JSON.stringify(Math.floor(Date.now() / 60000))
                  );
                   
                  let myHeaders = new Headers();
                  myHeaders.append("Authorization", `Bearer ${token}`);
  
                  let requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                  };
  
                  let googlePinData =  fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=999666`,
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((data) => {
                     
  
                      setUserType(data.EntityId);
                      window.localStorage.setItem(
                        "userType",
                        JSON.stringify(data?.EntityId)
                      );
                      updateAccessKeyInLocalStorage(data.Message)
                    
  
                      /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
                      console.log("setting access key in sign up");
                      // setMessageEncrypt(data.Message);
                      let element = document.getElementById("root").firstChild;
                      element.classList.add("fadeIn");
                      setTimeout(() => {
                        element.classList.remove("fadeIn");
                      }, 500);
  
                      let userType = {
                        111111: "ceo",
                        999999: "ceo",
                        222222: "cto",
                        333333: "cfo",
                      };
                      // setShowAnimation(false);
                      setAuth(true);
                      
                      makeAPICall(userType[data.EntityId], JSON.stringify(data.Message))
                      setShowAnimation(false);

                      //  if(data.EntityId===555555){
                      //    history.push({
                      //      pathname: `/mspv2/clients`,
                      //      state: { isLogin: true },
                      //    });
                      //    return
                      //  }
                        
                      //  history.push( 
                      //    `/${userType[data.EntityId]}/dashboard`, 
                      //   {
                      //      isLogin: true, 
                      //      userAccessKey: userAccessKey, 
                      //    }
                      //  );
                      
                    })
                    .catch((err) => {
                      setFormSubmit(1);
                      setRedirectingMessage(
                        "Creating your account, please wait.."
                      );
                      setShowAnimation(false);
                      setLoading(false);
                      CallToast(
                        "500 - Internal Server Error",
                        false,
                        false,
                        "v2style"
                      );
                      console.log("error- in api 1", error);
                    });
                }
              })
              .catch((error) => {
                setFormSubmit(3);
                setRedirectingMessage("Creating your account, please wait..");
                setShowAnimation(false);
                setLoading(false);
                CallToast("500 - Internal Server Error", false, false, "v2style");
                console.log("error- in api 2", error);
              });
          }
        })
        .catch((error) => {
          setFormSubmit(3);
          setRedirectingMessage("Creating your account, please wait..");
          setShowAnimation(false);
          setLoading(false);
          CallToast("500 - Internal Server Error", false, false, "v2style");
          console.log("login error", error);
        });
    }
  
     
  
   
  
    const strength = [
      {
        name: "Use A-Z uppercase, a-z lowercase, 0-9 number, symbols, 8-digits",
        maxValue: 0,
        color: "greyText",
        barColor: "bar-not-active",
      },
      {
        name: "Use A-Z uppercase, symbols, 0-9 number, 8-digits",
        maxValue: 1,
        color: "redText",
        barColor: "bar-filled-red",
      },
      {
        name: "Use symbols, 0-9 number, 8-digits",
        maxValue: 2,
        color: "redText",
        barColor: "bar-filled-red",
      },
      {
        name: "Use 0-9 number, 8-digits",
        maxValue: 3,
        color: "orangeText",
        barColor: "bar-filled-orange",
      },
      {
        name: "Use 8-digits",
        maxValue: 4,
        color: "orangeText",
        barColor: "bar-filled-orange",
      },
      {
        name: "Your password is strong",
        maxValue: 5,
        color: "greenText",
        barColor: "bar-filled",
      },
    ];
  
    useEffect(() => {
      const params = new URLSearchParams(window.location.search); 
      if (params?.get("email")) {
        setEmail(params.get("email"));
      }
    }, []);
  
    function togglePasswordShow() {
      if (passwordShow) return setPasswordShow(false);
      return setPasswordShow(true);
    }
  
   
  
    const handlePassword = (pwd) => {
      setPassword(pwd);
      var variations = {
        length: /^.{8,}$/.test(pwd),
        digits: /\d/.test(pwd),
        lower: /[a-z]/.test(pwd),
        upper: /[A-Z]/.test(pwd),
        nonWords: /\W/.test(pwd),
      };
  
      var strengthCount = 0;
      for (var check in variations) {
        strengthCount += variations[check] == true ? 1 : 0;
      }
      setPasswordStrength(strengthCount);
    };
  
    async function handleSubmit(value) {
      if (value === 0) {
       
  
        if (!password || passwordStrength < 5) {
          setPasswordError(
            "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters"
          );
          return;
        }
        if (confirmPassword!==password) {
          setConfirmPasswordError(
            "Passwords must be Same."
          );
          return;
        }
      }
  
     setFormSubmit(1)
     return
      
    }
  
  
  
   
  
 
    

   const [linkId, setLinkId] = useState("")
   const [pageLoading, setPageLoading] = useState(true)
   const [clientData, setClientData] = useState({
    email:"",
    name:"",
    password:"",
    jobRole:"",
   })
   function verifyLink(_linkId) {
     return new Promise((resolve, reject) => {
       CustomGetApi("CC_API/VerifyRegistration", authTokens, {
         linkId: _linkId,
       }).then((res) => {
         resolve(res);
       });
     });
   }

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const _linkId = urlParams.get("linkId");
      setLinkId(_linkId);
      verifyLink(_linkId).then((res) => {
        setPageLoading(false);
        if (res?.isLinkExpired || res?.isLinkInvalid) {
          setLinkValidation({ valid: false, message: res?.mr?.Message });
          // setTimeout(() => {
          //   history.push("/login")
          // }, 4000);
        } else {
          setLinkValidation({ valid: true, message: "" });
          console.log(res?.email,"res?.email")
          setClientData(prevData => ({
            ...prevData,
            email: res?.email
          }));
          
          setFormSubmit(0);
        }
      });
    }, []);
    // console.log(clientData,"clientData")
async function completeRegistration(){
  postData(authTokens,"CompleteRegistration",  {
    linkId: linkId,
    emailAddress: clientData.email,
    password:  password,
    repeatPassword:  password,
    happyToRecieve:happyToRecieve,
    fullName:fullName,
    jobTitle:jobTitle,
    file:selectedImage ,

  }).then((res) => {
    if (res?.isLinkExpired || res?.isLinkInvalid) {
      setLinkValidation({ valid: false, message: res?.mr?.Message });
      // RedirectToDashboard();
      // setTimeout(() => {
      //   history.push("/login")
      // }, 4000);
    } else {
      setShowAnimation(true);
      setUserAccessKey(res?.accessKey)
      RedirectToDashboard();
    }
    // if (res?.Success) {
    //   RedirectToDashboard(email, password);
    // } else {
    //   CallToast("500 - Internal Server Error", false, false, "v2style");
    // }
  }).catch(()=>{
    RedirectToDashboard();
  })
}
   
  return (
    <div className="d-flex w-100 h-100 p-4">
   {pageLoading===false &&   <>
      {
        linkValidation.valid ===false && 
        <div className="p-3 w-100">
        <div className="d-flex">
          <img alt="" src="/images/logo-large.svg" className="icon" />
        </div>

        <div className="d-flex align-items-center justify-content-center mt-5">
          <img src="/images/msp/snooze-icon.svg" alt="" />
        </div>
        <div className="f-24 f-700 d-flex align-items-center justify-content-center mt-4">
          {linkValidation.message}
        </div>
        <div className="mt-4 f-14 f-darkgrey d-flex align-items-center justify-content-center mx-auto w-25 text-center">
          Your login link has been expired or revoked. Contact sender again
          to review a new login link
        </div>
      </div>



      }
  {linkValidation.valid&&    <>
      <input
        type="file"
        accept="image/*"
        ref={fileUpload}
        className="d-none"
        onChange={(e) => {
          if (e.target.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);

            reader.onload = function () {
              setSelectedImage(reader.result);
              showCropperModal();
            };
          }
          // setSelectedImage(e.target.files[0]);
          // showCropperModal();
        }}
      />
      <div className="radius-24 border1 bg-grey w-25 p-4 d-flex flex-column overflow-y-auto hide-scrollbar">
        <img alt="" src="/images/mark-lamb.svg" className="w-60 mx-auto mb-5" />
        <div className="f-16 f-500">
        {<LeftSideWelcomeBannerText/>}
        </div>
        <br />
        <div>
          <div className="f-black f-16 f-700 highground-royal-font">Mark Lamb</div>
          <div className="f-darkgrey">Founder</div>
        </div>
      </div>

      <div className="w-25 mx-auto d-flex flex-column">
        {showAnimation ? (
          <div
            className="wrapperContainer paddingTopWrap text-center w-100"
            style={{ minHeight: "75vh" }}
          >            
            <Lottie                
                loop={false}
                play={true}
                animationData={animationData.default}
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                  filterSize: {
                    width: "200%",
                    height: "200%",
                    x: "-50%",
                    y: "-50%",
                  },
                }}                               
                onComplete={() => {
                }}
                width={200}
                height={200}
              ></Lottie>
            <div className="heading4">
              {/* Sending Verification Email... */}
              {redirectingMessage}
            </div>
          </div>
        ) : (
          <div className="m-auto ">
            <div>
              <Row
                className="align-items-center m-0"
                style={{ paddingTop: "40px" }}
              >
                <Col md={10} className="text-center mx-auto">
                  <div>
                    {formSubmit > 1 && (
                      <img
                        alt=""
                        src="/images/settings-v2/login-back.svg"
                        className="float-left cursor-pointer d-none"
                        onClick={() => setFormSubmit(0)}
                      />
                    )}
                    <img
                      alt=""
                      src="/images/logo-large.svg"
                      className="icon"
                    ></img>
                  </div>
                </Col>
              </Row>

              {formSubmit === 0 && (
                <Row
                  className="align-items-center"
                  style={{ paddingTop: "36px" }}
                >
                  <Col md={10} className="text-center pt-2 pb-4 mx-auto">
                    <div>
                      <h3 className="lgn_HeaderText2">Complete registration</h3>
                      <div className="greyText">
                        Complete creating your account for email
                        some_email@company.com
                      </div>
                    </div>
                  </Col>

                  <Col
                    md={12}
                    className="mx-auto"
                    style={{ paddingTop: "1em" }}
                  >
                    <Form className="userLoginForm" autoComplete="nope">
                      <div className="label-sign-up">Create Password</div>
                      <Form.Group
                        controlId="loginPassword"
                        className={`mt- ${
                          false && "border-danger"
                        }`}
                        style={{ border: "1px solid #EBEDF1" }}
                      >
                        <InputGroup className="mr-sm-2">
                          <Form.Control
                            className="inputControl"
                            size="lg"
                            placeholder="Type password"
                            type={passwordShow ? "text" : "password"}
                            onChange={(event) => {
                              handlePassword(
                                window.sanitizeHGtext(event.target.value)
                              );
                              setPasswordError("");
                            }}
                            defaultValue={password}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              className="passwordIcon"
                              onClick={togglePasswordShow}
                            >
                              {passwordShow ? (
                                <BsEyeSlashFill />
                              ) : (
                                <BsEyeFill />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      {passwordError && (
                        <span className="error-text">{passwordError}</span>
                      )}

                       
                      
                      <Fragment>
                        <div className="d-flex justify-content-between mt8px mb8px">
                          {[1, 2, 3, 4, 5].map((bar) => (
                            <div
                              className={
                                bar <= passwordStrength
                                  ? strength.find(
                                      (elmt) =>
                                        elmt.maxValue >= passwordStrength
                                    ).barColor
                                  : "bar-not-active"
                              }
                            ></div>
                          ))}
                        </div>
                        <div
                          className={
                            strength.find(
                              (elmt) => elmt.maxValue >= passwordStrength
                            ).color
                          }
                        >
                          {
                            strength.find(
                              (elmt) => elmt.maxValue >= passwordStrength
                            ).name
                          }
                        </div>
                      </Fragment>

                      <div className="label-sign-up">Repeat password</div>
                      <Form.Group
                        controlId="loginEmail"
                        className={`
                      
                     ${confirmPasswordError && "input-error"}
                     
                     
                     `}
                     style={{ border: "1px solid #EBEDF1" }}
                      >
                        <InputGroup className="mr-sm-2">
                          <Form.Control
                            className="inputControl"
                            size="lg"
                            placeholder="Repeat Password"
                            type={confirmPasswordShow ? "text" : "password"}
                            onChange={(event) => {
                              setConfirmPassword(event.target.value);

                              setConfirmPasswordError("");
                            }}
                            value={confirmPassword}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              className="passwordIcon"
                              onClick={() =>
                                setConfirmPasswordShow(!confirmPasswordShow)
                              }
                            >
                              {confirmPasswordShow ? (
                                <BsEyeSlashFill />
                              ) : (
                                <BsEyeFill />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      {confirmPasswordError && (
                        <span className="error-text">
                          {confirmPasswordError}
                        </span>
                      )}

                      <Form.Check
                        type="checkbox"
                        id="checkOne"
                        className="m-0 textBlackBold mt16px mobilePaddingTop"
                        label="I'm happy to receive updates by email"
                        onChange={() => setHappyToReceive(!happyToRecieve)}
                      />
                    </Form>
                    <div className="mt-4">
                      <Button
                        size="lg"
                        variant="blue"
                        disabled={confirmPassword === "" || password === ""}
                        className={`form-control btnControl     ${
                          confirmPassword === "" || password === ""
                            ? "button-disabled"
                            : ""
                        } `}
                        onClick={() => handleSubmit(0)}
                      >
                        {loading ? (
                          <span>
                            <Spinner
                              size="sm"
                              className="mr8px"
                              animation="border"
                            />
                            Please wait...
                          </span>
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              {formSubmit === 1 && (
                <Row
                  className="align-items-center slideLeft m-0"
                  style={{ paddingTop: "36px" }}
                >
                  <Col md={10} className="text-center pt-2 pb-4 mx-auto">
                    <div>
                      <h3 className="lgn_HeaderText2">Almost there...</h3>
                      <div className="greyText">
                        Once you provide us with a few more details and you can
                        get started transforming your cyber security
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-between align-items-center">
                      <img
                        className="rounded-circle border-grey camera-icon cursor-pointer"
                        src={
                          selectedImage
                            ? selectedImage
                            : "/images/msp/dummy-camera-icon.svg"
                        }
                        alt=""
                        // onClick={() => setShowUploadModal(true)}
                        width="79"
                      />

                      {selectedImage ? (
                        <div>
                          <Button
                            className="hg-blue-btn"
                            onClick={() => {
                              fileUpload.current.click();
                            }}
                          >
                            Change logo
                          </Button>
                          <Button
                            className="hg-tranparent-grey-btn"
                            onClick={() => {
                              setSelectedImage("");

                              CallToast(
                                "Logo removed",
                                false,
                                false,
                                "v2style"
                              );
                            }}
                          >
                            Remove logo
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className="hg-blue-btn"
                          onClick={() => {
                            fileUpload.current.click();
                          }}
                        >
                          Upload photo
                        </Button>
                      )}
                    </div>
                  </Col>
                  <Col
                    // md={10}
                    md={12}
                    className="mx-auto text-center"
                    style={{ paddingTop: "1em" }}
                  >
                    <Form
                      className="userLoginForm"
                      id="secondForm"
                      autoComplete="none"
                    >
                      <div className="label-sign-up">Full name</div>
                      <Form.Group
                        controlId="fullName"
                        // className={eFullName && "border-danger"}
                        style={{ border: "1px solid #EBEDF1" }}
                      >
                        <InputGroup className="mr-sm-2">
                          <Form.Control
                            className="inputControl"
                            size="lg"
                            name="fullName"
                            placeholder="Enter your full name"
                            onChange={(event) =>
                              setFullName(
                                window.sanitizeHGtext(event.target.value)
                              )
                            }
                            value={fullName}
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className="label-sign-up">Job Title</div>
                      <Form.Group
                        controlId="jobTitle"
                        className={`mt- ${jobTitleError && "border-danger"}`}
                        style={{ border: "1px solid #EBEDF1" }}
                      >
                        <InputGroup className="mr-sm-2">
                          <Form.Control
                            className="inputControl"
                            size="lg"
                            name="company-name"
                            placeholder="Enter Job title"
                            onChange={(event) =>
                              setJobTitle(
                                window.sanitizeHGtext(event.target.value)
                              )
                            }
                            defaultValue={jobTitle}
                          />
                        </InputGroup>
                      </Form.Group>
                      {jobTitleError && (
                        <span className="error-text">{jobTitleError}</span>
                      )}

                      <div className="mt-4">
                        <Button
                          disabled={fullName === "" || jobTitle === ""}
                          size="lg"
                          variant="blue"
                          className={`form-control btnControl ${
                            fullName === "" || jobTitle === ""
                              ? "button-disabled"
                              : ""
                          }`}
                          onClick={() => {
                            setLoading(true);
                            //  RedirectToDashboard()
                            completeRegistration()
                          }}
                        >
                          {loading ? (
                            <span>
                              <Spinner size="sm" animation="border" /> Please
                              wait..
                            </span>
                          ) : (
                            "Create Account"
                          )}
                        </Button>
                        <div
                          className="d-flex align-items-center justify-content-center f-grey mt-3 pointer"
                          onClick={() => setFormSubmit(0)}
                        >
                          Back
                        </div>
                      </div>
                    </Form>
                  </Col>
                </Row>
              )}
            </div>
            <Row className="">
              <Col md={10} className="pt-4 pb-4 mx-auto ">
                {!(formSubmit > 1) && (
                  <div className="text-center">
                    <p className="lgnGreyText terms">
                      By clicking “Continue” above, you acknowledge that you
                      have read and understood, and agree to M3 Networks Limited
                      &nbsp;
                      <a
                        className="lgnLinks"
                        target="_blank"
                        href="https://www.highground.io/highground-terms-and-conditions/"
                      >
                        Terms & Conditions
                      </a>
                      &nbsp; and
                      <a
                        href="https://www.highground.io/privacy_policy/"
                        target="_blank"
                        className="lgnLinks"
                      >
                        &nbsp; Privacy Policy
                      </a>
                      .
                    </p>
                    <p className="textBlack mb-5">
                      Already have an account?{" "}
                      <Link to="/login" className="txt-blue">
                        Log in
                      </Link>
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
        {/* </Col> */}

        {/* </Row> */}

        <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
          © 2024 HighGround.
          &nbsp;
          &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/privacy_policy/",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </u>
          &nbsp;
          &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/highground-terms-and-conditions/",
                "_blank"
              );
            }}
          >
            Terms & Conditions
          </u>
        </div>
      </div>
      <ImageCropperModal
        show={cropperModal}
        hideModal={() => {
          setCropperModal(false);
        }}
        profileImage={selectedImage}
        setProfileImage={setSelectedImage}
        uploadType={99}
      />
      </>}

      </>}
    </div>
  );
}

export default Registrationv2


 