import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { getAccessStateValue } from "../../App/App";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { ResetSettingsModal } from "../resetSettings/ResetSettings";
import AttackSurfaceNotificationsModal from "./AttackSurfaceNotificationsModal";
import CyberScoreNotificationModal from "./CyberScoreNotificationModal";
import "./notifications.scss";
import NotificationsV2Loader from "./NotificationsLoader";
import SupplyChainNotificationsModal from "./SupplyChainNotificationsModal";
const NotificationsV2 = () => {
  const { authTokens } = useAuth();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [allowNotifications, setAllowNotifications] = useState(true);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [type, setType] = useState("");
  const [
    showAttackSurfaceNotificationModal,
    setShowAttackSurfaceNotificationModal,
  ] = useState(false);
  // Reset settings Setup
  const [showResetModal, setshowResetModal] = useState(false);

  const [
    showSupplyChainNotificationModal,
    setShowSupplyChainNotificationModal,
  ] = useState(false);

  const showResettModal = () => {
    setshowResetModal(true);
  };
  const hideResetModal = () => {
    setshowResetModal(false);
  };
  const showModal = (type) => {
    setType(type);
    setShowNotificationModal(true);
  };
  const hideNotificationModal = () => {
    setShowNotificationModal(false);
  };
  async function handleSave(notifName, notifValue) {
    await postData(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: notifName,
      NotificationValue: notifValue,
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    setMainPageLoading(true);
    getAPIData(103, authTokens).then((response) => {
      setAllowNotifications(response?.Btn_Browser_Allow);
      setMainPageLoading(false);
    });
  }, []);

  return (
    <>
      {mainPageLoading ? (
        <NotificationsV2Loader />
      ) : (
        <div className="noitificaton-wrapper">
          <div className="header mb-3">Notifications</div>
          <Row>
            <div className="option-wrapper">
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div className="f-black f-500">
                    Allow notifications in the browser
                  </div>
                  <div>
                    {getAccessStateValue("AccessState_SystemSettings") >= 3 ? (
                      <Form.Group
                        controlId={"notification-check"}
                        className="pointer"
                      >
                        <Form.Check
                          type="switch"
                          id="notification-toggle"
                          className="m-0 allow-notification-switch switch-shadow-none pointer"
                          checked={allowNotifications}
                          onChange={(e) => {
                            setAllowNotifications(e.target.checked);
                            handleSave(
                              "Browser_Notification",
                              Number(e.target.checked)
                            );
                          }}
                          // defaultValue={1}
                        />
                      </Form.Group>
                    ) : (
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    )}
                  </div>
                </div>
              </Col>
              <hr className="m-0" />
              <Col
                xs={12}
                className={`${getAccessStateValue("AccessState_SystemSettings") >= 3 ? 'pointer': 'not-allowed'} `}
                onClick={() => {
                  if (getAccessStateValue("AccessState_SystemSettings") >= 3) {
                    showModal("actioncenter");
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div>
                    <p className="f-black f-500 mb-1">Action Centre</p>
                    <p className="f-12 f-grey">
                      Configure how you are notified about activities and
                      required actions in your action centre
                    </p>
                  </div>
                  {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                    <>
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="hand"
                      />
                    </>
                  )}
                </div>
              </Col>
              <hr className="m-0" />
              <Col
                xs={12}
                className={` d-none ${getAccessStateValue("AccessState_SystemSettings") >= 3 ? 'pointer': 'not-allowed'} `}
                onClick={() => {
                  if (getAccessStateValue("AccessState_SystemSettings") >= 3) {
                    setShowAttackSurfaceNotificationModal(true);
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div>
                    <p className="f-black f-500 mb-1">Attack Surface</p>
                    <p className="f-12 f-grey">
                      Configure how you are notified about activities and
                      required actions in your attack surface
                    </p>
                  </div>
                  {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                    <>
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="hand"
                      />
                    </>
                  )}
                </div>
              </Col>
              {/* <hr className="m-0" /> */}
              <Col
                xs={12}
                className={`${getAccessStateValue("AccessState_SystemSettings") >= 3 ? 'pointer': 'not-allowed'} `}
                onClick={() => {
                  if (getAccessStateValue("AccessState_SystemSettings") >= 3) {
                    setShowSupplyChainNotificationModal(true);
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div>
                    <p className="f-black f-500 mb-1">
                      Supply Chain Monitoring
                    </p>
                    <p className="f-12 f-grey">
                      Configure how you are notified about health of your supply
                      chain
                    </p>
                  </div>
                  {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                    <>
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="hand"
                      />
                    </>
                  )}
                </div>
              </Col>
              <hr className="m-0" />
              <Col
                xs={12}
                className={`${getAccessStateValue("AccessState_SystemSettings") >= 3 ? 'pointer': 'not-allowed'} `}
                onClick={() => {
                  if (getAccessStateValue("AccessState_SystemSettings") >= 3) {
                    showModal("cyberscore");
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div>
                    <p className="f-black f-500 mb-1">CyberScore</p>
                    <p className="f-12 f-grey">
                      Configure how you are notified about CyberScore changes
                    </p>
                  </div>
                  {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                    <>
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="hand"
                      />
                    </>
                  )}
                </div>
              </Col>
              <hr className="m-0" />
              <Col
                xs={12}
                className={`${getAccessStateValue("AccessState_SystemSettings") >= 3 ? 'pointer': 'not-allowed'} `}
                onClick={() => {
                  if (getAccessStateValue("AccessState_SystemSettings") >= 3) {
                    showModal("cyberthreat");
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div>
                    <p className="f-black f-500 mb-1">CyberThreat Level</p>
                    <p className="f-12 f-grey">
                      Configure how you are notified about CyberThreat Level
                      changes
                    </p>
                  </div>
                  {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                    <>
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="hand"
                      />
                    </>
                  )}
                </div>
              </Col>
              <hr className="m-0" />
              <Col
                xs={12}
                className={`${getAccessStateValue("AccessState_SystemSettings") >= 3 ? 'pointer': 'not-allowed'} `}
                onClick={() => {
                  if (getAccessStateValue("AccessState_SystemSettings") >= 3) {
                    showModal("cybercompliance");
                  }
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div>
                    <p className="f-black f-500 mb-1">CyberCompliance</p>
                    <p className="f-12 f-grey">
                      Configure how you are notified about CyberCompliance
                      changes
                    </p>
                  </div>
                  {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                    <>
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="hand"
                      />
                    </>
                  )}
                </div>
              </Col>
              <hr className="m-0" />
              <Col
                xs={12}
                className={`${getAccessStateValue("AccessState_SystemSettings") >= 3 ? 'pointer': 'not-allowed'} `}
                onClick={() => {
                  if (getAccessStateValue("AccessState_SystemSettings") >= 3) {
                    showResettModal();
                  }
                  // showModal("cybercompliance");
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div>
                    <p className="f-black f-500 mb-1">Reset settings</p>
                    <p className="f-12 f-grey">
                      Restore settings to their original defaults
                    </p>
                  </div>
                  {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                    <>
                      <img
                        className="ml-2"
                        width={14}
                        height={14}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="hand"
                      />
                    </>
                  )}
                </div>
              </Col>
            </div>
          </Row>
          <CyberScoreNotificationModal
            type={type}
            show={showNotificationModal}
            hideModal={hideNotificationModal}
          />
          <AttackSurfaceNotificationsModal
            show={showAttackSurfaceNotificationModal}
            hideModal={() => setShowAttackSurfaceNotificationModal(false)}
          />
          <SupplyChainNotificationsModal
            show={showSupplyChainNotificationModal}
            hideModal={() => setShowSupplyChainNotificationModal(false)}
          />
          <ResetSettingsModal
            showResetModal={showResetModal}
            hideModal={hideResetModal}
          />
        </div>
      )}
    </>
  );
};

export default NotificationsV2;
