import React, { useState, useEffect } from "react";

const SlidingModal = ({
  children,
  show,
  id,
  width,
  parentID,
  onHide,
  className,
  height,
  marginTop,
  top,
  right,
  modalZindex,
  backdropZindex,
  isDraggableParent
}) => {
  //  assets-list parent = assets-list-full-screen-modal-parent
  const [modalClasses, setModalClasses] = useState("hg-sliding-modal");
  function toCloseModal() {
     
    if (onHide) {
      onHide();
    }
  }
  // useEffect(() => {
  //   try {
  //     if (show === true) {
  //       debugger
  //       let parent = document.getElementById(parentID);
  //       let modalData = document.getElementById(id);
  //       if (!modalData.parentNode || modalData.parentNode !== parent) {
           
  //         parent.appendChild(modalData);
  //       }
  //       // parent.appendChild(modalData);
  //       setTimeout(() => {
  //         setModalClasses("hg-sliding-modal open");
  //       }, 10);

  //       document.body.insertAdjacentHTML(
  //         "beforeend",
  //         `<div id="sliding-modal-backdrop" style="z-index: ${backdropZindex};"></div>` //onclick="toCloseModal()"
  //       );

  //       let backdrops = document.body.querySelectorAll(
  //         "#sliding-modal-backdrop"
  //       );
  //       if (backdrops.length > 0) {
  //         let lastBackdrop = backdrops[backdrops.length - 1];
  //         lastBackdrop.addEventListener("click", toCloseModal);
  //       }
  //     }
  //     if (show === false) {
  //       setModalClasses("hg-sliding-modal");

  //       let backdrops = document.body.querySelectorAll(
  //         "#sliding-modal-backdrop"
  //       );
  //       console.log(backdrops, "backdrops 111");
  //       if (backdrops.length > 0) {
  //         let lastBackdrop = backdrops[backdrops.length - 1];
  //         lastBackdrop.remove();
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [show]);

  useEffect(() => {
    try {
      if (show === true) {
          
        // let parent = document.getElementById(parentID);
        // let modalData = document.getElementById(id);
        // parent.appendChild(modalData);
       
       
  
        setTimeout(() => {
          setModalClasses("hg-sliding-modal open");
        }, 10);
  
        document.body.insertAdjacentHTML(
          "beforeend",
          `<div id="sliding-modal-backdrop" style="z-index: ${backdropZindex};"></div>`
        );
          
        try{
          let backdrops = document.body.querySelectorAll("#sliding-modal-backdrop");
          if (backdrops.length > 0) {
            let lastBackdrop = backdrops[backdrops.length - 1];
            lastBackdrop.addEventListener("click", toCloseModal);
          }
        }catch(e){
          console.log(e,"backdrops")
        }
        
        return ()=>{
          console.log("clean up done")
        }
      }
      if (show === false) {
        setModalClasses("hg-sliding-modal");
  
        let backdrops = document.body.querySelectorAll("#sliding-modal-backdrop");
        if (backdrops.length > 0) {
          let lastBackdrop = backdrops[backdrops.length - 1];
          lastBackdrop.remove();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [show]);

  if(!show){
    return <></>
  }
  

  return (
    <div
      className={`${modalClasses} hide-scrollbar ${className}     ${isDraggableParent ?   "hg-sliding-modal-draggable-parent"  :""}`}
      id={id}
      style={{
        width: width,
      }}
    >
      <div
        className="w-100 h-100 position-relative d-flex flex-column hide-scrollbar"
        style={{
          overflowX: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};

SlidingModal.Header = (props) => (
  <div
    className={`${props.className} position-sticky top-0 bg-white z-Index-1000`}
  >
    {props.children}
  </div>
);
SlidingModal.Body = (props) => (
  <div className={props.className}>{props.children}</div>
);
SlidingModal.Footer = (props) => (
  <div
    className={`${props.className}  bottom-0 bg-white z-Index-100 sliding-modal-footer`}
    style={{ marginTop: "auto" }}
  >
    {props.children}
  </div>
);

export default SlidingModal;
