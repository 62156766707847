import React, { useState, useEffect, useRef } from "react";
import { FiPaperclip, FiCheck, FiX, FiMinusCircle } from "react-icons/fi";
import {
  Form,
  InputGroup,
  Button,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDb } from "../context/db";
import { useAuth } from "../context/Auth";
import "./IncidentResponseForm.css";
import { getAPIData, getRequestOptions } from "../../Utils/commonApi";
import { support } from "jquery";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { useDispatch } from "react-redux";
import { setsegment } from "../../redux/segmentEventStatus/actions";

const IncidentResponseForm = (props) => {

  //parameters
  const pageType = props.type;
  const dispatch = useDispatch();
  //Contexts
  const { dbObj } = useDb();
  const { authTokens, setAuthTokens } = useAuth();
  //Suport States
  const supportTypes = ["Compliance"];
  const supportPriorities = {
    low: "grey",
    medium: "blue",
    high: "yellow",
    critical: "red",
  };

  const [formType, setformType] = useState("");

  const [formDescription, setformDescription] = useState();

  //States
  const [formData, setFormData] = useState(false);

  const [incidentType, setIncidentType] = useState("");
  const [incidentSubType, setIncidentSubType] = useState("");
  const [incidentSubject, setIncidentSubject] = useState(
    pageType === "support" ? supportTypes[0] : ""
  );
  const [incidentSubjectContent, setIncidentSubjectContent] = useState("Low");

  //SubType Dynamic State
  const [subTypeList, setSubTypeList] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [file, setFile] = useState("");
  const [phoneNo, setPhoneNo] = useState();
  const [summary, setSummary] = useState();
  const [type] = useState();
  const [incidentSubjectDescription, setIncidentSubjectDescription] =
    useState();
  const [sendingIncidentReport, setSendingIncidentReport] = useState(false);
  const [sendIncidentReport, setSendIncidentReport] = useState(true);
  const [supportCreatingTicket, setSupportCreatingTicket] = useState(false);
  const [supportCreateTicket, isSupportCreateTicket] = useState(true);
  const [error, setError] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [ticketType, setTicketType] = useState("");
  const message = error ? error : "";

  const onFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // console.log(reader.result);
    };
    reader.readAsDataURL(file);
    if (file && !file.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      setFile("");
      setFileUpload(true);
    } else {
      setFile(file);
      setFileUpload(true);
    }
  };
  async function uploadIncidentResponse(event) {
    event.preventDefault();
    setFileUpload(false);

    if (handleValidations()) {
      return;
    } else {
      var message = JSON.parse(localStorage.getItem("user_MessageObj"));
      setError(false);
      setSendIncidentReport(false);

      setSendingIncidentReport(true);
      const incidentData = {
        TicketDataJSON: JSON.stringify({
          TicketDetails: {
            ContactName: dbObj.UserDetail.FullName,
            ContactEmail: dbObj.UserDetail.UserName,
            ContactPhone: phoneNo,
          },
          TicketNoteDetails: {
            Description: incidentSubjectDescription,
            Subject: incidentSubject,
            Type: incidentType,
            SubType: incidentSubType,
          },
          TicketType: 0,
        }),
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Files: file,
      };
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateConnectwiseTicket`,
        getRequestOptions(authTokens, "POST", incidentData)
      ).then((response) => response.json());
      setPhoneNo("");
      setIncidentSubjectDescription("");
      setIncidentSubject("");
      setFile("");

      if (data.Success) {
        dispatch(setsegment({ cyberScoreRelatedEventTriggered: true }));
        setSendingIncidentReport(false);
        setIsSubmit(true);
      } else {
        setError(data.Message);
        setSendingIncidentReport(false);
        setSendIncidentReport(true);
      }
    }
  }

  const handleValidations = () => {
    if (!phoneNo && !incidentSubjectDescription && !incidentSubject) {
      setError("All fields are required");
      return true;
    }
    if (!phoneNo) {
      setError("Phone No. is required");
      return true;
    }
    if (phoneNo) {
      if (valdatePhoneNo()) {
        return true;
      }
    }
    if (!incidentSubjectDescription) {
      setError("Description is required");
      return true;
    }
    if (!incidentSubject) {
      setError("Subject is required");
      return true;
    }
    if (file && !file.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      setError("Please select jpeg , pdf and png file");
      return true;
    }

    return false;
  };

  async function uploadSupportTicket(event) {
    event.preventDefault();
    setFileUpload(false);

    if (validateSupportTicket()) {
      return;
    } else {
      var message = JSON.parse(localStorage.getItem("user_MessageObj"));
      setError(false);
      isSupportCreateTicket(false);
      setSupportCreatingTicket(true);
      const supportData = {
        TicketDataJSON: JSON.stringify({
          TicketDetails: {
            ContactName: dbObj.UserDetail.FullName,
            ContactEmail: dbObj.UserDetail.UserName,
            ContactPhone: phoneNo,
          },
          TicketNoteDetails: {
            Subject: summary,
            Type: formType,
            Priority: incidentSubjectContent,

            Description: formDescription,
          },
          TicketType: 1,
        }),
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Files: file,
      };
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateConnectwiseTicket`,
        getRequestOptions(authTokens, "POST", supportData)
      ).then((response) => response.json());
      setSummary("");
      setPhoneNo("");
      setformDescription("");
      setIncidentSubjectContent("Low");
      setFile("");
      if (data.Success) {
        TrackUser("Support ticket created.");
        dispatch(setsegment({ onceTicketCreated: true }));

        setSupportCreatingTicket(false);
        setIsSubmit(true);
      } else {
        setError(data.Message);
        setSupportCreatingTicket(false);
        isSupportCreateTicket(true);
      }
    }
  }

  const valdatePhoneNo = () => {
    var pattern = /^\+?([0-9]{0,2})\)?[-. ]?([0-9]{10,16})$/;
    if (!pattern.test(phoneNo)) {
      setError("Please enter a valid phone number (min 10 digits)");
      return true;
    }
    return false;
  };
  const validateSupportTicket = () => {
    if (!phoneNo && !summary && !formDescription) {
      setError("All fields are required");
      return true;
    }
    if (!phoneNo) {
      setError("Phone no. is required");
      return true;
    }
    if (phoneNo) {
      if (valdatePhoneNo()) {
        return true;
      }
    }
    if (!summary) {
      setError("Summary is required");
      return true;
    }
    if (!formDescription) {
      setError("Description is required");
      return true;
    }
    if (file && !file.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      setError("Please select jpeg , pdf and png file");

      return true;
    }
    return false;
  };

  useEffect(() => {
    if (pageType !== "support") {
      getFormData();
    } else {
      getFormType();
    }
  }, []);

  async function getFormData() {
    let data = await getAPIData("12", authTokens, {
      optionEnum1: 0
    });
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setIncidentType();
      setFormData();
      data = await getAPIData("12", data.authToken);
    } else {
      setIncidentType(data.CategoryDDList[0]);
      setFormData(data);
    }
  }

  async function getUpdatedSubType(incident) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetIncidentSubType?Type=${incident}`,
      getRequestOptions(authTokens)
    ).then((response) => response.json());
    setIncidentSubType(data.IncidentSubType[0]);
    setSubTypeList(data.IncidentSubType);
  }
  async function getFormType() {
    let data = await getAPIData("12", authTokens, {
      optionEnum1: 1
    });
    setTicketType(data.CategoryDDList);
    setformType(data.CategoryDDList[0]);
  }

  const removeFileHandler = () => {
    setFile("");
    setFileUpload(false);
  };

  useEffect(() => {
    if (incidentType != "") getUpdatedSubType(incidentType);
    else if (formData) getUpdatedSubType(formData.CategoryDDList[0]);
    if (
      phoneNo ||
      incidentSubjectDescription ||
      incidentSubject ||
      incidentType ||
      incidentSubType ||
      summary ||
      incidentSubjectContent ||
      formDescription ||
      type ||
      formType
    ) {
      setIsSubmit(false);
      setSendIncidentReport(true);
      isSupportCreateTicket(true);
    }
  }, [
    incidentType,
    phoneNo,
    incidentSubjectDescription,
    incidentSubject,
    incidentType,
    incidentSubType,
    summary,
    incidentSubjectContent,
    formDescription,
    type,
    formType,
  ]);
  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const SupportComp = () => (
    <Form
      className="incidentResponseForm"
      onSubmit={(event) => uploadSupportTicket(event)}
    >
      <div style={{ padding: "0 24px" }}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Phone:</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            id="supportPhone"
            type="text"
            value={phoneNo}
            onChange={(event) => setPhoneNo(window.sanitizeHGtext(event.target.value).toString())}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Summary:</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            id="supportSummary"
            type="text"
            value={summary}
            onChange={(event) => setSummary(event.target.value)}
          />
        </InputGroup>
        <InputGroup className="grey-select">
          <InputGroup.Prepend>
            <InputGroup.Text>Type:</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            id="supportType"
            as="select"
            className="bgNone"
            onChange={(event) => setformType(event.target.value)}
          >
            {ticketType &&
              ticketType.map((element) => (
                <option value={element}>{element}</option>
              ))}
          </Form.Control>
        </InputGroup>
        <div className="companyModalContent pl-0 pr-0">
          <div className="greyContainer-4">
            <p
              onClick={() => setIncidentSubjectContent("Low")}
              style={
                incidentSubjectContent == "Low"
                  ? { background: "#B6B6BF", color: "white" }
                  : { color: "#B6B6BF" }
              }
            >
              Low
            </p>
            <p
              onClick={() => setIncidentSubjectContent("Medium")}
              style={
                incidentSubjectContent == "Medium"
                  ? { background: "#008FD4", color: "white" }
                  : { color: "#008FD4" }
              }
            >
              Medium
            </p>
            <p
              onClick={() => setIncidentSubjectContent("High")}
              style={
                incidentSubjectContent == "High"
                  ? { background: "#FFAC2C", color: "white" }
                  : { color: "#FFAC2C" }
              }
            >
              High
            </p>
            <p
              onClick={() => setIncidentSubjectContent("Critical")}
              style={
                incidentSubjectContent == "Critical"
                  ? { background: "#F36060", color: "white", fontSize: "1em" }
                  : { color: "#F36060", fontSize: "1em" }
              }
            >
              Critical
            </p>
          </div>
          <p class="informationMessage">Priority</p>
        </div>

        <Form.Group controlId="supportDescription">
          <Form.Control
            as="textarea"
            rows="7"
            placeholder="Description"
            value={formDescription}
            onChange={(event) => setformDescription(event.target.value)}
          />
        </Form.Group>
      </div>
      <div style={{ padding: "5px 24px" }}>
        <div className="d-flex justify-content-between mb-2">
          <div className="imageUpload p-0">
            <input
              id="file-input"
              type="file"
              style={{ cursor: "pointer" }}
              accept=".png,.jpeg, .pdf"
              onChange={(event) => onFileUpload(event)}
              onClick={(event) => (event.target.value = "")}
            />
            <FiPaperclip />
            <p>
              <b className="fnt14">Attach File</b>
            </p>
          </div>
          <div className="crsrpoint">
            {file && fileUpload ? (
              <div className="" onClick={() => removeFileHandler()}>
                <span className="textBlackBold pr-2">
                  <b>Remove</b>{" "}
                </span>
                <FiMinusCircle className="redText" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <p className=" greyText wordSpacing pt-1">{file ? file.name : ""}</p>
        {file && fileUpload ? (
          <p className="heading3 textBlackBold greenText">
            <FiCheck style={{ color: "#40d88e" }} />
            <span>File Uploded Succesfully</span>
          </p>
        ) : !file && fileUpload ? (
          <p className="heading3 textBlackBold redText">
            <FiX style={{ color: "#f36060" }} />
            <span className="pl-1">File Type Disallowed</span>
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="mt16px" style={{ background: "#f6f8fb" }}>
        {error != false && (
          <Form.Control.Feedback type="invalid" className="d-block pt-3">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 200, hide: 250 }}
              overlay={renderTooltip(message)}
            >
              <span>
                <img src="/images/icons/error.svg" /> Create Ticket attempt
                failed
              </span>
            </OverlayTrigger>
          </Form.Control.Feedback>
        )}
        <Button
          className={` ${isSubmit
            ? "btn-disable mt-16px disableIncidentBtn"
            : "w-100 submit-btn mt-16px"
            }`}
          type="submit"
        >
          {isSubmit ? (
            "Ticket Submitted!"
          ) : supportCreatingTicket ? (
            <span>
              <Spinner
                animation="border"
                variant="light"
                className="mr8px"
                size="sm"
              />{" "}
              Creating Ticket
            </span>
          ) : supportCreateTicket ? (
            "Create Ticket"
          ) : (
            ""
          )}
        </Button>
      </div>
    </Form>
  );

  return (
    <React.Fragment>
      {/* {alertDialog} */}
      <div className="grayBackground roundedCorners incidentResponse reportIncident ">
        <div style={{ paddingTop: "42px" }}>
          <div style={{ padding: "0 24px" }}>
            <div className="txt-grey">The report will be sent from:</div>
            <div className="txt-grey">
              Name: <b>{dbObj.UserDetail.FullName}</b>
            </div>
            <div className="txt-grey">
              Email: <b>{dbObj.UserDetail.UserName}</b>
            </div>
            <br />
            <div className="txt-grey">
              You can change this in the profile settings
            </div>
          </div>
          <div className="mt24px">
            {pageType == "support" ? (
              SupportComp()
            ) : formData ? (
              <Form
                className="incidentResponseForm"
                onSubmit={(event) => uploadIncidentResponse(event)}
              >
                <div style={{ padding: "0 24px" }}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Phone:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      id="supportPhone"
                      type="text"
                      value={phoneNo}
                      onChange={(event) =>
                        setPhoneNo(event.target.value.toString())
                      }
                    />
                  </InputGroup>

                  <InputGroup className="grey-select">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Incident Type:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      id="incidentType"
                      as="select"
                      className="bgNone"
                      onChange={(event) => setIncidentType(event.target.value)}
                    // required
                    >
                      {formData.CategoryDDList.map((element) => (
                        <option>{element}</option>
                      ))}
                    </Form.Control>
                  </InputGroup>

                  <InputGroup className="grey-select mt-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Incident Subtype:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      id="incidentSubType"
                      as="select"
                      className="bgNone text-ellipse"
                      onChange={(event) =>
                        setIncidentSubType(event.target.value)
                      }
                    >
                      {subTypeList
                        ? subTypeList.map((element) => (
                          <option>{element}</option>
                        ))
                        : ""}
                    </Form.Control>
                  </InputGroup>

                  <InputGroup className="mt-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Subject of Incident:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      id="incidentSubject"
                      // type="text"
                      value={incidentSubject}
                      onChange={(event) =>
                        setIncidentSubject(event.target.value)
                      }
                    />
                  </InputGroup>

                  <Form.Group controlId="incidentSubjectExtended">
                    <Form.Control
                      as="textarea"
                      rows="7"
                      placeholder="Description"
                      value={incidentSubjectDescription}
                      onChange={(event) =>
                        setIncidentSubjectDescription(event.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                <div style={{ padding: "5px 25px" }}>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="imageUpload p-0">
                      <input
                        id="file-input"
                        type="file"
                        accept=".png,.jpeg, .pdf"
                        style={{ cursor: "pointer" }}
                        onChange={(event) => onFileUpload(event)}
                        onClick={(event) => (event.target.value = "")}
                      />
                      <p>
                        <b className="fnt14">Add File</b>
                      </p>
                      <FiPaperclip />
                    </div>
                    <div className="crsrpoint ">
                      {file ? (
                        <div
                          className="pt-1"
                          onClick={() => removeFileHandler()}
                        >
                          <span className="textBlackBold pr-2">
                            <b>Remove</b>{" "}
                          </span>
                          <FiMinusCircle className="redText" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <p className=" greyText wordSpacing">
                    {file ? file.name : ""}
                  </p>
                  {file && fileUpload ? (
                    <p className="heading3 textBlackBold greenText">
                      <FiCheck style={{ color: "#40d88e" }} />
                      <span>File Uploded Succesfully</span>
                    </p>
                  ) : !file && fileUpload ? (
                    <p className="heading3 textBlackBold redText">
                      <FiX style={{ color: "#f36060" }} />
                      <span className="pl-1">File Type Disallowed</span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt16px" style={{ background: "#f6f8fb" }}>
                  {error != false && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block pt-3"
                    >
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 200, hide: 250 }}
                        overlay={renderTooltip(message)}
                      >
                        <span>
                          <img src="/images/icons/error.svg" /> Incident Report
                          attempt failed
                        </span>
                      </OverlayTrigger>
                    </Form.Control.Feedback>
                  )}
                  <Button
                    className={` ${isSubmit
                      ? "btn-disable mt-16px disableIncidentBtn"
                      : "w-100 submit-btn mt-16px"
                      }`}
                    type="submit"
                  >
                    {isSubmit ? (
                      "Incident Report Submitted!"
                    ) : sendingIncidentReport ? (
                      <span>
                        <Spinner
                          animation="border"
                          variant="light"
                          size="sm"
                          className="mr8px"
                        // style={{ marginRight: "5px" }}
                        />
                        Sending Incident Report
                      </span>
                    ) : sendIncidentReport ? (
                      "Send Incident Report"
                    ) : (
                      ""
                    )}
                  </Button>
                </div>
              </Form>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IncidentResponseForm;
