import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./dropdown.scss";

const CompanyTimeZoneDropDown = (props) => {
  const [search, setSearch] = useState("");

  return (
    <Dropdown className="custom_drop-down">
      <Dropdown.Toggle className={`${props.selected ? "selected" : ""}
      
      ${props.disabled && props.disabledIcon ? "locked" : ""}
      
      `}>
        <div className="selected-option">
          {props.selected
            ? props.showid
              ? props.selected[props.fieldId]
              : props.selected[props.fieldValue]
            : props.placeholder}
        </div>
        {props.disabled && props.disabledIcon ? (
              <>
                {" "}
                <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
            
                   
                />
              </>
            ) : (
              <></>
            )}
      </Dropdown.Toggle>
 {  !props.disabled&&   <Dropdown.Menu>
        <div className={`dropdown_item ${null}`}>
          <div className="d-flex align-items-center mb-3">
            <img alt="" src="/images/settings-v2/search-icon.svg" />
            <input
              type="text"
              className="search-timezone pl-2"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="light-text">{props.header || "Choose time zone"}</div>
        </div>
        <div
          className="hide-scrollbar"
          style={{ maxHeight: "270px", overflowY: "scroll" }}
        >
          {props?.data
            ?.filter((val) => {
              if (props.data && search) {
                return val[props?.fieldValue]
                  .toLowerCase()
                  .includes(search.toLowerCase());
              } else {
                return true;
              }
            })
            .map((val) => (
              <div
                className={`dropdown_item ${
                  props.selected &&
                  props.selected[props.fieldId] == val[props.fieldId]
                    ? "selected"
                    : ""
                }`}
                onClick={(e) => {
                  props.onClick(val[props.fieldId], val[props.fieldValue]);
                  e.target.closest(".custom_drop-down").click();
                }}
                key={val[props.fieldId]}
                data-id={val[props.fieldId]}
                data-value={val[props.fieldValue]}
              >
                {val[props.fieldValue]}
                {props.selected &&
                props.selected[props.fieldId] == val[props.fieldId] ? (
                  <img
                    src={"/images/settings-v2/black-tick.svg"}
                    className="float-right tick "
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
      </Dropdown.Menu>}
    </Dropdown>
  );
};

export default CompanyTimeZoneDropDown;
