import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ActionCenter from "../../ActionCenter/Action_center_modals/ActionCentreSettings";
import ResetModal from "../../ActionCenter/Action_center_modals/ResetModal";
import "./action-center.scss";
import { getAccessStateValue, } from "../../App/App";

const ActionCentreV2 = ({accessStateName}) => {
  const [actionCenterModal, setActionCenterModal] = useState(false);
  const [type, setType] = useState("");
  const [showResetModal, setShowResetModal] = useState(false);
  const IsMspClientView = window.location.pathname.split("/")?.[1] ==="clientmspsettingsv2"
 
  return (
    <div
      className="action-centre-wrapper"
      style={{
        pointerEvents: (getAccessStateValue(accessStateName) < 3)
          ? "none"
          : "all",
      }}
    >
      <div className="heading mb-4">Action Centre Settings</div>

      <Row className="option-wrapper">
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-3"
        >
          <div>
            <p className="title m-0">Integrations</p>
            <p className="subtitle">
              Sync your HighGround tasks with current task/project management
              tool for easier management
            </p>
          </div>
          <div>
            <Button
              className={` ${
                (getAccessStateValue(accessStateName) < 3) ? 'btn-disable' : 'connect-btn'
              }`}
              onClick={() => {
                if (!(getAccessStateValue(accessStateName) < 3)) {
                  setType("integration");
                  setActionCenterModal(true);
                }
              }}
            >
              {
                (getAccessStateValue(accessStateName) < 3) ? 
                <img
                  alt=''
                  className='lockerDarkGreyImage mr-2 mb-1'
                  src='/images/settings-v2/locker-grey.svg'
                />
                :
                <img src="/images/plus-icon.svg" alt="" />
              }
              {" "}
              Connect
            </Button>
          </div>
        </Col>
        <hr className="m-0" />
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-3 pointer"
          onClick={() => {
            if (!(getAccessStateValue(accessStateName) < 3)) {
              setType("taskcreation");
              setActionCenterModal(true);
            }
          }}
        >
          <div>
            <p className="title m-0">Task Creation</p>
            <p className="subtitle">
              Configure how tasks are automatically created in your action
              centre
            </p>
          </div>
          <div>
            {
              (getAccessStateValue(accessStateName) < 3) ? 
              <img
                alt=''
                className='lockerDarkGreyImage mr-2 mb-1'
                src='/images/settings-v2/locker-grey.svg'
              />
              :
              <img src="/images/chevron-right.svg" alt="" />
            }
          </div>
        </Col>
        {/* Notifications */}

        {!IsMspClientView && (
          <>
            <hr className="m-0" />
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center p-3 pointer"
              onClick={() => {
                if (!(getAccessStateValue(accessStateName) < 3)) {
                  setType("notifications");
                  setActionCenterModal(true);
                }
              }}
            >
              <div>
                <p className="title m-0">Notifications</p>
                <p className="subtitle">
                  Configure how you are notified about tasks created in your
                  action centre
                </p>
              </div>
              <div>
                {
                  (getAccessStateValue(accessStateName) < 3) ? 
                  <img
                    alt=''
                    className='lockerDarkGreyImage mr-2 mb-1'
                    src='/images/settings-v2/locker-grey.svg'
                  />
                  :
                  <img src="/images/chevron-right.svg" alt="" />
                }
              </div>
            </Col>
            {/* Reset Settings */}
            <hr className="m-0" />
            <Col
              xs={12}
              className={`d-flex justify-content-between align-items-center p-3 ${(getAccessStateValue(accessStateName) < 3) ? 'cursor-default' : 'pointer'}`}
              onClick={() => {
                if (!(getAccessStateValue(accessStateName) < 3)) {
                  setShowResetModal(true);
                }
              }}
            >
              <div>
                <p className="title m-0">Reset settings</p>
                <p className="subtitle">Restore settings to their defaults</p>
              </div>
              <div>
                {
                  (getAccessStateValue(accessStateName) < 3) ? 
                  <img
                    alt=''
                    className='lockerDarkGreyImage mr-2 mb-1'
                    src='/images/settings-v2/locker-grey.svg'
                  />
                  :
                  <img src="/images/chevron-right.svg" alt="" />
                }
              </div>
            </Col>
          </>
        )}
      </Row>
      <ActionCenter
        isOpen={actionCenterModal}
        setIsOpen={setActionCenterModal}
        settingsV2={type}
      />
      <ResetModal
        isOpen={showResetModal}
        setIsOpen={() => setShowResetModal(false)}
      />
    </div>
  );
};

export default ActionCentreV2;
