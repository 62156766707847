import React, { useState, useEffect } from "react";
import "./TrendData.scss";
import { Col, Row } from "react-bootstrap";
import CC from "../CyberCompliance/CyberCompliance";
import CyberScore from "../CyberScore/CyberScore";
import Threat from "../ThreatLevel/ThreatLevel";
import Loader from "../Common/loader";
import "../../main.scss";

import { useAuth } from "../context/Auth";
import AreaCharts from "../Charts/AreaChart/AreaChart";
 
import ContentLoader from "react-content-loader";

import Header from "../Header";

import { getAPIData } from "../../Utils/commonApi";
import UpgradeToPremiumModal from "../Integrationsv2/UpgradeToPremiumModal";
import { getMenuItemId } from "../SideNav/SideNav";
import { NoAccessUI } from "../App/App";
 
const TrendData = (props) => {
  const MSP = props.MSP;
const GraphLoader=()=>{
  return <div className="h-100 d-flex align-items-center justify-content-center"><Loader /> </div>
}
  const [chart1Data, setChart1Data] = useState(<GraphLoader />);
  const [chart2Data, setChart2Data] = useState(<GraphLoader />);
  const [chart3Data, setChart3Data] = useState(<GraphLoader />);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [cyberScoreData, setCyberScoreData] = useState(0);
  const [cyberThreatData, setCyberThreatData] = useState(0);
  const [cyberComplainceData, setCyberComplainceData] = useState(0);
const [upgradeOptions, setUpgradeOptions] = useState({})
  const [cyberScoreNumber, setcyberScoreNumber] = useState(0);
  const [cyberThreatNumber, setcyberThreatNumber] = useState(0);
  const [cyberComplianceNumber, setcyberComplianceNumber] = useState(0);

  const { authTokens, setAuthTokens } = useAuth();

  const [cyberScoreLabel, setcyberScoreLabel] = useState(0);
  const [cyberThreatLabel, setcyberThreatLabel] = useState(0);
  const [cyberComplianceLabel, setcyberComplianceLabel] = useState(0);
  const [allowDrillDownCyberScore, setAllowDrillDownCyberScore] = useState(true);
  const [allowDrillDownCyberThreat, setAllowDrillDownCyberThreat] = useState(true);
  const [allowDrillDownCyberCompliance, setAllowDrillDownCyberCompliance] = useState(true)

  const menuItemId = getMenuItemId(window.location.pathname)
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  async function makeAPICall(num) {
    // fetch(`${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPageData?pageEnum=${num}`, getRequestOptions())
    //     .then(async response => await response.json())
    //     .then(async data => {
    //         // await setdbObject(data);
    //     })
  }

  async function cyberScorePoints(num) {
    let data = await getAPIData(num, authTokens, {
      optionEnum1: 0
    });
    if (data.AccessToken !== undefined) {
      setAuthTokens(data.AccessToken);
      data = await getAPIData(num, data.AccessToken);
    }
    setcyberScoreNumber(data.CurrentValue);
    setCyberScoreData(data);
    setAllowDrillDownCyberScore(data?.mr?.AccessObjList?.[0]?.Option)
    setUpgradeOptions(data?.mr?.AccessObjList?.[0]?.UpgradeOptions)
  }

  async function cyberThreatPoints(num) {
    let data = await getAPIData(num, authTokens);
    if (data.AccessToken !== undefined) {
      setAuthTokens(data.AccessToken);
      data = await getAPIData(num, data.AccessToken);
    }

    setCyberThreatData(data);
    setAllowDrillDownCyberThreat(data?.mr?.AccessObjList?.[0]?.Option)
  }

  async function cyberCompliancePoints(num) {
    let data = await getAPIData(num, authTokens);
    if (data.AccessToken !== undefined) {
      setAuthTokens(data.AccessToken);
      data = await getAPIData(num, data.AccessToken);
    }

    setCyberComplainceData(data);
    setcyberComplianceNumber(data?.CurrentValue)
    setAllowDrillDownCyberCompliance(data?.mr?.AccessObjList?.[0]?.Option)
  }

  async function updateChartData(filterField, page) {
    if (page == "5") setChart1Data(<GraphLoader />);
    else if (page == "6") setChart2Data(<GraphLoader />);
    else if (page == "7") setChart3Data(<GraphLoader />);

    const newData = await getAPIData(page, authTokens, {
      optionEnum1: filterField
    });
     
    const chartPoints = processPointsData(newData.Points);
    if (page == "5") {
      setcyberScoreNumber(newData.CurrentValue);
      setcyberScoreLabel(newData.WebAppLabel);

      setChart1Data(
        generateChart("5", 
        newData.BaseColour
        , chartPoints, filterField)
      );
    } else if (page == "6") {
      setcyberThreatNumber(newData.CurrentValue);
      setcyberThreatLabel(newData.WebAppLabel);

      setChart2Data(
        generateChart("6", newData.BaseColour, chartPoints, filterField)
      );
    } else if (page == "7") {
      setcyberComplianceNumber(newData.CurrentValue);
      setcyberComplianceLabel(newData.WebAppLabel);

      setChart3Data(
        generateChart("7", newData.BaseColour, chartPoints, filterField)
      );
    }
  }

  function processPointsData(pointsArray) {
    var dateArray = [],
      valuesArray = [];

    pointsArray.map(function (data) {
      dateArray.push(data.Label);
      valuesArray.push(data.Value);
    });
    console.log("points array", {
      labels: dateArray,
      values: valuesArray,
    });
    return {
      labels: dateArray,
      values: valuesArray,
    };
  }

  function generateChart(pageType, colour, newData, activeTab) {
    return (
      <div class="line-chart-wrapper">
        <ul class="tabs">
          <li
            class={`tabs__item ${activeTab == "2" ? "active" : ""}`}
            onClick={() => updateChartData("2", pageType)}
          >
            Yearly
          </li>
          <li
            class={`tabs__item ${activeTab == "1" ? "active" : ""}`}
            onClick={() => updateChartData("1", pageType)}
          >
            Monthly
          </li>
          <li
            class={`tabs__item ${activeTab == "0" ? "active" : ""}`}
            onClick={() => updateChartData("0", pageType)}
          >
            Weekly
          </li>
        </ul>
        <AreaCharts color={colour} data={newData} />
      </div>
    );
  }

  useEffect(() => {
    if (cyberScoreData != 0) {
      var dateArray = [];
      var valuesArray = [];

      cyberScoreData.Points.map(function (data) {
        dateArray.push(data.Label);
        valuesArray.push(data.Value);
      });

      var scoreArray = {
        labels: dateArray,
        values: valuesArray,
      };

      const areaData1 = () => {
        return (
          <div className="line-chart-wrapper">
            <ul className="tabs">
              <li
                className="tabs__item"
                onClick={() => updateChartData("2", "5")}
              >
                Yearly
              </li>
              <li
                className="tabs__item"
                onClick={() => updateChartData("1", "5")}
              >
                Monthly
              </li>
              <li
                className="tabs__item active"
                onClick={() => updateChartData("0", "5")}
              >
                Weekly
              </li>
            </ul>
            <AreaCharts color={cyberScoreData.BaseColour} data={scoreArray} />
          </div>
        );
      };
      setChart1Data(areaData1);
    }

    if (cyberThreatData != 0) {
      var dateArray = [];
      var valuesArray = [];

      cyberThreatData.Points.map(function (data, index) {
        dateArray.push(data.Label);
        valuesArray.push(data.Value);
      });

      var threatArray = {
        labels: dateArray,
        values: valuesArray,
      };
      const areaData2 = () => {
        return (
          <div className="line-chart-wrapper">
            <ul className="tabs">
              <li
                className="tabs__item"
                onClick={() => updateChartData("2", "6")}
              >
                Yearly
              </li>
              <li
                className="tabs__item"
                onClick={() => updateChartData("1", "6")}
              >
                Monthly
              </li>
              <li
                className="tabs__item active"
                onClick={() => updateChartData("0", "6")}
              >
                Weekly
              </li>
            </ul>
            <AreaCharts color={cyberThreatData.BaseColour} data={threatArray} />
          </div>
        );
      };
      setChart2Data(areaData2);
    }

    if (cyberComplainceData != 0) {
      var dateArray = [];
      var valuesArray = [];

      cyberComplainceData.Points.map(function (data, index) {
        dateArray.push(data.Label);
        valuesArray.push(data.Value);
      });

      var complaianceArray = {
        labels: dateArray,
        values: valuesArray,
      };

      const areaData3 = () => {
        return (
          <div className="line-chart-wrapper">
            <ul className="tabs">
              <li
                className="tabs__item"
                onClick={() => updateChartData("2", "7")}
              >
                Yearly
              </li>
              <li
                className="tabs__item"
                onClick={() => updateChartData("1", "7")}
              >
                Monthly
              </li>
              <li
                className="tabs__item active"
                onClick={() => updateChartData("0", "7")}
              >
                Weekly
              </li>
            </ul>
            <AreaCharts
              color={cyberComplainceData.BaseColour}
              data={complaianceArray}
            />
          </div>
        );
      };
      setChart3Data(areaData3);
    }
  }, [cyberScoreData, cyberThreatData, cyberComplainceData]);

  useEffect(() => {
    makeAPICall(0);

    cyberScorePoints(5);
    cyberThreatPoints(6);
    cyberCompliancePoints(7);
  }, []);

  const [cyberScoreEnabled, setcyberScoreEnabled] = useState();
  useEffect(() => {
    setcyberScoreEnabled(true);
    setTimeout(() => {
      setcyberScoreEnabled(false);
    }, 10);
  }, [cyberScoreNumber]);

  const [cyberThreatEnabled, setcyberThreatEnabled] = useState();
  useEffect(() => {
    setcyberThreatEnabled(true);
    setTimeout(() => {
      setcyberThreatEnabled(false);
    }, 10);
  }, [cyberThreatNumber]);

  const [cyberComplianceEnabled, setcyberComplianceEnabled] = useState();
  useEffect(() => {
    setcyberComplianceEnabled(true);
    setTimeout(() => {
      setcyberComplianceEnabled(false);
    }, 10);
  }, [cyberComplianceNumber]);

  if(menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_CyberTrendData === 0)) {
    return (
      <>
         {
          userPortalRole === 'MSP' ? 
            <NoAccessUI/> : <NoAccessUI type={userPortalRole}/>
        }
      </>
    )
  } else if (cyberScoreData && cyberThreatData && cyberComplainceData) {
    return (
      <Row
        className={`trend_data_screen fullHeight ${MSP ? "MSPPortal" : ""}`}
        fluid
        // style={{
        //   overflow: "hidden",
        // }}
      >
        <Col
          xs={12}
          className="px-0"
          style={{
            height: "100%",
          }}
        >
          <div
            className="dashboard fullHeight "
            style={
              MSP
                ? { height: "auto", padding: "0 1em", background: "inherit" }
                : {}
            }
          >
            {MSP ? (
              ""
            ) : (
              <header
                className="d-flex justify-content-between navbar sticky-top pb-0 header-bottom-shadow"
                style={{
                  paddingTop: "1em",
                  background: "#FFFFFF",
                  marginLeft: "-27px",
                  marginRight: "-14px",
                }}
              >
                <h1 className="main_header ml-4 d-flex">Cyber Trend Data
                
                
                {!allowDrillDownCyberScore && (
                  <div
                    className="f-blue f-500 ml-4 f-14 pointer"
                    onClick={() => {
                      setShowUpgradeModal(true);
                    }}
                  >
                    <img
                      alt=""
                      src="/images/attack-surface/blue-bell.svg"
                      className="mr-1"
                    /> 
                    Upgrade now
                  </div>
                )}
                
                
                </h1>

              
                <Header />
              </header>
            )}
            <div
              className={`px-4 hide-scrollbar  ${!allowDrillDownCyberScore? "subscription-restriction-in-cyber-trend"  :""}`}
              style={{
                paddingTop: "2rem",
                overflowY: "auto",
                height: "102vh",
                
              }}
            >
              {MSP ? (
                ""
              ) : (
                <Row className="">
                  <div className="trendDataTitleContainer">
                    <p className="trendMain_Text mb-0 ">Average Score</p>
                  </div>
                </Row>
              )}

              <Row
                className={MSP ? "cc_background" : "trend_cc_background"}
                style={{ height: "251px" }}
              >
                {MSP ? (
                  ""
                ) : (
                  <Col xs={12} md={4} className="pl-4 pt-2">
                    <div className="pt-3">
                      <p className="trendMain_Text">
                        {cyberScoreLabel != 0
                          ? cyberScoreLabel
                          : cyberScoreData.WebAppLabel}
                      </p>
                    </div>
                    <Col
                      xs={12}
                      style={{ height: `210px`, padding: ".5em 0" }}
                      className="mini flexCenter"
                    >
                      {/* style={{ width: "230px", height: "180px" }} */}
                      <div
                        style={{
                          width: "74%",
                          height: "100%",
                          maxWidth: "270px",
                        }}
                      >
                        {cyberScoreEnabled ? (
                          ""
                        ) : (
                          <CyberScore
                            IsTrendDataScreen={true}
                            key={cyberScoreNumber}
                            size="small"
                            scoreNumber={
                              cyberScoreNumber != 0
                                ? cyberScoreNumber
                                : 0
                            }
                            dataStroke={15}
                            bottomText={false}
                            Width="150"
                            Height="150"
                          />
                        )}
                      </div>
                    </Col>
                  </Col>
                )}
                <Col xs={12} lg={MSP ? 12 : 8} className="chartDiagram">
                  {MSP ? (
                    <h3>
                      {cyberScoreLabel != 0
                        ? cyberScoreLabel
                        : cyberScoreData.WebAppLabel}
                    </h3>
                  ) : (
                    ""
                  )}
                  <div className="p2" style={MSP ? { height: "185px" } : {}}>
                    {chart1Data}
                  </div>
                </Col>
              </Row>

              <Row className="cc_background mt-3" style={{ height: "251px" }}>
                <Col xs={12} lg={4}>
                  {MSP ? (
                    ""
                  ) : (
                    <Row>
                      <Col md={12} className="pl-4 pt-2">
                        <div className="pt-3">
                          <p className="trendMain_Text">
                            {cyberThreatLabel != 0
                              ? cyberThreatLabel
                              : cyberThreatData.WebAppLabel}
                          </p>
                        </div>
                      </Col>
                      <Col md={12} className="pl-4">
                        <div className="pt-3">
                          {cyberThreatEnabled ? (
                            ""
                          ) : (
                            <Threat
                              padding={false}
                              threatHeight="160px"
                              threatText={
                                cyberThreatNumber != 0
                                  ? cyberThreatNumber
                                  : cyberThreatData.CurrentValue
                              }
                              removeBottom={true}
                              threatNumber={
                                cyberThreatNumber != 0
                                  ? cyberThreatNumber
                                  : 0
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs={12} lg={MSP ? 12 : 8} className="chartDiagram">
                  {MSP ? (
                    <h3>
                      {cyberThreatLabel != 0
                        ? cyberThreatLabel
                        : cyberThreatData.WebAppLabel}
                    </h3>
                  ) : (
                    ""
                  )}
                  <div className="p2" style={MSP ? { height: "185px" } : {}}>
                    {chart2Data}
                  </div>
                </Col>
              </Row>

              <Row className="cc_background mt-3" style={{ height: "251px" }}>
                {MSP ? (
                  ""
                ) : (
                  <Col xs={12} lg={4}>
                    <Row>
                      <Col md={12}>
                        <div className="pl-2 pt-4">
                          <p className="trendMain_Text">
                            {cyberComplianceLabel != 0
                              ? cyberComplianceLabel
                              : cyberComplainceData.WebAppLabel}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="pt-3">
                          {cyberComplianceEnabled ? (
                            ""
                          ) : (
                            <CC
                              wh="160"
                              dataStroke="18"
                              whpx="160px"
                              ccNumber={
                                cyberComplianceNumber != 0
                                  ? cyberComplianceNumber
                                  : 0
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={12} lg={MSP ? 12 : 8} className="chartDiagram">
                  {MSP ? (
                    <h3>
                      {cyberComplianceLabel != 0
                        ? cyberComplianceLabel
                        : cyberComplainceData.WebAppLabel}
                      %
                    </h3>
                  ) : (
                    ""
                  )}
                  <div className="p2" style={MSP ? { height: "185px" } : {}}>
                    {chart3Data}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <UpgradeToPremiumModal
          show={showUpgradeModal}
          hideModal={() => setShowUpgradeModal(false)}
          upgradeOptions={upgradeOptions}
        />
      </Row>
    );
  } else if (MSP) {
    return <React.Fragment />;
  } else {
    return (
      <Row className={`fullHeight ${MSP ? "MSPPortal" : ""}`} fluid>
        <Col xs={12} className="px-0">
          <div
            className="dashboard fullHeight "
            style={
              MSP
                ? { height: "auto", padding: "0 1em", background: "inherit" }
                : {}
            }
          >
            {MSP ? (
              ""
            ) : (
              <header
                className="d-flex justify-content-between navbar sticky-top pb-0"
                style={{ paddingTop: "1em", background: "#FFFFFF" }}
              >
                <h1 className="main_header">Cyber Trend Data</h1>
                <Header />
              </header>
            )}
            <ContentLoader
              speed={2}
              width={"100%"}
              height={"100%"}
              viewBox="0 0 1450 900"
              backgroundColor="#F6F8FB"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect x="10" y="80" rx="12" ry="12" width="1430" height="250" />
              <rect x="10" y="350" rx="12" ry="12" width="1430" height="250" />
              <rect x="10" y="620" rx="12" ry="12" width="1430" height="250" />
            </ContentLoader>
          </div>
        </Col>
      </Row>
    );
  }
};
export default TrendData;



export const BasicSubscriptionUIForTrendData = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img
        alt=""
        width={48}
        height={48}
        className="mt-4"
        src="/images/attack-surface/rocket.svg"
      />

      <div className="f-darkgrey text-center">
        Upgrade to basic or higher to view CyberScore Trend data
      </div>

      <div className="w-100 d-flex align-items-center justify-content-center f-blue f-500">
        <img
          alt=""
          className="mr-2"
          src="/images/attack-surface/blue-bell.svg"
        />{" "}
        Upgrade now
      </div>
    </div>
  );
};