import { useSelector } from "react-redux";
import { CallToast } from "../Components/PriceCalculator/modals/CustomToast";

export async function ResendEmail(email) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var inputs = new URLSearchParams();
  inputs.append("EmailAddress", email);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: inputs,
    redirect: "follow",
  };
  await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/CC_API/ResendVerification`,
    requestOptions
  )
    .then((response) => response.json())
    .then(async (data) => {
      if (data.Success == false) {
        CallToast("Something went wrong", false, false);
      } else {
        CallToast(
          '<img src="/images/settings-v2/email-sent.svg" class="mr-2"/>&nbsp;Verification Link has been sent to your email',
          false,
          false
        );
      }
    })
    .catch((error) => {
      console.log(error);
      CallToast("Something went wrong", false, false);
    });
}
