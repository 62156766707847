import React, {useState, useEffect} from 'react';
import {Nav } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { generateGravatar } from '../../../Utils/commonApi';
import { useDb } from '../../context/db';
import './VerticalNavigationBar.scss';
import {FiPlusCircle} from 'react-icons/fi';
import {IoMdBriefcase} from 'react-icons/io';
import {NavLink} from 'react-router-dom';


function getNavigationElements(){
    const IMAGE_SIZE = 25;
    return [
        {
            text: "Add Client",
            image: <FiPlusCircle size={IMAGE_SIZE}/>,
            url: "/MSP/Clients/Add"
        },
        {
            text: "Clients",
            image: <IoMdBriefcase size={IMAGE_SIZE}/>,
            url: "/MSP/Clients"
        }
    ]
}
const VerticalNavigationBar = (props) => {

    const { dbObj } = useDb(); 
    const NAVIGATION_BUTTONS = getNavigationElements();
    const [UserHasAvatar, setUserHasAvatar] = useState();
    
    useEffect(() => {
        console.log("dbObj", dbObj)
        if(dbObj != undefined && dbObj.UserDetail != undefined)
        {
            generateGravatar(dbObj.UserDetail.Avatar).then(value => setUserHasAvatar(value ? value : "404"))
        }
    }, []);
    
    if(dbObj === undefined)
    {
        return <Redirect to="/logout"/>
    }
    return ( 
        <div className="VerticalNavBar">
            <div className="NavGroup">
                {/* Image and Nav Icons */}
                <img className="logo" src="/images/logo/highground/short.svg" style={{  padding: '30px' }}></img>  
                <ul className="nav">
                    {
                        NAVIGATION_BUTTONS.map((element) => {
                            return (
                                <li> 
                                    <NavLink exact to={element.url} activeClassName="active">
                                        <span> 
                                            {element.image}
                                        </span> 
                                        <p>{element.text}</p> 
                                    </NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="UserAvatarSection">
                {/* Avatar */}
                <div>
                    {
                        !UserHasAvatar || (dbObj == undefined || dbObj.UserDetail == undefined)
                        ?
                        <span className="userInitials"><p>NA</p></span>
                        :
                        UserHasAvatar !== "404" ?
                            <img className="userAvatar" alt="users image" src={dbObj.UserDetail.Avatar}></img>
                            :
                            <span className="userInitials"><p>{dbObj.UserDetail ? dbObj.UserDetail.Initials : ""}</p></span>
                    }
                </div>
            </div>
        </div> 
    );
}
 
export default VerticalNavigationBar;