import React, { useState, useEffect } from "react";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import DatePicker from "../../Theme/datepicker";
import "./cdsa.scss";
import moment from "moment";
import { getAPIData, SubmitQuestionaire } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import IncidentResponseLoader from "../AttackSurfaceLoaders/IncidentResponseLoader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { AttackSurfaceResetModal } from "../AttackSurfaceResetModal";
import HighgroundDatePicker from "../../test/TestComponent";
import { viewOnlyAccess } from "../../App/App";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
const IncidentResponse = ({ refreshMenuItems }) => {
  const [selectedTaskDate, setSelectedTaskDate] = useState("");
  const { authTokens } = useAuth();
  const [questionList, setQuestionList] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setMainPageLoading(true);
    getPageData();
  }, []);
  function getPageData(success = false) {
    getAPIData(265, authTokens, { 
      optionEmun1: 0
    }).then((response) => {
      setMainPageLoading(false);
      setSelectedTaskDate("");
      if (response?.mr?.Success) {
        setOriginalData(
          response?.QuestionnaireSection_List?.[2]?.Question_List
        );
        if (success) {
          let updated_data = [...questionList].map((item, index) => {
            return {
              ...item,
              AnswerId:
                response?.QuestionnaireSection_List?.[2]?.Question_List[index]
                  .AnswerId,
            };
          });

          setQuestionList(updated_data);
        } else {
          if (
            response?.QuestionnaireSection_List?.[2]?.Question_List[3]
              .AnswerText
          ) {
            setTimeout(() => {
              setSelectedTaskDate(
                response?.QuestionnaireSection_List?.[2]?.Question_List[3]
                  .AnswerText
              );
            }, 50);
          }

          let updated_data = [
            ...response?.QuestionnaireSection_List?.[2]?.Question_List,
          ].map((item) => {
            return {
              ...item,
              selectedDropdownValue:
                item?.AnswerText && item?.QuestionTypeText == "DropdownList"
                  ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                      return {
                        id: index + 1,
                        category: listItem,
                      };
                    }).filter(
                      (filterItem) => filterItem.category === item?.AnswerText
                    )[0]
                  : null,
              dropDowndata: item?.QuestionDropdownListMembers
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                    return {
                      id: index + 1,
                      category: listItem,
                    };
                  })
                : [],
              loading: false,
            };
          });

          setQuestionList(updated_data);
        }
      }
    });
  }

  const SubmitQuestions = async (data_to_send) => {
    await SubmitQuestionaire(authTokens, "POST", {
      ObjectId: 0,
      QuestionnaireTypeEnum: 8,
      QuestionList: JSON.stringify(data_to_send),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (response?.Success) {
        CallToast("Updated successfully", false, false, "v2style");
        updateAccessKeyInLocalStorage(response?.Message)
      
        // getPageData(response?.Success);
        // refreshMenuItems();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };
  // console.log(moment("09/07/2022"));
  return (
    <>
      {mainPageLoading ? (
        <IncidentResponseLoader />
      ) : (
        <div className="cdsa-wrapper">
          {/* Vulnerbility Management */}
          <div className="font-weight-bold mt-3 mb-2">Incident Response</div>
          <div className="bg-grey radius-8 border-parent">
            {questionList.map((question, questionIndex) => {
              return (
                <div
                  className="d-flex flex-column border-item"
                  onClick={() => {
                    // goTo(vulnerbility.name);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100  px-4  py-3">
                    <div
                      className="d-flex align-align-items-baseline "
                      style={{ width: "75%" }}
                    >
                      <div>
                        <img
                          alt=""
                          src="/images/attack-surface/yellow-dot.svg"
                          className="mr-2"
                          style={{
                            visibility: `${
                              question?.AnswerId === 0 ? "visible" : "hidden"
                            }`,
                          }}
                        />
                      </div>
                      <p className="f-500 m-0">{question?.QuestionText}</p>
                      {/* <p className="f-12 f-darkgrey"> {service.noemptyStatus} </p> */}
                    </div>
                    <div>
                      {question.QuestionTypeText === "Date" ? (
                        <div className="attack-date-picker d-flex justify-content-end"> 
                        {
                          viewOnlyAccess("AccessState_AttackSurface") ?
                          <div className="f-darkgrey f-500 mx-3 important-width">
                            {selectedTaskDate ? selectedTaskDate : "No Selected"}
                          </div>
                          :
                          <HighgroundDatePicker
                            selected={selectedTaskDate}
                            // icon={"/images/attack-surface/date-icon.svg"}
                            onChange={(e) => {
                              // To send data to api

                              let data_to_send = [...originalData];
                              data_to_send[questionIndex].AnswerDate = e;
                              data_to_send[questionIndex].QuestionStatus = 3;
                              data_to_send[questionIndex].QuestionStatusText =
                                "Answered";
                              data_to_send[questionIndex].AnswerText = e;

                              setOriginalData(data_to_send);
                              SubmitQuestions(data_to_send);
                              // To control UI
                              setSelectedTaskDate(e);

                              // To upadate the questions list
                              let updated_data = [...questionList];

                              updated_data[questionIndex].AnswerId = 123;
                              setQuestionList(updated_data);
                            }}
                            id={"cbhfgvmv"}
                            isError={false}
                          />
            }

                          {/* {selectedTaskDate && (
                            <DatePicker
                              singleDatePicker={true}
                              startDate={`${moment(
                                selectedTaskDate,
                                "DD/MM/YYYY"
                              ).format("MM/DD/YYYY")}`}
                              // startDate={selectedTaskDate}
                              placeholder={selectedTaskDate}
                              onChange={(e) => {
                                // To send data to api
                                let data_to_send = [...originalData];
                                data_to_send[questionIndex].AnswerDate = e;
                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";
                                data_to_send[questionIndex].AnswerText = e;

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                // To control UI
                                setSelectedTaskDate(e);

                                // To upadate the questions list
                                let updated_data = [...questionList];

                                updated_data[questionIndex].AnswerId = 123;
                                setQuestionList(updated_data);
                              }}
                              value
                            />
                          )} */}
                          {/* 
                          {selectedTaskDate === "" && (
                            <DatePicker
                              singleDatePicker={true}
                              startDate={moment(new Date()).format(
                                "MM/DD/YYYY"
                              )}
                              placeholder="Choose a date"
                              onChange={(e) => {
                                // To send data to api
                                let data_to_send = [...originalData];
                                data_to_send[questionIndex].AnswerDate = e;
                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";
                                data_to_send[questionIndex].AnswerText = e;

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                // To control UI
                                setSelectedTaskDate(e);

                                // To upadate the questions list
                                let updated_data = [...questionList];

                                updated_data[questionIndex].AnswerId = 123;
                                setQuestionList(updated_data);
                              }}
                            />
                          )} */}
                        </div>
                      ) : (
                        <>
                          {
                            viewOnlyAccess("AccessState_AttackSurface") ?
                            <div className="f-darkgrey f-500 mx-3 important-width">
                              {question?.selectedDropdownValue?.category ?? "No Selected"}
                            </div>
                            :
                            <CompanySettingsDropdown
                              fieldId={"id"}
                              fieldValue={"category"}
                              selected={question?.selectedDropdownValue}
                              placeholder="N/A"
                              //  showid={true}
                              onClick={(id, val) => {
                                // To send data to api
                                let data_to_send = [...originalData];
                                data_to_send[
                                  questionIndex
                                ].AnswserSelectedDropdownItems = [val];
                                data_to_send[questionIndex].AnswerText = val;
                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";
                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);

                                // To control the drop value
                                let updated_data = [...questionList];
                                updated_data[questionIndex].selectedDropdownValue =
                                  { id, category: val };
                                updated_data[questionIndex].AnswerId = 123;
                                setQuestionList(updated_data);
                              }}
                              data={question?.dropDowndata}
                            />
                          }
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Reset button */}

          <div
            className={`f-grey  mt-4 ml-2 ${viewOnlyAccess("AccessState_AttackSurface") ? 'cursor-default' : 'pointer'} `}
            onClick={() => {
              if (!viewOnlyAccess("AccessState_AttackSurface")) {
                setShowResetModal(true);
              }
            }}
          >
            {
              viewOnlyAccess("AccessState_AttackSurface") ?
                <img
                  alt=''
                  className='lockerDarkGreyImage mr-2 mb-1'
                  src='/images/settings-v2/locker-grey.svg'
                />
                :
                <img
                  alt=""
                  src="/images/attack-surface/loader-icon.svg"
                  className="mr-2"
                />
            }
            Reset answers
          </div>
        </div>
      )}
      <AttackSurfaceResetModal
        refreshData={getPageData}
        refreshMenuItems={refreshMenuItems}
        resetPageEnum={269}
        optionEnum1={9}
        show={showResetModal}
        hideModal={() => {
          setShowResetModal(false);
        }}
      />
    </>
  );
};

export default IncidentResponse;
