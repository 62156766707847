import React, { useEffect, useState } from "react";
import "./secOps.scss";
import { CustomCyberComplianceGauge } from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import { Button } from "react-bootstrap";
 
import {useHistory} from 'react-router-dom'
import { Route } from "react-router-dom/cjs/react-router-dom";
import SecOpsHomeScreen from "./home/SecOpsHomeScreen";
import SecurityProcesses from "./securityProcesses/SecurityProcesses";
import OperationalSecurity from "./operationalSecurity/OperationalSecurity";
import OperationalSecurityAssetsScreen from "./operationalSecurity/OperationalSecurityAssetsScreen";
import MspFrameworks from "./frameworks/MspFrameworks";
import FrameworksAssetsScreen from "./frameworks/FrameworksAssetsScreen";
import TrainingCertificates from "./trainingCertificates/TrainingCertificates";
import Accreditations from "./accreditations/Accreditations";
import { CallToast, CustomRightSideToastForReport } from "../../PriceCalculator/modals/CustomToast";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { NoAccessUI, accessStateValue } from "../../App/App";
import SecurityBaseline from "./securityBaseline/SecurityBaseline";

const SecOps = () => {
  const { authTokens } = useAuth();
const history= useHistory()
 
const showBackBtn =  window.location.pathname.split("/")[2]==="secops" && window.location.pathname.split("/")?.length > 3;
 
async function exportStatement(event) {
  // CallToast("Report will be downloaded shortly.", false, false, "v2style");
  CustomRightSideToastForReport("Report is being created and will be sent to you by email shortly.")
  await getAPIData(`${656}`, authTokens, {})
    .then((response) => {
      if (response?.Success) {
        CallToast("Report has been sent successfully", false, false, "v2style");
        // downloadPDF(response?.FileURL);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function goBack() {
  history.push(
    window.location.pathname
      .split("/")
      .slice(0, window.location.pathname.split("/").length - 1)
      .join("/")
  );
}
  return (
    <div className="integration-header">
      <div
        className="w-100 bg-black d-flex align-items-center justify-content-between"
        style={{
          position: "sticky",
          top: "56px",
          height: "45px",
          borderTop: "1px solid rgba(255, 255, 255, 0.08)",
          zIndex: 6,
        }}
      >
        <div className="d-flex align-items-center ml-4">
          {showBackBtn && (
            <div
              className="d-flex align-items-center pointer menuItem"
              onClick={() => {
                goBack();
              }}
            >
              <img
                alt=""
                src="/images/msp/msp-Back-btn.svg"
                className=" pointer mr-4"
              />{" "}
              Back
            </div>
          )}
        </div>
        <div
          className="menuItem d-flex align-items-center pointer mr-3"
          onClick={exportStatement}
        >
          <img src="/images/msp/export-icon.svg" alt="" className="mr-2" />
          Print Security Statement
        </div>
      </div>

      {/* SECOPS ROUTINGS */}

      {accessStateValue("AccessState_SecOps") === 0 ? (
        <>
          <Route path={"/mspv2/secops"} component={NoAccessUI} />
        </>
      ) : (
        <>
          <Route exact path={"/mspv2/secops"} component={SecOpsHomeScreen} />

          <Route
            exact
            path={"/mspv2/secops/security-processes"}
            component={SecurityProcesses}
          />

          <Route
            exact
            path={"/mspv2/secops/operational-security"}
            component={OperationalSecurity}
          />
          <Route
            exact
            path={"/mspv2/secops/operational-security/security-assets"}
            component={OperationalSecurityAssetsScreen}
          />

          <Route
            exact
            path={"/mspv2/secops/security-baseline"}
            component={SecurityBaseline}
          />

          <Route
            exact
            path={"/mspv2/secops/frameworks"}
            component={MspFrameworks}
          />
          <Route
            exact
            path={"/mspv2/secops/frameworks/frameworks-assets"}
            component={FrameworksAssetsScreen}
          />

          <Route
            exact
            path={"/mspv2/secops/training-certificates"}
            component={TrainingCertificates}
          />

          <Route
            exact
            path={"/mspv2/secops/accreditations"}
            component={Accreditations}
          />
        </>
      )}
    </div>
  );
};

export default SecOps;

export const SecOpsCircularProgress = ({ progress }) => {
  return (
    <div className="w-fit-content position-relative">
      <div className="f-grey f-11 place_absolute_at_center">
        {progress ?? 0}%
      </div>

      <svg width="48" height="48" viewBox="0 0 48 ">
        <circle
          className="circle_animation_unfilled"
          r="20"
          cy="25"
          cx="25"
          strokeWidth="3"
          stroke="#dfe0e5"
          fill="none"
          pathLength="100"
          transform="rotate(-90, 25, 25)"
        />
        {/* <circle
          stroke-dasharray={progress}
          className=""
          r="20"
          cy="25"
          cx="25"
          strokeWidth="3"
          stroke="#008fd4"
          fill="none"
          pathLength="100"
          transform="rotate(-90, 25, 25)"
        /> */}
         <circle
          stroke-dasharray={100}
          stroke-dashoffset={100-progress}
          className=""
          r="20"
          cy="25"
          cx="25"
          strokeWidth="3"
          stroke="#008fd4"
          fill="none"
          pathLength={progress == 100 ? '0' : '100'}
          transform="rotate(-90, 25, 25)"
        />
      </svg>
    </div>
  );
};
export const UploadFileCircularProgress = ({ progress }) => {
  return (
    <div className="w-fit-content position-relative">
      {/* <div className="f-grey f-11 place_absolute_at_center">
        {progress ?? 0}%
      </div> */}

      <svg width="32" height="32" viewBox="0 0 32 32">
        {/* <circle
          className="circle_animation_unfilled"
          r="14"
          cy="16"
          cx="16"
          strokeWidth="3"
          stroke="#dfe0e500"
          fill="none"
          pathLength="100"
          transform="rotate(-90, 25, 25)"
        /> */}
        <circle
          stroke-dasharray={100}
          stroke-dashoffset={progress}
          className=""
          r="13"
          cy="15"
          cx="35"
          strokeWidth="3"
          stroke="#008fd4"
          fill="none"
          pathLength="100"
          transform="rotate(-90, 25, 25)"
        />
      </svg>
    </div>
  );
};
