import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAPIData, getRequestOptions } from "../../../../Utils/commonApi";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import "./companySettingsModals.scss";

const SelectEmailUsersModal = ({ show, hideModal, customerId }) => {
  const { authTokens } = useAuth();
  const [searchString, setSearchString] = useState("");

  const [isChecked, setChecked] = React.useState(false);
  const handleChange = () => {
    let temp = clientsList;
    if (isChecked) {
      temp.forEach(item => {
        if (item.isSelected) {
          item.isSelected = false;
        }
      });
      setClientsList(temp)
      setChecked(false);
    } else {
      temp.forEach(item => {
        item.isSelected = true;
      });
      setClientsList(temp)
      setChecked(true);
    }
  };
  const [clientsList, setClientsList] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (show) {
      getGeneralInfo();
    }
  }, [show]);

  useEffect(() => {
    setChecked(clientsList.some(obj => obj.isSelected === true))
  }, [clientsList])

  async function getGeneralInfo() {
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    let data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetEmailSettingsMembers?accessKey=${message}&pageNo=1&pageSize=20&CustomerId=${customerId}&search=`,
      getRequestOptions(authTokens)
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.Success) {
          console.log(result);
          setClientsList(result?.ObjectTemplateList);
        } else {
          // setMainPageLoading(false);

          CallToast("Something went wrong", false, false, "v2style");
        }
      });
  }
  async function UpdateNotificationOptions() {
    setSaving(true);
    var message = JSON.parse(localStorage.getItem("user_MessageObj"));
    let data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdateNotificationOptions?accessKey=${message}`,
      getRequestOptions(authTokens, "POST", {
        userEmailDetailList: JSON.stringify(clientsList),
      })
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.Success) {
          console.log(result);
        } else {
          // setMainPageLoading(false);

          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
  }

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName=" company-settings-selectuser-modal"
      className="hide-scrollbar "
      aria-labelledby=" company-settings-selectuser-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14">
          Select client users to receive email notifications{" "}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar block">
        <div className="mx-2 d-flex align-items-center w-100">
          <img
            alt=""
            src="/images/attack-surface/search-icon.svg"
            className=""
          />
          <input
            type="text"
            placeholder="Search"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            className={`${
              //   searchInput ?
              "animated-search-input-open"
              // : "animated-search-input-close"
              } pl-2`}
            autoFocus
          />
        </div>
        <div className="mt-3 d-flex item w-100">
          <Form.Group className="square-checkbox blue-checkbox-minus pointer-checkbox">
            <Form.Check
              type="checkbox"
              name={`checkbox`}
              checked={isChecked ? 1 : 0}
              onChange={(e) => handleChange()}
            />
          </Form.Group>
          <div>{clientsList?.filter((e) => e.isSelected).length} Selected</div>
        </div>
        {clientsList
          ?.filter((item) => {
            if (searchString) {
              return (
                item?.Name?.toLowerCase()?.includes(
                  searchString?.toLowerCase()
                ) ||
                item?.Email?.toLowerCase()?.includes(
                  searchString?.toLowerCase()
                )
              );
            } else {
              return true;
            }
          })
          ?.map((row, index) => (
            <div className="d-flex item w-100">
              <Form.Group className="square-checkbox  blue-checkbox titleCheckbox pointer-checkbox">
                <Form.Check
                  type="checkbox"
                  label=""
                  name={`checkbox`}
                  checked={row.isSelected ? 1 : 0}
                  onChange={(e) => {
                    let temp = clientsList.map((el) => {
                        if (el.objId_UserSettings == row.objId_UserSettings) {
                        el.isSelected = !row.isSelected;
                      }
                      return el;
                    });
                    setClientsList(temp);
                    // setChecked(true);
                  }}
                />
              </Form.Group>
              {row?.ShowInitials === true ? (
                <ImageWithInitials
                  initials={row?.Initials}
                  width={20}
                  background={"purple"}
                />
              ) : (
                <img
                  alt=""
                  src={row?.ImageURLu}
                //   style={{
                //     width: "20px",
                //     height: "20px",
                //     borderRadius: "10px",
                //     objectFit: "contain",
                //   }}
                />
              )}
              <div className="label">
                {row.Name && row.Name.trim() !== "" ? row.Name : row.Email}
              </div>
            </div>
          ))}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end border-top">
        <div className="d-flex ">
          <Button className="hg-cancel-btn" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="hg-blue-btn"
            onClick={() => {
              UpdateNotificationOptions();
            }}
          >
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectEmailUsersModal;
