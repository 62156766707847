import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import CyberCareContainer from "../CyberCareContainer/CyberCareContainer";

import {
  Form,
  Spinner,
  Button,
  Container,
  Alert,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import "./Settings.css";
import OneSignal, { useOneSignalSetup } from "react-onesignal";
import "../../main.scss";

import { BsPlus } from "react-icons/bs";
import { getAPIData, getRequestOptions } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import LoadingSpinner from "../Spinner/Spinner";
import {
  setOneSignalPlayer,
  removeOneSignalPlayer,
} from "../../Utils/onesignalApi";

const SettingsIndex = () => {
  var useragentid = null;

  // const events = [
  //   {
  //     listener: 'once',
  //     event: 'subscriptionChange',
  //     callback: (isSubscribed) => {
  //       if (isSubscribed) {
  //         console.log('The user subscription state is now:', isSubscribed);
  //         window.OneSignal.getUserId().then(function(userId) {
  //                               useragentid = userId;
  //                           }).then(function(){

  //                            console.log("subscribed");
  //                           //  setOneSignalPlayer(authTokens, useragentid);
  //                           });
  //       }
  //     },
  //   },
  //   {
  //     event: 'notificationDisplay',
  //     callback: (event) => {
  //       console.warn('OneSignal notification displayed:', event);
  //     },
  //   },
  //   {
  //     event: 'notificationDismiss',
  //     callback: (event) => {
  //       console.warn('OneSignal notification dismissed:', event);
  //     },
  //   },
  // ];

  const options = {
    allowLocalhostAsSecureOrigin: true,
    persistNotification: false,
    notifyButton: {
      enable: false,
    },
  };

  OneSignal.initialize("7c2dbd3c-1454-463d-a2b9-1a448d8c5f92", options);

  const [email, setEmail] = useState(0);
  const [loading, setLoading] = useState(false);
  const { authTokens, setAuthTokens } = useAuth();

  // Push notifications states
  const [pushScore, setpushScore] = useState(false);
  const [pushThreat, setpushThreat] = useState(false);
  const [pushCompliance, setpushCompliance] = useState(false);
  const [pushEnable, setpushEnable] = useState(false);

  // Scheduled Report states
  const [reportSummary, setreportSummary] = useState(false);
  const [reportExSummary, setreportExSummary] = useState(false);
  const [reportTrendData, setreportTrendData] = useState(false);
  const [reportTechDrill, setreportTechDrill] = useState(false);
  const [reportCompliance, setreportCompliance] = useState(false);
  const [reportDate, setreportDate] = useState("1st");
  const [reportTime, setreportTime] = useState();

  var x = 5; //minutes interval
  var times = []; // time array
  var tt = 0; // start time
  var ap = ["am", "pm"]; // AM-PM

  //loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] =
      ("0" + (hh % 12)).slice(-2) +
      ":" +
      ("0" + mm).slice(-2) +
      " " +
      ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }

  const dateArray = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
    "31st",
  ];

  const [data, setdata] = useState(0);

  useEffect(() => {
    if (window.OneSignal.initialized !== true) {
      console.log("Window History: ", window);
      window.OneSignal.push(function () {
        window.OneSignal.on("subscriptionChange", function (isSubscribed) {
          if (isSubscribed == true) {
            window.OneSignal.getUserId()
              .then(function (userId) {
                useragentid = userId;
              })
              .then(function () {
                console.log("subscribed");
                setOneSignalPlayer(authTokens, useragentid);
              });
          } else if (isSubscribed == false) {
            window.OneSignal.getUserId().then(function (userId) {
              useragentid = userId;
              console.log("un-subscribed");
              removeOneSignalPlayer(authTokens, useragentid);
            });
          } else {
            console.log("Unable to process the request");
          }
        });
      });
    }
  }, []);

  useOneSignalSetup(async () => {
    var result = await OneSignal.isPushNotificationsEnabled();
    setpushEnable(result);
  });

  function subscribeOneSignal() {
    console.log("test");
    if (useragentid != null) {
      console.log("test2");

      window.OneSignal.setSubscription(true);
    } else {
      console.log("test3");

      window.OneSignal.registerForPushNotifications({
        modalPrompt: true,
      });
    }
  }
  function unSubscribeOneSignal() {
    window.OneSignal.setSubscription(false);
  }

  async function callAPIData(num) {
    let data = await getAPIData(num, authTokens);
    if (data.authToken !== undefined) {
      setAuthTokens(data.authToken);
      setdata();
      data = await getAPIData(num, data.authToken);
    } else setdata(data);
  }

  useEffect(() => {
    callAPIData(10);
    // test();
  }, []);

  useEffect(() => {
    if (data != 0) {
      // Update all the states to match the incoming API data
      setpushScore(data.CyberScore_Notify);
      setpushThreat(data.CyberThreat_Notify);
      setpushCompliance(data.CyberCompliance_Notify);

      setreportSummary(data.MySummary_Report);
      setreportExSummary(data.ExecSummary_Report);
      setreportTrendData(data.CyberTrend_Report);
      setreportTechDrill(data.TechDrillDown_Report);
      setreportCompliance(data.CompAndGov_Report);

      // Set the date
      setreportDate(dateArray[parseInt(data.Report_DayTxt) - 1]);
      setreportTime(times[times.indexOf(data.Report_TimeTxt)]);

      // console.log(reportTime);

      // console.log(times);
    }
  }, [data]);

  // This page will call a API result that will fill in the settings page
  // A post will be made to update any settings users change from the input fields.

  const settingsEmailNotifications = (
    <Fragment>
      <Row>
        <Col style={{ padding: "1.5em 0 0 1.5em" }}>
          <p className="settings_emailHeader">Email Notifications</p>
          <p className="settings_emailSubHeader">
            We will only use these emails for cyber security notifications
          </p>
        </Col>
      </Row>
      <Row style={{ paddingTop: "1em" }}>
        <Col xs={9}>
          <Form className="settingsEmailNotification" autoComplete="none">
            <Form.Group controlId="emailNotificationForm">
              {/* <Form.Label>Email</Form.Label> */}
              <InputGroup className="mb-2 mr-sm-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Email: </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  size="lg"
                  className="inputControl"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="submitEmailNotification">
              <Col xs={4} className="pl-0">
                <Button
                  className="form-control"
                  variant="grey"
                  size="md"
                  type="submit"
                  onClick={() => (!loading ? setLoading(true) : "")}
                >
                  <BsPlus size={18} />

                  {loading ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Add email"
                  )}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );

  const togglePushScore = () => setpushScore((value) => !value);
  const toggleThreatScore = () => setpushThreat((value) => !value);
  const toggleComplainceScore = () => setpushCompliance((value) => !value);
  const toggleEnableScore = (value) => {
    var checked = value.target.checked;

    if (checked) {
      console.log("test");
      subscribeOneSignal();
      setpushEnable(true);
    } else {
      unSubscribeOneSignal();
      setpushEnable(false);
    }
  };

  const toggleReportSummary = () => setreportSummary((value) => !value);
  const toggleReportExSummary = () => setreportExSummary((value) => !value);
  const toggleReportTech = () => setreportTechDrill((value) => !value);
  const toggleReportTrend = () => setreportTrendData((value) => !value);
  const toggleReportCompliance = () => setreportCompliance((value) => !value);
  const toggleReportDate = () => setreportDate((value) => !value);

  useEffect(() => {
    callSettingsAPI();
  }, [
    pushScore,
    pushThreat,
    pushCompliance,
    reportSummary,
    reportExSummary,
    reportTechDrill,
    reportTrendData,
    reportCompliance,
    reportDate,
    reportTime,
  ]);

  async function callSettingsAPI() {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdateUserSettings?CyberScore_Notify=${pushScore}&CyberThreat_Notify=${pushThreat}&CyberCompliance_Notify=${pushCompliance}&MySummary_Report=${reportSummary}&ExecSummary_Report=${reportExSummary}&CyberTrend_Report=${reportTrendData}&TechDrillDown_Report=${reportTechDrill}&CompAndGov_Report=${reportCompliance}&Report_Day=${reportDate}&Report_Hour=${10}&Report_Min=${10}`,
      getRequestOptions(authTokens, "POST")
    ).then((response) => response.json());
  }

  if (data != 0) {
    return (
      <Row className="fullHeight">
        <Col xs={12} className="px-0">
          <div className="dashboard fullHeight">
            <header>
              <h1>Settings</h1>
            </header>
            <Row style={{ paddingTop: "5%" }}>
              <Col xs={6}>
                <CyberCareContainer
                  height="317px"
                  content={settingsEmailNotifications}
                />
              </Col>
              <Col xs={6}>
                <Col
                  xs={12}
                  className="cyberCareContainer"
                  style={{ height: "317px" }}
                >
                  <Fragment>
                    <Row>
                      <Col style={{ padding: "1.5em 0 0 1.5em" }}>
                        <p className="settings_emailHeader">
                          Push Notifications
                        </p>
                        <p className="settings_emailSubHeader">
                          Set up notifications for your mobile app and your
                          browser.
                        </p>

                        <p
                          style={{ paddingTop: "1em" }}
                          className="settings_emailSubsubHeader"
                        >
                          Receive notifications about:
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "1em" }}>
                      <Col xs={12} style={{ paddingLeft: "1.5em" }}>
                        <Form
                          className="pushNotificationForm"
                          autoComplete="none"
                        >
                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="pushScoreCheck"
                              label={"CyberScore"}
                              checked={pushScore}
                              onChange={togglePushScore}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="pushThreatCheck"
                              label={"CyberThreatLevel"}
                              checked={pushThreat}
                              onChange={toggleThreatScore}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="pushComplianceCheck"
                              checked={pushCompliance}
                              onChange={toggleComplainceScore}
                              label={"CyberCompliance Score"}
                            />
                          </Form.Group>

                          <p className="settings_emailSubsubHeader">
                            Allow notifications in the browser:
                          </p>
                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="pushEnableCheck"
                              checked={pushEnable}
                              onChange={toggleEnableScore}
                              label={"Enable"}
                            />
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  </Fragment>
                </Col>
              </Col>
            </Row>
            <Row style={{ paddingTop: "1.5em" }}>
              <Col xs={12}>
                <Col
                  xs={12}
                  className="cyberCareContainer"
                  style={{ height: "411px" }}
                >
                  <Fragment>
                    <Row>
                      <Col style={{ padding: "1.5em 0 0 1.5em" }}>
                        <p className="settings_emailHeader">
                          Scheduled Reports
                        </p>
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: "1em" }}>
                      <Col xs={6} style={{ paddingLeft: "1.5em" }}>
                        <Form
                          className="pushNotificationForm"
                          autoComplete="none"
                        >
                          <Form.Row style={{ paddingLeft: "0.4em" }}>
                            <Form.Group
                              controlId="reportDate"
                              style={{ paddingRight: "1em" }}
                            >
                              <InputGroup className="mb-2 mr-sm-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    Date of the month:
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  as="select"
                                  onChange={(event) =>
                                    setreportDate(event.target.value)
                                  }
                                >
                                  {dateArray.map((value, key) => {
                                    // console.log("report date: "+ reportDate);
                                    let selected = "";
                                    console.log("value:", value);

                                    // Set valueKey to the Key plus key so the dates match up
                                    let valueKey = key + 1;

                                    console.log("value Key:", valueKey);

                                    if (value == reportDate) {
                                      selected = "selected";
                                    }
                                    return (
                                      <option
                                        selected={selected}
                                        value={valueKey}
                                        key={key}
                                      >
                                        {value}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                              </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="reportDate">
                              <InputGroup className="mb-2 mr-sm-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>Time:</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  as="select"
                                  onChange={(event) =>
                                    setreportTime(event.target.value)
                                  }
                                >
                                  {times.map((value, key) => {
                                    // console.log("value:", value);
                                    // console.log("key:", key);
                                    // console.log("report date: "+ reportTime);
                                    let selected = "";

                                    if (value == reportTime) {
                                      selected = "selected";
                                    }
                                    return (
                                      <option
                                        selected={selected}
                                        value={key}
                                        key={key}
                                      >
                                        {value}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                              </InputGroup>
                            </Form.Group>

                            {/* <Form.Group controlId="reportTime">
  
    <Form.Control as="select" >
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
    </Form.Control>
  </Form.Group> */}
                          </Form.Row>

                          <p className="settings_emailSubHeader">
                            If there are fewer days in the month than you
                            <br></br> specified, the report will be sent on the
                            last day<br></br> of the month.
                          </p>

                          <p className="settings_emailHeader">
                            My Scheduled Report should include:
                          </p>

                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="reportSummary"
                              label={"My CEO/CFO/CTO Summary"}
                              checked={reportSummary}
                              onChange={toggleReportSummary}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="reportExSummary"
                              label={"Executive Summary (all RBCD)"}
                              checked={reportExSummary}
                              onChange={toggleReportExSummary}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="reportTrendData"
                              checked={reportTrendData}
                              onChange={toggleReportTrend}
                              label={"Cyber Trend Data"}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="reportTechDrill"
                              checked={reportTechDrill}
                              label={"Technical Drilldown"}
                              onChange={toggleReportTech}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Check
                              type="switch"
                              id="reportCompliance"
                              checked={reportCompliance}
                              label={"Compliance & Governance"}
                              onChange={toggleReportCompliance}
                            />
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  </Fragment>
                </Col>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  } else {
    return <LoadingSpinner />;
  }
};
export default SettingsIndex;
