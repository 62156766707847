import React, { useState, useEffect, Fragment } from "react";

import { useAuth } from "../../context/Auth";
import {
  Button,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Tooltip,
} from "react-bootstrap";
import { FiChevronLeft, FiCheck } from "react-icons/fi";

import { getRequestOptions } from "../../../Utils/commonApi";
import { useDb } from "../../context/db";
import { ToastContainer, toast } from "react-toastify";

import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const CyberEssentialModal = (props) => {
  const { authTokens } = useAuth();
  const [isEnquire, setIsEnquire] = useState(true);
  const { setCreditApply } = props;
  const [enquireSubmit, setIsEnquireSubmit] = useState(0);
  const [data, setData] = useState(props.data || "");
  const [phoneNo, setPhoneNo] = useState();
  const [formDescription, setformDescription] = useState("");
  const [title] = useState(data.title);
  const { dbObj } = useDb();
  const [error, setError] = useState(false);
  const [showToast] = useState(false);

  const [enquiring, setEnquiring] = useState(false);
  const [value, setValue] = useState(0);
  const [price, setPrice] = useState(0);
  let [incremt, setIncremnt] = useState([0, 0]);
  const [slide, setSlider] = useState(false);
  const [quantity, setQuantity] = useState(false);
  const [checkBox, setCheckBox] = useState([]);
  const [isEnquiry, setIsEnquiry] = useState(false);
  const [onceEnquired, setOnceEnquired] = useState(false);
  const URLpath = window.location.pathname.split("/")[1];
  const [phoneError, setPhoneError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  async function onEnquireHandler(event) {
    event.preventDefault();
    if (handleValidations()) {
      return;
    } else {
      setIsEnquireSubmit(1);
      setError(false);
      setEnquiring(true);
      var message = JSON.parse(localStorage.getItem("user_MessageObj"));
      const enquireData = {
        TicketDataJSON: JSON.stringify({
          Pro_Service: props.data?.Title,
          TicketDetails: {
            ContactName: dbObj.UserDetail.FullName,
            ContactEmail: dbObj.UserDetail.UserName,
            ContactPhone: phoneNo,
          },
          TicketNoteDetails: {
            Description: formDescription,
            ProServices: title,
          },
          TicketType: 2,
        }),
      };
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateConnectwiseTicket`,
        getRequestOptions(authTokens, "POST", enquireData)
      ).then((response) => response.json());
      if (data.Success == true) {
        TrackUser("Pro Services Enquire Sent");
        setOnceEnquired(true);
        setEnquiring(false);
        setPhoneNo("");
        setformDescription("");
        setTimeout(() => setIsEnquireSubmit(2), 1000);
      } else {
        setEnquiring(false);
        setError(data.Message);
        setIsEnquireSubmit(0);
        CallToast("Something went wrong", false, false, "v2style");
        // setIsEnquire(false);
        // setHideModal(true);
        // setShowToast(true);
        // toast.dark(
        //   <span>
        //     <FiX style={{ marginRight: "10px", color: "#f36060" }} />
        //     <span style={{ width: "500px", color: "#f36060" }}>
        //       {data.Message}
        //     </span>
        //     <span className="float-right">
        //       <CircularProgressBar durationInSec="30" />
        //     </span>
        //   </span>
        // );
      }
    }
  }

  const handleValidations = () => {
    var pattern = /^\+?([0-9]{0,2})\)?[-. ]?([0-9]{10,16})$/;

    if (!formDescription && !phoneNo) {
      setError("All fields are required");
      return true;
    }
    if (!phoneNo) {
      setError("Phone No. is required");
      setPhoneError("Phone No. is required");
      return true;
    }
    if (phoneNo) {
      if (!pattern.test(phoneNo)) {
        setError("Please enter a valid phone number (min 10 digits)");
        setPhoneError("Please enter a valid phone number (min 10 digits)");
        return true;
      }
    }
    if (!formDescription) {
      setError("Description is required");
      setDescriptionError("Description is required");
      return true;
    }
    return false;
  };

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const setSliderAndPrice = (elm, isCheck = false) => {
    if (!isCheck) {
      props.setselectedPackage(elm["Prices"][0]);
    }
    setSlider(true);
  };

  const setPackagesCheckbox = (elmt) => {
    const arr = [...checkBox];
    const arr2 = [...incremt];
    if (arr.indexOf(elmt.Id) === -1) {
      arr.push(elmt.Id);
      if (elmt.Id == 3046886) {
        arr2[0] = 1;
        setIncremnt(arr2);
      }

      if (elmt.Id == 3046887) {
        arr2[1] = 1;
        setIncremnt(arr2);
      }
    } else {
      arr.splice(arr.indexOf(elmt.Id), 1);
      if (elmt.Id == 3046886) {
        arr2[0] = 0;
        setIncremnt(arr2);
      }

      if (elmt.Id == 3046887) {
        arr2[1] = 0;
        setIncremnt(arr2);
      }
    }
    setCheckBox(arr);
    setQuantity(true);
    props.setselectedPackage(elmt);
  };

  const checkBoxSelect = (ele, counter) => {
    if (counter == 1) {
      if (!incremt[0]) {
        setPackagesCheckbox(ele);
      }
    }

    if (counter == 2) {
      if (!incremt[1]) {
        setPackagesCheckbox(ele);
      }
    }
  };

  return (
    <Modal
      show={props.show}
      className="right modal-medium rightModal headerBtnModal fullHeight"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setCreditApply(false);
        props.closeModal();
        props.setselectedPackage({});
        props.setselectedPackage2({});
        setSlider(false);
        setIncremnt([0, 0]);
        setQuantity(false);
        setCheckBox([]);
        setIsEnquiry(false);
        setPrice(0);
        setValue(0);
        // setIsEnquire(false);
        props.tostMsg({
          show: false,
          msg: "Something went wrong",
        });
      }}
    >
      {isEnquire && (
        <>
          {enquireSubmit == 1 && (
            <div className="loader_wrapper loader_enquiry">
              <div className="loader_enquiry_inner">
                <div className="loader">
                  <div className="text-center">
                    <div className="loader_circle" />
                    <p className="mt-3">Sending...</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showToast && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      <Modal.Header
        style={{ display: "inline-block", padding: "36px 24px 0px 28px" }}
      >
        {isEnquire ? (
          <div className="d-flex">
            {/* <span
              className="cursor-pointer align-self-center"
              onClick={() => {
                if (onceEnquired === false) {
                  TrackUser("Pro Services Enquire Clicked off");
                }
                setIsEnquire(false);
                setIsEnquireSubmit(0);
                setPhoneNo("");
                setformDescription("");
              }}
            >
              <FiChevronLeft size="25" />
            </span> */}
            <div className="heading4 align-self-center ml16px">Enquire</div>
          </div>
        ) : (
          <div className="d-flex justify-content-between w-100">
            <span
              className="cursor-pointer align-self-center"
              onClick={() => {
                setCreditApply(false);
                props.closeModal();
                props.setselectedPackage([]);
                props.setselectedPackage2({});
                setSlider(false);
                setIncremnt([0, 0]);
                setQuantity(false);
                setCheckBox([]);
                setPrice(0);
                setValue(0);
                setIsEnquiry(false);

                // {
                //   URLpath == "pro_services" &&
                //     TrackUser(`${props?.data?.Title} clicked off`);
                // }
              }}
            >
              <img src="/images/icons/close.svg" alt="" />
            </span>
            <div className="heading4 align-self-center ml16px">
              {data.Title}
            </div>
            {/* {creditApply ? (
              <div className="d-flex ml-auto">
                <Form.Group
                  controlId="checkApply"
                  className="round-checkbox blue-checkbox float-left mr8px"
                >
                  <Form.Check type="checkbox" checked label="" />
                </Form.Group>
                <div className="txt-blue align-self-center">
                  {data.Credit} Credit Applied
                </div>
              </div>
            ) : (
              <Button
                className="btn-blue ml-auto"
                onClick={() => {
                  if (Object.keys(props.selectedPackage).length === 0) {
                    props.tostMsg({
                      show: true,
                      msg: "You must select a product before applying credit",
                    });
                    setTimeout(() => {
                      props.tostMsg({
                        show: false,
                        msg: "Something went wrong",
                      });
                    }, 3100);
                  } else {
                    setCreditApply(true);
                  }
                }}
              >
                Apply {data.Credit} Credit
              </Button>
            )} */}
            <div className="d-flex ml-auto">
              <Form.Group
                controlId="checkApply"
                className="round-checkbox blue-checkbox float-left mr8px"
              >
                <Form.Check type="checkbox" checked label="" />
              </Form.Group>
              <div className="txt-blue align-self-center">
                {props.proCredit ? props.proCredit : data.Credit} credit
                available
              </div>
            </div>
          </div>
        )}
        {enquireSubmit != 2 && (
          <div className="mt-5">
            {isEnquire && <div className="greyText mb8px">Ask about:</div>}
            <div className="d-flex">
              <img
                className="mr-3"
                width="48"
                height="48"
                src={`/images/ProServices/${data.LogoFileName}`}
                alt=""
              />
              <div className="align-self-center">
                <div className="heading4">{data.Title}</div>
              </div>
              {!isEnquire && (
                <Button
                  className="align-self-center ml-auto btn-grey btn-light"
                  onClick={() => {
                    TrackUser("Pro Services Enquire Clicked");
                    // setIsEnquire(true);
                    setPhoneNo("");
                    setformDescription("");
                  }}
                >
                  Enquire
                </Button>
              )}
            </div>
            {!isEnquire && (
              <div className="grey-container heading2 mt24px mb24px">
                {/* Ideal for complying with the UK Governments minimum standard
                  for cyber security */}
                {data.Header}
              </div>
            )}
          </div>
        )}
      </Modal.Header>
      <Modal.Body
        className="p24px"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        {enquireSubmit == 2 ? (
          <Fragment>
            <div className="position-relative h-100">
              <div className="center-container text-center">
                <div className="circle-green">
                  <FiCheck color="#ffffff" size="36" />
                </div>
                <div className="heading4 mt40px">
                  Your enquiry has been submitted successfully
                </div>
                <div className="greyText mt16px">
                  A member of our Professional Services Team will be in contact
                  shortly
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="position-relative">
            {isEnquire ? (
              <Fragment>
                <p
                  className="greyText"
                  style={{
                    opacity: enquireSubmit == 1 ? 0.2 : "",
                    marginBottom: "24px",
                  }}
                >
                  The question will be sent from:
                  <br />
                  Name: <b className="text-dark">{dbObj.UserDetail.FullName}</b>
                  <br />
                  Company:
                  <b className="text-dark">{dbObj.UserDetail.UserName}</b>
                </p>
                <hr className="m-0" />
                <div className="greyText mt24px">Details</div>
                <Form>
                  <Form.Group>
                    <InputGroup
                      className={`grey-input   ${
                        phoneError !== "" ? "input-error" : ""
                      }  `}
                      style={{ height: "49px" }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text className="greyText">
                          Phone:
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="IntegrationName"
                        value={phoneNo}
                        onChange={(event) => {
                          setError(false);
                          setPhoneError("");

                          setPhoneNo(
                            event.target.value
                              .toString()
                              .replace(/<\/?[^>]+>/gi, "")
                          );
                        }}
                      />
                    </InputGroup>
                  </Form.Group>

                  <div className="f-12 f-danger mt-1">
                    <span style={{ color: "transparent" }}>.</span>
                    {phoneError}
                  </div>

                  <Form.Group>
                    <Form.Control
                      className={`   ${
                        descriptionError !== "" ? "input-error" : ""
                      }  `}
                      name="IntegrationComment"
                      placeholder="Your question..."
                      as="textarea"
                      value={formDescription}
                      onChange={(event) => {
                        setError(false);
                        setDescriptionError("");
                        setformDescription(
                          event.target.value.replace(/<\/?[^>]+>/gi, "")
                        );
                      }}
                      rows={5}
                    />
                  </Form.Group>

                  <div className="f-12 f-danger mt-1">
                    <span style={{ color: "transparent" }}>.</span>
                    {descriptionError}
                  </div>
                </Form>
                {error != false && false && (
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block pt-3"
                  >
                    {/* <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 200, hide: 250 }}
                      overlay={renderTooltip(null)}
                    > */}
                    <span>
                      {/* <img alt="" src="/images/icons/error.svg" /> Enquire
                        attempt failed */}
                      <img alt="" src="/images/icons/error.svg" />
                      {error}
                    </span>
                    {/* </OverlayTrigger> */}
                  </Form.Control.Feedback>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div className="position-relative">
                  <div className="description_pr" />
                  <div className="proServiceScroll2">
                    <div className="greyText">Description</div>
                    <p className="mt8px pb-2">{data.Detail}</p>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="border-top-0 pt-0 position-relative">
        <div
          className={`packageradio modalFooter ${isEnquire ? "d-none" : ""} `}
        >
          <div className="d-flex align-content-stretch flex-wrap">
            {(props.data.Title == "Vulnerability Assessment" ||
              props.data.Title == "Office 365 Security Audit") && (
              <p className="uptoHeader">Up to...</p>
            )}
            {data.Prices &&
              data.Title != "Security Consultation" &&
              data.Prices.map((elmt, key) => (
                <div
                  className={`greyBorderCont crsrpoint mr8px position-relative mt-2 flex-fill packsDivs   ${
                    props.data.Title == "Vulnerability Assessment" ||
                    props.data.Title == "Office 365 Security Audit" ||
                    props.data.Title == "Backup & Disaster Recovery Services"
                      ? "packdivswidth"
                      : ""
                  }   ${
                    props.data.Title == "Backup & Disaster Recovery Services"
                      ? "boxdivcheck"
                      : ""
                  } ${
                    props.selectedPackage.Id == elmt.Id && !quantity
                      ? "blurBorderCont"
                      : ""
                  } ${
                    checkBox.indexOf(elmt.Id) != -1 ? "blurBorderCont" : ""
                  } ${
                    props.selectedPackage2.Id == elmt.Id && !quantity
                      ? "blurBorderCont"
                      : ""
                  }
                  ${isEnquiry && elmt.Type_Enum == 2 ? "blurBorderCont" : ""}

                  ${elmt.Id == 3046881 ? "d-none" : ""}
                    `}
                  onClick={() => {
                    if (elmt.Type_Enum == 2) {
                      props.setselectedPackage({});
                      props.setselectedPackage2({});
                      setIsEnquiry(true);
                      setCheckBox([]);
                      setIncremnt([0, 0]);
                    } else {
                      if (elmt.Id == 3046710) {
                        if (Object.keys(props.selectedPackage2).length === 0) {
                          props.setselectedPackage2(elmt);
                        } else {
                          props.setselectedPackage2({});
                        }
                      } else if (
                        props.data.Title ==
                        "Backup & Disaster Recovery Services"
                      ) {
                        setPackagesCheckbox(elmt);
                      } else if (
                        props.data.Title == "Incident Response Handling"
                      ) {
                        props.setselectedPackage(elmt);
                      } else {
                        props.setselectedPackage(elmt);
                      }
                      setIsEnquiry(false);
                    }
                  }}
                >
                  <Form.Group
                    controlId={"check" + key}
                    className={`round-checkbox blue-checkbox ${
                      props.data.Title ==
                        "Backup & Disaster Recovery Services" ||
                      elmt.Id == 3046710
                        ? "checkboxstyle"
                        : ""
                    }`}
                  >
                    {props.data.Title ==
                    "Backup & Disaster Recovery Services" ? (
                      <Form.Check
                        // type={`${  props.data.Title == "Backup & Disaster Recovery Services" ||  elmt.Id == 3046710 ? 'checkbox' : 'radio' }`}
                        id={elmt.Id}
                        type={`${
                          props.data.Title ==
                            "Backup & Disaster Recovery Services" ||
                          elmt.Id == 3046710
                            ? "checkbox"
                            : "radio"
                        }`}
                        checked={
                          checkBox.indexOf(elmt.Id) != -1
                            ? true
                            : isEnquiry && elmt.Type_Enum == 2
                            ? true
                            : false
                        }
                        name="StripeId"
                        label=""
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        // defaultChecked={elmt.StripeId}
                      />
                    ) : (
                      <Form.Check
                        // type={`${  props.data.Title == "Backup & Disaster Recovery Services" ||  elmt.Id == 3046710 ? 'checkbox' : 'radio' }`}
                        id={elmt.Id}
                        type={`${
                          props.data.Title ==
                            "Backup & Disaster Recovery Services" ||
                          elmt.Id == 3046710
                            ? "checkbox"
                            : "radio"
                        }`}
                        name="StripeId"
                        label=""
                        checked={
                          props.selectedPackage.Id == elmt.Id
                            ? true
                            : props.selectedPackage2.Id == elmt.Id
                            ? true
                            : isEnquiry && elmt.Type_Enum == 2
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </Form.Group>
                  <div
                    className={`center-container text-center boxdiv  ${
                      props.data.Title ==
                        "Backup & Disaster Recovery Services" && "boxcheck"
                    }  `}
                    style={{
                      paddingTop: "19px",
                    }}
                  >
                    <div className="heading3" style={{ color: "#101010" }}>
                      {elmt.Title}
                    </div>
                    <div className="heading3 txt-blue">
                      {elmt.Price.replace(" ", "")}
                    </div>
                    <div
                      className={`${
                        props.data.Title ==
                          "Backup & Disaster Recovery Services" &&
                        (elmt.Type_Enum == 1 || elmt.Type_Enum == 0)
                          ? ""
                          : "d-none"
                      }`}
                    >
                      <hr className="mt-2 mb-2" />
                      <div className="incrementsWrapper">
                        <div
                          className="w-25 crsrpoint"
                          onClick={(e) => {
                            e.stopPropagation();
                            const arr = [...incremt];
                            if (elmt.Id == 3046886) {
                              if (incremt[0] > 0) {
                                arr[0] = arr[0] - 1;
                                setIncremnt(arr);
                              }

                              if (incremt[0] == 1) {
                                setPackagesCheckbox(elmt);
                              }
                              // checkBoxSelect(elmt, 1);
                            }

                            if (elmt.Id == 3046887) {
                              if (incremt[1] > 0) {
                                arr[1] = arr[1] - 1;
                                setIncremnt(arr);
                              }
                              if (incremt[1] == 1) {
                                setPackagesCheckbox(elmt);
                              }
                              // checkBoxSelect(elmt, 2);
                            }
                            setQuantity(true);
                          }}
                        >
                          <img src={`/images/minus.svg`} alt="" />
                        </div>
                        <div className="w-50 txt-blue">
                          {elmt.Id == 3046886 && incremt[0]}
                          {elmt.Id == 3046887 && incremt[1]}
                        </div>
                        <div
                          className="w-25 crsrpoint"
                          onClick={(e) => {
                            e.stopPropagation();
                            // e.stopImmediatePropagation();
                            const arr = [...incremt];
                            if (elmt.Id == 3046886) {
                              if (incremt[0] < 100) {
                                arr[0] = arr[0] + 1;
                                setIncremnt(arr);
                              }
                              checkBoxSelect(elmt, 1);
                            }

                            if (elmt.Id == 3046887) {
                              if (incremt[1] < 100) {
                                arr[1] = arr[1] + 1;
                                setIncremnt(arr);
                              }
                              checkBoxSelect(elmt, 2);
                            }

                            setQuantity(true);
                          }}
                        >
                          <img src={`/images/plus.svg`} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`hourlySliderWrapper pt-3 ${
              props.data.Title == "Incident Response Handling" ||
              props.data.Title == "Security Consultation"
                ? ""
                : "d-none"
            }`}
          >
            <span
              className={`${
                props.data.Title == "Security Consultation" && "d-none"
              }`}
            >
              {props.data.Prices &&
                props.data.Prices[props.data.Prices.length - 1]["Title"]}
              :&nbsp;
              {props.data.Prices &&
                props.data.Prices[props.data.Prices.length - 1]["Price"]}
            </span>
            <div
              className={`text-center ${
                props.data.Title == "Incident Response Handling" && "d-none"
              }`}
            >
              {/* <hr className="mt-0" /> */}
              {props.data && props.data.Prices[0]["Title"]}:
              <span className="txt-blue d-block">
                {props.data && props.data.Prices[0]["Price"]}
              </span>
            </div>
            <div className="hourlySliderContainer mt-4">
              <div className="sliderWrapper">
                <span className="greyText">1 h</span>
                <div className="slider">
                  <Slider
                    max={20}
                    tooltip={false}
                    step={1}
                    value={value}
                    handleLabel={value}
                    orientation="horizontal"
                    onChangeComplete={() => {
                      if (props.data.Title == "Security Consultation") {
                        setPrice(value * 95);
                        setSliderAndPrice(props.data, false);
                      } else {
                        setPrice(value * 130);
                        setSliderAndPrice(props.data, true);
                      }
                    }}
                    onChange={(value) => {
                      setValue(value);
                    }}
                  />
                </div>
                <span className="greyText">20 h</span>
              </div>
              <div className="sliderTextWrapper d-flex align-items-center justify-content-end">
                Total: <span className="txt-blue pl-2">£ {price}</span>
              </div>
            </div>
          </div>
          <div className="greyText text-center" style={{ paddingTop: "15px" }}>
            Prices are exclusive of VAT
          </div>
        </div>
        {!isEnquire ? (
          <Button
            onClick={() => {
              if (isEnquiry) {
                // setIsEnquire(true);
                return false;
              }

              if (Object.keys(props.selectedPackage).length === 0) {
                props.tostMsg({
                  show: true,
                  msg: "You must select a product before checkout",
                });
                setTimeout(() => {
                  props.tostMsg({
                    show: false,
                    msg: "Something went wrong",
                  });
                }, 3100);
              } else {
                TrackUser("Product added");
                if (
                  props.data.Title == "Backup & Disaster Recovery Services" &&
                  incremt[0] == 0 &&
                  incremt[1] == 0 &&
                  props.selectedPackage.Id
                ) {
                  props.tostMsg({
                    show: true,
                    msg: "You must select a product before checkout",
                  });
                  setTimeout(() => {
                    props.tostMsg({
                      show: false,
                      msg: "Something went wrong",
                    });
                  }, 3100);
                } else {
                  props.intergration(true);
                  if (props.selectedPackage2.Id) {
                    if (props.data.Title == "Incident Response Handling") {
                      props.requestPackage(
                        props.selectedPackage.Id,
                        value,
                        false,
                        props.selectedPackage2.Id
                      );
                    } else {
                      props.requestPackage(
                        props.selectedPackage.Id,
                        props.selectedPackage2.Id
                      );
                    }
                  } else if (props.data.Title == "Incident Response Handling") {
                    props.requestPackage(
                      props.selectedPackage.Id,
                      value,
                      false,
                      value ? 3046881 : null
                    );
                  } else if (quantity) {
                    props.requestPackage(checkBox, incremt, true);
                  } else {
                    if (slide) {
                      props.requestPackage(
                        props.selectedPackage.Id,
                        value,
                        true,
                        true
                      );
                    } else {
                      props.requestPackage(props.selectedPackage.Id);
                    }
                  }
                }
              }
            }}
            className="btn-blue p16px w-100"
          >
            Buy Now
          </Button>
        ) : (
          <Button
            disabled={enquireSubmit == 2 ? false : !formDescription && !phoneNo}
            className={`w-100 ${
              enquireSubmit == 1
                ? "btn-grey loading_txt_enq"
                : enquireSubmit == 2
                ? "green-btn"
                : "submit-btn"
            } btn-blue p16px w-100`}
            onClick={(event) => {
              if (enquireSubmit == 2) {
                // setIsEnquire(false);
                setIsEnquireSubmit(0);
              } else {
                onEnquireHandler(event);
              }
            }}
            style={{ bottom: 0 }}
          >
            {/* {enquiring ? (
              <span>
                <Spinner
                  animation="border"
                  className="mr8px"
                  variant="light"
                  size="sm"
                />
                Enquiring
              </span>
            ) : (
              ""
            )} */}
            {enquireSubmit == 2 ? "Okay, Go Back" : "Enquire Now"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CyberEssentialModal;
