import React, { useEffect,   useState } from "react";
import { Button,   Dropdown,   Modal,  } from "react-bootstrap";
import "../membersAccess/addMemberModal.scss";
import "../membersAccess/membersAccess.scss"
import MemberAccessRoleModal from "../membersAccess/MemberAccessRoleModal";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../context/Auth";
import { getAPIData, postData } from "../../../Utils/commonApi";
import MultiSelectDropdown from "./MultiSelectedDropdown";

const AddMemberModal = ({ show, hideModal, refreshData, showMemberAccessModal, hideCreateOption }) => {
  const { authTokens } = useAuth();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dashBoardTypes, setDashBoardTypes] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [selectedMembersList, setSelectedMembersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [IsRoleDropdownOpen, setIsRoleDropdownOpen] = useState(false);
  const [IsDashboardDropdownOpen, setIsDashboardDropdownOpen] = useState(false);
  const [selectedDefaultDashboard, setSelectedDefaultDashboard] = useState('');

  useEffect(() => {
    if (show) {
      getMembersDetails(0, "");
      getDashboardsData();
      getAccessRolesData();
    }
  }, [show]);

  async function getMembersDetails() {
    await getAPIData("553", authTokens, {
      optionEnum1: "1",
      optionEnum2: "20",
      optionEnum3: 1,
      optionEnum99:42
    }).then((response) => {
      if (response?.mr?.Success) {
        setMembers(response?.users);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }


  async function getDashboardsData(){
    await getAPIData("575", authTokens, {
    }).then((response) => {
      if (response.mr.Success) {
        setDashBoardTypes(response.dashBoardTypes);
        setRoles(response.roles);
      }
    });
  }
  async function getAccessRolesData(){
    await getAPIData("551", authTokens, {
    }).then((response) => {
      if (response.mr.Success) {
        setRoles(response.roles);
      }
    });
  }

  async function inviteMember(){
    setLoading(true);
    let list = [];
    selectedMembersList.map(member=>{
      list.push({Key: member.objId_PSAMember, Value: member.userName });
    })

    const selectedAccessRoles = selectedRoles.map(role => role.objId_AccessRole).join(',');
    const selectedAccessDashboards = selectedDashboards.map(role => role.objId_Dashboard).join(',');

    if(list.length > 0){
      await postData(authTokens, "Invite_SaaSBased_Member", 
      {
        objId_AccessRole : selectedAccessRoles,
        obj_List: JSON.stringify(list),
        optionEnum99: 42,
        objId_Dashboard: selectedAccessDashboards,
        objId_DefaultDashboard: selectedDefaultDashboard,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
      }
    )
      .then((response) => {
        setLoading(false);
        if (response?.Success) {
          CallToast("Member invited successfully", false, false, "v2style");

          hideModal();
          setSelectedMembersList([]);
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
    }else {
      // setLoading(false);
      CallToast("Choose member to invite", false, false, "v2style");

    }
    
  }

  return (
    <Modal
      show={show}
      dialogClassName="msp-add-member-modal "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          Add Member
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            setSelectedMembersList([]);
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="p-2 hide-scrollbar">
        <div className="d-flex ">
          <div
            className={` d-flex align-items-center radius-4 grey-border   flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
          >
            <div
              className="bg-transparent w-100 border-none pl-2"
              tabIndex={-1}
            >
              <MultiSelectDropdown
                placeholder={"Enter email"}
                textBlock={true}
                selected={selectedMembersList}
                onChange={(val) => {                 
                  setSelectedMembersList(val);
                }}
              />
            </div>
          </div>
        </div>
        <div className="ml-1 accessRoleBlock">
            <p>
                Access role
            </p>
            <div
              className="w-fit-content dropdown-grey-theme score-question-dropdown ml-3 w-70"
              style={{ alignSelf: "flex-end" }}
            >
              <Dropdown
                className="hg-white-dropdown user-detail-dropdown hide-scrollbar"
                onToggle={(e) => {
                  setIsRoleDropdownOpen(e);
                }}
              >
                <Dropdown.Toggle
                  className={`custom-dropdown-toggle f-gray-imp ${
                    IsRoleDropdownOpen ? "opened" : ""
                  }`}
                >
                  {
                    selectedRoles[0] ? (selectedRoles.map((role) => (
                      <>{`${role?.RoleName}, `}</>
                    ))) : ('Select')
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {!hideCreateOption && (
                    <div
                      className="p-2 my-1 pointer f-darkgrey"
                      style={{
                        display: "grid",
                        "grid-template-columns": "13% 87%",
                      }}
                      onClick={(e) => {
                        setShowAccessLevelModal(true);
                        e.target.closest(".hg-white-dropdown").click();
                      }}
                    >
                      <img
                        alt=""
                        src="/images/settings-v2/lightgrey-plus-icon.svg"
                        className="mr-2"
                      />
                      <div>Create Access</div>
                    </div>
                  )}
                  <div
                    style={{
                      maxHeight: '170px',
                      overflowY: 'auto'
                    }}
                  >
                    {roles.map((el) => {
                      return (
                        <div
                          className="p-2 my-1 pointer f-grey"
                          style={{
                            display: "grid",
                            "grid-template-columns": "15% 85%",
                          }}
                          onClick={(e) => {
                            const roles = selectedRoles;
                            const isRoleSelected = roles && roles.find(
                              (item) => item.objId_AccessRole === el.objId_AccessRole
                            );
                          if(isRoleSelected) {
                              const filteredRoles = roles.filter( (item) => item.objId_AccessRole !== el.objId_AccessRole);
                              setSelectedRoles(filteredRoles);
                          } else {
                              roles.push(el);
                              setSelectedRoles(roles);
                          }
                            e.target.closest(".hg-white-dropdown").click();
                          }}
                        >
                          <div>
                              {
                                  selectedRoles && selectedRoles?.map((role) => (
                                      <div key={role.objId_AccessRole}>{
                                          role?.objId_AccessRole === el?.objId_AccessRole ? (
                                              <img
                                                alt=""
                                                src="/images/settings-v2/check-icon.svg"
                                                className="mr-2"
                                              />
                                            ) : (
                                              <></>
                                            )
                                      }</div>
                                  ))
                              }
                          </div>

                          <div>
                            {el?.RoleName}
                            <div className="smallGreyText text-ellipsis f-darkgrey">
                              {el.RoleDescription}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
        </div>
        <div className="ml-1 dashboardBlock">
            <p>
                Available dashboards
            </p>
            <div
              className="w-fit-content dropdown-grey-theme score-question-dropdown ml-3 w-70"
              style={{ alignSelf: "flex-end" }}
            >
              <Dropdown
                className="hg-white-dropdown user-detail-dropdown hide-scrollbar"
                onToggle={(e) => {
                  setIsDashboardDropdownOpen(e);
                }}
              >
                <Dropdown.Toggle
                  className={`custom-dropdown-toggle f-gray-imp ${
                    IsDashboardDropdownOpen ? "opened" : ""
                  }`}
                >
                  {
                    selectedDashboards[0] ? (selectedDashboards.map((dashboard) => (
                      <>{`${dashboard?.name}, `}</>
                    ))) : ('All dashboards')
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <>
                    <div className="dropdownDashboardsLabel">Available dashboards</div>
                    <div
                      style={{
                        maxHeight: '140px',
                        overflowY: 'auto'
                      }}
                    >
                      {dashBoardTypes.map((dashboard) => {
                        if(dashboard?.objId_Dashboard !== '') {
                        return (
                          <div
                            className="p-2 my-1 pointer f-grey"
                            style={{
                              display: "grid",
                              "grid-template-columns": "7% 93%",
                            }}
                          >
                            <div>
                                {
                                    selectedDashboards && selectedDashboards?.map((dashboardItem) => (
                                        <div key={dashboardItem.objId_Dashboard}>{
                                            dashboardItem?.objId_Dashboard === dashboard?.objId_Dashboard ? (
                                                <img
                                                  alt=""
                                                  src="/images/settings-v2/check-icon.svg"
                                                  className="mr-2"
                                                />
                                              ) : (
                                                <></>
                                              )
                                        }</div>
                                    ))
                                }
                            </div>

                            <div className="d-flex justify-content-between">
                              <div
                                onClick={(e) => {
                                  const dashboards = selectedDashboards;
                                  const isDashboardSelected = dashboards && dashboards.find(
                                    (item) => item.objId_Dashboard === dashboard.objId_Dashboard
                                  );
                                  if(isDashboardSelected) {
                                    if (isDashboardSelected?.objId_Dashboard === selectedDefaultDashboard) {
                                      CallToast("Cannot unassign the only default dashboard. Please set another dashboard as default before unassigning.", false, false, "v2style");
                                    } else {
                                      const filteredDashboards = dashboards.filter( (item) => item.objId_Dashboard !== dashboard.objId_Dashboard);
                                      setSelectedDashboards(filteredDashboards);
                                    }
                                  } else {
                                    dashboards.push(dashboard);
                                      setSelectedDashboards(dashboards);
                                  }
                                  getDashboardsData();
                                }}
                              >
                                {dashboard?.name}
                              </div>
                              <div>
                                {
                                      dashboard.objId_Dashboard === selectedDefaultDashboard ? (
                                        <div className="f-blue">
                                          Default
                                        </div>
                                      ) : (
                                        <div 
                                          className="f-darkgrey child-visible"
                                          onClick={() => {
                                            setSelectedDefaultDashboard(dashboard?.objId_Dashboard)
                                          }}
                                        >
                                          Make default
                                        </div>
                                      )
                                    }
                              </div>

                            </div>
                          </div>
                        );
                        }
                      })}
                    </div>
                  </>
                </Dropdown.Menu>
              </Dropdown>
            </div>
        </div>
        <div/>
        {showAccessLevelModal && (
          <MemberAccessRoleModal
            show={showAccessLevelModal}
            hideModal={() => {
              setShowAccessLevelModal(false);
            }}
            parentID="msp-main-screen-for-integration-modal"
            refreshData={() => {
              getMembersDetails();
              getAccessRolesData();
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer-block">
            <Button
            className="modal-cancel-button cursor-pointer"
            onClick={() => {
              setSelectedMembersList([]);
              setSelectedRoles([]);
              setSelectedDashboards([]);
              setSelectedDefaultDashboard({});
              hideModal();
            }}>
                Cancel
            </Button>
            <Button
              disabled={selectedMembersList.length === 0 || loading || !selectedRoles}
              className="modal-invite-button cursor-pointer"
              onClick={() => {
                inviteMember();
              }}
            >
                {loading ? "Inviting..." : "Invite"}{" "}
            </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMemberModal;
