import React, { useEffect, useState } from "react";
import "../secOps.scss";
import { Status } from "../../../AttackSurface/Index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  FrameworksToolTip,
  PackageToolTip,
  QuestionChip,
} from "../../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import CreateTaskModal from "../../../PriceCalculator/modals/CreateTaskModal";
import { setactioncentredata } from "../../../../redux/actionCentre/actions";
import { useDispatch } from "react-redux";

import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SubmitQuestionaire, getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import Loader from "../../../Common/loader";
import { useRef } from "react";
import { NO_ACCESS_TEXT, accessStateValue } from "../../../App/App";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import { auditUpdate } from "../audit/AuditHeader";
const SecurityProcesses = ({isAudit = false, setAddedUrlKey = () => {}}) => {
  const { authTokens } = useAuth();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  let selectedSideBarTab = isAudit ? window.location.href?.split('selectedSideBarTab=')[1] : new URLSearchParams(window.location.search).get(
    "selectedSideBarTab"
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [sideMenu, setSideMenu] = useState([]);
  const [questionData, setQuestionData] = useState({});
  const [answerAuditList, setAnswerAuditList] = useState([]);
  const [dataLoaders, setDataLoaders] = useState({
    listLoading: false,
    detailLoading: false,
    questionSaving: false,
    auditsLoading: false,
  });
  const [previousAnswer, setPreviousAnswer] = useState('')

  useEffect(() => {
    if (showSidePanel === false) {
      setSideBarOpen(true);
    }
  }, [showSidePanel]);

  useEffect(() => {
    setLoading(true);
    getSideMenu().then((response) => {
      getPageData(selectedSideBarTab);
    });
  }, []);

  useEffect(() => {
    if (answerAuditList && answerAuditList?.length) {
      // if (answerAuditList[0]?.AnswerText == "Yes") {
      let updData = questionData;
      updData.NoteAnswerText = answerAuditList[0]?.InternalComment;
      updData.AnswerText = answerAuditList[0]?.ExternalStatement;
      setQuestionData(updData);
      setPreviousAnswer(questionData?.AnswerJson == null ? '' : questionData?.AnswerJson[0])
      // }
    }
  }, [answerAuditList]);

  async function getPageData(_selectedSideBarTab) {
    await getAPIData("650", authTokens, { optionEnum1: _selectedSideBarTab })
      .then((response) => {
        if (response?.mr?.Success) {
          setPageData(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
        setDataLoaders({ ...dataLoaders, detailLoading: false });
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  async function getSideMenu() {
    return new Promise((resolve, reject) => {
      getAPIData("651", authTokens, { optionEnum1: 0 })
        .then((response) => {
          if (response?.mr?.Success) {
            setSideMenu(response?.SCMAllFormTemplate_List);
            setAddedUrlKey(response?.SCMAllFormTemplate_List[0]?.ObjectId)
            resolve(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  async function getQuestionDetails(_questionId) {
    return new Promise((resolve, reject) => {
      getAPIData("657", authTokens, {
        optionEnum1: _questionId,
        optionEnum2: 1,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setAnswerAuditList(response?.FormAnswerAudit_List);
            setDataLoaders({ ...dataLoaders, auditsLoading: false });
            resolve(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
            reject(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  async function SubmitQuestion() {
    await SubmitQuestionaire(authTokens, "POST", {
      ObjectId: 0,
      QuestionnaireTypeEnum: 27,
      QuestionList: JSON.stringify([questionData]),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setDataLoaders({ ...dataLoaders, questionSaving: false });
        if (response.Success) {
          getSideMenu().then(() => {
            CallToast("Question Saved Successfully", false, false, "v2style");
          });
          if (isAudit) {
            auditUpdate(
              authTokens, 
              questionData?.QuestionId, 
              4, 
              questionData?.AnswerJson[0] == 'Yes' ? 3 : questionData?.AnswerJson[0] == 'No' ? 4 : questionData?.AnswerJson[0] == 'In Progress' ? 5 : 2,
              previousAnswer == '' ? 2 : previousAnswer == 'Yes' ? 3 : previousAnswer == 'No' ? 4 : previousAnswer == 'In Progress' && 5
            )
          }
          // setQuestionData({});
          // setShowSidePanel(false);
          setDataLoaders({ ...dataLoaders, detailLoading: true });
          getPageData(selectedSideBarTab);
          getQuestionDetails(questionData?.QuestionId);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function isAtTop() {
    if (
      pageData?.QuestionnaireSection_List?.length === 0 ||
      !pageData?.QuestionnaireSection_List
    )
      return;
    let all_list = pageData?.QuestionnaireSection_List.reduce(
      (accumulator, currentValue) => {
        return accumulator.concat(currentValue.Question_List);
      },
      []
    );
    let index = all_list.findIndex(
      (item) => item?.QuestionId === questionData?.QuestionId
    );

    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }
  function isAtBottom() {
    if (
      pageData?.QuestionnaireSection_List?.length === 0 ||
      !pageData?.QuestionnaireSection_List
    )
      return;
    let all_list = pageData?.QuestionnaireSection_List.reduce(
      (accumulator, currentValue) => {
        return accumulator.concat(currentValue.Question_List);
      },
      []
    );
    let index = all_list.findIndex(
      (item) => item?.QuestionId === questionData?.QuestionId
    );

    if (index === all_list.length - 1) {
      return true;
    } else {
      return false;
    }
  }
  console.log("questionData --- ", questionData);

  async function moveNext() {
    if (
      pageData?.QuestionnaireSection_List?.length === 0 ||
      !pageData?.QuestionnaireSection_List
    )
      return;
    let all_list = pageData?.QuestionnaireSection_List?.reduce(
      (accumulator, currentValue) => {
        return accumulator.concat(currentValue?.Question_List);
      },
      []
    );
    let index = all_list.findIndex(
      (item) => item?.QuestionId === questionData?.QuestionId
    );

    if (index > -1 && index < all_list.length - 1) {
      setQuestionData({
        ...all_list[index + 1],
        AnswerText: "",
        NoteAnswerText: "",
        showExternalInput: true,
      });
      setDataLoaders({
        ...dataLoaders,
        auditsLoading: true,
      });
      getQuestionDetails(all_list[index + 1]?.QuestionId);
    }
  }

  async function movePrev() {
    if (
      pageData?.QuestionnaireSection_List?.length === 0 ||
      !pageData?.QuestionnaireSection_List
    )
      return;
    let all_list = pageData?.QuestionnaireSection_List?.reduce(
      (accumulator, currentValue) => {
        return accumulator.concat(currentValue?.Question_List);
      },
      []
    );
    let index = all_list.findIndex(
      (item) => item?.QuestionId === questionData?.QuestionId
    );

    if (index > 0) {
      setQuestionData({
        ...all_list[index - 1],
        AnswerText: "",
        NoteAnswerText: "",
        showExternalInput: true,
      });
      setDataLoaders({
        ...dataLoaders,
        auditsLoading: true,
      });
      getQuestionDetails(all_list[index - 1]?.QuestionId);
    }
  }

  return (
    <div className="w-100">
      {/*SecOps Side Bar  */}
      {loading ? (
        <div className="w-45 mx-auto">
          <SecurityProcessesLoader />
        </div>
      ) : (
        <>
          <div
            className={`secops-sidebar bg-white transparent-scroller ${
              showSidePanel === false
                ? "bar-expand"
                : sideBarOpen
                ? "bar-expand"
                : "bar-collapse"
            }`}
            style={{
              position: "fixed",
            }}
          >
            <div className="f-500 f-darkgrey d-flex align-items-center justify-content-between position-relative">
              {sideBarOpen && showSidePanel ? (
                <div>MSP Security Processes & Procedures</div>
              ) : sideBarOpen ? (
                <div>MSP Security Processes & Procedures</div>
              ) : (
                <></>
              )}
              <div
                className={` ${
                  sideBarOpen
                    ? "position-absolute right-0"
                    : " d-flex align-items-center justify-content-center w-100 mt-2"
                }                `}
              >
                {showSidePanel && (
                  <img
                    src="/images/msp/sidebar-expanding-arrow.svg"
                    alt=""
                    className="trigger-icon pointer z-Index-1"
                    onClick={() => {
                      setSideBarOpen(!sideBarOpen);
                      if (isAudit) {
                        setShowSidePanel(false);
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <div className="d-flex flex-column">
              {sideMenu?.map((item, menuIndex) => {
                return (
                  <div
                    className={` align-items-center justify-content-between px-2 pointer menu-item py-2 mt-1
                      ${
                        item?.DevelpomentStage?.toLowerCase() === "hide"
                          ? "d-none"
                          : "d-flex"
                      }
                      
                      ${
                        Number(isAudit ? window.location.href.split('=')[2] : selectedSideBarTab ?? 0) ===
                        Number(item?.ObjectId)
                          ? "selected-menu-item"
                          : ""
                      }   `}
                    onClick={() => {
                      if (
                        Number(isAudit ? window.location.href.split('=')[2] : selectedSideBarTab ?? 0) ===
                        Number(item?.ObjectId)
                      ) {
                        return;
                      }
                      setDataLoaders({ ...dataLoaders, detailLoading: true });

                      getPageData(item?.ObjectId);
                      history.push(`${
                        isAudit ? `/mspv2/audit?type=SecurityProcessesProcedures?selectedSideBarTab=${item?.ObjectId}` :
                        `/mspv2/secops/security-processes?selectedSideBarTab=${item?.ObjectId}`
                      }` 
                      );
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        {item?.Title?.toLowerCase() === "overview" && (
                          <img
                            alt=""
                            src="/images/attack-surface/overview-icon.svg"
                            className="ml-1"
                          />
                        )}

                        {item?.Title?.toLowerCase() !== "overview" &&
                          item?.DevelpomentStage?.toLowerCase() ===
                            "coming soon" && (
                            <img
                              alt=""
                              src="/images/attack-surface/grey-thunder-icon.svg"
                              className="ml-1"
                              style={{ width: "14px", marginRight: "3px" }}
                            />
                          )}

                        {item?.Title?.toLowerCase() !== "overview" &&
                          // item?.DevelpomentStage?.toLowerCase() === "live" &&
                          item?.Percentage === 0 && (
                            <img
                              alt=""
                              src="/images/attack-surface/thunder-icon.svg"
                              className="ml-1"
                              style={{ width: "14px", marginRight: "3px" }}
                            />
                          )}

                        {item?.Title?.toLowerCase() !== "overview" &&
                          // item?.DevelpomentStage?.toLowerCase() === "live" &&
                          item?.Percentage > 0 &&
                          item?.Percentage < 100 && (
                            <Status value={100 - item?.Percentage} />
                          )}

                        {item?.Title?.toLowerCase() !== "overview" &&
                          // item?.DevelpomentStage?.toLowerCase() === "live" &&
                          item?.Percentage === 100 && (
                            <img
                              alt=""
                              src="/images/attack-surface/menu-completed.svg"
                              className="ml-1"
                              style={{ width: "14px", marginRight: "3px" }}
                            />
                          )}
                      </div>

                      <div className="ml-2 d-flex align-items-center justify-content-center">
                        {item?.Title}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      {(item?.DevelpomentStage?.toLowerCase() === "live" ||
                        true) && <>{item?.Percentage}%</>}
                      {item?.DevelpomentStage?.toLowerCase() ===
                        "coming soon" && (
                        <span
                          className="attack-menu-tag"
                          style={{
                            width: `${
                              item?.Title.toLowerCase() == "directory services"
                                ? "51px"
                                : "51px"
                            }`,
                          }}
                        >
                          {item?.Title.toLowerCase() == "directory services"
                            ? "COMING"
                            : "COMING"}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Page Body */}
          <div className="slidable-body-new position-relative">
            <div
              className={`main-body hide-scrollbar ${
                showSidePanel ? "panel-open" : "panel-closed"
              }`}
              onClick={() => {
                // setShowSidePanel(true);
              }}
            >
              {dataLoaders.detailLoading ? (
                <SecurityProcessesLoader />
              ) : (
                <>
                  <div className="f-16 f-600 my-4">
                    {/* {
                      sideMenu?.find(
                        (_item) =>
                          _item?.ObjectId === Number(selectedSideBarTab)
                      )?.Title
                    } */}
                    MSP Security Processes & Procedures
                  </div>

                  {pageData?.QuestionnaireSection_List?.map(
                    (pillar, pillarIndex) => {
                      return (
                        <>
                          <div className="d-flex align-items-center mb-3">
                            <div
                              className={`f-600 f-black mr-3 ${
                                pillarIndex > 0 ? "mt-3" : ""
                              }`}
                            >
                              {pillar?.Title}
                            </div>
                            <div className="position-relative tooltip-parent d-none">
                              <QuestionChip
                                iconUrl={
                                  !"chip.IsFramework"
                                    ? "/images/msp/msp-framework-icon.svg"
                                    : null
                                }
                                title={"ID.GV"}
                                className={"mr-2"}
                              />
                              {/* {!chip.IsFramework && (
                                    <PackageToolTip content={chip?.Detail} />
                                  )}

                                  {chip.IsFramework && <FrameworksToolTip />} */}
                            </div>
                          </div>
                          {/* Questions List */}
                          <div className="border-parent radius-8 bg-grey">
                            {pillar?.Question_List?.map((item, index) => {
                              return (
                                <div
                                  className={`
                    
                    ${
                      questionData?.QuestionId === item?.QuestionId
                        ? "bg-lightblue-imp"
                        : ""
                    }
                            
                    
                    border-item py-3 pl-1 pr-2 align-items-start justify-content-between pointer`}
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "86% 14%",
                                  }}
                                  onClick={() => {
                                    if (
                                      questionData?.QuestionId ===
                                      item?.QuestionId
                                    ) {
                                      return;
                                    }
                                    setShowSidePanel(true);
                                    setSideBarOpen(false);
                                    // getQuestionDetails(item?.QuestionId)

                                    setQuestionData({
                                      ...item,
                                      AnswerText: "",
                                      NoteAnswerText: "",
                                      showExternalInput: true,
                                    });
                                    setDataLoaders({
                                      ...dataLoaders,
                                      auditsLoading: true,
                                    });
                                    getQuestionDetails(item?.QuestionId);
                                  }}
                                >
                                  <div>
                                    <div className="d-flex align-items-start">
                                      <img
                                        src="/images/attack-surface/yellow-dot.svg"
                                        className="mx-1 mt-2"
                                        alt=""
                                        style={{
                                          visibility: `${
                                            !item?.AnswerJson
                                              ? "visible"
                                              : "hidden"
                                          }`,
                                        }}
                                      />
                                      <div className="f-black">
                                        {item?.QuestionText}{" "}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-wrap ml-3 mt-1">
                                      {item?.NISTAssetClassification_List?.map(
                                        (chip, chipIndex) => {
                                          return (
                                            <div className="d-flex align-items-center position-relative tooltip-parent">
                                              <QuestionChip
                                                iconUrl={
                                                  chip.IsFramework
                                                    ? "/images/msp/msp-framework-icon.svg"
                                                    : null
                                                }
                                                title={chip?.Name}
                                                className={"mr-2"}
                                                IsBlue={chip.isBlue}
                                              />
                                              {!chip.IsFramework && (
                                                <PackageToolTip
                                                  content={chip?.Detail}
                                                />
                                              )}

                                              {chip.IsFramework && (
                                                <FrameworksToolTip />
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  {item?.AnswerJson && (
                                    <div
                                      className={`mx-2 ${
                                        item?.AnswerJson[0] === "Yes"
                                          ? "f-darkgrey"
                                          : item?.AnswerJson[0] === "No"
                                          ? "f-danger"
                                          : item?.AnswerJson[0] ===
                                            "In Progress"
                                          ? "f-blue"
                                          : ""
                                      }`}
                                      style={{ justifySelf: "end" }}
                                    >
                                      {item?.AnswerJson && item?.AnswerJson[0]}
                                      {/* {item?.AnswerBool === false && "No"} */}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </>
                      );
                    }
                  )}
                </>
              )}

              <div className="p-4" />
            </div>

            <div
              className={`sidepanel  ${
                showSidePanel ? "panel-open" : "panel-closed"
              } `}
            >
              {dataLoaders.detailLoading ? (
                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="panel-header d-flex align-items-center justify-content-between p-3">
                    <div className="d-flex align-items-center">
                      <div
                        className={`mx-2 pointer ${
                          isAtTop() ? "pointer-events-none opacity-40" : ""
                        }`}
                        onClick={() => {
                          movePrev();
                        }}
                      >
                        <img
                          src="/images/msp/secops-chevron-down.svg"
                          className="rotate180"
                          alt=""
                        />
                      </div>
                      <div
                        className={`mx-2 pointer ${
                          isAtBottom() ? "pointer-events-none opacity-40" : ""
                        }`}
                        onClick={() => {
                          moveNext();
                        }}
                      >
                        <img
                          src="/images/msp/secops-chevron-down.svg"
                          className=""
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <WrapperDropdwon data={questionData?.Explanation}>
                        <div className="f-grey f-500 mr-4">
                          <img
                            src="/images/msp/secops-info-icon.svg"
                            alt=""
                            className="mr-2"
                          />
                          More info
                        </div>
                      </WrapperDropdwon>
                      <div
                        className="f-grey f-500 mr-2 pointer"
                        onClick={() => {
                          if (accessStateValue("AccessState_ActionCentre") > 3) {
                            dispatch(
                              setactioncentredata({
                                refreshCreateTaskModalModule: true,
                              })
                            );
                            const drag = document.getElementById("create-task-modal");
                            drag.style.display = "block";
                            }
                          }}
                      >
                        {accessStateValue("AccessState_ActionCentre") < 3 ? (
                          <img
                            src="/images/msp/lock-icon.svg"
                            alt=""
                            className="mr-3"
                          />
                        ) : (
                          <img
                            src="/images/msp/secops-create-task-icon.svg"
                            alt=""
                            className="mr-3"
                          />
                        )}
                        Create task
                      </div>
                      <div
                        className="pl-2 border-left pointer"
                        onClick={() => {
                          setShowSidePanel(false);
                          setQuestionData({});
                          setAnswerAuditList([]);
                        }}
                      >
                        <img
                          width={13}
                          src="/images/attack-surface/cross-icon.svg"
                          alt=""
                          className=" "
                        />
                      </div>
                    </div>
                  </div>

                  <div className="panel-body transparent-scroller">
                    {/* Previus Responses UI */}
                    <div className="px-3">
                      <div className="my-2 f-500 mt-4">
                        Question
                        {
                          accessStateValue("AccessState_SecOps") === 1 && 
                          <img
                            height={14}
                            width={14}
                            alt=""
                            src="/images/attack-surface/small-lock.svg"
                            className="ml-2 mb-1"
                          />
                        }
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="f-black">
                          {questionData?.QuestionText}
                        </div>
                        <div className="w-fit-content dropdown-grey-theme score-question-dropdown ml-3 z--1">
                          {
                            accessStateValue("AccessState_SecOps") === 1 ?
                            <div className="f-darkgrey f-500 mx-3 important-width">
                                  {questionData?.AnswerJson
                                  ? questionData?.AnswerJson[0] === "Yes"
                                    ? { id: 1, cat: "Yes" }
                                    : questionData?.AnswerJson[0] === "No"
                                    ? { id: 2, cat: "No" }
                                    : questionData?.AnswerJson[0] ===
                                      "In Progress"
                                    ? { id: 0, cat: "In Progress" }
                                    : 'No Selected'
                                  : 'No Selected'}
                                </div>
                            :
                            <CompanySettingsDropdown
                              fieldId={"id"}
                              fieldValue={"cat"}
                              selected={
                                questionData?.AnswerJson
                                  ? questionData?.AnswerJson[0] === "Yes"
                                    ? { id: 1, cat: "Yes" }
                                    : questionData?.AnswerJson[0] === "No"
                                    ? { id: 2, cat: "No" }
                                    : questionData?.AnswerJson[0] ===
                                      "In Progress"
                                    ? { id: 0, cat: "In Progress" }
                                    : null
                                  : null
                              }
                              placeholder="Select"
                              //  showid={true}
                              onClick={(id, val) => {
                                let updated_data = { ...questionData };
                                updated_data.AnswerJson = [];

                                updated_data.AnswerJson.push(val);

                                updated_data.QuestionStatus = 3;
                                updated_data.QuestionStatusText = "Answered";

                                setQuestionData(updated_data);
                              }}
                              data={[
                                { id: 1, cat: "Yes" },
                                { id: 2, cat: "No" },
                                { id: 0, cat: "In Progress" },
                              ]}
                            />
                          }
                        </div>
                      </div>

                      <textarea
                        disabled={accessStateValue("AccessState_SecOps") === 1}
                        className="w-100 radius-4 p-2 border1 mt-2"
                        rows={5}
                        placeholder="Add Internal Note..."
                        value={questionData?.NoteAnswerText ?? ""}
                        onChange={(e) => {
                          let updated_data = { ...questionData };
                          updated_data.NoteAnswerText = e.target.value;
                          updated_data.QuestionStatus = 3;
                          updated_data.QuestionStatusText = "Answered";
                          setQuestionData(updated_data);
                        }}
                      />

                      {questionData?.showExternalInput && (
                        <>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="f-500 my-2">
                              External Security Statement
                              {
                                accessStateValue("AccessState_SecOps") === 1 && 
                                <img
                                  height={14}
                                  width={14}
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="ml-2 mb-1"
                                />
                              }
                            </div>
                            <div>
                              <img
                                width={10}
                                src="/images/attack-surface/cross-icon.svg"
                                alt=""
                                className=" pointer"
                                onClick={() => {
                                  setQuestionData({
                                    ...questionData,
                                    showExternalInput: false,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <textarea
                            disabled={accessStateValue("AccessState_SecOps") === 1}
                            className="w-100 radius-4 p-2 border1"
                            rows={5}
                            placeholder="Add Note to be included in your External Security Statement..."
                            value={questionData?.AnswerText ?? ""}
                            onChange={(e) => {
                              let updated_data = { ...questionData };
                              updated_data.AnswerText = e.target.value;
                              updated_data.QuestionStatus = 3;
                              updated_data.QuestionStatusText = "Answered";
                              setQuestionData(updated_data);
                            }}
                          />
                        </>
                      )}

                      <div className="d-flex w-100 flex-end justify-content-end my-2">
                        <AttackSurfaceTooltip
                          bottomLeft={true}
                          disabled={
                            accessStateValue("AccessState_SecOps") !== 1
                          }
                          topLeft={false}
                          content={<span>{NO_ACCESS_TEXT}</span>}
                        >
                          <div>
                            <Button
                              className={`hg-blue-btn
                     ${
                       accessStateValue("AccessState_SecOps") === 1
                         ? " btn-disable"
                         : ""
                     }
                     
                     `}
                              disabled={
                                dataLoaders?.questionSaving ||
                                !questionData?.AnswerJson
                              }
                              onClick={() => {
                                setDataLoaders({
                                  ...dataLoaders,
                                  questionSaving: true,
                                });
                                SubmitQuestion();
                              }}
                            >
                              {accessStateValue("AccessState_SecOps") === 1 ? (
                                <img
                                  height={16}
                                  width={16}
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="mr-2 mb-1"
                                />
                              ) : (
                                <></>
                              )}{" "}
                              {dataLoaders?.questionSaving
                                ? "Saving..."
                                : "Save"}
                            </Button>
                          </div>
                        </AttackSurfaceTooltip>
                      </div>

                      {questionData?.NISTAssetClassification_List?.length >
                        0 && <div className="my-3 f-500">References</div>}
                      <div className="mb-3 d-flex  flex-wrap">
                        {questionData?.NISTAssetClassification_List?.map(
                          (chip, chipIndex) => {
                            return (
                              <div className="d-flex align-items-center position-relative tooltip-parent">
                                <QuestionChip
                                  iconUrl={
                                    chip.IsFramework
                                      ? "/images/msp/msp-framework-icon.svg"
                                      : null
                                  }
                                  title={chip?.Name}
                                  className={"mr-2"}
                                  IsBlue={chip.isBlue}
                                />
                                {!chip.IsFramework && (
                                  <PackageToolTip content={chip?.Detail} />
                                )}

                                {chip.IsFramework && <FrameworksToolTip />}
                              </div>
                            );
                          }
                        )}
                      </div>

                      {dataLoaders.auditsLoading ? (
                        <div className="d-flex align-items-center justify-content-center w-100 h-100">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          {answerAuditList?.length > 0 && (
                            <div className="my-3 f-500">Previous responses</div>
                          )}

                          {answerAuditList
                            ?.slice(
                              1
                            ) /*?.filter(obj => obj.AnswerText !== "No")*/
                            ?.map((item, index) => {
                              return (
                                <div
                                  className="d-flex flex-column"
                                  style={{ gap: "8px", marginBottom: "12px" }}
                                >
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                      <div className="mr-2">
                                        {item.AddedByLogo ? (
                                          <img
                                            alt=""
                                            width={24}
                                            height={24}
                                            src={item.AddedByLogo}
                                            style={{ borderRadius: "9999px" }}
                                          />
                                        ) : (
                                          <ImageWithInitials
                                            initials={
                                              item?.AddedById_Text
                                                ? item?.AddedById_Text[0]
                                                : "N"
                                            }
                                            width={24}
                                            background={"purple"}
                                          />
                                        )}
                                      </div>
                                      <span className=" mr-2">
                                        {item.AddedById_Text}
                                      </span>
                                      <div className="f-darkgrey">
                                        {item.AnswerDate}
                                      </div>
                                    </div>
                                    <div className="f-500 f-black">
                                      {item?.AnswerText}
                                    </div>
                                  </div>
                                  {item?.InternalComment && (
                                    <>
                                      <div className="f-12 f-500 f-darkgrey ">
                                        Internal Comment
                                      </div>
                                      <div>{item?.InternalComment}</div>
                                    </>
                                  )}
                                  {item?.ExternalStatement && (
                                    <>
                                      <div className="f-12 f-500 f-darkgrey ">
                                        External Statement
                                      </div>
                                      <div>{item?.ExternalStatement}</div>
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          {/* {answerAuditList?.map((item, index) => {
                        return (
                          <div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="f-500 f-black">
                                {item?.AnswerText}
                              </div>
                              <div className="f-grey f-500">
                                {item?.AnswerDate}{" "}
                              </div>
                            </div>
                            {item?.InternalComment && (
                              <>
                                <div className="f-12 f-500 f-darkgrey my-2">
                                  Internal Comment
                                </div>
                                <div>{item?.InternalComment}</div>
                              </>
                            )}
                            {item?.ExternalStatement && (
                              <>
                                <div className="f-12 f-500 f-darkgrey my-2">
                                  External Statement
                                </div>
                                <div>{item?.ExternalStatement}</div>
                              </>
                            )}
                          </div>
                        );
                      })} */}
                        </>
                      )}
                      {/* {showSidePanel && <div className="p-5" />} */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <CreateTaskModal />
        </>
      )}
    </div>
  );
};

export default SecurityProcesses;

export const previousResponses = [
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
];

export const WrapperDropdwon = ({ children, header = "Explanation", data }) => {
  return (
    <Dropdown className="transparennt-wrapper-dropdown">
      <Dropdown.Toggle>{children}</Dropdown.Toggle>
      <Dropdown.Menu className="p-0 wrapper-dropdown">
        <header className="d-flex align-items-center bg-grey p-2 justify-content-between">
          <div className="f-grey">
            <img
              alt=""
              src="/images/attack-surface/info-icon.svg"
              className="mr-2 pointer"
            />
            {header}
          </div>
          <div className="d-flex align-items-center pointer">
            <img
              alt=""
              src="/images/attack-surface/expand-icon.svg"
              className="mx-2"
            />
            <img
              alt=""
              src="/images/attack-surface/cross-icon.svg"
              className="mx-2 mr-2"
              onClick={(e) => {
                e.stopPropagation();
                e.target.closest(".transparennt-wrapper-dropdown").click();
              }}
            />
          </div>
        </header>
        <div
          className="f-black p-2"
          style={{
            width: "300px",
          }}
        >
          {data ??
            `Attackers are scanning all of the time and getting more sophisticated
          with the use of AI and bots. If you are attracting attention (be it
          scatter gun or targetted), you need to know about it, and proactively
          be alerted to this as well as blocking malicious activity before it
          turns into something more serious. Regular routers cannot perform this
          function, whereas a suitably spec'd firewall can.`}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const SecurityProcessesLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      // height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" />
    </ContentLoader>
  );
};
