import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { FullScreenLoader } from "../Common/loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function PrivateRoute({ component: Component,render, ...rest }) {
  const { auth, authTokens, setAuth } = useAuth();
  // const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  

  function allowedURL() {
    if (location.pathname === "/login") {
      return true;
    }
    if (location.pathname === "/signup") {
      return true;
    }
    if (location.pathname.includes("login")) {
      return true;
    }
    if (location.pathname.includes("signup")) {
      return true;
    }

    return false;
  }
  function authenticate() {
    setAuth(true);
    setIsAuthenticated(true);
    setLoading(false);
  }
  function unauthenticate() {
    window.localStorage.clear();
    setIsAuthenticated(false);
    setLoading(false);
  }

  useEffect(() => {
    const token = window.localStorage.getItem("tokens");
    if (token) {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/AttackSurfaceAPI/ValidateUser?AccessKey=${JSON.parse(localStorage.getItem("user_MessageObj"))}`,
        {
          method: "GET",
          headers: {
            Accept: "*/*",

            Connection: "keep-alive",

            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            authenticate();
          } else {
            unauthenticate();
          }
        })
        .then((data) => {})
        .catch((error) => unauthenticate());
    } else {
      unauthenticate();
    }
  }, []);

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ||
        auth ||
        (isAuthenticated === false && allowedURL()) ? (
          // <Component {...props} />
          Component ? <Component {...props} /> : render ? render(props) : null
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                // redirectUser: allowedURL() ? false : true,
                // redirectUrl: allowedURL()
                //   ? ""
                //   : `${window.location.pathname}${window.location.search}`,
              },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
