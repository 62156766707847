import React, { useState, useEffect } from "react";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./cdsa.scss";
import { Button, Form } from "react-bootstrap";
import { useAuth } from "../../context/Auth";
import { getAPIData, SubmitQuestionaire } from "../../../Utils/commonApi";
import CDSAmainpageloader from "../AttackSurfaceLoaders/CDSAmainpageloader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import { AttackSurfaceResetModal } from "../AttackSurfaceResetModal";
import { viewOnlyAttackSurface } from "../../../Utils/AccessLevelFunctions";
import { viewOnlyAccess } from "../../App/App";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";

const SecurityOperations = ({ refreshMenuItems }) => {
  const [questionList, setQuestionList] = useState([]);
  const { authTokens } = useAuth();
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [tempText, setTempText] = useState([]);
  const [dummyData, setDummyData] = useState([]);
  const [loadingMulticheckbox, setLoadingMulticheckbox] = useState(false);
  const [reloadingData, setReloadingData] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setMainPageLoading(true);
    getPageData();
  }, []);
  function getPageData(success = false) {
    getAPIData(265, authTokens, {
      optionEmun1: 0
    }).then((response) => {
      setMainPageLoading(false);
      setReloadingData(false);
      if (response?.mr?.Success) {
        setOriginalData(
          [...response?.QuestionnaireSection_List?.[4]?.Question_List].map(
            (item) => {
              return {
                ...item,
                AnswserSelectedDropdownItems:
                  item?.QuestionTypeText === "MultiSelectCheckBox"
                    ? []
                    : item?.AnswserSelectedDropdownItems,
              };
            }
          )
        );

        if (success) {
          let updated_data = [...questionList].map((item, index) => {
            return {
              ...item,
              AnswerId:
                response?.QuestionnaireSection_List?.[4]?.Question_List[index]
                  .AnswerId,
            };
          });

          setQuestionList(updated_data);
        } else {
          let updated_data = [
            ...response?.QuestionnaireSection_List?.[4]?.Question_List,
          ].map((item) => {
            return {
              ...item,
              AnswerText: item?.AnswerText ? item?.AnswerText : "",
              handleMultiCheckbox:
                item?.QuestionTypeText === "MultiSelectCheckBox" &&
                  item?.AnswerText?.split(",")?.length > 0
                  ? item?.AnswerText?.split(",")
                  : [],
              selectedDropdownValue:
                item?.AnswerText && item?.QuestionTypeText == "DropdownList"
                  ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                    return {
                      id: index + 1,
                      category: listItem,
                    };
                  }).filter(
                    (filterItem) => filterItem.category === item?.AnswerText
                  )[0]
                  : null,
              dropDowndata: item?.QuestionDropdownListMembers
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                  return {
                    id: index + 1,
                    category: listItem,
                  };
                })
                : [],
              checkBoxList:
                item?.QuestionTypeText === "MultiSelectCheckBox"
                  ? item?.QuestionDropdownListMembers?.filter(
                    (item) => item?.toLowerCase() !== "none"
                  )
                  : [],
              loading: false,
              savingText: false,
            };
          });

          setQuestionList(updated_data);
        }
      }
    });
  }

  function ToggleAccordion(e) {
    var parent = e.target.closest(".border-item");

    var panel = parent.querySelector(".panel");

    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  const SubmitQuestions = async (data_to_send, action, isDropdown) => {
    await SubmitQuestionaire(authTokens, "POST", {
      ObjectId: 0,
      QuestionnaireTypeEnum: 8,
      QuestionList: JSON.stringify(data_to_send),
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (response?.Success) {
        setLoadingMulticheckbox(false);

        CallToast("Updated successfully", false, false, "v2style");
        updateAccessKeyInLocalStorage(response?.Message)
        
        if (isDropdown) {
          // getPageData(response?.Success);
        } else {
          getPageData();
        }

        // refreshMenuItems();
        if (action) {
          if (action?.type === "SingleText") {
            let updated_data = [...questionList];
            updated_data[action.questionIndex].savingText = false;
            setQuestionList(updated_data);
            let panel = action.parentElement.querySelector(".panel");
            panel.style.maxHeight = null;
            let updated_text = [...tempText];
            updated_text[action.questionIndex] = "";
            setTempText(updated_text);
          }
          if (action?.type === "MultiCheckBox") {
            let panel = action.parentElement.querySelector(".panel");
            panel.style.maxHeight = null;
          }
        }
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };

  return (
    <>
      {mainPageLoading ? (
        <CDSAmainpageloader />
      ) : (
        <div className="cdsa-wrapper"
          style={{
            pointerEvents: viewOnlyAttackSurface() ? "none" : "all"
          }}

        >
          {/* Attack Simulation */}
          <div className="font-weight-bold mt-3 mb-2">Security Operations</div>
          <div className="bg-grey radius-8 border-parent">
            {questionList.map((question, questionIndex) => {
              return (
                <div
                  className="d-flex flex-column border-item"
                  onClick={(e) => {
                    if (!viewOnlyAccess("AccessState_AttackSurface")) {
                      // goTo(vulnerbility.name);
                      ToggleAccordion(e);

                      // Auto fill value in Input field
                      if (
                        question?.AnswerText &&
                        question?.QuestionTypeText === "SingleText"
                      ) {
                        let updated_data = [...tempText];
                        updated_data[questionIndex] = question?.AnswerText;
                        setTempText(updated_data);
                      }
                      // Add focus in the Input Field
                      if (question?.QuestionTypeText === "SingleText") {
                        console.log("clicked question");
                        var parent = e.target.closest(".border-item");
                        console.log("parent", parent);

                        var panel = parent.querySelector(".panel");

                        panel
                          .getElementsByClassName("transparent-input")[0]
                          .focus();
                      }
                    }
                  }}
                >
                  <div className={`d-flex align-items-center justify-content-between w-100  px-4  py-3 bg-hover-row ${viewOnlyAccess("AccessState_AttackSurface") ? "cursor-default" : 'pointer'
                    } `}>
                    <div
                      className="d-flex flex-column "
                      style={{ width: "75%" }}
                    >
                      <div className="d-flex align-items-baseline ">
                        <img
                          alt=""
                          src="/images/attack-surface/yellow-dot.svg"
                          className="mr-1"
                          style={{
                            visibility: `${question?.AnswerId === 0 ? "visible" : "hidden"
                              }`,
                          }}
                        />

                        <p className="f-500 m-0">{question?.QuestionText}</p>
                      </div>
                      <p className="f-12 f-darkgrey ml-2">
                        {question?.QuestionTypeText ===
                          "MultiSelectCheckBox" && (
                            <span className="ml-1">
                              {question?.AnswerText}
                              <span style={{ color: "transparent" }}>..</span>
                            </span>
                          )}
                        {question?.QuestionTypeText === "SingleText" && (
                          <>
                            {question?.AnswerText && (
                              <span className="ml-1">
                                {question?.AnswerText}
                                <span className={` ml-2 ${viewOnlyAccess("AccessState_AttackSurface") ? 'f-darkgrey' : 'f-blue'} `}>
                                  {
                                    viewOnlyAccess("AccessState_AttackSurface") &&
                                    <img
                                    style={{
                                      marginRight: '4px',
                                      marginBottom: '2px'
                                    }}
                                      alt=""
                                      src="/images/attack-surface/small-lock.svg"
                                    />
                                  }
                                  Change
                                </span>
                              </span>
                            )}
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      {/* <img
                        src="/images/attack-surface/chevron-up.svg"
                        className="arrow-up"
                      /> */}
                      {(question?.QuestionTypeText === "SingleText" ||
                        question?.QuestionTypeText ===
                        "MultiSelectCheckBox") && (
                          !viewOnlyAccess("AccessState_AttackSurface") &&
                          <img
                            src="/images/attack-surface/chevron-down.svg"
                            className="arrow-down"
                            alt=""
                          />
                        )}
                      {question?.QuestionTypeText === "DropdownList" && (
                        viewOnlyAccess("AccessState_AttackSurface") ?
                          <div className="f-darkgrey f-500 mx-3 important-width">
                            {question?.selectedDropdownValue?.category ?? "No Selected"}
                          </div>
                          :
                          <CompanySettingsDropdown
                            fieldId={"id"}
                            fieldValue={"category"}
                            selected={question.selectedDropdownValue}
                            placeholder="Select"
                            //  showid={true}
                            onClick={(id, val) => {
                              //   To send Data to API
                              let data_to_send = [...originalData];
                              data_to_send[
                                questionIndex
                              ].AnswserSelectedDropdownItems = [val];
                              data_to_send[questionIndex].QuestionStatus = 3;
                              data_to_send[questionIndex].QuestionStatusText =
                                "Answered";
                              data_to_send[questionIndex].AnswerText = val;
                              setOriginalData(data_to_send);
                              SubmitQuestions(data_to_send, null, true);

                              // To Update the drop down value
                              let updated_data = [...questionList];
                              updated_data[questionIndex].selectedDropdownValue =
                              {
                                id,
                                category: val,
                              };
                              updated_data[questionIndex].AnswerId = 123;

                              setQuestionList(updated_data);
                            }}
                            data={question?.dropDowndata}
                          />
                      )}
                    </div>
                  </div>
                  <div className="panel" onClick={(e) => e.stopPropagation()}>
                    {/* for Input Box */}
                    {question?.QuestionTypeText === "SingleText" && (
                      viewOnlyAccess("AccessState_AttackSurface") ?
                        <div className="f-darkgrey f-500 mx-3 important-width">
                          {tempText[questionIndex] ?? "No Entered"}
                        </div>
                        :
                        <div
                          className="d-flex align-items-center input-name-field ml-5 mb-3 mt-2"
                          style={{ width: "42%" }}
                        >
                          <input
                            type="text"
                            className="transparent-input w-100 pr-3"
                            placeholder="Enter Full Name"
                            value={tempText[questionIndex]}
                            onChange={(e) => {
                              let updated_data = [...tempText];
                              updated_data[questionIndex] = e.target.value;
                              setTempText(updated_data);
                            }}
                            autoFocus
                          />
                          <Button className="hg-blue-btn d-flex align-items-center">
                            <>
                              {question?.savingText && <WhiteLoader width={12} />}
                            </>
                            <div
                              className="d-flex align-items-center"
                              onClick={(e) => {
                                if (!tempText[questionIndex]) return;
                                let parent = e.target.closest(".border-item");
                                let updated_data = [...questionList];
                                updated_data[questionIndex].savingText = true;
                                setQuestionList(updated_data);
                                //   To send Data to API
                                let data_to_send = [...originalData];

                                data_to_send[questionIndex]["AnswerText"] =
                                  tempText[questionIndex];

                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";
                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send, {
                                  type: "SingleText",
                                  parentElement: parent,
                                  questionIndex,
                                });
                              }}
                            >
                              Save
                            </div>
                          </Button>
                        </div>
                    )}
                    {/* For Multi Select Check Box */}
                    {question?.QuestionTypeText === "MultiSelectCheckBox" && (
                      viewOnlyAccess("AccessState_AttackSurface") ?
                        <div className="f-darkgrey f-500 mx-3 important-width">
                          {questionList[questionIndex][
                            "handleMultiCheckbox"
                          ]?.length ===
                            questionList[questionIndex]["checkBoxList"]
                              ?.length ?? "No Selected"}
                        </div>
                        :
                        <>
                          <div className="f-12 f-grey ml-4 mb-2">Choose</div>
                          <div className="d-flex align-items-center ml-4 mb-2">
                            <div className="mb-3">
                              <Form.Group
                                controlId={"check" + 98}
                                className="round-checkbox blue-checkbox checkboxstyle mb-2 radio-shadow"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + 677}
                                  label=""
                                  checked={
                                    questionList[questionIndex][
                                      "handleMultiCheckbox"
                                    ]?.length ===
                                    questionList[questionIndex]["checkBoxList"]
                                      ?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let updated_data = [...questionList];
                                      updated_data[questionIndex][
                                        "handleMultiCheckbox"
                                      ] = [
                                          ...updated_data[questionIndex][
                                          "checkBoxList"
                                          ],
                                        ];

                                      setQuestionList(updated_data);
                                      // for api to send
                                      let orginial = [...originalData];

                                      orginial[questionIndex]["AnswerText"] =
                                        updated_data[questionIndex][
                                          "checkBoxList"
                                        ].join(",");

                                      orginial[questionIndex].QuestionStatus = 3;
                                      orginial[questionIndex].QuestionStatusText =
                                        "Answered";

                                      setDummyData(orginial);
                                    } else {
                                      let updated_data = [...questionList];
                                      updated_data[questionIndex][
                                        "handleMultiCheckbox"
                                      ] = [];
                                      setQuestionList(updated_data);
                                      // for api to send
                                      let orginial = [...originalData];
                                      orginial[questionIndex]["AnswerText"] =
                                        null;
                                      orginial[questionIndex].QuestionStatus = 3;
                                      orginial[questionIndex].QuestionStatusText =
                                        "Answered";
                                      setDummyData(orginial);
                                    }
                                  }}
                                // defaultChecked={elmt.StripeId}
                                />
                              </Form.Group>
                            </div>

                            <div className="ml-3 f-500">All </div>
                          </div>

                          {question?.checkBoxList?.map((item, index) => {
                            return (
                              <div className="d-flex align-items-center ml-5 mb-2">
                                <div className="mb-3">
                                  <Form.Group
                                    controlId={"check" + index}
                                    className="round-checkbox blue-checkbox checkboxstyle mb-2 radio-shadow"
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      name={"check" + index}
                                      label=""
                                      checked={
                                        questionList[questionIndex][
                                          "handleMultiCheckbox"
                                        ]?.indexOf(item) > -1
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          let updated_data = [...questionList];
                                          updated_data[questionIndex][
                                            "handleMultiCheckbox"
                                          ].push(item);

                                          setQuestionList(updated_data);

                                          // send API
                                          let original = [...originalData];

                                          original[questionIndex]["AnswerText"] =
                                            updated_data[questionIndex][
                                              "handleMultiCheckbox"
                                            ].join(",");
                                          original[
                                            questionIndex
                                          ].QuestionStatus = 3;
                                          original[
                                            questionIndex
                                          ].QuestionStatusText = "Answered";

                                          setDummyData(original);
                                        } else {
                                          let updated_data = [...questionList];
                                          let index =
                                            updated_data[questionIndex][
                                              "handleMultiCheckbox"
                                            ]?.indexOf(item);

                                          updated_data[questionIndex][
                                            "handleMultiCheckbox"
                                          ].splice(index, 1);
                                          setQuestionList(updated_data);

                                          // to send API

                                          let original = [...originalData];

                                          original[questionIndex]["AnswerText"] =
                                            updated_data[questionIndex][
                                              "handleMultiCheckbox"
                                            ].join(",");
                                          original[
                                            questionIndex
                                          ].QuestionStatus = 3;
                                          original[
                                            questionIndex
                                          ].QuestionStatusText = "Answered";

                                          setDummyData(original);
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="ml-3 f-500">{item} </div>
                              </div>
                            );
                          })}
                          <div className="d-flex align-items-center ml-4 mb-2">
                            <div className="mb-3">
                              <Form.Group
                                controlId={"check" + 54}
                                className="round-checkbox blue-checkbox checkboxstyle mb-2 radio-shadow"
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"check" + 65}
                                  label=""
                                  checked={
                                    questionList[questionIndex][
                                      "handleMultiCheckbox"
                                    ]?.length === 0
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let updated_data = [...questionList];
                                      updated_data[questionIndex][
                                        "handleMultiCheckbox"
                                      ] = [];
                                      setQuestionList(updated_data);
                                      // for api to send
                                      let orginial = [...originalData];
                                      orginial[questionIndex]["AnswerText"] =
                                        null;

                                      orginial[questionIndex].QuestionStatus = 3;
                                      orginial[questionIndex].QuestionStatusText =
                                        "Answered";

                                      setDummyData(orginial);
                                    }
                                  }}
                                />
                              </Form.Group>
                            </div>

                            <div className="ml-3 f-500">None </div>
                          </div>

                          <div className="d-flex align-items-center ml-4 my-3">
                            <Button
                              className="hg-blue-btn"
                              onClick={(e) => {
                                let parent = e.target.closest(".border-item");
                                setLoadingMulticheckbox(true);
                                SubmitQuestions(dummyData, {
                                  type: "MultiCheckBox",
                                  parentElement: parent,
                                  questionIndex,
                                });
                              }}
                            >
                              {loadingMulticheckbox ? (
                                <span className="d-flex align-items-center">
                                  <WhiteLoader width={12} />
                                  Save
                                </span>
                              ) : (
                                "Save"
                              )}
                            </Button>
                            <div
                              className="f-grey f-500 ml-3 pointer"
                              onClick={() => {
                                setReloadingData(true);
                                getPageData();
                              }}
                            >
                              {reloadingData ? "Cancelling.." : "Cancel"}
                            </div>
                          </div>
                        </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* Reset button */}

          <div
            className={`f-grey  mt-4 ml-2 ${viewOnlyAccess("AccessState_AttackSurface") ? 'cursor-default' : 'pointer'} `}
            onClick={() => {
              if (!viewOnlyAccess("AccessState_AttackSurface")) {
                setShowResetModal(true);
              }
            }}
          >
            {
              viewOnlyAccess("AccessState_AttackSurface") ?
                <img
                  alt=''
                  className='lockerDarkGreyImage mr-2 mb-1'
                  src='/images/settings-v2/locker-grey.svg'
                />
                :
                <img
                  alt=""
                  src="/images/attack-surface/loader-icon.svg"
                  className="mr-2"
                />
            }
            Reset answers
          </div>
        </div>
      )}
      <AttackSurfaceResetModal
        refreshData={getPageData}
        refreshMenuItems={refreshMenuItems}
        resetPageEnum={269}
        optionEnum1={22}
        show={showResetModal}
        hideModal={() => {
          setShowResetModal(false);
        }}
      />
    </>
  );
};

export default SecurityOperations;
