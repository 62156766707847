import React, { useState } from "react";
import { Row, Col, Modal, Button, Spinner } from "react-bootstrap";
import { getRequestOptions } from "../../../Utils/commonApi";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { useAuth } from "../../context/Auth";
import {
  CallToast,
  RightSideToast,
} from "../../PriceCalculator/modals/CustomToast";
// import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./rewards.scss";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";

const RewardsInvitationModal = ({ show, hideModal, referralLink }) => {
  const { authTokens } = useAuth();
  const [inviteEmail, setInviteEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const [sendDisabled, setSendDisabled] = useState(true);

  async function handleSendInvite(inviteEmail) {
    setSending(true);

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/SendReferral`,
      getRequestOptions(authTokens, "POST", {
        EmailAddress: inviteEmail,
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
    ).then((response) => response.json());
    if (data.mr.Success) {
      updateAccessKeyInLocalStorage(data?.mr?.Message)
    
      setSending(false);
      setSent(true);
      setInviteEmail("");
      setTimeout(() => {
        setSent(false);
        setSendDisabled(true);
      }, 1500);

      TrackUser("Invite Sent");
      // CallToast("Invitation sent successfully", false, false, "v2style");
      RightSideToast(
        5,
        `Thanks for sending invitation, we have credited to your Pro Services account.`
      );
    } else {
      CallToast("Invite failed", false, false, "v2style");
    }
  }

  return (
    <Modal
      // show={true}
      show={show}
      dialogClassName="rewards-invitation-modal"
      className=" "
      aria-labelledby="rewards-invitation-modal"
      centered
      onHide={hideModal}
      //   scrollable
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          Invite Member
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="item-wrapper">
              <div className="label-input mt-2 w-100">Invite link</div>
              <div className="d-flex align-items-center w-100">
                <div className="d-flex align-items-center field-wrapper">
                  <div className="link-text p-2">{referralLink}</div>
                  {/* <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={post}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      setPost({ id, category: val });
                    }}
                    data={[
                      { id: 12, category: "CEO" },
                      { id: 112, category: "CTO" },
                      { id: 113, category: "CFO" },
                    ]}
                  /> */}
                </div>
                <Button
                  className="d-flex align-items-center copy-link-btn"
                  style={{ minWidth: "105px" }}
                  onClick={() => {
                    TrackUser("Invite link copied");
                    navigator.clipboard.writeText(referralLink);
                    CallToast(
                      '<img src="/images/settings-v2/copy-link.svg" className="mr-2" />&nbsp;&nbsp;Link Copied &nbsp; &nbsp;',
                      false,
                      false,
                      "v2style"
                    );
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/copy-link.svg"
                    className="mr-2"
                  />
                  <div>Copy link</div>
                </Button>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div
              className="email-item-wrapper mt-4 mb-3"
              style={{ width: "98%", marginLeft: "5px" }}
            >
              <div className="label-input mt-3">Invite by email</div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center email-field-wrapper ">
                  <input
                    type="email"
                    className="email-input p-2"
                    placeholder="Enter Email"
                    value={inviteEmail}
                    onChange={(e) => {
                      const re =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                      if (!re.test(e.target.value)) {
                        setSendDisabled(true);
                      } else {
                        setSendDisabled(false);
                      }

                      setInviteEmail(e.target.value);
                    }}
                  />
                  {/* <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={post}
                    placeholder="Choose..."
                    //  showid={true}
                    onClick={(id, val) => {
                      setPost({ id, category: val });
                    }}
                    data={[
                      { id: 12, category: "CEO" },
                      { id: 112, category: "CTO" },
                      { id: 113, category: "CFO" },
                    ]}
                  /> */}
                </div>
                <Button
                  className={`d-flex align-items-center copy-link-btn  ${
                    sendDisabled ? "disabled-btn" : ""
                  }  ${sending && "sending-btn"} ${sent && "sent-btn"}   `}
                  disabled={sending || sent}
                  onClick={() => {
                    const re =
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (!inviteEmail || !re.test(inviteEmail)) {
                      CallToast(
                        "Please enter a valid email",
                        false,
                        false,
                        "v2style"
                      );
                      return false;
                    }
                    handleSendInvite(inviteEmail);
                  }}
                >
                  {!sending && !sent && <div>Send Invite</div>}
                  {sending && (
                    <div>
                      <span>
                        <Spinner
                          size="sm"
                          className="mr8px"
                          animation="border"
                        />{" "}
                        Sending...
                      </span>
                    </div>
                  )}
                  {sent && <div>Sent!</div>}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default RewardsInvitationModal;
