import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import SkipModal from "../SkipModal/index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const IntegrationsModal = (props) => {
  const { authTokens } = useAuth();
  const [integrationsInfo, setIntegrationsInfo] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const [formState, setFormState] = useState(0);
  const [skip, isSkip] = useState(false);
  const [showChecked, setShowChecked] = useState(false);

  useEffect(() => {
    getIntegrationsInfo(1);
  }, []);

  async function getIntegrationsInfo(page_number) {
    if (page_number > integrationsInfo.PageCount) {
      setShowModal(false);
    } else {
      const integrations_info = await getAPIData("146", authTokens, {
        // OptionEnum1: page_number,
      });

      setIntegrationsInfo(integrations_info);
    }
  }

  const onCloseHandler = () => {
    isSkip(true);
  };

  if (skip) {
    return (
      <SkipModal
        show={showModal}
        onHide={() => setShowModal(false)}
        isChecked={showChecked}
      />
    );
  } else {
    return integrationsInfo?.PageContent?.length ? (
      <Modal
        show={showModal}
        dialogClassName="modal-50w"
        className="integrationModal centerModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        onHide={() => onCloseHandler()}
      >
        <Modal.Header className="text-center">
          <h1 className="w-100 mb-0">
            {formState == 0
              ? integrationsInfo.PageContent[0]?.PageTitle
              : formState == 1
              ? integrationsInfo.PageContent[1]?.PageTitle
              : formState == 2
              ? integrationsInfo.PageContent[2]?.PageTitle
              : formState == 3
              ? integrationsInfo.PageContent[3]?.PageTitle
              : formState == 4
              ? integrationsInfo.PageContent[4]?.PageTitle
              : formState == 5
              ? integrationsInfo.PageContent[5]?.PageTitle
              : ""}
          </h1>
          <span className="cursor-pointer" onClick={() => onCloseHandler()}>
            <img src="/images/icons/close.svg" alt="" />
          </span>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <div>
            {formState == 0 ? (
              <>
                <LazyLoadImage
                  effect="blur"
                  className="imageHeight"
                  src={`/images/onBoardingModal1/${integrationsInfo.PageContent[0].Image1URL}`}
                />
                <h1>{integrationsInfo.PageContent[0].TextHeading}</h1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: integrationsInfo.PageContent[0].TextHtml,
                  }}
                />
              </>
            ) : formState == 1 ? (
              <>
                <LazyLoadImage
                  effect="blur"
                  className="imageHeight"
                  src={`/images/onBoardingModal1/2_Mobile.svg`}
                />
                <h1>{integrationsInfo.PageContent[1].TextHeading}</h1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: integrationsInfo.PageContent[1].TextHtml,
                  }}
                />
                <div className="m-0 playStoreButton" style={{ width: "570px" }}>
                  <img src="/images/onBoardingModal1/appStore.svg" />
                  <img
                    src="/images/onBoardingModal1/googlePlay.svg"
                    style={{ paddingLeft: "23px" }}
                  />
                </div>
              </>
            ) : formState == 2 ? (
              <>
                <LazyLoadImage
                  effect="blur"
                  className=" imageHeight"
                  src={`/images/onBoardingModal1/3_Compliance.svg`}
                />
                <h1>{integrationsInfo.PageContent[2].TextHeading}</h1>
                <span
                  style={{ listStyleType: "circle" }}
                  dangerouslySetInnerHTML={{
                    __html: integrationsInfo.PageContent[2].TextHtml.replace(
                      "<ul>",
                      "<ul style='list-style-type:disc;margin-left:26px'>"
                    ),
                  }}
                />
                <img className="img2" src={integrationsInfo.Image2URL} alt="" />
              </>
            ) : formState == 3 ? (
              <>
                <LazyLoadImage
                  effect="blur"
                  className="imageHeight"
                  src={`/images/onBoardingModal1/4_Integration.svg`}
                />
                <h1>{integrationsInfo.PageContent[3].TextHeading}</h1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: integrationsInfo.PageContent[3].TextHtml,
                  }}
                />
              </>
            ) : formState == 4 ? (
              <>
                <LazyLoadImage
                  effect="blur"
                  className="mainImg"
                  src={`/images/onBoardingModal1/5_Rewards.svg`}
                />
                <h1>{integrationsInfo.PageContent[4].TextHeading}</h1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: integrationsInfo.PageContent[4].TextHtml.replaceAll(
                      "<ul>",
                      "<ul style='list-style-type:disc;margin-left:26px'>"
                    ),
                  }}
                />
              </>
            ) : formState == 5 ? (
              <>
                <LazyLoadImage
                  effect="blur"
                  className="imageHeight"
                  src={`/images/onBoardingModal1/6_Pricing.svg`}
                />
                <h1>{integrationsInfo.PageContent[5].TextHeading}</h1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: integrationsInfo.PageContent[5].TextHtml,
                  }}
                />
                <img className="img2" src={integrationsInfo.Image2URL} alt="" />
              </>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className="m-0" style={{ justifyContent: "space-between" }}>
            <Col md={4} className="p-0">
              <p
                className="cursor-pointer pt-3"
                onClick={() => setFormState(formState - 1)}
              >
                {formState == 0 ? "" : "Back"}
              </p>
            </Col>
            <Col md={4} className="p-0">
              <p className="text-center pt-3">{formState + 1 + "/" + 6}</p>
            </Col>
            <Col md={4} className="p-0">
              {formState == 5 ? (
                <Button
                  variant="primary"
                  type="submit"
                  className="float-right"
                  onClick={() => {
                    setShowModal(true);
                    isSkip(true);
                    setShowChecked(true);
                  }}
                >
                  Get Started
                </Button>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  className="float-right"
                  onClick={() => {
                    setFormState(formState + 1);
                  }}
                >
                  Next
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    ) : (
      <></>
    );
  }
};

export default IntegrationsModal;
