import React from "react";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { Button, Modal, } from "react-bootstrap";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import "../membersAccess/membersAccess.scss"

const LogoutAllDevicesModal = ({ show, hideModal, userId, userSettingsId, isFromMyProfile, passedOptionEnum99 }) => {
    const { authTokens } = useAuth();

    async function logoutAllSessions() {
        await getAPIData(853, authTokens, isFromMyProfile ? {
            optionStrEnum1: userId,
            optionEnum1: userSettingsId
        } : {
            optionEnum99: passedOptionEnum99 ? passedOptionEnum99 : 42,
            optionStrEnum1: userId,
            optionEnum1: userSettingsId
        })
            .then((result) => {
                if (result.Success) {
                    CallToast("All sessions logged out successfully", false, false, "v2style");
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
            })
            .catch((err) => {
                console.log(err);
                CallToast("Something went wrong", false, false, "v2style");
            });
    }

    return (
        <Modal
            show={show}
            className="logoutAllMemberDevicesModal-container"
            dialogClassName="logout-all-member-devices-modal "
            aria-labelledby=" "
            onHide={hideModal}
            centered
            scrollable
        >
            <Modal.Header className="logoutAllMemberDevicesModal-header">
                <div
                    className="modalTitle"
                >
                    Logout all devices
                </div>
            </Modal.Header>
            <Modal.Body className="logoutAllMemberDevicesModal-body hide-scrollbar">
                <div className="modalContent">
                    You wil be logout from all devices including current session. You may need to login again
                </div>
            </Modal.Body>
            <Modal.Footer className="logoutAllMemberDevicesModal-footer">
                <div className="modal-footer-block">
                    <Button
                        className="modalCancelButton cursor-pointer"
                        onClick={() => {
                            hideModal();
                        }}>
                        Cancel
                    </Button>
                    <Button
                        className="modalLogoutButton cursor-pointer"
                        onClick={() => {
                            logoutAllSessions();
                        }}>
                        Logout
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default LogoutAllDevicesModal;
